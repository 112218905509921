/*
 ------------------ Shared ------------------
*/

.filterPanel > div {
  padding: 0 !important;
}

.errorSpacingBottom input {
  margin-bottom: -20px;
}

.colorButton {
  div svg {
    height: 26px !important;
  }
}

.colorGrid {
  width: 320px;
}

.fieldDescription {
  color: $regularColor !important;
  width: 290px;
  padding-left: 40px;
  margin: 5px 0;
}

.squaredBorder {
  border-radius: 0% !important;
}

.optionsListItem .optionsHolder span svg {
  padding-top: 5px !important;
}

.optionsContainer {
  .optionsList {
    margin: 0;
  }

  .optionsListItem {
    &.disabled {
      .optionsHolder {
        span {
          color: rgba(0, 0, 0, 0.38);
        }

        svg {
          fill: rgba(0, 0, 0, 0.38);
        }
      }
    }
    &:last-child {
      border-bottom: 1px solid $lineColor;
    }

    .optionsHolder {
      & span:first-child {
        width: 24px;
        margin-right: 16px;
      }
    }
  }

  .favoriteFieldWrapper {
    label > span + span {
      color: $paymentHubsColor;
    }
  }

  .favoriteButton {
    stroke: none !important;
    margin-left: 16px;
  }
}

.detailPanelHolder {
  .giftCardDetail {
    padding-bottom: 10px;
    a {
      font-size: 13px !important;
    }
  }
  > .optionsList {
    .optionsHolder {
      & span:first-child {
        width: 24px;
        margin-right: 16px;
      }

      & .optionsAnchor {
        text-decoration: none;
        color: inherit;
      }
    }
  }

  .customerReviewDetails {
    cursor: default;

    .platform {
      display: flex;
      align-items: center;
      column-gap: 15px;
    }

    .title {
      padding-top: 10px;

      h1 {
        margin-bottom: 0 !important;
      }
    }

    .rating {
      padding-top: 5px;
      padding-bottom: 20px
    }

    .date {
      font-size: 8px;
      padding-top: 15px;
    }

    .reviewContainer {
      div {
        position: relative;
        background: #EFEFF2;
        border-radius: 10px 10px 10px 0px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
        z-index: 950;

        p {
          margin-top: 0px;
          font-size: 12px;
          font-weight: bold;
          z-index: 950;
        }

        span {
          font-size: 10px;
          letter-spacing: -0.12px;
          z-index: 950;
        }
      }


      div:before {
        content: '';
        bottom: 0;
        left: -48px;
        position: absolute;
        height: 24px;
        width: 48px;
        background-color: transparent;
        border-bottom-right-radius: 48px;
        box-shadow: #EFEFF2 24px 0px 0px 0px;
        z-index: 900;
      }
    }

    em {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: -0.12px;
      color: #000000DE;
    }

    .responseContainer {
      padding-top: 15px;

      .response {

        div {
          position: relative;
          background: #1FC4B3;
          border-radius: 10px 10px 0px 10px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 20px;
          z-index: 950;
          color: #FFFFFF;

          p {
            margin-top: 0px;
            margin-bottom: 12px;
            font-size: 12px;
            line-height: 15px;
            font-weight: bold;
            z-index: 950;
          }

          span {
            font-size: 10px;
            letter-spacing: -0.12px;
            line-height: 12px;
            z-index: 950;
          }
        }


        div:after {
          content: '';
          bottom: 0;
          right: -48px;
          position: absolute;
          height: 24px;
          width: 48px;
          background-color: transparent;
          border-bottom-left-radius: 48px;
          box-shadow: #1FC4B3 -24px 0px 0px 0px;
          z-index: 900;
        }

      }

      .responseHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 12px;
          line-height: 36px;
          color: #1FC4B3;
          cursor: pointer;
        }
      }

      .editResponse {
        padding-top: 20px;
        font-weight: bold;
        font-size: 12px;
        color: #1FC4B3;
        display: flex;
        align-items: center;
        column-gap: 10px;
        cursor: pointer;
      }

      p {
        text-align: left;
        font-size: 10px;
        line-height: 36px;
        font-weight: bold;
        letter-spacing: 0px;
        margin-bottom: 0;
      }

      .responseTextField {
        div {
          font-size: 10px !important;
        }

        .textFieldHolder {
          height: 156px !important;
          border: 1px solid #E0E1E1;
        }
      }

      .quickReplies {
        margin-bottom: 40px;
        .category {
          display: flex;
          align-items: center;
          padding-top: 20px;
          .categoryTitle {
            margin-top: 18px;
            margin-left: 10px;
          }
        }
        .reply {
          padding: 18px;
          background: #EFEFF2;
          border-radius: 10px;
          font-size: 10px;
          letter-spacing: -0.12px;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &:hover {
            background-color: #1FC4B3;
            cursor: pointer;
            color: #FFFFFF;
          }
        }

        .activeReply {
          background-color: #1FC4B3;
          color: #FFFFFF;
        }
      }
    }
  }
}

.categoryNameWrapper {
  font-family: $primaryFontFamily !important;
  font-weight: 600;
  display: flex;

  p {
    font-family: $primaryFontFamily !important;
    font-weight: 600;
    line-height: 1.5em !important;
  }

  .bubble {
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 15px !important;
    padding-top: 5px !important;
  }
}

/*
 ------------------ Items ------------------
*/

.showValue {
  display: flex;
  align-items: center;
  padding-left: 8px;

  .categoryColor {
    display: flex !important;
    margin-right: 5px;
    align-items: center;
  }

  .categoryName {
    padding-right: 5px;
  }

  svg {
    width: 18px;
  }
}

.inventoryItems {

  #masterList.masterListContainer {
    overflow-y: auto !important;
    height: auto;
    flex: 1 1 auto;

    #masterList {
      overflow-x: auto;
      &, .infiniteListAutoSizer, .ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
        height: auto !important;
      }

    }

    #masterList.withPagination {
      padding-bottom: 76px;

      .paginationHolder {
        height: 72px;
        position: fixed;
        bottom: 0;
        background: #fff;
      }
    }

    &.scroll-x {
      height: auto;
    }

    .ReactVirtualized__Grid__innerScrollContainer {
      height: auto !important;
      max-height: none !important;
    }

    .ReactVirtualized__Table__row {
      top: 0 !important;
      position: relative !important;
    }

  }

  .bottomBarActivated #masterList.masterListContainer #masterList.withPagination .paginationHolder {
    bottom: 130px;

    @media screen and (max-width: $sizeTabletMax) {
      bottom: 210px;
    }
  }


  h3 {
    font-size: 13px;
    font-weight: 400;
  }

  .itemsTabs {
    width: 400px;

    @media screen and (max-width: $sizeDesktopMin) {
      width: auto;
    }

    > div {
      background: white !important;
    }
  }

  .tab {
    background: white !important;
  }

  .searchAccessory {
    .filterItemsButton {
      display: flex;
      align-items: center;
    }
  }

  .detail {
    min-width: 980px !important;
  }

  .filter {
    overflow-y: auto;
  }

  .filterPanel,
  fieldset.category {
    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }


  .categorySelect {
    margin-left: 20px;
  }

  .selectedListRow {
    svg {
      padding-left: 0;
    }
  }

  .lowStockAlert {
    width: 24px;
    margin-right: 8px;
  }

  .stockAlert {
    left: 2px;
    margin-right: 15px;
  }

  .detailPanelHolderButtons {
    min-width: 980px !important;
    justify-content: flex-end;
    padding-right: 50px !important;
  }

  .itemsCheckbox {
    svg:first-of-type {
      //Unselected checkbox only, selected is label color
      fill: #ffffff !important;
      stroke: $gray !important;
      color: $gray !important;
    }

    svg + svg {
      stroke: none !important;
    }
  }

  .masterListContainer {
    .mobileScrollNote {
      padding-top: 30px;
    }

    > div:nth-child(3) > div {
      @media screen and (max-width: $sizeMobileMax) {
        min-width: 720px !important;
      }
    }
  }

  .itemRow .checkboxColumn {
    @media screen and (max-width: $sizeMobileMax) {
      width: 60px;
    }
  }
}

.archiveItemDescription {
  background-color: $cardBorderColor;
  display: flex;
  margin-top: 50px;
  padding: 30px 20px;
  div:last-child {
    padding: 0px 20px;
    span {
      font-weight: bold;
    }
    a {
      cursor: pointer;
    }
  }
}

.itemThumb {
  border-radius: 0% !important;
}

.infiniteListTable.hideLastRow {
  .ReactVirtualized__Table__row:last-child {
    display: none;
  }
}

.inventoryItems .itemThumbnailPerformance {
  position: relative;
  width: 50px;
  height: 50px;

  .itemThumb {
    width: 50px;
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
    transform: translateZ(0);
  }
}

.itemsDetail {
  width: 95%;

  .textFieldHolder {
    min-height: 48px;

    div > div {
      align-items: center;
      display: flex;
    }
  }

  .optionsContainer {
    width: 65%;
    margin-right: 5%;
  }

  .thumbnailContainer {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0 auto;
  }

  .itemThumbnail {
    margin: 0;
    position: relative;

    .itemThumb {
      width: 100% !important;
      height: auto;
      margin: 0 auto;
      display: block;
      border-radius: 10% !important;
    }

    .existingImageCameraIcon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 10%;
      background-color: rgba(0, 0, 0, 0.541176);
      cursor: pointer;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0%;
      top: 0;
      transition: all 0.5ms;

      svg {
        height: 30px !important;

        path {
          fill: #fff !important;
        }
      }
    }

    .fieldSet {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .itemsDetailThumbnail {
      cursor: pointer;
      position: relative;

      .camera {
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.541176);
        transition: all 0.5ms;
        top: 0;
        left: 0;
      }

      .active {
        display: flex;
      }

      &:hover .camera {
        display: flex;
      }
    }

    &.itemThumbnailDisabled {
      .itemsDetailThumbnail {
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  .uploadImageContainer {
    width: 100%;
    min-width: 200px;
    min-height: 200px;
    height: auto;

    .itemsDetailThumbnail {
      min-height: 200px !important;
    }

    .camera {
      border-radius: 10px !important;
      background-color: transparent !important;

      svg {
        height: 30px !important;
      }
    }
  }

  .deletePriceBtn {
    position: absolute;
    right: -50px;
    top: 29px;
    width: 45px;
    height: 45px;
    padding: 5px;
  }

  .nameInput {
    margin-top: 50px;
    margin-bottom: 0;
  }

  .category {
    width: 100%;
    margin-bottom: 1.5em;
  }

  .detailsHeader {
    margin: 2.6em 0 1.2em;
  }

  .buttonContainer {
    margin-bottom: 3em;
  }

  .status {
    position: relative;
    .bubble {
      position: absolute;
      top: calc(50% - 12px);
      left: -30px;
    }
  }
}

.itemTab {
  margin-bottom: 3em;

  & > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    width: 100%;

    @media screen and (max-width: $sizeTabletMin) {
      flex-direction: column;
    }
  }
}

.detailContent{
  .modifiersContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    .floatRight{
      margin-top: 13px;
    }
  }
}

.detailsCheckBox {
  margin-bottom: 1em;
  align-items: center;
}

.listRow .badge {
  padding: 8px 12px !important;
}

div.tracking {
  left: -20px;
  position: relative;
  font-size: 17px;
  .customToggle {
    margin-left: 20px;
  }
}

div.stock {
  width: 400px;
  margin-bottom: 3em;
  @extend %body;

  .flexRow:not(:last-child) {
    border-bottom: 1px solid $lineColor;
  }

  .flexRow {
    width: 90%;
    margin: 0 auto;

    div.description,
    div.quantity,
    div.alertStock {
      width: 24%;
    }

    .name {
      margin: 14px 0;
      display: block;
    }

    .barcode {
      display: block;
      word-wrap: break-word;
    }

    .textField.quantity {
      width: 90%;
      padding-top: 16px;
    }
  }
}

div.prices {
  .name,
  .barcode,
  .itemPrice,
  .quantityContainer,
  .quantity,
  .stockAlert {
    box-sizing: border-box;
  }

  .name {
    min-width: 200px !important;
    width: 50%;
  }

  .barcode {
    min-width: 200px !important;
    width: 50%;
  }

  .price {
    div:last-of-type {
      text-align: right;
    }
  }

  .textField.stockAlert,
  .quantityContainer {
    margin-left: 0 !important;
    width: 33.33% !important;

    .textFieldHolder {
      input,
      > div > div {
        text-align: center;
      }
    }

    @media screen and (max-width: $sizeMobileMax) {
      .textFieldInfo {
        min-height: 3.5em;
      }
    }
  }

  .textField.itemPrice,
  .quantityContainer,
  .textField.stockAlert {
    width: 50% !important;
  }

  .flexRow .textField {
    padding-left: 0;
    padding-right: 12px;
  }

  .quantityContainer {
    position: relative;

    .quantity {
      padding-right: 0;
    }

    .stockAlertIconContainer {
      position: absolute;
      top: 38px;
      right: 21px;

      @media screen and (max-width: $sizeMobileMax) {
        top: 67px;
        right: initial;
        left: 2px;
      }
    }
  }
}

.importItemsForm {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  padding: 0 !important;

  &.addAccountForm {
    ol li {
      &:before {
        left: -15px !important;
      }
    }

    .dropZoneSmall {
      align-items: baseline;
      height: 95px !important;

      svg {
        transform: translateY(7px);
      }
    }
    .linkLike {
      @extend .linkLike;
      text-decoration: underline;
    }
  }

  strong,
  h3 {
    color: $black;
    font-family: $primaryFontFamily;
    font-weight: 600;
  }

  ol {
    list-style: none;
    counter-reset: numList;
    padding-left: 0;
    margin-bottom: 0;

    li {
      text-align: left;
      padding-left: 25px;
      margin-bottom: 30px;
      display: inline-block;
      width: 259px;
      vertical-align: top;

      p {
        font-size: 13px;
        font-family: $primaryFontFamily;
        font-weight: 500;
        color: $black;
      }

      strong {
        font-size: 16px;
      }

      p, strong {
        padding-left: 20px;
        display: block;
      }

      .log {
        display: inline-flex;
        vertical-align: middle;
        align-items: center;
      }

      .logContainer {
        max-height: 191px;
        overflow-y: scroll;
      }

      .fileStatus {
        line-height: 1.1em;
        min-height: 2.4em;
        font-weight: bold;
        color: $black;
        margin: 4px;
      }

      .progress {
        padding-bottom: 5px;
      }

      &:before {
        counter-increment: numList;
        content: counter(numList);
        float: left;
        position: relative;
        left: -20px;
        text-align: center;
        color: #fff;
        width: 18px;
        height: 18px;
        background: $black;
        border-radius: 999px;
        line-height: 18px;
      }
    }

    li.halfed {
      width: 44%;
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
}

.importItemsForm.fullPage {
  text-align: left;

  ol {
    display: flex;
    justify-content: space-between;
  }
}

.itemsImportDialog {
  .dialogHeader {
    padding: 0 !important;
  }

  .detailClose {
    margin-left: 20px !important;
  }

  .appBar {
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    font-size: 10px !important;

    div:last-child {
      text-align: center;
      color: #000;
    }

    strong {
      font-size: 14px !important;
      color: #000;
    }
  }
}

.itemStockAlert {
  display: inline-block;
  width: 50%;

  &__wrapper {
    margin: 5px 10px;
    width: 200px;
  }

  &__alertHeading {
    @extend %heading2;
    display: flex;

    span {
      align-self: center;
      padding-left: 5px;
    }
  }

  &__stockCol {
    width: 45%;

    &.right {
      text-align: right;
    }

    span {
      display: block;
      margin-bottom: 5px;

      &.barcode {
        color: $secondaryTextColor;
      }
    }
  }
}

/*
 ------------------ Categories ------------------
*/

.inventoryCategories {
  tr.categoryRow {
    td div,
    td span {
      @extend %bodyText;
    }

    td.categoryValue {
      text-align: right !important;
      padding-right: 20px !important;
    }
  }
}

/*
 ------------------ Modifiers ------------------
*/

div.modifiers {
  .name {
    min-width: 210px !important;
  }
}

.modifiersRow {
  td .name,
  td .count {
    @extend %bodyText;
  }

  td.modifierCount {
    width: 140px;
    text-align: right !important;
    margin-right: 16px !important;
  }
}

.modifierRow {

  .removeRow {
    margin-top: 28px;
    width: 45px;
    height: 45px;
    margin-left: 5px;
  }
}

div.prices,
div.modifiers {
  .subHead {
    @extend %detailsHeader;
    border: none !important;
  }

  td.row {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rightTextInput,
  .modifierPrice {
    min-width: 110px !important;
    margin-left: 5px;
  }

  .rightTextInput {
    input {
      text-align: right;
    }
  }
  .modifierPrice {
    input {
      text-align: center;
    }
  }

  div.priceRow {
    border-bottom: 1px solid $lineColor;
    margin-bottom: 20px;
    padding-bottom: 5px;

    .flexRow {
      position: relative;
      gap: 20px;

      .textField {
        padding-right: 0;
      }
    }
  }

  .price {
    width: 130px !important;

    div {
      bottom: 24px;
    }
  }

  .barcode > div {
    bottom: -13px;
  }
}

/*
 ------------------ Discounts ------------------
*/

.inventoryDiscounts {
  tr.discountRow {
    td .name {
      @extend %bodyText;
    }

    td.discountValue {
      text-align: right !important;
      padding-right: 20px !important;
    }
  }

  .detailsContainer .headerDivider {
    margin-top: -30px !important;
  }
}

.discountsDetail {
  .flexRow {
    .textField + .textField {
      padding-left: 0;
    }
  }

  .detailsHeader {
    margin-top: 25px;
  }

  .toggle {
    width: 74px;
    height: 16px;
    margin-top: 36px;
  }
}

.discountType {
  display: flex;
  width: 100px;
  float: right;
  margin-top: 10px;

  > div {
    width: 64px !important;
  }
}

.deleteConfirmation {
  font-family: $primaryFontFamily !important;
  font-weight: 500;

  > div {
    border-radius: 15px !important;
    padding: 15px;

    div {
      border: 0 !important;
    }
  }

  .confirmationText {
    font-family: $primaryFontFamily !important;
    font-weight: 500;
    color: $black;
  }

  .itemsTitle {
    @extend %subheading;
  }
}

.itemsDialog {
  max-width: 1000px !important;

  > div {
    border-radius: 15px !important;
    padding: 15px;

    div {
      border: 0 !important;
    }
  }

  .itemsTitle {
    @extend %subheading;
  }
}

.filterSelect {
  font-family: $primaryFontFamily !important;
  font-weight: 500;
}

.inventoryDiscounts {
  .selectedListRow {
    svg {
      padding-left: 0
    }
  }
}

.picker {
  .textFieldHolder {
    height: 48px;
  }
}

.mergeCategoryForm {
  .mergeCategoryFormContent {
    margin-top: 50px;

    .status {
      align-items: center
    }

    .bubble {
      width: 128px;
      margin-right: 20px;
      margin-left: 15px;
    }

    .categoriesDetail {
      .picker {
          margin-right: 20px;
      }

      .categoryNameField {
          width: 100%;
      }
    }
  }
}

.bottomBarActivated * #masterList {
  margin-bottom: 80px !important;
}

.inventoryBottomBar {

  >:first-child {
    font-weight: bold !important;
    padding-top: 18px !important;
  }

}
