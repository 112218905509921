.pciHolder {
  max-height: 100vh;
  overflow-y: scroll;

  .heading {
    margin-bottom: 35px;
    @extend %subheading;
  }

  .subText {
    @extend %bodyText;
    margin-bottom: 35px;
  }

  .pciSteps {
    background-color: $lightGray;
    border-radius: 5px;
    padding: 14px;
    margin-bottom: 20px;

    .alert, .compliant, .pending {
      @extend %contentLabel;
      color: $errorColor;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .icon {
        margin-right: 5px;
      }
    }

    .pending {
      color: $warningColor;
    }

    .compliant {
      color: $positiveColor;
    }

    .notice {
      @extend %contentLabel;
      margin-bottom: 25px;
    }

    .stepList {
      list-style: none;
      margin: 0 0 25px 0;
      padding: 0;
      display: inline-block;
      width: 45%;

      .item {
        display: flex;
        border-bottom: 1px solid $lineColor;
        margin-bottom: 15px;

        .link {
          display: flex;
          align-items: center;
          text-decoration: none;
          cursor: pointer;

          .file {
            margin-right: 15px;
          }

          .text {
            display: inline-block;
            @extend %contentLabel;
            width: 220px;
          }
        }
      }
    }

    .step {
      @extend %columnLabel;
      margin-bottom: 20px
    }

    .buttonHolder {
      margin-bottom: 35px;
    }

    .subNotice {
      @extend %contentLabel;
      font-weight: lighter !important;
      font-size: 12px !important;
    }
  }

  .columnHolder {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;

    .column {

      .title {
        display: block;
        @extend %columnLabel;
        margin-bottom: 10px;
      }

      .text {
        display: block;
        @extend %contentLabel;
      }

      .gray {
        color: $paymentHubsColorAlternativeColor;
      }

    }
  }

  .learnMore {
    display: inline-block;
    @extend %contentLabel;
    span {
      cursor: pointer;
    }
  }
}

.pciFaq {
    font-family: $primaryFontFamily !important;
    font-weight: 500;

    border-bottom: 1px solid $lineColor;
    padding: 15px;
    cursor: pointer;
    position: relative;

    &:last-child {
      border-bottom: 0;
    }

    > svg {
      width: 20px;
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 1;
    }

    .heading {
      display: flex;
      @extend %bodyText;
      font-weight: bold;
      padding-right: 22px;
      position: relative;
      z-index: 3;

      .icon {
        margin-left: auto;
      }
    }

    .content {
      @extend %contentLabel;
      padding:0 24px 24px 32px;

      table {

        border-collapse: collapse;

        th {
          @extend %bodyText;
        }

        td {
          vertical-align: top;

          :first-child {
            margin-top: 0;
          }

          :last-child {
            margin-bottom: 0;
          }
        }

        tr:not(:last-child) {
          border-bottom: 1px solid $lineColor;
        }

        th, td {
          text-align: left;
          padding: 20px 10px;
        }
      }
    }

}
