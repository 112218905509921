.paymentPortal {
  width: 1585px;
  display: flex;
  border: 1px solid $lineColor;
  border-radius: 5px;

  &Preview {
    user-select: none;
    pointer-events: none;
  }

  .sideContainer {
    background-color: $paymentPageGrey;
    padding-right: 24px;
    display: flex;

    .panel {
      display: flex;
      flex-direction: column;
      background-color: $backgroundColor;
      padding: 50px 25px 35px 25px;

      .merchantLogo {
        img {
          max-width: 150px;
          max-height: 100px;
          height: auto;
          width: auto;
        }
      }

      .list {
        list-style: none;
        margin: 75px 0 0 0;
        padding-left: 0;

        .item {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .active {
          color: $payAnywhereColor;
        }
      }

      .footer {
        margin-top: auto;

        p {
          font-size: 16px;
          line-height: 1.5;
          margin-bottom: 0;

          b {
            font-weight: 800;
          }

          span {
            color: $paymentAddressColor;
          }
        }

        .social {
          display: flex;
          list-style: none;
          margin: 15px 0 0 0;
          padding: 0;

          li {
            margin-right: 15px;
            width: 25px;
            height: 25px;
            cursor: pointer;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .branding {
          margin-top: 40px;
          display: flex;
          align-items: center;

          .img {
            width: 35px;
            height: 22px;
            background-image: url('/images/payanywhere/logoSmall.svg');
            background-size: contain;
          }

          p {
            margin-left: 12px;
            @extend %secondaryFontFamilySemiBold;
            font-size: 10px;
          }
        }
      }
    }
  }

  .main {
    display: flex;
    height: 100%;
    padding: 50px 50px 50px 90px;

    .form {
      margin-right: 80px;
      padding-bottom: 50px;

      .backButton {
        span {
          svg {
            transform: scaleX(-1);
            margin-right: 8px;
            padding-bottom: 2px;
          }
        }
      }
      h3 {
        @extend %heading1a;
        margin-bottom: 10px;
      }
      h4 {
        margin: 0 0 30px 0;
        color: $paymentAddressColor;
        @extend %body;
        font-size: 16px;
      }

      .multiField {
        div {
          padding-right: 10px;
          box-sizing: border-box;

          &:last-child {
            padding-right: 0;
          }
        }

        .half {
          width: 50%;
        }

        .quarter {
          width: 25%;
        }
      }

      .switchContainer {
        display: flex;
        margin-bottom: 20px;

        p {
          margin-left: 10px;
          margin-top: 0;
          @extend %body;
          font-size: 16px;
          padding-top: 16px;
        }
      }

      .tipContainer {
        margin-bottom: 40px;

        .tipLabel {
          @extend %columnLabel;
          padding-left: 15px;
          margin-bottom: 15px;
        }

        .tipBar {
          background-color: $paymentPageGrey;
          border-radius: 24px;

          .tipHolder {
            cursor: pointer;
            box-sizing: border-box;
            display: inline-block;
            padding: 10px 25px;
            border-radius: 24px;
            width: 25%;
            text-align: center;
          }

          .tipActive {
            background-color: black;
            color: $backgroundColor;
          }
        }
      }

      .buttonContainer {
        display: flex;
        justify-content: flex-end;

        button {
          margin-right: 0 !important;
        }
      }
    }

    .detail {
      box-sizing: border-box;
      background-color: $paymentPageGrey;
      padding: 50px;
      display: flex;
      flex-direction: column;

      .heading {
        margin-bottom: 50px;

        h3, p {
          text-align: center;
        }

        h3 {
          @extend %secondaryFontFamilySemiBold;
          font-size: 18px;
          margin-bottom: 15px;
        }

        p {
          margin: 0 0 5px 0;
          font-size: 14px;
          color: $paymentDetailText;
        }
      }

      &List {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      &Item {
        display: flex;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid $lineColor;
        font-size: 14px;

        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }

        .text {
          p {
            margin: 0 0 3px 0;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .pullLeft {
          margin-left: auto;
        }

        .sub {
          color: $paymentDetailText !important;
        }

        .price {
          margin: 0 0 5px auto;
          font-size: 14px;
          color: $paymentDetailText;
        }
      }

      .applePay {
        margin-top: auto;
        text-align: center;

        span {
          cursor: pointer;
          display: inline-block;
          width: 156px;
          height: 52px;
          background-image: url('/images/integrations/apple_pay.png');
          background-size: 156px 52px;
        }
      }
    }
  }
}
