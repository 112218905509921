%addPaymentMethodLink {
  color: $phGradientTwo;
  &:hover {
    cursor: pointer;
  }
}

.customerDetail {

  .ratingContainer {
    margin-bottom: 30px;

    .averageRating {
      display: flex;

      .starContainer {
        display: flex;
        margin-top: 2px;
        flex-direction: column;
        margin-left: auto;

        svg {
          width: 16px !important;
          height: 16px !important;
        }

        span {
          display: inline-block;
        }
      }
    }
  }

  .paymentMethodsContainer {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    background-color: $backgroundColor3;

    a {
      @extend %addPaymentMethodLink;
    }

    .updateSpinner {
      display: block;
      justify-content: center;
      align-items: center;
      position: static;
      height: unset;
      width: unset;
      opacity: 1;
      background-color: unset;
      z-index: unset;
    }

    .cardInfo {
      .expDate {
        margin-top: 5px;
        margin-bottom: 0;
      }

      .cardNumber {
        margin-top: 3px;
        margin-bottom: 0;
      }
    }

    .leftSide {
      display: flex;
      align-items: center;
      gap: 25px;
      padding: 10px 0;
    }

    .deleteIcon {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

    &.loader {
      justify-content: center;
    }
  }

  .cardsWrap {
    a {
      @extend %addPaymentMethodLink;
    }
    .flexContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .textFieldHolder {
    @media screen and (max-width: $sizeMobileMax) {
      max-width: 180px;
    }
  }

  .loyaltyStatus {
    padding: 20px 15px;
    border-radius: 5px;
    background-color: $loyaltyResendGray;

    .loyaltyStatusTitle {
      font-size: 12px;
      margin-bottom: 4px;
    }

    p {
      margin: 0;
      font-size: 10px;
    }
  }

  .rewardResend {
    box-sizing: border-box;
    background-color: $loyaltyResendGray;
    padding: 15px;
    border-radius: 5px;

    div[disabled] {
      pointer-events: none;
      opacity: 0.2;
    }

    .actionContainer {
      display: flex;
      cursor: pointer;
      align-items: center;

      .icon {
        margin-right: 10px;
      }

      .text {
        font-size: 12px;
        font-weight: bold;
      }

    }
  }

}
