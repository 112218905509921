@use 'sass:math';

$drag-handle-width: 9px;
$drag-handle-height: 9px;
$drag-handle-background-colour: rgba(0, 0, 0, 0.2);
$drag-handle-border: 1px solid rgba(255, 255, 255, 0.7);

$drag-bar-size: 6px;

$cropped-area-overlay-color: rgba(0, 0, 0, 0.6);

$mobile-max-width: 768px;
$drag-handle-mobile-width: $drag-handle-width + 8;
$drag-handle-mobile-height: $drag-handle-height + 8;
$drag-bar-mobile-size: $drag-bar-size + 8;

.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;

  &--disabled {
    cursor: inherit;
  }

  &:focus {
    outline: none;
  }

  &__image {
    display: block;
    max-width: 100%;
  }

  &__image-copy {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
  }

  &__crop-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $cropped-area-overlay-color;
  }

  &__crop-selection {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    box-sizing: border-box;
    cursor: move;

    box-shadow: 0 0 0 9999em rgba(195, 178, 178, 0.8);

    .ReactCrop--disabled & {
      cursor: inherit;
    }
    & {
      border: 1px solid transparent;
      border-image-slice: 1;
      border-image-repeat: repeat;
    }

  }

  &__drag-handle {
    position: absolute;
    width: $drag-handle-width;
    height: $drag-handle-height;
    background-color: $drag-handle-background-colour;
    border: $drag-handle-border;
    box-sizing: border-box;

    // This stops the borders disappearing when keyboard
    // nudging.
    outline: 1px solid transparent;
  }

  .ord-nw {
    top: 0;
    left: 0;
    margin-top: -(ceil(math.div($drag-handle-height, 2)));
    margin-left: -(ceil(math.div($drag-handle-width, 2)));
    cursor: nw-resize;
  }

  .ord-n {
    top: 0;
    left: 50%;
    margin-top: -(ceil(math.div($drag-handle-height, 2)));
    margin-left: -(ceil(math.div($drag-handle-width, 2)));
    cursor: n-resize;
  }

  .ord-ne {
    top: 0;
    right: 0;
    margin-top: -(ceil(math.div($drag-handle-height, 2)));
    margin-right: -(ceil(math.div($drag-handle-width, 2)));
    cursor: ne-resize;
  }

  .ord-e {
    top: 50%;
    right: 0;
    margin-top: -(ceil(math.div($drag-handle-height, 2)));
    margin-right: -(ceil(math.div($drag-handle-width, 2)));
    cursor: e-resize;
  }

  .ord-se {
    bottom: 0;
    right: 0;
    margin-bottom: -(ceil(math.div($drag-handle-height, 2)));
    margin-right: -(ceil(math.div($drag-handle-width, 2)));
    cursor: se-resize;
  }

  .ord-s {
    bottom: 0;
    left: 50%;
    margin-bottom: -(ceil(math.div($drag-handle-height, 2)));
    margin-left: -(ceil(math.div($drag-handle-width, 2)));
    cursor: s-resize;
  }

  .ord-sw {
    bottom: 0;
    left: 0;
    margin-bottom: -(ceil(math.div($drag-handle-height, 2)));
    margin-left: -(ceil(math.div($drag-handle-width, 2)));
    cursor: sw-resize;
  }

  .ord-w {
    top: 50%;
    left: 0;
    margin-top: -(ceil(math.div($drag-handle-height, 2)));
    margin-left: -(ceil(math.div($drag-handle-width, 2)));
    cursor: w-resize;
  }

  // Use the same specificity as the ords above but just
  // come after.
  &__disabled &__drag-handle {
    cursor: inherit;
  }

  &__drag-bar {
    position: absolute;

    &.ord-n {
      top: 0;
      left: 0;
      width: 100%;
      height: $drag-bar-size;
      margin-top: -($drag-bar-size - 2);
    }

    &.ord-e {
      right: 0;
      top: 0;
      width: $drag-bar-size;
      height: 100%;
      margin-right: -($drag-bar-size - 2);
    }

    &.ord-s {
      bottom: 0;
      left: 0;
      width: 100%;
      height: $drag-bar-size;
      margin-bottom: -($drag-bar-size - 2);
    }

    &.ord-w {
      top: 0;
      left: 0;
      width: $drag-bar-size;
      height: 100%;
      margin-left: -($drag-bar-size - 2);
    }
  }

  &--new-crop &__drag-bar,
  &--new-crop &__drag-handle,
  &--fixed-aspect &__drag-bar {
    display: none;
  }

  &--fixed-aspect &__drag-handle.ord-n,
  &--fixed-aspect &__drag-handle.ord-e,
  &--fixed-aspect &__drag-handle.ord-s,
  &--fixed-aspect &__drag-handle.ord-w {
    display: none;
  }

  @media (max-width: $mobile-max-width) {
    &__drag-handle {
      width: $drag-handle-mobile-width;
      height: $drag-handle-mobile-height;
    }

    .ord-nw {
      margin-top: -(ceil(math.div($drag-handle-mobile-height, 2)));
      margin-left: -(ceil((math.div($drag-handle-mobile-width, 2))));
    }
    .ord-n {
      margin-top: -(ceil(math.div($drag-handle-mobile-height, 2)));
      margin-left: -(ceil(math.div($drag-handle-mobile-width, 2)));
    }
    .ord-ne {
      margin-top: -(ceil(math.div($drag-handle-mobile-height, 2)));
      margin-right: -(ceil(math.div($drag-handle-mobile-width, 2)));
    }
    .ord-e {
      margin-top: -(ceil(math.div($drag-handle-mobile-height, 2)));
      margin-right: -(ceil(math.div($drag-handle-mobile-width, 2)));
    }
    .ord-se {
      margin-bottom: -(ceil(math.div($drag-handle-mobile-height, 2)));
      margin-right: -(ceil(math.div($drag-handle-mobile-width, 2)));
    }
    .ord-s {
      margin-bottom: -(ceil(math.div($drag-handle-mobile-height, 2)));
      margin-left: -(ceil(math.div($drag-handle-mobile-width, 2)));
    }
    .ord-sw {
      margin-bottom: -(ceil(math.div($drag-handle-mobile-height, 2)));
      margin-left: -(ceil(math.div($drag-handle-mobile-width, 2)));
    }
    .ord-w {
      margin-top: -(ceil(math.div($drag-handle-mobile-height, 2)));
      margin-left: -(ceil(math.div($drag-handle-mobile-width, 2)));
    }

    &__drag-bar.ord-n {
      height: $drag-bar-mobile-size;
      margin-top: -($drag-bar-mobile-size - 2);
    }
    &__drag-bar.ord-e {
      width: $drag-bar-mobile-size;
      margin-right: -($drag-bar-mobile-size - 2);
    }
    &__drag-bar.ord-s {
      height: $drag-bar-mobile-size;
      margin-bottom: -($drag-bar-mobile-size - 2);
    }
    &__drag-bar.ord-w {
      width: $drag-bar-mobile-size;
      margin-left: -($drag-bar-mobile-size - 2);
    }
  }
}

