.invoiceDetail {

  @extend %bodyText;

  a {
    @extend %link;
    font-size: 12px !important;
    color: $paymentHubsColor;
    display: inline-block;
    margin-top: 8px;
  }

  .invoiceInfo {

    @extend %contentLabel;

    h2 {
      @extend %subheadingInvoicing;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }

  .invoiceStatus {
    display: flex;
    align-items: center;

    .DoneIcon, svg {
      height: 22px !important;
      width: 22px !important;
    }

    div {
      margin-left: 18px;

      .statusLabel {
        margin: 0;
        text-transform: capitalize !important;
        font-size: 14px;
        font-weight: 600;
      }

      h4 {
        margin: 0px;
        text-transform: capitalize;
      }

      p {
        margin: 0px;
        font-weight: 500;
        font-size: 12px;
        span {
          text-transform: capitalize;
        }
      }
    }

  }

  .invoiceSeries {
    display: flex;
    align-items: center;
    padding: 30px 20px;

    .title {
      font-size: 30px;
      font-weight: 600;
      margin-left: 10px;
    }

    .DoneIcon, svg {
      height: 40px !important;
      width: 40px !important;
    }

    h2 {
      padding: 0px 20px;
    }
  }

  .failedInvoices {
    font-size: 18px;
    font-weight: bold;
    margin-top: 55px;
    margin-bottom: 10px;

    .warn {
      font-size: 14px;
      padding-top: 5px;
    }
  }

  .summary {
    padding: 30px;
    border: solid 1px $cardBorderColor;
    border-radius: 5px;

    .text { margin-top: 7px}
    .marginBottom { margin-bottom: 20px;}
  }

  .invoiceActions {
    padding: 0px;
    .optionsList{
      margin: 0;
      .optionsListItem{
        padding-top:12px;
        span{
          font-weight: bold;
          svg{
            padding: 0 !important;
          }
          + div{
            margin-bottom: 0;
          }
        }
      }
    }
    .optionsHolder {
      font-size: 12px;
      span{
        line-height: 6px;
        &:first-child {
          margin-right: 24px;
        }
      }

    }

  }


  .invoiceActivityItem {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .invoiceActivityItemComment {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
    }

    .invoiceActivityItemComment p{
      margin: 0;
      display: inline-block;
      max-width: 350px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  .purchaseItem, .paymentMethodItem {

    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 24px 16px;
    margin-bottom: 16px;
    background-color: #F6F8FA;

    .content {
      width: 100%;
      display: flex;
      margin-left: 16px;
      align-items: center;
      justify-content: space-between;
    }

  }

  .editPayment {
    .editPaymentText {
      margin-bottom: 30px
    };

    .notice {
      display: flex;
      border-radius: 20;
      width: 100%;
      padding: 30px 0;
      background-color: '#F6F8FA' !important;
      margin-top: 10px;

      .icon {
        flex: 1;
        padding-left: 25px;
      }

      .text {
        flex: 12;
      }
    }

  }

  .paymentMethodItem {

    svg {
      height: 22px;
      width: 48px;
    }

    a {
      margin-top: 0px;
    }

  }

  .purchaseItem {

    .avatar {
      font-size: 14px
    }

    .contentHolder {
      width: 100%;

      .content {
        width: auto;
        display: flex;
        margin: 0 16px;
        align-items: center;
        justify-content: space-between;

        div {
          margin-bottom: 0;
        }
      }

      .price {
        margin-left: 8px;
      }

    }

  }

  .total {

    div {
      display: flex;
      justify-content: space-between;
    }

    div:last-of-type {
      span {
        font-family: $primaryFontFamily !important;
        font-weight: 600;
      }
    }
  }

  .invoiceStatus {
    @extend %contentLabel;
    margin-top: 28px;
    border-top: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    padding: 28px 0;
    display: flex;
    align-items: flex-start;
  }

  .invoiceStatus,
  .invoiceActions,
  .failedPaymentInfo,
  .invoiceDetail,
  .customerDetail,
  .invoiceDescription,
  .invoiceActivity,
  .purchases,
  .total {
    margin-bottom: 40px;

    h4 {
      @extend %contentLabel;
      padding-top: 10px;
      padding-bottom: 8px;
      font-weight: bold !important;
      text-transform: none !important;
      font-size: 10px !important;
    }

    div, p {
      font-size: 12px !important;
      margin-bottom: 7px;
    }

    a {
      font-size: 12px !important;
    }
  }
  .invoiceStatus {
    margin-bottom: 0;
  }
  .invoiceInfo{
    .noQuantityInvoice{
      font-size: 14px;
    }
  }
  .invoiceDetail{
    padding-bottom: 0 !important;
  }

}
