.attachFilesForm, .importItemsForm {

  .fileInput {
    padding: 0;
    margin: 0 0 25px;

    div:first-of-type {
      width: 100% !important;
      border: 0 !important;
      border-radius: 5px;
      background-color: $lightGray;
      height: 85px !important;

      .dropZoneArea {
        box-sizing: border-box;
        display: flex;
        padding: 15px;
        align-items: center;

        .iconHolder {
          height: 55px !important;
          width: 15% !important;
          display: flex;
          flex-direction: column;
          background-color: $white;
          align-items: center;
          justify-content: center;
          margin-right: 15px;

          span {
            display: inline-block;
          }

          &Text {
            @extend %contentLabel;
            margin: 0;
            padding: 0 !important;
            text-align: center;
          }
        }

        .browse {
          margin: 0;
          display: inline-block;
          @extend %bodyText;
          cursor: pointer;

          &.pa {
            color: $payAnywhereColor !important;
          }

          &.ph {
            color: $paymentHubsColor !important;
          }
        }

      }
    }

  }

  .textField {
    width: 100%;
  }

  .fileInputLabel {
    margin: 0 0 15px;
    @extend %columnLabel;
  }

  .acceptedFiles {
    margin-bottom: 15px;
    text-align: left;
    color: $gray;
    font-weight: lighter;
    white-space: pre-line;
  }

  .errorMsg {
    color: $errorColor;
    text-align: center;
  }

}
