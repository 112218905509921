.paymentLink {
  box-sizing: border-box;
  font-family: $primaryFontFamily;
  font-weight: 500;

  &Container {
    background: $backgroundColor3;
    height: 100vh;
    overflow: auto;

    .userData, .cardData, .cardMetadata, .avs {
      display: flex;
      gap: 15px;

      .textField {
        width: auto;
      }

      > div {
        width: 50%;
      }

      @media screen and (max-width: $sizeMobileMaxPortrait) {
        flex-direction: column;
        gap: 0;

        > div {
          width: 100%;
        }
      }
    }

    .avs {
      .textField:first-of-type {
        width: 80%;
      }
    }

    .userData .textField {
      width: 100%;
    }

    .cardData {
      .cardContainerWithVisibilityToggle {
        width: 100%;
      }
    }

    .cardContainerWithVisibilityToggle {
      position: relative;
      display: inline-block;

      .cardImage {
        position: absolute;
        top: 39px;
        right: 48px;
      }

      .cardNumber {
        width: 100%;
      }

      #cardNumberVisibilityIcon {
        position: absolute;
        top: 26px;
        right: 5px;
        padding: 8px;

        > span:not(:last-child) {
          height: 26px;
          width: 26px;
          margin-bottom: 2px;
        }

        svg {
          width: 21px;
        }
      }
    }

    .textFieldHolder {
      border-width: 1px;
    }

    .footerText {
      font-size: 14px;
      text-align: center;
      font-family: $primaryFontFamily;
      font-weight: normal;
      margin-top: 18px;
    }

    #tipSelector {
      margin-top: 20px;

      > div {
        width: 100%;
        margin-bottom: 0;

        > label {
          margin: 0 0 7px 0;
        }
      }

      label {
        flex: 1;
        font-family: $primaryFontFamily;
        font-weight: 600;
        color: #404143;
        margin-left: 0;
      }
    }

    .textField + #tipSelector {
      margin-top: 0;
    }

    .grecaptcha-protection {
      margin: 20px 20px 35px;
      font-size: 11px;
      text-align: center;
      color: #333;
      font-family: $primaryFontFamily;
      font-weight: normal;

      a {
        margin: 0 3px;
        color: inherit;
      }
    }

  }

  &Header {
    display: flex;
    border-bottom: 1px solid $borderGray;
    padding: 15px;
    align-items: center;
    justify-content: center;
    background: $backgroundColor;
    min-height: 80px;
    margin-bottom: 50px;
    user-select: none;

    .merchantLogoImage {
      width: auto;
      height: auto;
      max-width: 200px;
      max-height: 85px;
    }

    a {
      text-decoration: none;
      color: inherit;
      transition: opacity .5s;
      font-size: 19px;

      &:hover {
        opacity: .7;
      }
    }
  }

  &Section {
    background: $backgroundColor;
    max-width: 685px;
    margin: 0 auto 35px auto;
    padding: 40px;
    box-sizing: border-box;

    @media screen and (max-width: $sizeMobileMaxPortrait) {
      padding: 20px;
    }

    &:last-child {
      margin-bottom: 35px;
    }
  }

  &Alert {
    text-align: center;

    .title {
      font-family: $primaryFontFamily;
      font-weight: 500;
      font-size: 50px;
      margin: 5px 0 20px 0
    }

    .subtitle, .countdownTimer {
      font-family: $primaryFontFamily;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }

    .image {
      margin: 30px 0 20px 0;
      max-width: 50%;
    }
  }

  &Title {
    font-family: $primaryFontFamily;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin: 5px 0 20px 0
  }

  &SubTitle {
    font-family: $primaryFontFamily;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  &Image {
    display: block;
    margin: 20px auto 0 auto;
    width: auto;
    max-height: 200px;
    max-width: 250px;
  }

  &Form {
    padding: 0 8px;
  }

  &AmountForm {
    margin: 40px 0 10px 0;

    .fixedAmount {
      font-family: $primaryFontFamily;
      font-weight: 600;
      color: #404143;
      font-size: 55px;
      text-align: center;
    }

    .multipleAmounts {
      display: flex;
      gap: 20px;
      user-select: none;

      @media screen and (max-width: $sizeMobileMaxPortrait) {
        flex-direction: column;
      }

      .fixedAmounts {
        flex: 1;
      }

      .otherAmount {
        max-width: 170px;

        @media screen and (max-width: $sizeMobileMaxPortrait) {
          max-width: 100%;
        }
      }

      .amountOptions {
        display: flex;
        gap: 20px;

        &.required {
          .amountOption {
            border-color: red;
          }
        }
      }

      .amountOption {
        cursor: pointer;
        border-radius: 6px;
        background-color: #F7F7F7;
        border: .5px solid #B5B5B5;
        flex: 1;
        text-align: center;
        line-height: 50px;
        font-size: 18px;
        transition: background-color .3s, border-color .3s;

        @media screen and (max-width: $sizeMobileMaxPortrait) {
          font-size: 15px;
        }
      }

      .amountOptionError {
        color: red;
        font-size: 13px;
        padding: 5px 0 0 15px;
      }
    }

    .extraField {
      margin-top: 10px;
    }

    .amountSubtitle, .textFieldInfo label {
      font-family: $primaryFontFamily;
      font-weight: 600;
      font-size: 13px;
      color: #404143;
      margin: 0;
      padding-bottom: 14px;
      padding-left: 4px;
    }

    .textFieldInfo label {
      padding-bottom: 7px;
    }

    .applePayContainer {
      .apple-pay-button {
        margin-top: 50px;
        width: 100%;
        height: 51px;
        border-radius: 24px;
        -apple-pay-button-type: plain;
      }
    }
  }

  &CardForm {
    margin: 40px 0 22px 0;
  }

  &CardForm + button {
    span:first-of-type {
      width: 100%;
    }
  }

  &Success {
    text-align: center;

    .title {
      font-family: $primaryFontFamily;
      font-weight: 500;
      font-size: 24px;
      margin: 5px 0 20px 0
    }

    .subtitle {
      font-family: $primaryFontFamily;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
    }

    .image {
      margin: 40px;

      @media screen and (max-width: $sizeMobileMin) {
        margin: 30px;
      }
    }

    .paymentInformation {
      background: $backgroundColor3;
      padding: 30px;

      .line {
        display: flex;
        text-align: right;
        font-size: 15px;
        font-family: $primaryFontFamily;
        font-weight: normal;
        margin-bottom: 10px;

        div:first-child {
          text-align: left;
          flex: 1;
        }

        &.highlight {
          font-family: $primaryFontFamily;
          font-weight: 500;
        }
      }
    }

    .countdownTimer {
      margin-top: 30px;
      font-size: 15px;
      font-family: $primaryFontFamily;
      font-weight: normal;
    }

    .doAnotherButton {
      margin-top: 30px;
    }
  }
}

.declinedPaymentModal {

  text-align: center;

  .title .title-label {
    justify-content: center;
  }

  .declinedModalBody {

    margin: 13px 0;

    p {
      margin: 8px 0;
    }

    .paymentError {
      margin-top: 40px;
    }

  }

  .dialogActions {

    justify-content: center;
    margin-bottom: 21px;

    button {

      width: 333px;

      @media screen and (max-width: $sizeMobileMax) {
        width: 200px;
      }

    }

  }

}
