.customerRow, .transactionRow, .employeeRow, .itemRow, .depositRow, .categoryRow, .discountRow, .modifiersRow, .invoiceRow {
  cursor: pointer;
  height: 70px !important;

  &:hover {
    background-color: $selectedRow !important;
  }

}

.transactionRow, .depositRow, .customerRow, .employeeRow, .itemRow, .categoryRow, .discountRow, .modifiersRow, .invoiceRow, .reportRow {

  .amount, .type, .invoiceNumber, .name, .itemThumb, .reportName {
    @extend %body;

    @media screen and (max-width: $sizeMobileMax) {
      font-size: 14px !important;
    }

  }

  .date, .soldBy, .count, .lastLogin, .price, .value, .reportSummary {
    @extend %body3;
    color: $secondaryTextColor;

    @media screen and (max-width: $sizeMobileMax) {
      font-size: 12px !important;
    }

  }

  .count {
    padding-right: 15px;
  }

}

%rightColumn {
  width: 140px;
  padding-right: 40px !important;
}

.categoryRow {
  .categoriesIconColumn {
    width: 10px;
  }
}


.itemRow {

  .priceColumn {
    @extend %rightColumn;
  }

  .itemIndicatorColumn {
    width: 80px;
    padding: 0 36px 0 36px;
  }

  .checkboxColumn {
    width: 80px;
  }

  .taxableIndicator {
    font-size: 10px;
    margin-right: 5px;
  }

}

.discountRow {

  .discountIndicatorColumn {
    width: 60px;

    .discountIcon {
      padding: 0 20px !important;
      width: 75px !important;
      height: 75px !important;

      svg {
        height: 24px !important;
        width: 24px !important;
      }
    }
  }

  .discountValueColumn {
    @extend %rightColumn;
  }

}

.employeeRow {

  .employeeLogin {
    @extend %rightColumn;
  }

}

.customerRow {

  .customerTransactionCount {
    @extend %rightColumn;
  }

}

.depositRow {

  .depositIndicatorColumn {
    width: 10px;
  }

  .depositAmount {
    @extend %rightColumn;
  }
}

.transactionRow.selectedTransaction {
  background-color: $selectedRow;
}

.transactionRow {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 10px !important;
  padding-right: 14px !important;
  box-sizing: border-box;

  .invoiceNumber {
    width: 185px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;

    @media screen and (max-width: $sizeMobileMax) {
      padding: 0 10px !important;
    }

  }

  .transactionContext {
    overflow-y: visible !important;
    white-space: normal !important;
    display: inline-block;

    @media screen and (max-width: $sizeMobileMax) {
      padding: 0 10px !important;
    }

  }

  .transactionAmount {
    @extend %rightColumn;
    width: 140px;
    display: inline-block;
    margin-left: auto;
    margin-right: 30px;

    .flexColumnMiddle {

      .floatRight {

        @media screen and (max-width: $sizeMobileMax) {
          text-align: left;
        }

      }

    }

    @media screen and (max-width: $sizeMobileMax) {
      padding: 0 10px !important;
    }

  }
}

.invoiceRow {

  svg {
    padding-left: 10px;
    width: 18px;
  }

  span.noneLabel {
    color: $phoneSwipeColorAlternativeColor !important;
    font-size: 14px !important;
  }

  .invoiceNumber {
    width: 50px;

    @media screen and (max-width: $sizeDesktopMid) {
      padding: 0 10px !important;
    }

  }

  .invoiceContext {
    overflow-y: visible !important;
    white-space: normal !important;

    @media screen and (max-width: $sizeDesktopMid) {
      padding: 0 10px !important;
    }

  }

  .transactionAmount {
    @extend %rightColumn;

    .flexColumnMiddle {

      .floatRight {

        @media screen and (max-width: $sizeMobileMax) {
          text-align: left;
        }

      }

    }

    @media screen and (max-width: $sizeMobileMax) {
      padding: 0 10px !important;
    }

  }

  .invoiceType {
    padding: 0 !important;
    width: 30px;

    div svg {
      @extend %x-smallIcon;
      position: relative;
      top: -4px;
      left: 16px;
    }
  }

  .invoiceContext {
    padding-left: 10px !important;
    min-width: 260px !important;
  }

  .invoiceAmount {
    padding-right: 40px !important;
  }

}

.pagination {

  list-style: none;
  display: flex;
  align-items: center;
  padding: 5px 0;
  margin: 0;
  border-bottom: 1px solid #DDDDDD;
  justify-content: space-around;

  li {
    cursor: pointer;
    padding: 8px;
    list-style: none;
    display: inline;
    text-align: center;
  }

  li.active {
    font-weight: bolder;
    color: $payAnywhereColor;
  }

  li.disabled {
    cursor: not-allowed;
    color: $subNavBackgroundColor;
  }

}
