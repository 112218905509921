.customerPayment {

  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  .customerPaymentWrapper {

    display: flex;
    height: 100%;

    .filter {

      width: 410px;
      @media screen and (max-width: $sizeTabletMax) {
        width: 100%;
      }

      .filterTitle {

        max-width: 100%;
        min-width: auto;
        margin-top: 60px;

      }

      .logoHolder {
        width: 100%;
        min-height: 100px;
        max-height: 300px;
        text-align: center;
        overflow: hidden;
        img {
          max-width: 100%;
        }

        @media screen and (max-width: $sizeMobileMax) {
          min-height: 0px;
          max-height: 110px;
        }
      }

      .merchantLogoImage {
        max-width: 100%;
      }

      .filterItem{
          font-family: $primaryFontFamily !important;
          font-weight: 500;
          letter-spacing: 0.36px !important;
          margin-bottom: 35px;
          font-size: 18px !important;
        &.filterItemSelected {
          font-weight: bold;
        }
      }

      .customerPaymentFilterFooter {

        margin: auto 0 15px 0;

        .businessInfo {
          margin-bottom: 40px;
          div {
            @extend %bodyText;
            margin: 0.5em 0;
            color: $grayText !important;
            font-family: $primaryFontFamily !important;
            font-weight: 500;
            letter-spacing: 0.36px !important;
            &.name {
              font-family: $primaryFontFamily !important;
              letter-spacing: 0.36px !important;
              font-weight: bold;
              color: $customerPaymentPrimaryColor !important;
            }

            a {
              font-family: $primaryFontFamily !important;
              font-weight: 500;
              letter-spacing: 0.36px !important;
              color: $grayText;
              text-decoration: none;
            }

          }
        }

        .social {
          display: flex;
          list-style: none;
          margin: 15px 0 18px 0;
          padding: 0;

          li {
            margin-right: 15px;
            width: 25px;
            height: 25px;
            cursor: pointer;

            &:last-child {
              margin-right: 0;
            }
          }

          .icon {
            background-size: 25px;
          }
        }

        .footer {

          display: flex;
          flex-direction: row;
          align-items: center;
          @extend %contentLabel;

          .filterLogo {
            margin: 0;
          }

        }

      }

      &.filterMobile {
        .mainMenuIcon { display: none; }
        .leftSide {
          width: 100%;
          & > div + div {
            width: 100%;
          }
        }
      }
    }

    .customerPaymentContent {
      h1 {
        font-weight: 600;
      }
      width: 100%;
      height: 100%;
      background: #f3f3f3;
      overflow-y: auto;

      .paymentHistorySection {
        .sectionTitle {

          .selectedActionsContainer {
            max-width: 75%;
            text-align: right;
            display: flex;

            @media screen and (max-width: $sizeTabletMax) {
              flex-direction: column;
              align-items: initial;
            }

            button:nth-child(1) {
              width: auto;
              margin-right: 8px;
              margin-bottom: 8px;
            }
            button:nth-child(2) {
              min-width: 205px;
              margin-bottom: 8px;
            }
            button:nth-child(3) {
              min-width: 170px;
              margin: 0 0 8px !important;
            }
          }
        }

        input, .sectionLabel span{
          font-family: $tertiaryFontFamily !important;
        }
      }


      .customerPaymentSection, .paymentHistorySection {
        margin: 80px 11%;

        &_empty {
          margin: 0;
        }


        @media screen and (max-width: $sizeTabletMax) {
          margin: 70px 7%;
        }

        @media screen and (max-width: $sizeMobileMax) {
          margin: 40px 5%;
        }
      }

      .sectionTitle {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 80px 0 55px;
        cursor: auto;
        min-height: 66px;

        @media screen and (max-width: $sizeMobileMax) {
          margin: 20px 0 0px;
        }

        .searchContainer {
          border-radius: 62px;
          background-color: $white;
          padding: 0 10px;
          margin-bottom: 12px;
        }
      }

      .sectionLabel {
        @extend %columnLabel;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .select {
          margin-left: auto;
        }
      }

      .openInvoicesDetail {

        background: $white;
        margin-left: 22px;
        box-sizing: border-box;
        min-height: 100vh;

        @media screen and (max-width: $sizeTabletMax) { margin-left: 0}

        .backToInvoices {
          @extend %link;
          color: $paymentHubsColor !important;
        }

        .payInvoiceFormContainer {

          .invoiceFormContainer {

            .invoiceDue {

              .invoiceTitle, .invoiceSubtitle {
                text-align: left;
              }
              .invoiceTitle {
                @extend %heading;
                margin: 3em 0 0.2em 0;
              }
              .invoiceSubtitle {
                @extend %contentLabel;
                color: $gray;
              }

            }

          }

        }

      }

      .openInvoicesEmpty, .paymentHistoryEmpty, .sessionExpired {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .emptyImg {
          width: 303px;
          height: 342px;
          background-size: cover;
          margin-bottom: 88px;
        }

        h2 {
          @extend %heading1;
          margin: 0 0 17px 0;
        }

        p {
          @extend %body;
          font-size: 18px;
          color: $grayText;
        }
      }

      .openInvoicesEmpty {

        .emptyImg {
          background-image: url("/images/paymenthubs/noOpenInvoices.svg");
        }

      }

      .paymentHistoryEmpty {

        .emptyImg {
          width: 353px;
          background-image: url("/images/paymenthubs/noPaymentHistory.svg");
        }

      }

      .sessionExpired {

        .expiredImg, .sentImg {
          background-size: cover;
          margin: 10px;
        }

        .expiredImg {
          width: 295px;
          height: 312px;
          background-image: url("/images/paymenthubs/sessionExpired.svg");
        }

        .sentImg {
          width: 407px;
          height: 281px;
          background-image: url("/images/paymenthubs/linkSent.svg");
        }

      }

      .openInvoices {
        .mainInfo{
          color: $customerPaymentPrimaryColor !important;
          font-size: 18px !important;
          font-family : $tertiaryFontFamily !important;
          letter-spacing: 0.36px !important;
        }
      }

      .error {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h1 {
          font-size: 25px;
        }

        .notFoundImage {
          display: block;
          width: 100%;
          max-width: 700px;
          margin: 4% auto 0;
        }
      }

      .eInvoiceDetail {
        display: flex;
        padding: 50px 45px 0 45px;
        min-height: 100vh;
        box-sizing: border-box;

        .autoPayText {
          margin: 30px 0;
          font-size: 16px;
          font-family : $tertiaryFontFamily !important;
        }

        @media screen and (max-width: $sizeTabletMax) {
          display: block;
          height: auto;
          padding: 20px 15px 0 15px;
        }

        @media screen and (max-width: $sizeDesktopMax) {
          padding: 50px 20px 0 20px;
        }

        .leftContent {
          width: 65%;
          margin-right: 45px;

          @media screen and (max-width: $sizeTabletMax) {
            margin-bottom: 20px;
            width: 100%;
            align-items: center;
            display: flex;
            flex-direction: column;
          }

          @media screen and (max-width: $sizeDesktopMax) {
            margin-right: 20px;
          }

          h3 {
            font-size: 16px;
            color: $paymentDetailText;
            margin: 3px 0 35px 0;
          }

          h4 {
            margin-top: 95px;
            @extend %heading2RegularCase;
            font-family : $tertiaryFontFamily !important;
            font-size: 12px !important;
          }

          .goBack button{
            span:first-of-type {
              display: flex;
              align-items: center;
              & svg {
                margin-right: 5px;
              }
            }
          }

          .dueDateTop {
            font-weight: 500;

            @media screen and (max-width: $sizeTabletMax) {
              display: flex;
              justify-content: center;
            }
          }

          .paidInvoice {
            display: flex;
            flex-direction: column;
            align-items: center;

            .paidImg {
              width: 309px;
              height: 311px;
              background-repeat: no-repeat;
              background-size: cover;
              background-image: url("/images/paymenthubs/invoicePaid.svg");
              margin-bottom: 90px;
            }

            h3 {
              @extend %heading1;
              color: #000000;
            }

            p {
              @extend %body;

              span {
                font-weight: bold;
              }
            }

            .summary {
              box-sizing: border-box;
              width: 100%;
              display: flex;
              padding: 32px;
              background-color: $paymentPageGrey;
              margin-bottom: 50px;

              .right {
                margin-left: auto;
                text-align: right;

                .bold {
                  font-weight: bold !important;
                  margin: 0 0 10px 0;
                }
                .light {
                  color: $paymentAddressColor !important;
                  margin-bottom: 0;
                }
              }

              .left {
                p {
                  font-size: 16px;
                }
                .bold {
                  font-weight: bold !important;
                  margin: 0 0 10px 0;
                }
                .light {
                  color: $paymentAddressColor !important;
                  margin-bottom: 0;
                }
              }
            }

            &.zeroAmount {
              .paidImg {
                margin-top: 110px;
                margin-bottom: 0px;
              }

              .details {
                p {
                  font-size: 14px !important;
                  &:first-child {
                    font-weight: 800 !important;
                  }
                }
              }

              .savedPayment {
                width: 80%;
              }

              .extra p {
                font-weight: 800 !important;
                margin-left: 60px;
                margin-right: 0;
              }
            }
            .loyaltyVpcInfo {
              box-sizing: border-box;
              width: 100%;
              display: flex;
              padding: 32px;
              background-color: #F2F2F2;
              margin-bottom: 50px;
              align-items: center;
              .loyaltyVpcInfoText{
                margin-left: 10px;
                p {
                  margin: 0;
                  &.bold {
                    color: #000000 !important;
                    font-size: 18px !important;
                    font-weight: bold !important;
                  }
                }

              }
            }

            .enrollInLoyaltyBox {
              padding-bottom: 35px;
              .boxHeader {
                text-align: center;

                span {
                  font-size: 19px;
                  font-weight: bold;
                }

                p {
                  margin-top: 5px;
                }
              }

              .boxToggle {
                background-color: $paymentPageGrey;
                padding: 32px;

                .control {
                  display: flex;
                  justify-content: space-between;

                  p {
                    margin: 0;
                    font-size: 14px !important;
                  }
                }

                .enrollForm {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  button {
                    max-width: 150px;
                  }
                }
              }
            }
          }
          .flatButton {
            span {
              font-size: 16px !important;
            }
          }
        }

        .rightContent {
          width: 35%;
          box-sizing: border-box;
          background-color: #F2F2F2;
          padding: 50px;
          display: flex;
          flex-direction: column;

          @media screen and (max-width: $sizeTabletMax) {
            width: 100%;
          }

          @media screen and (max-width: $sizeDesktopMax) {
            padding: 20px;
          }
          .topHolder, .bottomHolder {
            display: flex;
            flex-direction: column;
          }

          .heading {
            margin-bottom: 50px;
            overflow: hidden;
            word-break: break-word;
          }

          .loyaltyButtonContainer {
            display: flex !important;
            justify-content: center;

            .loyaltyButton {
              column-gap: 10px;
            }
          }

          .detailList {
            list-style: none;
            padding: 0;
            margin: 0;
            font-family : $tertiaryFontFamily !important;
          }

          .detailItem {
            display: flex;
            margin-bottom: 20px;
            padding-bottom: 10px;
            border-bottom: 1px solid $lineColor;
            font-size: 14px;
            font-weight: bold;

            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }

            .pullLeft {
              margin-left: auto;
              text-align: right;

              .descModifier {
                color: #5D5E5F;
                font-size: 12px;
                margin: 0 0 3px 0;
              }
            }

            &.sub {
              color: #5D5E5F !important;
              font-weight: normal;
            }

            .price, .priceModifier {
              margin: 0 0 5px auto;
              font-size: 14px;
              color: #5D5E5F;
            }

            .priceBold {
              margin: 0 0 5px auto;
              font-size: 14px;
              font-family: $tertiaryFontFamily;
            }

            .priceModifier {
              font-size: 12px;
            }

            &.noBorder {
              border-bottom: none;
            }
          }
          .payHolder {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            flex-direction: column;

            .applePayContainer {
              text-align: center;
            }

            button.raisedButton {
              margin-right: 0 !important;
            }
          }
        }
      }

    }

    .paymentMethods {
      display: flex;
      flex-flow: column nowrap;
      margin: 80px 11%;
      overflow-y: auto;

      @media screen and (max-width: $sizeTabletMax) {
        margin: 70px 7%;
      }

      @media screen and (max-width: $sizeMobileMax) {
        margin: 40px 5%;
      }

      &Header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid;
        padding: 0 0 10px 0;
      }

      &Content {
        width: 100%;
        max-width: 650px;
        align-self: center;
        display: flex;
        flex-direction: column;


        &Title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0;

          h1 {
            margin: 0;
          }
        }
        &MethodAdd {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
        }

        &Method, &MethodAdd {
          background-color: $backgroundColor;
          min-height: 95px;
          margin-bottom: 12px;
          padding: 0 22px;
          cursor: pointer;

          &Add {
            svg {
              margin-right: 10px;
            }
          }

          & .Method {

            &Icon {
              margin-right: 25px;
              width: 52px;
              height: 52px;
              border-radius: 52px;
              display: flex;
              align-items: flex-start;
              justify-content: center;
              padding-top: 15px;

              & svg {
                width: 35px !important;
                height: auto !important;
              }

              &.Bank {
                background-color: $backgroundColor2;
                align-items: center;
                padding-top: 0;
                & svg {
                  width: 25px !important;
                }
              }
            }

            &Details {
              display: flex;
              flex-flow: column nowrap;
              flex: 2 1 auto;

              &Number {
                font-size: 17px;
                margin-bottom: 5px;
              }
            }

            &Chevron {
              position: relative;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              .MuiIconButton-root:hover {
                background-color: transparent !important;
              }
            }
          }
          & .MuiIconButton-root {
            & svg {
              padding-bottom: 3px !important;
            }
            &:hover {
              background: none !important;
            }
          }
        }
      }

    }

  }

}

.customerPaymentBody{
  .savedPayment {
    @media screen and (max-width: $sizeMobileMax) {
      flex-direction: column;
      align-items: flex-start;
    }
    .card {
      @media screen and (max-width: $sizeMobileMax) {
        flex-direction: column;
      }
      .details{
         p{
          font-family : $tertiaryFontFamily !important;
         }
      }
    }
  }
  .modal{
    .textFieldInfo label {
      padding-left: 0;
    }

    #sharedSelect {
      &:focus {
        background: none;
      }
    }
    .close-icon{
      left: 16px;
      top: 10px;
    }
  }
}

.addPaymentModal {
  .MuiDialog-paper {
    padding: 0 !important;
  }
}

.applyReward, .removeReward {
  background: transparent!important;
  border: none;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  height: auto !important;
  justify-content: flex-start;
  line-height: normal;
  padding: 0!important;
}

.applyReward {
  min-height: 40px !important;

  .buttonLabel {
    margin: auto;
  }
}

.applyRewardButton {
  text-align: center;
}

.rewardFieldFetching {

  span {
    width: 25px !important;
    height: unset !important;
    padding: 0 22px !important;
  }
}

.invalidRewardCodeIndicator {
  color: $errorColor;
  margin: 0;
}

.loyaltyVpcRewardBox {
  width: 100%;

  .loyaltyVpcInput {
    color: #1B1B1B;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }

  .textFieldHolder {
    border-radius: 0px;
    border-width: 1px;
    background: #fff;
  }
}

.addPaymentForm {

  .fieldContainer {

    .fullWidth, .halfWidth, .quartWidth {
      box-sizing: border-box;
    }

    .fullWidth {
      width: 100% !important;
    }
    .halfWidth {
      width: 50% !important;
      padding-right: 15px;
    }
    .quartWidth {
      width: 25% !important;
      padding-right: 15px;
    }
    .last {
      padding-right: 0;
    }
  }

  .grayDisclaimer {
    display: flex;
    background-color: $listItemBackground;
    border-radius: 7px;
    padding: 30px 40px 30px 21px;

    div {
      margin-right: 14px;
    }

    p {
      margin: 0;
    }
  }
}

.grayBckBtn {
  background-color: $paymentCardBackground !important;
}

.MethodDefault {
  color: $primaryTextColor !important;
  flex: 2 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    margin-right: 10px;
  }
}

.MethodOption {

  .MuiPaper-root {
    background-color: $iconColor;
    color: $backgroundColor2;
    border-radius: 0;
  }

  [role='menu'] {
    padding: 15px 0 !important;
    min-width: 128px !important;
  }

  [role='menuitem'] {
    font-size: 12px !important;
    height: 30px;
    padding-left: 20px !important;

    &.disabled {
      cursor: default;
      color: $secondaryTextColor;
    }
  }
}

// To make method type select visible over dialog
#menu-methodType,  #menu-accountType {
  z-index: 2000 !important;
}

.editAddPaymentDialog {

  .MuiPaper-root {
    padding: 10px;
    @media screen and (min-width: $sizeMobileMaxPortrait + 1) {
      width: 736px;
    }
  }
  .textFieldInfo {
    & label {
      padding-left: 5px;
      font-size: 12px !important;
      font-weight: 600 !important;
    }
  }
  .textFieldHolder {
    border-radius: 2px;
    border-width: .5px;
  }
  &Content {
    padding: 20px;
    border: none !important;

    @media screen and (max-width: $sizeTabletMax) {
      padding: 0;
    }

    .textFieldHolder {
      border-radius: 0px;
      border-width: 1px;
    }
    .MuiDialogContent-root {
      padding: 0 !important;
      overflow-x: hidden !important;
    }
    .dialogTitle {
      margin-bottom: 53px;
    }
    .methodTypeSelect, .accountTypeSelect {
      .textFieldHolder {
        height: 48px !important;
      }
    }
    .Column, .accountTypeSelect {
      @media screen and (min-width: $sizeMobileMaxPortrait + 1) {
        width: auto !important;
        flex: 1;
      }
    }
    .FieldWithToggle {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: flex-end;

      .innerIcons {
        position: absolute;
        right: 0;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .tooltip {
          padding-top: 5px;
          &:last-child {
            padding-right: 15px;
          }
        }
        .MuiBox-root {
          &:hover {
            background-color: transparent;
          }
          svg {
            fill: #000;
            padding-top: 12px;
            width: 13px;
            height: auto;
          }
        }
      }
    }
    &Row {
      &ThreeColumns, &TwoColumns {
        align-content: flex-start;
        display: flex;
        flex-wrap: wrap;
      }

      &TwoColumns {
        @media screen and (min-width: $sizeMobileMaxPortrait + 1) {
          .Column {
            &:last-child {
              margin-left: 15px;
              flex: 2;
            }
          }
        }
      }

      &ThreeColumns {
        .Column {
          @media screen and (min-width: $sizeMobileMaxPortrait + 1) {
            &:first-child {
              flex-grow: 2;
            }
            &:nth-child(2) {
              margin: 0 15px;
            }
          }
        }
      }
    }
  }
  &Actions {
    border-top: .5px solid $lineColor2;
    padding: 0 !important;
    @media screen and (min-width: $sizeMobileMaxPortrait + 1) {
      border-top: none;
    }
    button {
      &:last-child {
        margin-left: 15px;
        margin-right: 0 !important;
        min-width: 130px;
      }
    }
  }
}
.tipChecked {
  background-color: $black !important;
  color: $backgroundColor !important;
}

.paymentErrorModal {
  min-width: 200px;
}

.loyaltyModal {
  .loyaltyModalContent {
    h1 {
      margin-top: 0;
      margin-bottom: 40px;
      font-size: 28px;
    }
    .availableReward {
      p:first-child {
        font-weight: bold;
        font-size: 12px;
        text-align: left;
      }
      .availableRewardContent {
        background-color: #f2f2f4;
        display: flex;
        align-items: center;
        height: 90px;
        margin-bottom: 30px;

        .loyaltyIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
        }

        .loyaltyInfo {
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            font-size: 12px;
            color: $paymentDetailText;
            margin: 0;
          }
        }

        .customToggle {
          margin-left: auto;
          padding-right: 20px;
        }
      }
    }
  }
}

.tipSelectorContainer {
  margin-top: 30px;
}

.enrollInLoyaltyForm {
  padding: 35px 0;
  max-width: 315px;

  span {
    color: $paymentAddressColor;
    font-size: 10px;
  }

  .phoneNumberField {

    span {
      color: red;
    }

    .textFieldHolder {
      background-color: $backgroundColor;
    }
  }
}
