
.cornerCheckMark {
  height: 72px;
  float: right;

  .st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: $secondaryTextColor;
  }

  .st1 {
    fill: $backgroundColor;
  }

}
