.roleSettings {
  padding-bottom: 55px;
  box-sizing: border-box;

  .detailsHeader {
    @extend %contentRow;
  }

  .detailsRow, .detailsCheckBox {
    @extend %contentRow;

    &:first-child {
      margin-top: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .infoText {
    font-family: $primaryFontFamily;
    font-weight: normal;
  }

}