.mainMenu {

  .mainMenuHeader {

    padding: 12px 0px 0px 25px !important;

    svg {
      overflow: visible;
    }

    .headerItem {
      @extend %bodyText;
      padding: 14px 16px 16px 2px !important;

      svg {
        color: red !important;
      }
    }

    .menuLogo {
      height: 35px;
      width: 170px;

      &.small {
        width: 35px;

      }
    }
  }

  .mainMenuItems {

    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-top: 15px;

    .mainMenuItem {

      font-family: $primaryFontFamily !important;
      font-weight: 500;

      > div:first-of-type {
        min-width: initial;
        margin-right: 23px;
      }

      &.selected {

        > div:first-of-type {
          background-color: #F7F7F7;
          border-radius: 10px;
        }

        &.pa {
          color: $payAnywhereColor !important;
          border-color: $payAnywhereColor !important;
          .iconMenuText {
            color: $payAnywhereColor !important;
          }
        }

        &.ph {
          color: $paymentHubsColor !important;
          border-color: $paymentHubsColor !important;
          .iconMenuText {
            color: $paymentHubsColor !important;
          }
        }

        &.nh {
          color: $northColor !important;
          border-color: $northColor !important;
          .iconMenuText {
            color: $northColor !important;
          }
        }
      }

      .iconWrapper {

        width: 20px;
        height: 20px;

        svg {
          overflow: visible;
        }

      }

      .iconMenuText {
        color: $primaryTextColor;
      }
    }

  }

  .mainMenuItemLink {
    text-decoration: none !important;

    &.displayDividers::after {
      display: block;
      content: "";
      border-bottom: 1px solid #c7c7c7;
      margin: 15px 28px 14px 21px;
    }
  }

  .customToggle {

    padding-left: 10px;
    margin-top: 17px;
    padding-bottom: 150px;

    label span:first-child {
      margin-right: 18px;
    }
  }
}
