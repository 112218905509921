.notFound {

  .toolbar {

    &, .logoBar {
      border: none;
    }

    .logoBar {
      margin-top: 34px;
    }

  }

  .container {

    width: 80%;
    margin: 0 auto;
    text-align: center;

    .title {
      @extend %heading;
      margin-top: 7em;
    }

    .subtitle {
      @extend %contentLabel;
      margin-bottom: 4em;
    }

    .linkAsButton {
      svg {
        fill: #ffffff !important;
        width: 14px;
        margin-left: 8px;
      }
    }

    .notFoundImage {
      display: block;
      width: 100%;
      max-width: 700px;
      margin: 4% auto 0;
    }

  }
}
