.invoicePreview {
  text-align: center;
  pointer-events: none;
  user-select: none;

  .logoImg {
    height: auto;
    width: auto;
    max-height: 100px;
    max-width: 150px;
  }

  h1, h2, p {
    margin: 0 0 10px 0;
  }

  p {
    color: $secondaryTextColor;
    font-size: 13px;
  }

  h1 {
    color: $primaryTextColor;
    font-size: 20px;
  }

  h2 {
    color: $primaryTextColor;
    font-size: 18px;
  }

  .recurringInfo {
    display: flex;
    align-items: center;

    svg {
      height: 22px !important;
      width: 22px !important;
      margin-right: 8px;
    }

  }

  .previewDetail {
    text-align: initial;

    .listing {
      padding: 15px 20px;
      list-style: none;
      background-color: $selectedRow;

      li {
        border-bottom: 1px solid $secondaryTextColor;
        margin-bottom: 10px;

        &:last-child {
          border-bottom: none;
        }
      }

      .row {
        display: block;
        margin-bottom: 5px;

        .item, .amount, .extra, .holder {
          display: inline-block;
        }

        .item {
          color: $primaryTextColor;
          font-size: 13px;
          width: 50%;
          font-weight: bold;
        }

        .amount, .modifierAmount {
          color: $secondaryTextColor;
          font-size: 13px;
          width: 50%;
          text-align: right;
          font-weight: bold;
        }

        .extra, .modifier {
          color: $secondaryTextColor;
          font-size: 13px;
          width: 50%;
          font-weight: bold;
        }

        .modifier, .modifierAmount {
          font-size: 11px;
        }

        .holder {
          width: 50%;
          .amount, .modifierAmount, .extra, .modifier {
            width: 100%;
            display: block;
          }
        }
      }

      .summaryTotal {
        color: black !important;
      }
    }
  }

  .social {
    text-align: center;

    ul {
      list-style: none;
      display: inline-block;
      margin: 0;
      padding-left: 0;

      li {
        display: inline-block;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }

        .icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-size: cover;
          border-radius: 50%;

          &Facebook {
            background-image: url('/images/social/facebook_circle.svg');
          }

          &Instagram {
            background-image: url('/images/social/instagram_circle.svg');
          }

          &Twitter {
            background-image: url('/images/social/twitter_circle.svg');
          }

          &Yelp {
            background-image: url('/images/social/yelp_circle.svg');
          }

        }
      }
    }
  }

  .poweredBy {
    width: 156px;
    height: 20px;
    margin-top: 45px;
  }

  .previewHeader, .previewDetail, .previewPayment, .social, .recurringInfo {
    margin: 24px 0px;
  }

}
