.customToggle {
  margin-left: 10px;

  label {
    @extend %columnLabel;
  }

  .toggleDescription {
    @extend %contentLabel;
    font-size: 13px !important;
    font-weight: 500;
    margin: 0;
    padding-left: 46px;
    padding-top: 10px;
  }

  .toggleError {
    @extend %contentLabel;
    position: absolute;
    color: red;
    padding-top: 5px;
  }
}
