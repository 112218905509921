.transactionsSummary {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 30px 0;
  margin: 0;
  border-bottom: 1px solid $lineColor;
  justify-content: space-around;

  .item {

    .amount {
      @extend %bodyText;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .text {
      @extend %contentLabel;
      white-space: pre;
    }
  }
}
