.cardPlanDetails {
  margin-bottom: 25px;

  .detailsBody {
    display: flex;
    min-width: 760px;

    .leftList, .rightList {
      width: 50%;
      padding: 0 10px;
      box-sizing: border-box;
      list-style: none;
      margin: 0;

      .planFeature {
        display: flex;
        border-bottom: 1px solid $lineColor2;
        font-size: 12px;
        padding-bottom: 7px;
        margin-bottom: 7px;

        .check {
          margin-left: auto;
        }

        .addOn {
          margin-left: auto;
          color: $secondaryTextColor;
          font-size: 10px;
        }
      }
    }
  }
}

.cardPlanDisclaimer {
  font-size: 9px;
  text-align: center;
  color: $black;
}

.comparisonTable {
  margin-bottom: 25px;

  .tableHeader {

    .label, .price {
      margin: 0;
    }

    .label, .feature {
        font-size: 12px;
    }

    .price {
      color: $secondaryTextColor;
      font-size: 10px;
    }
  }

  .feature {
    font-size: 12px;
  }

  .addOn {
    color: $secondaryTextColor;
  }
}

.detailsHeader {
  display: flex;
  margin-bottom: 30px;

  .title {
    font-size: 27px;
    text-align: left;
    margin: 0;
  }

  .generalInfo {
    margin-left: auto;
  }

  p {
    margin: 0;
    text-align: right;
  }

  .price {
    font-size: 12px;
  }

  .description {
    font-size: 10px;
    color: $todaySecondaryDataColor;
  }
}

.cardSelectedPlan {
  border: 2px solid $paymentHubsColor;
}
