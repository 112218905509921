.datePickerFrom,
.datePickerTo {
  padding-top: 10px;
  padding-bottom: 8px;
  border: 0 !important;
  width: 100%;
  @extend %contentLabel;

  @media screen and (max-width: $sizeMobileMax) {
    width: 100%;
  }

}
