.filterPanel {
  width: 230px;
  overflow: hidden;

  .selected {
    background-color: $selectedRow !important;
    width: 90%;
  }

  .listItem {
    width: 90%;
  }

  .selected:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    border-left-color: $selectedRow;
    border-width: 20px;
    margin-top: -20px;
  }

  .listItem:hover::after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    border-left-color: $selectedRow;
    border-width: 20px;
    margin-top: -20px;
  }

  span.listItem > div > div {
    padding: 10px 0px 10px 50px !important;

    div {
      line-height: 20px;
    }
  }
}

.filter {
  display: inline-block;
  min-width: 320px;
  padding: 30px 35px 0;
  box-sizing: border-box;
  border-right: 1px solid $lineColor;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .filterItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h1 {
    display: inline-block !important;
    margin: 14px 0;
  }

  &Logo {
    display: inline-block;
    background-image: url('/images/payanywhere/logoSmall.png');
    background-size: 51px 35px;
    background-repeat: no-repeat;
    width: 51px;
    height: 35px;
    margin: 10px 0 45px 0;
  }

  &Title {
    display: inline-block;
    min-width: 180px;
    font-family: $primaryFontFamily;
    font-weight: 500;
    @extend %heading;
    margin-bottom: 25px;
  }

  &Button {
    margin-bottom: 35px;
  }

  &List {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    margin-bottom: 65px;
  }

  &ListButtonBar {
    @extend .filterList;
    margin-bottom: 150px;
  }

  &Item {
    width: auto;
    border-bottom: none;
    @extend %bodyText;
    cursor: pointer;
    margin-bottom: 30px;
    min-height: 19px;

    &:last-child {
      margin-bottom: 0;
    }

    &Selected {
      color: $payAnywhereColor;
      font-family: $primaryFontFamily !important;
      font-weight: 600;
    }
  }

  &.filterMobile {

    padding: 0;
    overflow: initial !important;
    box-shadow: 0px 7px 10px -2px rgba(0,0,0,0.15) !important;
    z-index: 100;
    width: 100%;
    display: inline-block;
    flex-flow: column nowrap;
    padding-top: 0;

    .mainOptions {

      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $listItemBackground;
      padding: 20px 24px 20px 12px !important;

      .leftSide, .rightSide {
        display: flex;
        align-items: center;
      }

      .leftSide {
        justify-content: flex-start;
      }

      .rightSide {
        justify-content: flex-end;
      }

      .searchIcon, .searchAccesoriesIcon {
        cursor: pointer;
      }

      .mainMenuIcon {
        cursor: pointer;
        display: flex;
        margin-right: 8px;
        padding-top: 8px;
      }

      h1, .filterLogo, .filterTitle, .filterButton {
        margin: 0;
      }

      .searchIcon, .searchAccesoriesIcon {
        padding: 0 5px;
        margin: 0 10px 0 0;
      }

      .searchAccesoriesIcon {

        svg {
          width: 24px;
        }

      }

      .filterTitle {
        min-width: initial;
      }

    }

    .filterSearchBarContainer {

      position: relative;
      padding: 0 0 0 4px;
      height: 0;
      overflow: hidden;
      transition: height 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

      &.searchBarOpen {
        height: 62px;
        border-bottom: 1px solid $listItemBackground;
      }

      .closeSearchBar {
        position: absolute;
        top: 14px;
        right: 16px;
        display: inline-block;
        padding: 5px;
        cursor: pointer;
      }

      .searchBarContainer .clearButton {
        position: static !important;
      }

    }

    .filtersSelectDropdown {
      padding-top: 5px;
      margin: 2px 0 7px;

      > .textField {
        margin: 2px 0 7px;
      }

      .textFieldInfo {
        display: none;
      }

      .textFieldHolder {

        border: none;
        padding: 5px 20px 0 24px;

        div {
          box-sizing: border-box;
          z-index: 1000;
        }

        button {
          margin: 0 !important;
          right: -5px !important;
          padding-right: 0 !important;
        }

        span {
          @extend %bodyText;
          padding: 0;
        }
      }
    }

    &.reduceSizeMobile { //Slightly reduced sizes, necessary for pages that have a long title

      @media screen and (max-width: $sizeMobileMax) {

        .mainOptions {

          .filterLogo {
            width: 40px;
          }

          .filterTitle {
            font-size: 22px !important;
          }

          .mainMenuIcon {
            margin-right: 0px !important;
          }

          .searchIcon, .searchAccesoriesIcon {
            padding: 0 3px;
            margin: 0 5px 0 0;
          }

        }

      }

    }

  }

}

.roundAddButton, .roundReputationButton {

  margin-left: 4px;
  min-width: initial !important;

  &, button {
    border-radius: 30px !important;
    width: 42px !important;
    height: 42px !important;
  }

  button {
    margin: 0 !important;

    div div {

      padding: 0 !important;
      height: 42px !important;

      span {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }

  svg {
    width: 24px !important;
    height: 24px !important;
    stroke: #ffffff;
    stroke-width: 2px;
    vertical-align: middle;
  }

}

.inventoryFiltersDialog.detailDialog {

  width: 80% !important;

  .wrappedMobileSearchAccessory {

    margin: 20px 30px;
    position: relative;

    .accessoryWrapper {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .accessoryDialogTitle {
        flex-grow: 1;
      }

      .accessoryDialogContainer {
        flex-grow: 2;

        .activityToolbarButtons {
          width: 100%;
        }
      }
    }

    .accessoryDialogTitle {
      @extend %bodyText;
      margin: 200px 0 16px;
      @media screen and (max-width: $sizeTabletMax) {
        margin: 20px 0 16px;
      }
    }

    .accessoryDialogContainer {

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        float: none;

        @media screen and (max-width: $sizeMobileMax) {
          flex-direction: row;
          width: 100% !important;
          align-items: baseline;
          justify-content: space-between;
        }
      }

      .exportButtonsContainer {

        position: absolute;
        top: 57px;
        display: flex;
        width: 100%;
        flex-direction: column;

        @media screen and (max-width: $sizeTabletMax) {
          position: inherit;
        }

        @media screen and (max-width: $sizeMobileMax) {
          flex: 0.1;
        }

        .exportButton {
          width: 100%;
          border-bottom: 1px solid $lineColor;

          &:last-child {
            border-bottom: none;
          }

        }

        .exportButtonIcon {
          padding-left: 0;
        }

      }

      .filterSelect, .dateButton {

        @media screen and (max-width: $sizeMobileMax) {
          width: 150px;
        }

        & {
          border: 4px solid #dddddd;
          border-radius: 10px;
          height: 48px;
          padding: 0 0 0 6px;
          margin-bottom: 20px;
        }

        .customFilterDropdown {

          div, span {
            @extend %bodyText;
          }

          > div > div > div {
            line-height: 48px !important;
          }

          button {
            top: 0 !important;
            right: 0 !important;
          }

        }

      }

      .dateButton {
        display: inline-block;
      }

      .activityToolbar {

        border-bottom: none;

        .activityToolbarButtons {

          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .compareButton {

            width: auto;

            span {
              @extend %bodyText;
            }

          }

        }

        .dateButton {
          display: inline-block;
        }

      }

    }

    .accessoryDialogCloseIcon {
      cursor: pointer;

      @media screen and (max-width: $sizeTabletMax) {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }
    }

  }

  @media screen and (max-width: $sizeTabletMax) {
    .filterItemsButton{
      border: none !important;
    }
  }
}
