.transactionDetail {

  .detailDescription {

    .detailTotals {
      padding: 0 15px;

      .cartDiscounts {
        margin-bottom: 1.6em;
      }

      .details {
        @extend %bodyText;
        display: flex;
        margin-top: 0;
        margin-bottom: 2px;

        &Total {
          font-family: $primaryFontFamily !important;
          font-weight: 600;
        }

        .number {
          margin-left: auto;
        }
      }
    }

    .addCustomerButton {
      margin-top: 20px;
    }

  }

  .buttonHolder {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .levelsToggleLabel {
      margin: 0;
      @extend %contentLabel;
    }

    .toggleHolder {
      margin-left: auto;
      padding-right: 30px;

      .customToggle {
        margin-bottom: 0 !important;
      }

      label {
        @extend %contentLabel;
      }
    }
  }

  .levelsRow {
    width: 100%;
    display: flex;
    margin-bottom: 5px;

    .levelsLabel {
      padding-right: 4px;
    }

    .levelsColumn {
      width: 50%;
    }
  }

}
