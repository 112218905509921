.devicesRow, .deviceDetailsIcon {
  .deviceStatus {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-block;
    padding: 0;
  }
}

.devicesRow .deviceStatus {
  margin: 0 10px;
}

.selectedListRow.devicesRow {
  svg {
    padding-left: 0
  }
}

.deviceDetails {
  &Icon {
    width: 24px;
  }

  &Row {
    display: flex;
    flex-direction: row;
    padding-bottom: 30px;
  }

  &DetailsColumn {
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
    }
  }
}

.deviceDetailsText {
  margin-top: 0;
  @extend %contentLabel;

  .deviceStatus {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-block;
    padding: 0;
    margin-right: 10px;
  }
}

.deviceBlockContainer {
  background-color: $subNavBackgroundColor;
  margin-bottom: 10px;
  display: flex;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;

  .deviceBlockIcon {
    margin-right: 15px;
  }

  .deviceBlockDetails {
    color: $regularColor;
    font-size: 12px;
  }
}

.managePermissions {
  display: flex;
  align-items: center;
  padding: 40px;
  border-bottom: 1px solid $lineColor;

  .toggleHolder {
    margin-right: 25px;

    .customToggle {
      margin-bottom: 0 !important;
    }
  }

  .header {
    height: auto;
    @extend %columnLabel;
    padding: 0;
  }

  .text {
    @extend %contentLabel;
  }

}

