.accountIntegrations,
.accountReferrals,
.accountForms,
.accountPaSupplies {
  padding-left: 10%;
  padding-right: 10%;

  @media screen and (max-width: $sizeMobileMax) {
    padding: 0 5%;
  }
}

.shippingAddressSelect {
  width: 200px !important;
  @media screen and (max-width: $sizeMobileMax) {
    margin-left: 20px;
  }
}

.account {
  .businessSettingsSubsection {
    padding: 60px 10% 50px;
    box-sizing: border-box;
    margin: 0;
    overflow-x: hidden;

    .textFieldHolder {

      div > div {
        align-content: center;
        display: flex;
        align-items: center;
        text-wrap: wrap;
      }
    }

    .invoiceAlerts {
      &Container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        &, & .subText {
          margin-bottom: 3em;
        }

      }
      &Header {
        font-weight: bolder !important;
        margin-top: 4em;
        padding-bottom: 5px;
      }
      &Header, &Description {
        flex: 1 1 100%;
      }
      &Column {
        padding-right: 10%;

        &.withTooltip {
          gap: 10px;
          display: flex;
          align-items: center;
        }
      }
    }

    @media screen and (max-width: $sizeDesktopMin) {
      padding-left: 7%;
      padding-right: 7%;
    }

    @media screen and (max-width: $sizeTabletMax) {
      padding-top: 50px;
    }

    @media screen and (max-width: $sizeMobileMax) {
      padding: 35px 4% 50px;
    }

    &.appSettings,
    &.receiptSettings,
    &.alertsHolder {
      margin-bottom: 100px;

      @media screen and (max-width: $sizeMobileMax) {
        margin-bottom: 134px;
      }
    }

    .cardContainer {
      display: flex;
      flex-wrap: wrap;
    }

    .tagsContainer {
      display: flex;
      flex-wrap: wrap;

      .tag {
        background: #F7F7F7 0% 0% no-repeat padding-box;
        color: #000000;
        font-size: 10px;
        margin-bottom: 5px;
        margin-right: 5px;
        padding: 16px 0;
      }
    }

    .connected{
      background: #000000DE 0% 0% no-repeat padding-box;
      border-radius: 0 6px 0 0;
      clip-path: polygon(0% 0%, 100% 100%, 100% 0%, 100% 0%);
      height: 36px;
      position: absolute;
      right: 0;
      top: 0;
      width: 36px;

      .iconWrapper{
        position: relative;

        svg{
          height: 12px;
          position: absolute;
          right: 5px;
          top: 5px;
          width: 12px;
        }
      }
    }

    #intuButton {
      img {
        cursor: pointer;
      }
    }

  }

  .alertError {
    text-align: center;
  }

  .businessProfileContainer {
    .title {
      margin-bottom: 50px;
    }

    .editButton {
      padding: 0 0 50px;
    }

    .profileBusinessForm {
      padding-top: 0;
    }
  }

  .funding {
    .fundingOptimizationContainer {
      .secondaryTitle {
        padding: 50px 0 30px;
      }
    }
  }
}

.accountPaSupplies {
  .formHeading {
    @extend %contentLabel;
    padding: 0 48px;
    margin-bottom: 10px;
  }

  .supplyItem {
    line-height: 2;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: $sizeMobileMax) {
      flex-direction: column;
      margin-bottom: 20px;
      border-bottom: 1px solid $lineColor;
    }
  }

  .supplySummary {
    padding-bottom: 5px;
    padding-top: 5px;
    margin-bottom: 5px;
  }

  .totalOrder {
    margin-top: 10px;
    border-top: 4px solid #dddddd !important;
    box-shadow: 0 3px 0 0 white, 0 4px 0 0 #dddddd;
    border-bottom: none;
  }

  .nameColumn {
    width: 30%;

    @media screen and (max-width: $sizeMobileMax) {
      width: 100%;
      text-align: center;
    }
  }

  .priceColumn {
    width: 10%;

    @media screen and (max-width: $sizeMobileMax) {
      width: 100%;
      text-align: center;
    }
  }

  .selectColumn {
    width: 40%;

    @media screen and (max-width: $sizeMobileMax) {
      width: 100%;
    }
  }

  .totalsColumn {
    float: right;
    width: 20%;
    text-align: right;

    @media screen and (max-width: $sizeMobileMax) {
      float: none;
      width: 100%;
      text-align: center;
    }
  }

  .buttonWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .counterTextContainer {
      margin-left: 60px;
    }

    .buttonColor {
      svg {
        fill: $payAnywhereColor !important;
      }
    }
  }

  .applePayContainer {
    text-align: center;
    margin: 15px;
    font-size: 18px;
  }

  .orderTotal {
    @media screen and (max-width: $sizeMobileMax) {
      text-align: center;
      display: block;
      float: none;
    }
  }
}

.accountIntegrations .pageWrap {
  width: 50%;
  padding-top: 50px;
  min-width: 700px;

  td.quickbooks-btn {
    float: right;
    @media screen and (max-width: $sizeMobileMax) {
      padding-top: 35px;
      float: none;
    }
  }

  .integration-logo {
    height: 36px;
    width: 36px;
    display: inline;
    vertical-align: middle;
    margin-right: 20px;
  }

  .integrations-title {
    color: #888c8d;
    font-weight: 500;
  }
}

.accountForms {
  a {
    color: $payAnywhereColor;
    text-decoration: none;
  }
}

.accountSupplies {
  display: flex;
  flex-direction: column;
  height: 100%;

  .accountSuppliesWrapper {
    display: flex;
    height: 100%;

    .supply_content {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .itemsList {
      display: flex;
      flex-direction: column;
      width: 100%;

      .itemRow {
        cursor: default;
        height: 70px;
        border-bottom: 1px solid $lineColor;

        .itemItems {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 70px;
        }

        .itemColumn {
          padding: 20px 8px;
          display: flex;
          flex-basis: 0;
          flex-grow: 1;

          @media screen and (max-width: $sizeMobileMax) {
            padding: 15px 8px 0;
            text-align: center;
          }

          .itemColumnItem,
          span {
            @extend %bodyText;

            @media screen and (max-width: $sizeMobileMax) {
              text-align: center;
              width: 100%;
            }
          }

          .trackingNumber {
            overflow-wrap: break-word;
          }

          &.name {
            width: 30%;

            @media screen and (max-width: $sizeMobileMax) {
              width: 30%;
            }

            .itemColumnItem,
            span {
              @media screen and (max-width: $sizeMobileMax) {
                padding-left: 2%;
              }
            }

            .iconHeader {
              padding: 0;
              width: 7px;
            }
          }
        }
      }

      .itemHeader {
        background-color: $lightGray;
        height: 37px !important;
        border-top: 1px solid $lineColor;
        border-bottom: 1px solid $lineColor;

        @media screen and (max-width: $sizeMobileMax) {
          height: 15px !important;
          padding: 10px 0;
        }

        .itemItems {
          height: auto;
        }

        .itemColumn {
          padding: 12px 8px;

          @media screen and (max-width: $sizeMobileMax) {
            padding-top: 0;
            text-align: center;
          }

          span {
            @extend %columnLabel;

            @media screen and (max-width: $sizeMobileMax) {
              font-size: 13px !important;
              text-align: center;
              padding: 0;
            }
          }

          .iconHeader {
            margin-left: 3px;
            width: 7px;
            padding: 0;

            svg {
              height: 7px;
              width: 7px;
            }
          }

          .flipIcon {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  .cartContainer {
    box-shadow: 0 -5px 10px -10px rgba(163, 163, 166, 1);
    padding: 15px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .amountContainer {
      padding-right: 10px;
      display: flex;
      align-items: center;

      @media screen and (max-width: $sizeMobileMax) {
        display: none;
      }

      div {
        float: right;
      }

      .cartQuantity {
        @media screen and (max-width: $sizeMobileMax) {
          display: none !important;
        }
      }

      .totalAmount {
        @extend %bodyText;
        font-family: $primaryFontFamily !important;
        font-weight: 600;
        padding-top: 10px;
        margin-right: 15px;
      }
    }

    .actionContainer {
      padding: 0 35px 0 15px;

      span {
        font-weight: 600;
      }
    }
  }

  .titleContainer {
    display: flex;
    width: 100%;

    .titlePanel {
      display: flex;
      height: 100px;
      align-items: center;
      border: none;

      @media screen and (max-width: $sizeMobileMax) {
        height: 50px;
      }

      .title {
        @extend %subheading;
        padding-left: 48px;

        @media screen and (max-width: $sizeMobileMax) {
          padding: 0;
        }

        .orders {
          @extend %contentLabel;

          @media screen and (max-width: $sizeMobileMax) {
            text-align: center;
          }
        }
      }
    }

    .terminalList {
      border: 1px solid;
    }

    & > p {
      width: 100%;
      display: block;
      font-size: 13px;
      color: #888c8d;
    }
  }

  .filterMobile {
    .filterTitle {
      @media screen and (max-width: $sizeMobileMax) {
        width: 7em;
        padding: 0.5em 0;
      }
    }
  }

  .supplyContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    font-family: $primaryFontFamily;

    @media screen and (max-width: $sizeMobileMax) {
      flex-direction: column;
      width: 100%;
    }

    .terminalsAndSupplies {
      height: 100%;
      flex-direction: column;
      transition: all 0.5s;
      display: flex;
      width: 100%;

      &.withSearchBar {
        height: calc(100vh - 65px);
      }
    }

    .iPhoneHeight {height: 75% !important}

    .androidHeight {height: 80% !important}

    .suppliesGridContainer {
      overflow: auto;
      height: 100%;
      margin: 0;
      padding: 131px 5% 0;

      @media screen and (max-width: $sizeTabletMax) {
        padding-top: 5%;
      }

      @media screen and (max-width: $sizeMobileMax) {
        padding: 0;
        width: 100%;
      }

      .suppliesGrid {
        position: relative;
        margin: 0 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        height: 125px;

        .supplyDetailHeader {
          padding: 25px;
          background-color: $lightGray;
          margin-right: 2%;
          width: 70%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .detailContainer {
            width: 70%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .detailImage {
              width: 60px;
              margin-right: 25px;
              flex-shrink: 0;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: auto;
                height: auto;
                max-height: 60px;
              }

              .imagePlaceholder {
                width: 50px;
                height: 50px;
                margin: 0 auto;
                background: $gray;
                border-radius: 5px;
              }
            }

            .detailName {
              .detailTitle {
                @extend %bodyText;
                font-family: $primaryFontFamily !important;
                font-weight: 600;
                margin-bottom: 3px;
              }
              .detailDescription {
                @extend %contentLabel;
              }
            }
          }

          .detailPrice {
            @extend %bodyText;
            font-family: $primaryFontFamily !important;
            font-weight: 600;
            width: 30%;
            text-align: right;
          }
        }

        .supplyDetailHeader,
        .supplyActions {
          border-radius: 7px;
        }

        .supplyActions {
          padding: 0;
          box-sizing: border-box;
          width: 28%;
          background-color: $lightGray;
          display: flex;
          justify-content: center;
          align-items: center;

          form {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .addButtonContainer {
            @extend %link;
            text-align: center;
          }

          form {
            text-align: center;

            .quantityField {
              background-color: #ffffff !important;
              border: 4px solid $listItemBackground;
              border-radius: 9px;
              width: 4em !important;
              height: 3em !important;
              margin: 0 14px;

              div:after, div:before {
                display: none;
              }

              input {
                @extend %contentLabel;
                text-align: center;
                margin-top: 6px;
              }
            }
          }
        }

        @media screen and (max-width: $sizeMobileMax) {
          & {
            margin: 15px;
            height: 160px;
            width: 90%;

            .supplyDetailHeader {
              padding: 25px 10px;
              width: 75%;

              .detailContainer {
                .detailImage {
                  margin-right: 13px;
                }
              }
            }

            .supplyActions {
              width: 23%;

              form .quantityField {
                margin: 0;
              }
            }
          }
        }

        .quantityField {
          @extend %columnLabel;
          width: 25px !important;
          margin: 0;
          padding: 0;
        }

        .quantityButton {
          width: 18px !important;
          height: 18px !important;
          margin: 0 !important;
          padding: 2px !important;

          svg {
            display: flex;
            width: 10px !important;
            height: 10px !important;
          }
        }
      }
    }

    .supplyDetailClose {
      display: none;
      padding: 0;
      border: none;

      .orderSummary {
        display: none;
      }
    }
  }

  .orderHistoryContainer {
    display: flex;
    transition: all 0.5s;

    .titleContainer {
      .titlePanel {
        @media screen and (max-width: $sizeTabletMax) {
          height: auto;
          padding: 25px 0 20px;

          .title {
            padding-left: 24px;
          }
        }
      }
    }

    .orderHistoryList {
      position: relative;
      height: calc(100vh - 65px);
      overflow: auto;
      width: 100%;

      .orderList {
        .itemRow {
          cursor: pointer;
        }
      }
    }

    .orderItem {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 1px solid rgb(224, 224, 224);
      cursor: pointer;
      transition: all 0.2s;

      .orderItemColumn {
        display: flex;
        flex-direction: column;
        font-size: 16px;
      }

      .orderItemColumn:last-child {
        align-items: flex-end;
        padding-right: 40px;
      }

      .orderItemColumn div:not(:first-child) {
        color: #888c8d;
      }

      .orderItemColumn div:not(:last-child) {
        padding-bottom: 10px;
      }
    }

    .orderItem:hover {
      background-color: rgb(224, 224, 224);
      border-radius: 15px 0 0 15px;
      margin-left: -30px;
      padding-left: 30px;
      margin-top: -1px;
    }
  }

  .toolbar {
    .searchBar {
      .searchBarContainer {
        @media screen and (max-width: $sizeMobileMax) {
          justify-content: normal;
        }

        .textFieldContainer {
          @media screen and (max-width: $sizeMobileMax) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .mobileTools {
    display: none;

    @media screen and (max-width: $sizeMobileMax) {
      display: block;
    }

    .filterDropdown {
      svg {
        fill: $iconColor !important;
      }
    }
  }
}

.summaryFooter {
  span .buttonLabel {
    font-weight: 600;
  }
  @media screen and (max-width: $sizeTabletMax) {
    justify-content: space-evenly !important;
    width: 100% !important;
  }
  @media screen and (max-width: $sizeMobileMax) {
    justify-content: space-between !important;
    width: 100% !important;
  }
}

.orderSummaryDialog {
  .orderSummary {
    display: flex;
    flex-direction: column;
    height: 100%;

    .orderSummaryHeader {
      margin: 30px;
      display: block;

      @media screen and (max-width: $sizeMobileMax) {
        margin: 30px 10px;

        & > div.OrderSummaryTitle {
          padding-left: 0;
          padding-right: 0;
        }
      }

      & > .OrderSummaryTitle {
        @extend %heading;
        padding: 30px 83px 0 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .close {
        cursor: pointer;
        display: flex;
        svg {
          fill: rgb(136, 140, 141);
        }
      }
    }

    .orderSummaryClearButton {
      display: block;
      margin: 0;
      padding: 0;

      .clearButton {
        @extend %link;
      }
    }

    .orderSummaryList {
      display: flex;
      flex-direction: column;
      margin: 15px 80px;
      overflow: auto;
      flex-grow: 1;

      @media screen and (max-width: $sizeMobileMax) {
        margin: 10px;
      }

      .summaryListContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        .summaryListRow {
          @extend %bodyText;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 5px;
          margin-bottom: 15px;

          .summaryListRowDetails {
            background-color: $lightGray;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            min-height: 50px;
            width: 100%;
          }

          .summaryListRowDescription {
            margin-right: 10px;
            width: 80%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .summaryListRowImage {
              width: 60px;
              margin-right: 25px;
              flex-shrink: 0;

              img {
                width: 100%;
                height: auto;
              }

              .imagePlaceholder {
                width: 50px;
                height: 50px;
                margin: 0 auto;
                background: $gray;
                border-radius: 5px;
              }
            }
          }

          .summaryListRowAmount {
            width: 20%;
            text-align: right;
          }

          .summaryListRowActions {
            text-align: center;
            height: 22px;
            width: 24px;
            margin-left: 15px;
            cursor: pointer;
          }
        }
      }
    }

    .orderSummaryTotal {
      @extend %bodyText;
      font-family: $primaryFontFamily !important;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      padding: 30px 50px 0 20px;

      .summaryTotal {
        display: flex;
        flex-direction: column;
        width: 100%;

        .summaryTotalRow {
          display: flex;
          flex-direction: row;
          height: 30px;
          width: 100%;

          & > div:first-child {
            display: block;
            text-align: left;
            width: 50%;
          }

          & > div:last-child {
            display: block;
            text-align: right;
            width: 50%;
          }
        }

        & > div:last-child {
          display: flex;
          flex-direction: row;
          height: 30px;
          width: 100%;
          font-weight: bold;

          & > div:first-child {
            display: block;
            text-align: left;
            width: 50%;
          }

          & > div:last-child {
            display: block;
            text-align: right;
            width: 50%;
          }
        }
      }
    }

    .orderSummaryButton {
      box-shadow: 0 -5px 10px -10px rgba(163, 163, 166, 1);
      padding: 0;
      text-align: right;

      .proceedToCheckout {
        width: auto;
        padding: 29px 44px;
        display: inline-block;

        .orderSummaryButton {
          padding: 15px;
          margin: auto;
        }
      }
    }
  }
}

.checkoutHardwareSuppliesDialog {
  > div > div {
    border: none !important;
  }

  @media screen and (max-width: $sizeMobileMax) {
    &.detailDialog .dialogBody {
      padding: 0 0 109px 0 !important;
      overflow: auto;
    }

    .dialogBody + div {
      height: 109px;
    }
  }
}

.successDialog {
  @extend %bodyText;

  .dialogBody > div {
    padding: 88px 80px 0;
  }

  .dialogBody + div {
    padding: 29px 44px !important;
  }

  h2 {
    @extend %heading;
    margin-bottom: 45px;
  }

  .thankYou,
  .recurringInfo {
    @extend %bodyText;
    font-family: $primaryFontFamily !important;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .successDescription {
    @extend %bodyText;
  }
}

.orderHistoryDetailsContainer {
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;

  .orderDetailsHeader {
    .title {
      @extend %heading;
      padding: 30px 50px 45px;

      @media screen and (max-width: $sizeMobileMax) {
        text-align: center;
        padding: 15px 0;
        margin: auto;
      }
    }

    .close {
      cursor: pointer;

      svg {
        fill: rgb(136, 140, 141);
      }
    }
  }

  .orderDetailsRow,
  .orderTrackingNumber,
  .orderInvoiceNumber,
  .orderShippingDate {
    @extend %bodyText;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .orderDetailsSection {
    display: flex;
    flex-direction: column;
    padding: 0 50px 40px;

    @media screen and (max-width: $sizeMobileMax) {
      padding: 0 0 20px;
    }
  }

  .orderDetailsPurchases {
    display: flex;
    flex-direction: column;
    padding: 40px 50px;

    @media screen and (max-width: $sizeMobileMax) {
      padding: 40px 0;
    }
  }

  .orderDetailsHeading {
    @extend %columnLabel;
    padding-bottom: 8px;
  }

  .orderDetailsList {
    display: flex;
    flex-direction: column;
  }

  .orderDetailsItems {
    display: flex;
    margin-bottom: 10px;

    .orderDetailsItemName {
      display: flex;

      div {
        margin-right: 10px;
      }
    }
  }

  .orderDetailsItem {
    @extend %bodyText;
    justify-content: space-between;
    background-color: $lightGray;
    border-radius: 5px;
    display: flex;
    padding: 20px;
    width: 100%;
  }

  .orderDetailsTotals {
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    .orderDetailsTotalsRow {
      @extend %bodyText;
      font-family: $primaryFontFamily !important;
      font-weight: 600;
      justify-content: space-between;
      padding: 0px 20px;
      display: flex;
    }
  }
}

.checkoutContent {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
  padding-bottom: 200px;

  .orderFormContainer {
    margin: 15px 44px;

    @media screen and (max-width: $sizeMobileMax) {
      margin: 15px 10px;
    }

    .flex {
      @media screen and (max-width: $sizeTabletMin) {
        display: block;

        .quarterScreen {
          width: 50% !important;
        }
      }
    }
  }

  .loadingIndicator {
    margin: 10px 0;
  }

  &.checkoutLoading {
    .close {
      margin: 30px;
    }
  }

  form {
    padding: 0 23px;

    @media screen and (max-width: $sizeMobileMax) {
      padding: 0px;
    }

    formHeading {
      @extend %columnLabel;
      padding-top: 10px;
    }

    .cardContainerWithVisibilityToggle {
      position: relative;
      display: inline-block;
      width: 100%;

      .cardImage {
        position: absolute;
        top: 36px;
        right: 54px;
      }

      .cardNumber {
        width: 100%;
      }

      #cardNumberVisibilityIcon {
        position: absolute !important;
        top: 28px;
        right: 6px;
        padding: 12px;
      }
    }

    .textField {
      padding: 0 6px;
      margin-bottom: 18px;
      box-sizing: border-box;
      font-size: 12px;
      vertical-align: top;

      label {
        font-family: $primaryFontFamily;
        font-weight: 500;
        color: $primaryTextColor;
      }

      .textFieldHolder {
        div > div {
          //hintText

          @media screen and (max-width: 1185px) {
            font-size: 12px !important;
          }
        }
      }
    }
  }

  .checkoutContainer {
    @media screen and (max-width: $sizeMobileMax) {
      height: 80vh !important;
      padding: 10px !important;
    }
  }

  .orderCheckoutHeader {
    margin: 30px;

    & > .OrderCheckoutTitle {
      @extend %heading;
      padding: 30px 0 15px 50px;
    }

    & > .payTitle {
      @extend %contentLabel;
      padding: 30px 0 10px 50px;
    }

    p {
      @extend %bodyText;
      padding: 0 30px 10px 50px;
    }

    .close {
      cursor: pointer;

      svg {
        fill: rgb(136, 140, 141);
      }
    }
  }

  .paymentError {
    @extend %columnLabel;
    padding: 10px 0 0 32px;
    color: $errorColor;
  }

  .paymentMethod,
  .shippingMethod,
  .deliverTo,
  .total {
    @extend %contentLabel;
    padding: 0 80px;
    margin-bottom: 25px;

    & > p {
      @extend %columnLabel;
      margin: 5px 0;
    }

    label {
      @extend %contentLabel;
    }
  }

  .paymentMethod,
  .shippingMethod,
  .total {
    & > div > p {
      @extend %contentLabel;
    }
  }

  .paymentMethod {
    @extend %contentLabel;

    & > div > p {
      margin-left: 40px;
    }
  }

  .deliverTo {
    margin-top: 10px;

    .deliveryAddressDetail {
      display: flex;
      @media screen and (max-width: $sizeMobileMax) {
        flex-direction: column;
      }

      div:nth-child(2) {
        margin: auto;
      }

      & > p {
        padding-bottom: 5px;

        @media screen and (max-width: $sizeMobileMax) {
          font-family: $primaryFontFamily !important;
          font-weight: normal;
          font-size: 11px !important;
        }
      }

      & > div > p {
        @extend %bodyText;
        padding-bottom: 5px;
      }
    }
  }

  .total {
    background-color: $lightGray;
    flex-direction: column;
    border-radius: 5px;
    display: flex;
    padding: 30px;
    margin: 0 80px;

    @media screen and (max-width: $sizeMobileMax) {
      padding: 15px 30px;
    }

    & > div {
      @extend %bodyText;
      display: flex;
      flex-direction: row;
      padding-bottom: 5px;
      width: 100%;

      & > div:first-child {
        width: 80%;
      }

      & > div:last-child {
        width: 20%;
        text-align: right;
      }
    }

    & > div:last-child {
      font-family: $primaryFontFamily !important;
        font-weight: 600;
    }
  }
}

.checkoutActions {
  padding: 15px 30px;
  background: #ffffff;

  @media screen and (max-width: $sizeMobileMax) {
    text-align: center;
  }
}

.cityInput,
.shippingZipInput,
.corporateZipInput,
.businessZipInput {
  width: 40% !important;

  @media screen and (max-width: $sizeMobileMax) {
    width: 50% !important;
  }
}

.stateInput {
  width: 10% !important;
  vertical-align: bottom; // FIX: Material-UI - Aligns SelectField with other fields on a line

  @media screen and (max-width: $sizeMobileMax) {
    width: 50% !important;
  }
}

.leftAlerts,
.rightAlerts {
  @extend .halfScreen;
  vertical-align: top;
}

table .fileIcon {
  @media screen and (max-width: $sizeMobileMax) {
    width: 20% !important;
    padding: 0 !important;
  }
}

.formLinks,
.taxLinks,
.secondaryText,
.equipmentLinks {
  line-height: 19px;

  a {
    @extend %link;
  }
}

div .disclaimer {
  color: $secondaryTextColor !important;
  font-size: 8px !important;
  font-family: $primaryFontFamily;
  font-weight: 500;
  line-height: 10px !important;
  min-height: 10px !important;
}

.customAlertCheckBox {
  @extend .halfScreen;
  @extend .portalCheckbox;
  height: 34px !important;
  display: inline-block !important;

  label {
    border-bottom: solid 1px rgb(224, 224, 224);
    width: 100% !important;
    bottom: -2px;
  }
}

.customCheckBox {
  height: 34px !important;
  display: inline-block !important;

  label {
    line-height: inherit !important;
    width: 100% !important;
    @extend %columnLabel;
  }

  svg + svg {
    fill: $payAnywhereColor !important;
    stroke: none !important;
    color: $payAnywhereColor !important;
  }

  svg {
    fill: #ffffff !important;
    stroke: none !important;
    color: $gray !important;
  }
}

.bannerText {
  font-size: 28px;
  font-weight: 500;
}

.dialogSubtitle {
  font-family: $primaryFontFamily;
  font-weight: 600;
  text-transform: none;
}

li {
  &:before {
    left: -25px !important;
    padding: 8px;
  }
}

.addAccountDialog {
  > div {
    > div {
      > div {
        border-radius: 5px !important;
      }
    }
  }
}

.merchantAccountRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: none !important;
  color: $secondaryTextColor !important;
  padding: 5px;
  cursor: pointer;

  .merchantAccountInfo,
  .merchantAccountId,
  .addMerchantAccount {
    display: inline-block;
  }

  .merchantAccountName,
  .addMerchantAccount {
    flex: 1;
    margin-left: 10px;
  }

  .addMerchantAccount {
    color: $payAnywhereColor !important;
    border-bottom: 1px solid $cardBorderColor;
    padding-bottom: 25px;
    margin-bottom: 25px;
    position: relative;
    top: 3px;
  }

  .merchantAccountId {
    text-align: right;

    p {
      &:first-child {
        margin: 0;
      }

      &:last-child {
        font-size: 12px;
        font-weight: bold;
        margin-top: 2px;
      }
    }
  }

  svg {
    fill: $secondaryTextColor !important;
  }
}

.merchantAccountRow.closedAccount {
  color: $secondaryTextColor !important;

  svg {
    fill: $secondaryTextColor !important;
  }
}

.merchantAccountRow.active {
  color: $payAnywhereColor !important;

  svg {
    fill: $payAnywhereColor !important;
  }
}

.updateContainer {
  text-align: right;
  display: inline-block;
  padding-bottom: 4px;
  border-bottom: 1px solid rgb(224, 224, 224);

  @media screen and (max-width: $sizeMobileMax) {
    border-bottom: 0;
  }
}

.updateEmailContainer,
.updatePasswordContainer,
.updateTwoFactorContainer,
.membershipOption,
.membershipDialogButton {
  @media screen and (max-width: $sizeMobileMax) {
    font-size: 9px !important;
  }
}

.upgradeMembershipContainer {
  width: 100%;
  text-align: center;
  padding-top: 2%;

  button {
    max-width: 250px;
  }

  .upgradeMembershipButton {
    width: 200px;
  }

  @media screen and (max-width: $sizeMobileMax) {
    font-size: 9px !important;
  }
}

.membershipDialogButton {
  font-size: 14px;
  display: inline-block;
  padding: 25px;
}

.updateErrorMessage {
  font-size: 12px !important;
}

.formItems {
  .tooltipHolder {
    right: 77px;
    top: 204px;

    @media screen and (max-width: $sizeTabletMax) {
      right: 50px;
    }

    @media screen and (max-width: $sizeMobileMax) {
      top: 490px;
      right: 30px;
    }
  }

  .cashDiscountInfo {
    width: 25%;
    display: inline-block;
    padding-bottom: 1%;
    font-weight: 600;
  }

  .tooltipCashDiscount {
    position: absolute;
    word-wrap: break-word !important;

    &:hover {
      &:after {
        font-size: 12px;
        top: -55px;
        max-width: 300px;
        min-width: 200px;
      }
    }

    @media screen and (max-width: $sizeTabletMax) {
      right: 50px;
    }

    @media screen and (max-width: $sizeMobileMax) {
      top: 490px;
      right: 30px;
    }
  }
}

.profileEmailAddressField,
.profilePasswordField {
  width: 50% !important;

  @media screen and (max-width: $sizeMobileMax) {
    width: 75% !important;
  }
}

.importItemsForm {
  p {
    padding-left: 34px !important;
  }
}

.templateDownload {
  padding: 20px;
}

.dialogBody {
  max-height: 460px !important;
}

.barButton {
  background-color: white !important;

  span {
    @extend %heading2;
    color: $payAnywhereColor !important;
  }
}



.idology {
  @extend %contentLabel;
  overflow-x: hidden;

  &DialogTitle {
    @extend %heading;
    text-align: center;
  }

  &FormHeading {
    font-family: $primaryFontFamily;
    font-weight: 500;
    font-size: 22px;
    color: $primaryTextColor;
    margin-bottom: 25px;
  }

  &FormText {
    @extend %bodyText;
    line-height: 5px;
    margin: 15px 0;
  }

  &Select {
    margin-top: 15px;
  }

  &Button {
    margin: 0 20px 20px 0 !important;
  }

  .textFieldHolder div {
    padding-right: 0 !important;
  }
}

.messageDialog {
  &.changePasswordDialog,
  &.messageDialog.changeEmailDialog,
  &.changeTwoFactorDialog,
  &.manageRememberedDevicesDialog {
    .dialogBody {
      overflow-y: auto !important;
      max-height: 100% !important;
    }

    .headerBody {
      margin-bottom: 20px;
    }

    .textField {
      margin: 0 0 18px 0 !important;
    }
  }

  .twoFactorSettings,
  .updateEmailForm,
  .updatePasswordForm {
    padding: 5px 0;
  }

  .tiersUpgradedPlanDialogContent {
    padding: 0 !important;
  }

  .chargezoomDialogContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;

    @media screen and (max-width: $sizeMobileMax) {
      img {
        width: 60vw;
      }
    }
  }

  .loyaltyAddOnDialogContent {
    text-align: center;

    span {
      font-size: 14px;
    }

    p {
      font-size: 11px;
      padding: 15px;
    }
  }

}

.idologyDialog {
  .idologyFormText {
    line-height: 19px;
  }
}

.communicationSelect,
.statementsHolder {
  font-size: 16px;
  border-bottom: 1px solid $lineColor;
  line-height: 24px;
  margin-bottom: 8px;
  display: inline-block;
  vertical-align: bottom;
}

.communicationSelectPending,
.emailVerifyContainer {
  width: 25% !important;
  display: inline-block;
}

.emailVerifyContainer {
  line-height: 24px;
  margin-bottom: 8px;
  display: inline-block;
  padding: 0 0 0 21px;
  box-sizing: border-box;

  span {
    display: block;
    line-height: 20px !important;
  }

  .email,
  .pending {
    @extend %contentLabel;
  }

  .pending {
    display: flex;
    align-items: center;

    .text {
      padding-bottom: 5px;
    }

    .icon {
      padding-left: 5px;
      cursor: pointer;
    }
  }
}

.alertsHolder {
  @extend %contentLabel;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 14%;
  padding-right: 14%;
  margin: 30px 0;
}

.apiAccessHolder {
  @extend %contentLabel;

  @media screen and (min-width: $sizeDesktopMax){
    padding: 131px 25% 50px 10%;
  }

  & {
    width: 100%;
    overflow-y: auto;
    padding: 131px 10% 50px 10%;
    box-sizing: border-box;
    margin: 0;
    overflow-x: hidden;
  }

  .error {
    color: $errorColor;
  }

  .borderError {
    border: 1px solid $errorColor;
  }

  .credentials + .formSection {
    margin-top: 45px;
  }

  .midListPane {
    box-shadow: 0px 3px 6px $borderShadowColor;
    border: 0.5px solid $borderGrayColor;
    border-radius: 8px;
    padding: 10px;
    margin: 0 0 30px 0;
    height: 150px;
    overflow-y: scroll;

    .remove {
      color: $paymentHubsColor;
    }
    .mid {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0px 10px;

      &:hover {
        cursor: pointer;
        background: $backgroundColor5;
      }
    }
  }
  .formSection {

    &.separator {
      border-top: 1px solid $borderGrayColor;
      border-radius: 8px;
    }

    &.topSpacing {
      padding-top: 25px;
    }

    .formToggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .jsSDKField {
      margin-bottom: 0;
    }

    .domainFieldContainer {
      position: relative;
      display: flex;
      justify-content: end;
      align-items: center;
      .lockIcon {
        display: inline-flex;
        position: absolute;
        margin-right: 10px;

        &.withError {
          bottom: 37px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .credentials {
    margin-top: 29px;

    .credInfo {
      font-size: 12px;
      padding: 13px 20px;
    }
    .credential {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 19px;
      background: $backgroundColor3 0% 0% no-repeat padding-box;
      border-radius: 30px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .data, .copyIcon {
        display: inline-flex;

        &:hover {
          background-color: transparent;
        }
      }

      .dataKey {
        text-wrap: nowrap;
        display: flex;
        align-items: center;
      }

      .dataValue {
        word-break: break-word;
        padding: 0 10px;
      }

      .copyIcon {
        cursor: pointer;
        padding: 0;
      }

      .dataKey {
        color: $navyBlue;
      }
    }
  }

  .segment {
    box-shadow: 0px 3px 6px $borderShadowColor;
    border: 0.5px solid $borderGrayColor;
    border-radius: 8px;
    padding: 24px 32px 32px;

    .segmentInfo {
      margin-bottom: 42px;
    }

    .getCredentialsButton {
        svg {
          padding-right: 10px;
          position: relative;
          bottom: 1px;
        }
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .importantText {
      color: $errorColor;
      text-transform: uppercase;
    }
  }

  .titleText {
    @extend %heading;
    display: inline-block;
    width: 100%;
    color: $primaryTextColor;
    margin-bottom: 0.4em;
  }

  .subTitle {
    font-size: 18px;

    .error {
      font-size: 13px;
    }
  }

  .subText {
    @extend %contentLabel;
    display: inline-block;
    width: 100%;
    color: $primaryTextColor;
    margin-bottom: 2.6em;
  }

  .actions {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    &.alignRight {
      justify-content: flex-end;
    }

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}

.jsSdkForm, .webHookForm {
  @media screen and (min-width: $sizeMobileMax) {
    width: 520px;
  }
}

.businessFormHolder {
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;

  .additionalInfo {
    padding: 30px 60px 20px 0;
  }

  .ownersInfo {
    padding-bottom: 60px;
    padding-top: 25px;

    .ownersDetail {
      @extend %bodyText;
    }
  }
}

.businessForm {
  position: relative;

  .title {
    .heading {
      @extend %heading;
      display: inline-block;
      width: 100%;
    }

    .subText {
      @extend %contentLabel;
      display: inline-block;
      width: 100%;
      padding-top: 5px;
    }
  }

  .editButton {
    padding: 20px 0;
    display: flex;
    z-index: 2;

    span {
      display: flex;
      align-items: center;
      @extend %bodyText;
      color: $payAnywhereColor;
    }
  }

  .businessInfo {
    margin-bottom: 25px;

    .info {
      font-size: 16px;
      margin: 0;
      line-height: 25px;
    }
  }
}

.statements {
  margin-bottom: 30px;

  .statementNotice {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;

    &Icon {
      padding-right: 5px;
    }
  }
}

.irsInformation {
  margin-bottom: 100px;
}

.attachFilesTitle {
  color: $black !important;
  font-family: $secondaryFontFamily;
  border-bottom: 0 !important;
  text-align: center;
  padding: 20px;
}

.commItem {
  display: flex;
  flex-direction: row;
  font-size: 13px;

  &Icon {
    vertical-align: top;
  }

  &Data {
    font-size: 12px;
    color: $lineColor;
  }
}

.businessButtonHolder {
  margin: 30px 0 50px 0;

  .saveBusiness {
    min-width: 300px !important;
    height: 26px !important;
  }
}

.additionalInfoHolder,
.primaryBankingHolder {
  position: relative;
  margin-bottom: 40px;

  .tooltipHolder {
    @media screen and (max-width: $sizeMobileMax) {
      top: -5px;
    }
  }
}

.profileBankingForm {
  padding-bottom: 50px;

  .textField {
    padding-top: 25px;

    span {
      position: absolute;
      padding: 5px 0 0 5px;
    }
  }

  .primaryBankingHolder {
    margin-top: 5px;
  }

  .routingNumber {
    padding-right: 2%;
    width: 48%;
  }

  .bankAccount {
    padding-left: 2%;
    width: 48%;
  }
}

.bankingHolder {
  position: relative;
  margin-top: 20px;
  margin-bottom: 40px;

  .button {
    cursor: pointer;
    font-family: $secondaryFontFamily;
  }

  .tooltipHolder {
    @media screen and (max-width: $sizeMobileMax) {
      top: -5px;
    }
  }

  .buttonTooltip {
    position: absolute;
    top: 7px;
    margin-left: 10px;
  }

  input[type="checkbox"] {
    display: none;
  }

  .label {
    font-weight: 500;
    cursor: pointer;
  }
}

.communicationDisclaimer {
  color: $iconColor;
  margin: 0;
}

.profileSection {
  margin: 0 0 4em;

  &Holder {
    position: relative;
    padding: 0;
    margin: 0 0 4em;
  }

  .profileSectionHolder.businessProfile {
    margin: 0;
  }

  .ownerProfileActions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .personalInformation {
    padding-top: 20px;

    .textField {
      label {
        font-size: 12px !important;
      }
    }

    .datepickerHolder {
      border: 4px solid #ddd;
      display: flex;
      border-radius: 10px;
      padding: 0 10px 0 15px;
      > div {
        width: 100% !important;
      }
      input {
        font-size: 16px !important;
        box-shadow: none !important;
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }
}

.communicationPreferences {
  margin-bottom: 5em;

  .formHeader {
    text-align: right;
  }
}

.personalSubmit {
  .saveProfileButton {
    margin-right: 5px;
  }
}

.integrationsHeaderBanner {
  padding-bottom: 30px;
}

.integrationsPage {
  height: 100%;

  .flexContainer {
    display: flex;
    height: 100%;

    .integrationsHolder {
      overflow-y: auto;
      width: 100%;

      .integrationsHeaderBanner {
        hr {
          margin: 15px 0px 0px !important;
        }
      }
    }
  }

  .utilityBar {
    @extend %pageBar;
    padding-left: 20px;
    justify-content: flex-start;
  }

  .returnToIntegrations {
    @extend %bodyText;
    color: $payAnywhereColor !important;

    svg {
      vertical-align: sub;
      width: 18px !important;
      margin-right: 5px;
      top: -1px;
      position: relative;
    }
  }

  .integrationDetails {
    .integrationDetailsContainer {
      width: 100%;
      margin-bottom: 20px;
      display: flex;

      .integrationDetailsIconContainer {
        width: 20%;
        height: 100%;

        img {
          margin-left: auto;
          margin-right: auto;
          display: block;
          position: relative;
          max-height: 140px;
          max-width: 140px;
          width: 75%;
          padding-top: 40px;

          @media screen and (max-width: $sizeMobileMax) {
            max-height: 35px;
            max-width: 35px;
          }
        }
      }
    }

    .integrationInformationDetailsContainer {
      .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
      }

      .column {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
      }

      .integrationTextContainer {
        width: 100%;
        padding-top: 40px;

        .titleText {
          @extend %heading;
          display: inline-block;
          width: 100%;
          color: $primaryTextColor;
          margin-bottom: 0.4em;
        }

        .subText {
          @extend %contentLabel;
          display: inline-block;
          width: 100%;
          color: $primaryTextColor;
          margin-bottom: 2.6em;
          font-size: 13px;
        }
      }

      .integratedAccessKeysContainer, .transactionsWebhookForm {
        padding: 40px 10% 40px;
        &.midWidth {
          padding: 40px 3% 40px;
        }
        .subForm {
          margin: 0 20px;
        }

        .fieldMaxWidth {
          max-width: 600px;
        }

        .iconButton {
          margin-bottom: 40px;
          font-size: 15px;
          cursor: pointer;

          span {
            display: flex;
            color: $paymentHubsColor;
          }
        }

        .appCustomToggle {
          display: flex;
          flex-direction: row;

          .customToggle {
            margin-bottom: 25px;
          }

          .label {
            margin: 2px 0 10px;
            font-size: 16px;
          }

          .twoLines {
            margin-left: 10px;

            .label {
              @extend %columnLabel;
              padding-top: 5px;
            }
          }
        }

        .tokensAndPasswordsContainer {
          background-color: $lightGray;
          padding: 20px;
          font-size: 15px;

          .textSpacing {
            display: flex;
            margin: 5px 15px;
            word-break: break-all;

            .withoutIconButtons {
              margin-left: 50px;
            }
          }

          .iconButtonGroup {
            min-width: 50px;
            margin-bottom: 0;
          }

          .iconButton {
            vertical-align: middle;
            cursor: pointer;
          }
        }

        .resetAPIPasswordButton {
          margin-top: 40px;
        }

        .integratedTokensFieldArray {
          margin: 20px 0;

          .textField {
            width: 50%;
          }

          .addButtonIcon {
            padding-top: 40px;
            cursor: pointer;
          }

          .deleteButtonIcon {
            display: flex;
            padding-top: 45px;
            cursor: pointer;

            .deleteButtonText {
              color: #ff0000;
              font-size: 15px;
            }
          }
        }
      }

      .integrationStatusDetails {
        display: flex;
        flex-direction: row;
        width: 80%;

        @media screen and (max-width: $sizeMobileMax) {
          width: 100%;
        }

        .integrationStatusIcon {
          .subscribedCheckContainer {
            width: 40px;

            svg {
              float: right;
              padding-right: 15px;
            }
          }
        }

        .integrationStatusDetailsAndActions {
          width: 80%;

          .integrationStatusDetailsText {
            width: 100%;

            .titleText {
              @extend %bodyText;
              font-family: $primaryFontFamily !important;
              font-weight: 600;
              display: inline-block;
              width: 100%;
              color: $primaryTextColor;
            }

            .subText {
              @extend %bodyText;
              display: inline-block;
              width: 100%;
              color: $primaryTextColor;
            }
          }

          .integrationStatusDetailsActions {
            .integrationStatusDetailsButtons {
              padding-top: 20px;

              .managePlanButtonContainer {
                display: inline-block;
                width: 150px;

                span {
                  @extend %bodyText;
                  text-transform: none !important;
                }
              }

              .launchButtonContainer {
                display: inline-block;
                width: 150px;

                a {
                  margin-left: 0 !important;
                }

                span {
                  @extend %bodyText;
                  color: $payAnywhereColor !important;
                  text-transform: none !important;
                }
              }
            }

            .integrationStatusDetailsChecks {
              padding-top: 20px;

              .enableDisableTimeClockContainer {
                width: 330px;

                @media screen and (max-width: $sizeMobileMax) {
                  width: 100%;
                }

                .customCheckBox {
                  height: auto !important;
                }

                label {
                  color: $primaryTextColor !important;
                  font-family: $primaryFontFamily !important;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }

  .integrationsHolder {
    &.integrationsDetail {
      padding: 0;
    }
  }

  .integrationsHeader,
  .accountIntegrations {
    padding: 0;
  }

  .integrationsHeader {
    margin: 0 0 50px;

    .headerTitleContainer {
      .titleText {
        @extend %heading;
        margin-bottom: 5px;
      }

      .subText {
        @extend %contentLabel;
      }
    }
  }

  .accountIntegrations {
    .integrationContainer {
      width: 100%;
      height: 150px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      position: relative;
      -moz-border-radius: 5px;
      border-radius: 5px;
      background: $lightGray;

      .integrationIconContainer {
        width: 15%;
        min-width: 90px;
        height: 100%;

        img {
          margin-left: auto;
          margin-right: auto;
          display: block;
          position: relative;
          max-height: 70px;
          max-width: 70px;
          top: 50%;
          transform: translateY(-50%);

          @media screen and (max-width: $sizeMobileMax) {
            max-height: 35px;
            max-width: 35px;
          }
        }
      }

      .integrationInformationContainer {
        width: 75%;

        .integrationTextContainer {
          width: 100%;

          .titleText {
            @extend %bodyText;
            font-family: $primaryFontFamily !important;
            font-weight: 600;
            display: inline-block;
            width: 100%;
            margin-bottom: 3px;
          }

          .subText {
            @extend %bodyText;
            display: inline-block;
            width: 100%;
            margin-bottom: 16px;
          }
        }

        .learnMoreHomebase,
        .learnMoreIntegration,
        .disconnectQuickBooks,
        .learnMoreChargezoom {
          @extend %bodyText;
          text-transform: none;
          color: $payAnywhereColor !important;

          .learnMore {
            text-decoration: none;
            color: $paymentHubsColor;
          }
        }

        .learnMoreTag {
          text-transform: none;
          color: $paymentHubsColor !important;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
        }

        .integrationEnrollment {
          @extend .learnMoreTag;
          svg {
            max-height: 32px;
            fill: $inventoryTrendDiscount !important;
          }
        }


      }

      .integrationCheckContainer {
        left: -48px;
        position: absolute;

        svg {
          fill: #00d100 !important;
        }
      }
    }
  }

  //.accountIntegrations

  //Responsive styles for tablet landscape
  @media screen and (max-width: $sizeDesktopMin) {
    .integrationsHeader {
      .headerTitleContainer {
        .titleText {
          font-size: 24px !important;
        }
      }
    }

    .accountIntegrations {
      .integrationContainer {
        .integrationCheckContainer {
          left: -34px;
        }
      }
    }

    .integrationDetails {
      .integrationInformationDetailsContainer {
        .integrationTextContainer {
          .titleText {
            font-size: 24px !important;
          }
        }
      }
    }
  }
  //End of responsive tablet landscape

  //Responsive styles for tablet portrait
  @media screen and (max-width: $sizeTabletMin) {
    .integrationsHeader {
      .headerTitleContainer {
        .titleText {
          font-size: 22px !important;
        }
      }
    }

    .integrationDetails {
      .integrationDetailsContainer {
        .integrationDetailsIconContainer {
          width: 23%;

          img {
            width: 60%;
          }
        }
      }

      .integrationInformationDetailsContainer {
        .integrationTextContainer {
          .titleText {
            font-size: 22px !important;
          }

          .subText {
            width: 85%;
            box-sizing: border-box;
            padding-right: 20px;
          }
        }

        .integrationStatusDetails {
          .integrationStatusDetailsAndActions {
            .integrationStatusDetailsActions {
              .integrationStatusDetailsButtons {
                .launchButtonContainer,
                .managePlanButtonContainer {
                  width: 117px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $sizeMobileMax) {
    .accountIntegrations {
      .integrationContainer {
        &.connected {
          margin-left: 10%;
          width: 90%;
        }

        .integrationCheckContainer {
          left: -10%;
        }
      }
    }
  }
}

.accountAlerts,
.forms {
  .buttonPanel {
    display: flex;
    box-sizing: border-box;
    padding: 25px 150px 25px 0;
    box-shadow: 0 -10px 25px -20px $regularColor;
    justify-content: flex-end;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $white;
    z-index: 3;

    @media screen and (max-width: $sizeMobileMax) {
      padding-left: 50px;
    }

    span {
      display: inline-block;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .feedback {
    padding: 25px;
    border-radius: 10px;
    background-color: $lightGray;

    .title {
      @extend %columnLabel;
      font-size: 17px !important;
      margin-bottom: 20px;
    }

    .text {
      @extend %contentLabel;
    }
  }
}

.alertsHeader {
  @extend %contentLabel;
  width: 100%;
  margin-bottom: 50px;

  .headerTitleContainer {
    display: inline-block;
    width: 100%;

    .titleText {
      @extend %heading;
      display: inline-block;
      width: 100%;
    }

    .subText {
      display: inline-block;
      @extend %contentLabel;
      width: 100%;
      padding-top: 5px;
    }
  }
}

.dailySummaryAlert {
  margin-top: 40px;
}

.communicationAlerts {
  margin-top: 50px;
  margin-bottom: 60px;

  .subText {
    display: inline-block;
    @extend %contentLabel;
    width: 100%;
    padding-top: 5px;
    margin-bottom: 20px;
  }

  .businessEmailWrapper {
    display: flex;
    margin-bottom: 50px;
    margin-top: 20px;
    align-items: flex-end;

    .lockIcon {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 8px;
      svg {
        width: 32px !important;
      }
    }

    .fieldHolder {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .businessEmailLabel {
      padding-left: 15px;
      padding-bottom: 7px;
      @extend %columnLabel;
    }
    .textFieldHolder {
      @extend %bodyText;
      height: 48px;
      line-height: 48px;
      background: #f2f2f4;
      border-color: #f2f2f4;
    }
  }
}

.alertsContainer {
  margin-bottom: 40px;
  .formItems {
    display: flex;
    justify-content: space-between;

    .textField {
      margin-right: 2%;
      width: 98%;

      & + .textField {
        margin-right: 0px;
        margin-left: 2%;
      }
    }
  }
}

.customAlerts {
  margin-bottom: 40px;
}

.basicAlerts,
.customAlerts,
.communicationAlerts {
  .formHeading,
  .leftAlerts,
  .rightAlerts {
    @extend %contentLabel;
    font-weight: bolder !important;

    > div {
      padding-bottom: 5%;

      > div {
        > div {
          margin-right: 5% !important;
        }
      }
    }
  }

  .rightAlerts {
    .customToggle {
      > div {
        > div {
          width: 70% !important;
          float: right;
        }
      }
    }
  }
}

.customAlertsRow {
  display: flex;

  @extend %contentLabel;
  padding-bottom: 1%;

  .customToggle {
    width: 100%;
  }

  .textField {
    margin-left: auto;
    width: 100%;

    .textFieldHolder {
      width: 210px;
      float: right;
    }

    span {
      float: right;

      @media screen and (
      (
        (min-width: $sizeTabletMedium) and
        (max-width: $sizeTabletMax)
      ) or (min-width: $sizeDesktopMedium)) {
        margin-right: 10px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  > div:first-child {
    padding-top: 2%;

    > div {
      > div {
        margin-right: 5% !important;
      }
    }
  }

  @media screen and (max-width: $sizeMobileMax) {
    flex-direction: column;

    .textField {
      margin-top: 15px;

      .textFieldHolder {
        float: none;
        width: auto;
      }

      span {
        float: left;
      }
    }

  }
}

.settings {
  position: relative;

  &Error {
    &Top,
    &Bottom {
      color: $errorColor;
    }

    &Top {
      margin-right: 10px;
      padding-top: 10px;

      @media screen and (max-width: $sizeMobileMax) {
        text-align: right;
        margin-right: 0;
      }
    }

    &Bottom {
      font-family: $primaryFontFamily !important;
      font-weight: 500;
      font-size: 13px;

      @media screen and (max-width: $sizeMobileMax) {
        display: inline-block;
        margin-left: 0;
        padding-top: 10px;
      }
    }
  }

  &Notice {
    margin: 0 0 25px 0;
    @extend %body3;
    color: $secondaryTextColor;
  }

  .saveContainer {
    margin: 20px 0 50px 0;

    @media screen and (max-width: $sizeMobileMax) {
      margin-bottom: 150px;
      padding-left: 10px;
    }

    &Top {
      display: flex;
      justify-content: flex-end;
      width: 50%;

      @media screen and (max-width: $sizeMobileMax) {
        flex-direction: column-reverse;
        align-items: flex-end;
      }

      .saveButtonTop {
        max-height: 35px !important;
        max-width: 88px !important;
      }
    }
  }

  .banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: url("/images/payanywhere/settings.png") no-repeat right center/50% 100%;
    height: 215px;
    border-bottom: 1px solid $lineColor;
    padding-left: 40px;
    margin-bottom: 80px;

    @media screen and (max-width: $sizeTabletMax) {
      background: url("/images/payanywhere/settings.png") no-repeat right/50% 100%;
    }

    @media screen and (max-width: $sizeMobileMax) {
      padding-left: 10px;
      background: url("/images/payanywhere/settings.png") no-repeat right/70% 100%;
    }

    .forms {
      display: inline-block;
      overflow: auto;
      width: 100%;
    }

    .heading {
      margin: 0;
      @extend %heading1;
    }

    .subHeading {
      margin: 0;
      @extend %body3;
      color: $secondaryTextColor;
      padding-top: 5px;

      @media screen and (max-width: $sizeTabletMax) {
        padding-right: 50vw;
      }

      @media screen and (max-width: $sizeMobileMax) {
        padding-right: 20vw;
      }
    }
  }

  .forms {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;

    .businessFormHolder {
      padding-bottom: 0 !important;

      .settingsContainer {
        border: none !important;
        box-shadow: none !important;
      }
    }
  }

  .settingsFormContainer.appSettings {
    width: 100%;
    overflow: auto;
    overflow-x: auto;
    height: auto;

    .secondaryTitle {
      padding: 50px 0 7px 0;
    }
  }

  .settingsFormContainer {
    @extend %contentLabel;

    .instantFunding {
      padding-top: 2%;

      .headerDivider {
        padding-top: 5%;
      }

      .instantFundingToggle {
        display: flex;
        padding-bottom: 5%;

        .customToggle {
          margin: 1% 1% 0 1% !important;
        }

        .portalCheckbox {
          width: auto !important;
          padding-right: 15px;
        }
      }
    }

    .appSettings {
      fieldset {
        margin: 0 !important;
      }
    }

    .storeSettingsForm {

      .toggleContainer {
        padding-top: 40px;
        display: flex;
        justify-content: space-between;

        .storeToggle {
          margin-bottom: 0 !important;
          display: flex;
          align-items: center;
        }

        .previewStore {
          background: $lightGray;
          color: $paymentHubsColor;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          padding: 0 0.5em;
          border-radius: 5px;
          width: 25%;
          text-align: center;
          min-height: 70px;
          cursor: pointer;

          .icon {
            display: flex;
            align-items: center;
            padding-right: 10px;
          }

          .text {
            padding-top: 3px;
          }
        }
      }

      .urlTextFieldContainer {
        display: flex;
        align-items: center;

        .urlTextField {
          width: 50% !important;
          padding-top: 0 !important;
          padding-right: 10px;
          padding-bottom: 5px;
        }
      }

      .formDisabled {
        color: rgba(0, 0, 0, 0.38)
      }

      .shippingToggle {
        margin-bottom: 0 !important;
      }

      p {
        margin-left: 43px;
        margin-top: 5px !important;
      }
    }

    .defaultFunding {
      fieldset {
        margin: 0 !important;

        [role="radiogroup"] {
          label {
            align-items: flex-start;
            height: 100px;
          }
        }
      }
    }

    .secondaryTitle {
      padding: 30px 0;
      @extend %contentLabel;
    }

    .title {
      .heading {
        @extend %heading;
        display: inline-block;
        width: 100%;
      }

      .subText {
        display: inline-block;
        color: $primaryTextColor;
        width: 100%;
        padding-top: 5px;
        font-size: 13px;
      }
    }

    .formHeading {
      margin-bottom: 40px;
      font-weight: 600;

      &.banking {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;

        span.edit {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
    }

    .headingContainer {
      margin-bottom: 40px;

      .title {
        display: flex;

        .formHeading {
          width: 90%;
          margin-bottom: 0;
          font-weight: normal;

          p {
            @media screen and (max-width: $sizeMobileMax) {
              margin-top: 10px;
            }
          }
        }

        .saveContainerTop {
          width: 10%;
        }
      }

      .subText {
        color: $secondaryTextColor;
        font-size: 13px;
        text-align: justify;
        width: 50%;
        margin-bottom: 40px;
        margin-top: -10px;

        @media screen and (max-width: $sizeMobileMax) {
          width: 100%;
          margin-top: 10px;
        }
      }
    }

    .settingsDisclaimer {
      @extend %contentLabel;
      padding: 0 30px;
      margin: 30px 0 0;
    }

    .settingsContainer {
      margin: 0 auto 50px auto;
      width: 100%;
      border: 1px solid $lineColor;
      box-shadow: 5px 0 15px 0 $cardBorderColor;

      &LessMargin {
        margin-bottom: 25px;
      }

      .settingsClose {
        padding: 30px 30px 2px 30px;
        min-height: 80px;
        border-bottom: 1px solid $lineColor;

        @media screen and (max-width: $sizeMobileMax) {
          padding-bottom: 30px;
        }

        .label {
          width: 100%;
        }

        .subText {
          width: 100%;
          color: $secondaryTextColor;
        }
      }

      .setting {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $lineColor;

        @media screen and (max-width: $sizeTabletMax) {
          flex-direction: column;
          border-bottom: none;
        }

        @media screen and (max-width: $sizeMobileMax) {
          flex-direction: column;
          border-bottom: none;
        }
      }

      .row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $lineColor;
        padding: 5px 10px 5px 30px;
        min-height: 100px;

        @media screen and (max-width: $sizeMobileMax) {
          padding: 0 10px 0 10px;
        }

        .logoImg {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin-right: 70%;
          transition: transform 0.2s;

          &:hover {
            transform: scale(2.5);
          }
        }
      }

      .receiptButton {
        padding: 0;
      }

      .column {
        display: flex;
        align-items: center;
        width: 50%;
        border-right: 1px solid $lineColor;
        box-sizing: border-box;
        padding: 5px 10px 5px 30px;
        min-height: 100px;

        @media screen and (max-width: $sizeTabletMax) {
          width: 100%;
          border-right: none;
          border-bottom: 1px solid $lineColor;
        }

        @media screen and (max-width: $sizeMobileMax) {
          width: 100%;
          border-right: none;
          border-bottom: 1px solid $lineColor;
          padding-left: 10px;
        }
      }

      .columnThree {
        width: 33.33%;

        @media screen and (max-width: $sizeTabletMax) {
          width: 100%;
        }

        @media screen and (max-width: $sizeMobileMax) {
          width: 100%;
        }
      }

      .input {
        width: 30%;
        box-sizing: border-box;

        &State {
          width: 50%;
        }

        &Wide {
          width: 50%;
        }

        @media screen and (max-width: $sizeTabletMax) {
          width: 50%;
        }

        @media screen and (max-width: $sizeMobileMax) {
          width: 50%;
        }

        &Cash {
          padding-left: 17vw;

          @media screen and (max-width: $sizeDesktopMin) {
            padding-left: 22.5vw;
          }

          @media screen and (max-width: $sizeTabletMax) {
            padding-left: 33.5vw;
          }

          @media screen and (max-width: $sizeMobileMax) {
            padding-left: 6.5vw;
          }

          &Text {
            > div {
              float: right;
              width: initial;

              &:after {
                clear: both;
              }
            }
          }
        }

        &Checkbox {
          padding-left: 6.5vw;

          @media screen and (max-width: $sizeDesktopMin) {
            padding-left: 8.5vw;
          }

          @media screen and (max-width: $sizeTabletMax) {
            padding-left: 33.5vw;
          }

          @media screen and (max-width: $sizeMobileMax) {
            padding-left: 6.5vw;
          }
        }

        .icon {
          width: 15.87px;
          height: 15px;
          background-color: $payAnywhereColor;
          background-size: cover;
          border-radius: 50%;
          margin-right: 10px;

          &Facebook {
            background-image: url("/images/social/facebook_circle.svg");
          }

          &Instagram {
            background-image: url("/images/social/instagram_circle.svg");
          }

          &Twitter {
            background-image: url("/images/social/twitter_circle.svg");
          }

          &Yelp {
            background-image: url("/images/social/yelp_circle.svg");
          }
        }

        &Inline {
          padding: 0;
          display: flex;
          align-items: center;
        }

        &Tip {
          width: 50%;

          .tipInput {
            width: 50%;
          }
        }
      }

      .label {
        width: 70%;
        margin: 0;
        font-size: 16px;

        &State {
          width: 50%;
        }

        @media screen and (max-width: $sizeMobileMax) {
          width: 50%;
          font-size: 13px;
        }

        &Receipt {
          width: 50%;
        }

        &Tip {
          width: 50%;
        }
      }

      .alignRight {
        padding-right: 20px;
        text-align: right;
      }

      .noBorderRight {
        border-right: none;
      }

      .noBorderBottom {
        border-bottom: none;
      }

      .noPaddingleft {
        padding-left: 0;
      }

      .fullWidth {
        width: 100%;
      }

      .flexCenter {
        justify-content: center;
      }

      .button {
        font-family: $secondaryFontFamily;
        color: $payAnywhereColor;
        display: inline-block;
        margin: 0;
        cursor: pointer;
        font-size: 14px;
      }

      .removeButton {
        cursor: pointer;
        display: inline-block;
        margin: 0;
      }
    }

    .businessButtonHolder {
      margin-bottom: 150px !important;
    }

    .formTitle {
      @extend %subheading;
      margin: 0 0 60px 10px;
    }

    .formSubtitle {
      font-family: $primaryFontFamily;
      font-weight: 500;
      font-size: 10px;
      color: $primaryTextColor;
    }
  }
}

.settings,
.integrationsPage,
.accountReceiptSettings,
.businessProfile,
.accountTinMatching,
.fundingSection,
.accountAlerts,
.accountDevices,
.accountSupplies {
  .filter {
    @media screen and (min-width: 1200px) {
      min-width: 330px;
    }
  }
}

.settingsContainer {
  .twoLines {
    width: 70%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: $sizeTabletMax) {
      width: 50%;
    }

    @media screen and (max-width: $sizeMobileMax) {
      width: 50%;
    }

    .label {
      @extend %columnLabel;

      @media screen and (max-width: $sizeTabletMax) {
        width: 100%;
      }

      @media screen and (max-width: $sizeMobileMax) {
        width: 100%;
      }
    }

    .subText {
      @extend %contentLabel;
      margin: 5px 0 0 0;
    }
  }

  .label {
    width: 70%;
    margin: 0;
    font-size: 16px;

    &State {
      width: 50%;
    }

    @media screen and (max-width: $sizeMobileMax) {
      width: 50%;
      font-size: 13px;
    }

    &Receipt {
      width: 50%;
    }

    &Tip {
      width: 50%;
    }
  }
}

.fundingOptimizationContainer {
  .settingsContainer {
    border: none !important;
    box-shadow: none !important;

    .headerDivider {
      padding-top: 4%;
    }

    .instantFundingToggle,
    .columnRight,
    .fundingFields {
      .twoLines {
        width: 90%;

        .subText {
          width: 100%;
        }
      }
    }

    .columnRight {
      width: 100%;
    }

    .fundingFields {
      display: flex;
      padding: 2% 0 0 2%;
    }

    .fundingHeader {
      width: 100%;
      display: block;
    }

    .defaultFunding {
      display: inline-block;
      padding-right: 7px;

      & > div {
        height: 100px !important;
      }
    }

    .columnLeft {
      display: inline-block;
    }

    .columnRight {
      display: inline-block;

      .twoLines {
        height: 100px;
        justify-content: normal;
      }
    }

    .businessButtonHolder {
      .saveButton {
        float: right;
        margin-right: 15px;
      }
    }
  }

  .debitCardInput {
    padding: 0 5% 0 5%;

    .textField {
      padding-top: 25px;

      span {
        position: absolute;
        padding: 5px 0 0 5px;
      }
    }

    .smallerField {
      padding-left: 25px;

      span {
        width: 20%;
      }
    }

    .zipField {
      padding-left: 25px;
    }
  }

  .debitCardInfo {
    @extend %contentLabel;
    @extend %customLine;
    display: flex;
    width: 80%;
    justify-content: space-between;
    padding: 4% 8%;
    border-bottom: none !important;

    .field {
      position: relative;
      top: 15px;
    }
  }
}

.confirmFundingDialog,
.savedSuccessFundingDialog {
  > div {
    > div {
      > div {
        border-radius: 10px !important;
      }
    }
  }
}
.savedSuccessFundingDialog {
  z-index: 1503 !important;
}

.headerDivider {
  @extend %customLine;
  margin-bottom: 0;
}

.closingSettingsWrapper {
  @extend %settingsWrapper;

  .label {
    @extend %bodyText;
  }

  .printReportToggle {
    margin-bottom: 0;
    margin-top: 40px;
  }
}

.davoSettingsWrapper {
  @extend %settingsWrapper;
  background-image: url("/images/davo/woman-enable-davo.png");
  background-size: auto 100%;
  margin-bottom: 1rem;
  background-repeat: no-repeat;
  background-position: top right;
  height: auto;
  padding-right: 172px;
  padding-left: 25px;

  &.davoEnrolled {
    background-image: none;
    padding-right: inherit;
  }

  .davoLogo {
    width: 72px;
    max-width: 100%;
  }

  .davoConnectedLabel {
    color: white;
    display: flex;
    align-items: center;
    width: 125px;
    height: 32px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 7px;
    justify-content: center;

    svg {
      margin-right: 5px;
    }
  }

  .twoLines {
    .label {
      font-family: $primaryFontFamily;
      font-weight: 600;
    }
    .subText {
      font-family: $primaryFontFamily;
      font-weight: normal;
    }
  }

  .disclaimer {
    width: 224px;
    text-align: center;
  }
}

.taxSettingsWrapper {
  @extend %settingsWrapper;

  .textFieldHolder {
    background-color: white;
  }
}

.davoIntegrationDetails {
  .title {
    font-family: $primaryFontFamily;
    font-weight: 600;
    text-align: center;
  }
  .boxed {
    border: 0.5px solid $borderGray;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    p {
      text-align: center;
    }

    p.boxBody {
      padding: 0 70px;
    }

    .boxBottomBody {
      font-family: $primaryFontFamily;
      font-weight: 600;
    }

    .connectedContent {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      p {
        margin: 0;
      }

      .activePlan{
        margin: 0;
        max-width: 125px;
      }

      .text {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        font-family: $primaryFontFamily;
        font-weight: 600;

        span {
          color: #747373;
          font-family: $primaryFontFamily;
          font-weight: 500;
          font-size: 11px;
        }
      }
    }

    .legal {
      max-height: 400px;
      overflow-y: scroll;
      width: 100%;

       p {
         margin: 0 0 15px 0;
         padding-left: 20px;
         text-align: left;
       }

      .label {
        margin-bottom: 6px;
      }

      .subTitle {
        margin: 0;
      }
    }

    &.left {
      align-items: flex-start;
    }

  }

  .actions {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .termsButton {
      width: 100%;
    }
  }

  .syncTitle {
    font-size: 18px;
    line-height: 36px;
    color: $black;
  }

  .section {
    .title {
      font-family: $primaryFontFamily;
      font-weight: 600;
      font-size: 14px;
      text-align: left;
    }
    li {
      span {
        font-size: 13px !important;
      }
      b {
        font-family: $primaryFontFamily;
        font-weight: 600;
      }
    }
  }

  .listItem {
    display: flex;
    align-items: flex-start;
  }

  .listItemIcon {
    min-width: 26px;

    div {
      margin-top: 5px;
    }
  }

  .label {
    font-family: $primaryFontFamily;
    font-weight: 600;
    padding-left: 20px;
    margin-bottom: 5px;

    &.top {
      font-size: 10px;
      margin-bottom: 5px;
    }
  }

  .confirmationHeader {
    p {
      margin: 0;
    }

    .boxed{
      margin-bottom: 20px;
    }

    .row {
      align-items: center;
      display: flex;
      font-size: 12px;
      justify-content: space-between;

      span {
        color: #747373;
      }
    }
  }

  .tagsContainer {
    .tag {
      color: #000000;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .divider {
    border: none;
    border-bottom: 1px solid $lineColor;
    margin: 24px 0 !important;
    width: 100%;
  }

  .connectedLinks {
    a {
      align-items: center;
      border-bottom: 0.5px #C3C2C2 solid;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      width: 100%;

      &:last-child {
        border: 0;
      }
    }
  }
}

.davoDialogContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img{
    height: 44px;
    width: 72px;
  }

  h3 {
    margin: 20px 0;
  }

  p {
    margin: 0;
  }
}

.appSettingsWrapper,
.profileBusinessForm {
  padding-top: 30px;

  .textField {
    padding-top: 25px;
    box-sizing: border-box;
    &.smallerLabel {
      margin-bottom: 8px;
      label {
        font-size: 11px;
      }
    }
  }

  .title .secondaryTitle {
    font-family: $primaryFontFamily !important;
    font-weight: 600;
  }

  .invoiceNumberingStartField {
    .subText.subField {
      font-size: 11px !important;
      margin-bottom: 30px;
    }
    .showField {
      cursor: pointer;
      margin: 20px 0;
      display: inline-block;
    }
  }

  .invoiceNumberingResetToggle, .invoiceNumberingApplyAccounts {
    display: flex;
  }

  .appCustomToggle {
    display: flex;
    flex-direction: row;
    padding-top: 25px;

    .customToggle {
      margin-bottom: 25px;
    }

    .label {
      margin: 2px 0 10px;
      font-size: 16px;
    }

    .subText {
      margin: 0;
    }

    .twoLines {
      margin-left: 10px;

      .label {
        @extend %columnLabel;
        padding: 3px 0 2px;
      }

      .subText {
        @extend %contentLabel;
        padding-top: 10px;
      }
    }
  }

  .learnMoreLink {
    display: block;
    font-family: $primaryFontFamily;
    font-size: 13px;
    font-weight: bold;
    color: $paymentHubsColor;
    margin-top: 20px;
    text-decoration: none;
  }

  .appSpacer {
    margin: 40px 0;
    border-top: 1px solid #e1e1e1;
  }
}

.identifiers {
  padding: 20px 30px;

  &Holder {
    border: 1px solid $lineColor;
    padding-bottom: 20px;
    border-radius: 5px;
    margin-bottom: 15px;
  }

  &Title,
  &Name {
    font-size: 18px;
  }

  &Title {
    margin-top: 0;
    font-size: 22px;
    color: $iconColor;
  }

  &Name {
    padding-left: 30px;
    font-weight: bold;
    font-size: 16px;
  }

  &Item {
    color: $iconColor;

    &Holder {
      padding-left: 30px;
      margin-bottom: 0;
    }
  }
}

.tipsWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .tipContainer {
    width: 30%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    > p.label {
      font-family: $primaryFontFamily;
      font-weight: 600;
      padding-right: 80px;
      margin: 0;
      padding-left: 15px;
    }

    .presetTips {
      display: flex;
      .textFieldInfo {
        display: none;
      }
    }

    @media screen and (max-width: 1300px) {
      width: 49%;
    }
    @media screen and (max-width: $sizeTabletMin) {
      width: 100%;
      align-items: initial;
      .textField.halfScreen{
        width: 100% !important;
      }
    }


    .textField + .textField {
      padding-left: 5%;
    }

    @media screen and (max-width: $sizeMobileMax) {
      .textFieldInfo {
        display: flex;
        min-height: 30px;
      }
    }
  }
}

.flexRow {
  .halfScreen + .halfScreen,
  .textField + .textField {
    padding-left: 2%;
  }
}

.edgeNotice {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: $lightGray;

  .title {
    @extend %columnLabel;
    margin-bottom: 8px;
  }

  .text {
    @extend %contentLabel;
    font-size: 11px;
    margin-bottom: 8px;
  }
}

.edgeProgramPending {
  display: flex;
  align-items: center;
  padding: 13px 20px;
  border-radius: 5px;
  background-color: $lightGray;
  @extend %columnLabel;
  margin: 8px 0;
  span {
    margin-right: 8px;
  }
}

.detailPanelHolderButtons.saveContainer {
  right: 0;
  left: 0;
  justify-content: flex-end;
  padding: 25px 70px;
  margin: 0;
  align-items: center;
  width: 100%;

  span {
    font-weight: 600;
  }

  .openTransactionsBottomBar div{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding-left: 40px;
    .errorMessage {
      color: red;
    }
  }
}

.profileBusinessForm {
  @extend %contentLabel;

  .informationWrapper {
    display: flex;
    justify-content: flex-start;

    .bizDetails + .transactionsDetails {
      margin-left: 60px;
    }

    .bizDetails,
    .transactionsDetails {
      display: flex;
      width: 50%;

      .informationTile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 15px;

        .informationTitle {
          @extend %contentLabel;
          padding-bottom: 15px;
          margin-top: 4px;
        }

        span + span {
          margin-left: 10px;
        }
      }

      .info {
        @extend %bodyText;
        margin: 0 0 5px;
      }
    }
  }

  .accordionContainerWrapper{
    margin: 40px 0 140px;
    @media screen and (max-width: $sizeMobileMax) {
      .fieldsWrapper{
        .flexRow{
          column-gap: 18px;
        }
      }
      .corporateCityContainer{
        display: flex;
        align-items: end;
        gap: 7px;
      }
    }
  }
}

.ownerForm {
  padding: 20px;
  @extend %contentLabel;

  .checkboxContainer {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
  }
}

.tinValidationItems {
  padding: 100px 150px 0 150px;
  overflow-y: scroll;

  .headerTitleContainer {
    @extend %heading;
    padding-bottom: 50px;
  }

  .subheading {
    @extend %subheading;
    padding-bottom: 30px;
  }

  .tinContent {
    @extend %bodyText;
    padding-bottom: 50px;

    button {
      font-weight: 600;
    }

    .subheadingText {
      @extend %contentLabel;
      padding-bottom: 10px;
      font-size: 16px;
    }

    .tinImportant {
      font-family: $primaryFontFamily !important;
      font-weight: 600;
    }

    .question {
      font-size: 16px !important;
      font-weight: bolder;
      padding-bottom: 30px;
      margin-top: 50px;
    }

    .answer {
      font-size: 14px !important;
      padding-bottom: 10px;
    }

    .download1099k {
      margin: 20px 0;
    }

    .linkLike {}
  }

  .headerDivider {
    margin: 60px 0;
  }
}

.updateInstructionDialog {
  .content {
    @media screen and (max-width: $sizeMobileMax) {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .titleText {
      @extend %bodyText;
    }

    .text {
      @extend %contentLabel;
    }

    .contactInfo {
      padding: 30px;
      &,
      .address,
      .fax {
        display: flex;
      }

      .address,
      .fax {
        &Text {
          padding-left: 10px;
          display: flex;
          flex-direction: column;
          span {
            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }
      }
      .address {
        margin-right: 60px;
      }
    }
  }

  .updateInstructionDialogList {
    padding: 0;
    list-style: none;
    li::before {
      content: "•";
      padding: 0 8px 0 0 !important;
      font-size: 15px;
    }
  }

  .headerDivider {
    margin: 20px 0 50px 0;
  }
}

.assistanceDialog {
  .content {
    padding: 0px 18px 0 !important;

    .titleText {
      @extend %contentLabel;
    }

    .text {
      @extend %bodyText;
    }
  }

  .assistanceDialogTitle {
    padding: 24px 60px 20px !important;
  }

  .formCopyLink {
    display: flex;

    .textWithLink {
      padding-left: 10px;
    }
  }
}

.w9 {
  margin: 30px 0 50px;
  color: $paymentHubsColor;
  display: flex;
  text-decoration: none;

  svg {
    fill: $paymentHubsColor !important;
    margin-left: -5px;
    margin-right: 5px;
  }

  a {
    text-decoration: none;
  }
}

.tinValidationList {
  border-collapse: inherit !important;

  .tblHeader {
    height: 20px !important;
  }

  .tinValidationData {
    td {
      @media screen and (max-width: 1200px) {
        font-size: 14px !important;
      }
      @media screen and (max-width: $sizeMobileMax) {
        font-size: 12px !important;
      }
    }
  }
  .tinValidationHistoryTable {
    .tableRow {
      &:first-of-type {
        background: $lightGray;
        th {
          background: transparent !important;
          height: auto !important;
        }
      }
    }
  }
}

.manageRememberedDevicesDialogContent {
  padding-bottom: 40px;
  min-height: 120px;

  .devicesTableContainer {
    min-height: 300px;
    max-height: 480px;
    overflow-y: auto;

    table {
      thead {
        th {
          font-size: 10px;
          font-weight: bold;
          border: 0;
        }
      }
      tbody {
        td {
          font-size: 12px;
          border: 8px solid white;
          border-left: 0;
          border-right: 0;
          background-color: $listItemBackground;
          height: 38px;
          .action {
            cursor: pointer;
            height: 100%;
            width: 100%;
            line-height: 38px;
          }
        }
      }
    }
  }
}

.drawerContent {
  .title {
    @extend %subheading;
    padding: 20px 0;
  }

  .drawerItems {
    padding: 50px;
  }
}
.twoFactorSettings .twoFactorToggle {
  margin-bottom: 15px;
}

.bankingErrorContainer {
  margin: 20px 0;
  background: $backgroundColor2;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;

  .icon + .bankingErrorText {
    padding-left: 15px;
  }

  .bankingErrorText {
    padding-top: 4px;
    display: flex;
    flex-direction: column;

    p {
      font-size: 12px;
    }
    .headingText {
      font-size: 15px;
      padding-bottom: 10px;
    }
  }
}

.editButtonContainer {
  padding: 20px 0;
  cursor: pointer;
  border-bottom: 1px solid $backgroundColor2;
  display: flex;
  align-items: center;

  span + span {
    padding-left: 5px;
  }
}

.testContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30000000 !important;
  background-color: rgba(0, 0, 0, .5);
}

.updatePasswordForm {

  .footerForm {
    div {
      width: 100%;
      margin: 0 auto;
    }

    .updateErrorMessage {
      color: red;
      text-align: center;
      margin-bottom: 16px;
    }

  }
}

.returnLabel {
  .returnLabelHeader {
    @media screen and (max-width: $sizeTabletMin) {
      flex-direction: row;
      flex-wrap: wrap;
    }
    & {
      padding: 25px;
      border-bottom: 1px solid $lineColor;
    }

    .returnLabelHeaderText {
      padding: 0 15px 0 15px;
    }

    .returnLabelHeaderLocations {
      margin-left: auto;
      cursor: pointer;
      white-space: nowrap;
      @media screen and (max-width: $sizeTabletMin) {
        width: 100%;
        margin-left: unset;
        padding-top: 10px;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .returnLabelHeaderContent, .returnLabelHeader {
    display: flex;
    align-items: center;
  }

  .returnLabelBody {
    padding-top: 25px;
    display: flex;
    background-color: white;
    width: 100%;
    border-bottom: solid 1px $lineColor;
    color: $primaryTextColor;
    height: 105px !important;
    align-items: center;

    .returnLabelBodyDetails {
      box-sizing: border-box;
      padding: 0 30px 0 52px;
      position: relative;
      text-align: left;
      width: 85%;
      @media screen and (max-width: $sizeTabletMin) {
        width: 85% !important;
      }

      .returnLabelBodyName {
        font-family: $primaryFontFamily !important;
        font-weight: 500;
        @extend %bodyText;
      }

      .returnLabelBodyChevron {
        align-items: center;
        display: none;
        height: 100%;
        position: absolute;
        right: 20px;
        top: 0;

        svg {
          fill: $primaryTextColor;
        }
      }
    }

    .returnLabelBodyActions {
      border-left: 1px solid #dddddd;
      height: 100%;
      font-family: $primaryFontFamily;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15%;

      @media screen and (max-width: $sizeTabletMin) {
        width: 30% !important;
      }

      .returnLabelBodyExport {
        @extend %bodyText;
        color: $paymentHubsColor;
        display: none;

        svg {
          transform: none;
          position: relative;
          top: 2px;
          fill: $paymentHubsColor;
        }
      }
    }
  }

  .returnLabelBody:hover:not(.reportRowDataTime) {
    background-color: $selectedRow;
    cursor: pointer;
  }

  .returnLabelBody:hover {
    .returnLabelBodyDetails .returnLabelBodyChevron {
      display: flex;
    }

    .returnLabelBodyActions .returnLabelBodyExport {
      display: block;
    }
  }
}

.gift_card_enabled {
  &Preset {
    &Container {
      display: flex;
      flex-flow: column nowrap;
    }
    &Stock {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &Text {
        margin-right: 15px;
        flex: 1 .5 auto;
      }
    }
    &Presets {
      display: flex;
      gap: 10px;
    }
    @media screen and (max-width: 635px) and (min-width: 992px) and (max-width: 1088px) {
      &Presets, &Stock {
        flex-wrap: wrap;
      }

      &Stock {
        &Text {
          margin-top: 15px;
        }
      }
    }
    @media screen and (max-width: 635px) {
      &Presets, &Stock {
        flex-wrap: wrap;
      }

      &Stock {
        &Text {
          margin-top: 15px;
        }
      }
    }
  }
}
