$zoom-levels: (
  $sizeDesktopMax: .8,
  $sizeDesktopMid: .6,
  $sizeDesktopMin: .4,
  $sizeMobileMaxPortrait: .25,
);

.storePreview {
  display: flex;
  pointer-events: none;
  user-select: none;
  width: 100%;
  min-width: 800px;
  max-width: 1296px;

  @each $max-width, $zoom in $zoom-levels {
    @media screen and (max-width: $max-width) {
      zoom: $zoom;
    }
  }

  .leftPanel {
    position: relative;
    width: 252px;
    box-sizing: border-box;
    border-right: 1px solid rgba(0,0,0,.12);
    padding: 36px;

    .logo {
      max-width: 150px;
      max-height: 100px;
      width: auto;
      height: auto;
      margin-bottom: 20px;
    }

    .itemCategories {
      margin: 0;
      padding: 0;
      list-style: none;

      .category {
        text-transform: capitalize;
        color: $placeholderTextColor;
        font-family: $primaryFontFamily;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 20px;
      }
    }

    .divider {
      border-bottom: 1px solid rgba(0,0,0,.12);
      margin: 25px 0;
    }

    .bottom {
      position: absolute;
      bottom: 36px;
      max-width: 145px;
    }

    .merchantInfo {
      h5 {
        color: #393a3f;
        font-size: 14px;
        margin: 23px 0;
      }
      p {
        color: #888c8d;
        font-size: 12px;
        margin: 12px 0;
      }
    }

    .branding {
      display: flex;
      align-items: center;

      .img {
        width: 30px;
        height: 33px;
        background-image: url('/images/paymenthubs/logoSmall.png');
        background-size: contain;
        background-repeat: no-repeat;
      }

      p {
        font-size: 12px;
        color: #888c8d;
        margin-left: 10px;
        max-width: 100px;
      }
    }
  }

  .holder {
    box-sizing: border-box;
    width: 1044px;
    background-color: $paymentPageGrey;
    display: flex;
    flex-direction: column;
    padding: 0 58px 58px;
  }

  .toolsContainer {
    padding-top: 58px;

    .main, .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 58px;

      .search {
        display: flex;
        box-sizing: border-box;
        background-color: $backgroundColor;
        width: 279px;
        height: 50px;
        align-items: center;
        border-radius: 50px;
        padding: 0 18px;

        .icon {
          svg {
            fill: rgba(0, 0, 0, 0.54);
          }
        }

        .text {
          margin-left: 5px;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.26);
        }
      }

      .cart {
        background-color: $backgroundColor;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: rgba(0, 0, 0, 0.54);
        }
      }
    }

    .controls {
      margin-bottom: 30px;

      .sort, .view {
        display: flex;
        align-items: center;

        .text {
          font-size: 12px;
          color: #414042;
          margin-right: 10px;
        }
        .icon {
          svg {
            fill: rgba(0, 0, 0, 0.54);
          }
        }
      }

      .view {

        .grid {
          margin-right: 16px;
          svg {
            fill: rgba(0, 0, 0, 0.54);
          }
        }

        .list {
          svg {
            color: rgba(0, 0, 0, 0.26);
          }
        }
      }
    }

    .paginationList {
      margin: 0 auto 35px auto;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: space-evenly;
      max-width: 266px;
      align-items: center;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;

        &:first-of-type {
          svg {
            color: rgba(0, 0, 0, 0.26);
          }
        }
      }

      .active {
        color: $backgroundColor !important;
      }
    }
  }
}

.itemCard {
  display: flex;
  cursor: pointer;
  position: relative;
  flex-direction: column;
  max-width: 230px;
  max-height: 370px;

  .itemImage {
    display: table;
    width: 100%;
    height: 180px;
    aspect-ratio: 1 / 1;

    span {
      margin: auto;
      color: #fff;
      font-weight: bold;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      font-size: 22px;
    }
  }

  .cardContent {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    height: 100px;
    padding: 25px 25px 0;

    & h5 {
      margin: 0;
      color: $primaryTextColor;
      font-size: 18px;
      font-family: $primaryFontFamily;
      font-weight: bold;
    }

    & p {
      overflow: hidden;
      color: $secondaryTextColor;
      font-family: $tertiaryFontFamily;
      font-size: 14px;
    }
  }

  .price {
    display: flex;
    padding: 0 25px 25px;

    & span {
      font-size: 12px;
      font-weight: bold;
    }
  }

}
