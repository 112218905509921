.toggle {
  width: 10%;
  display: inline-block;
  border: solid 1px $secondaryTextColor;
  text-align: center;
  float: right;
  margin: 10px;

  @media screen and (max-width: $sizeMobileMax) {
    float: none;
    display: block;
    margin: auto;
    margin-bottom: 30px;
  }

  .leftToggle, .rightToggle {
    cursor: pointer;
    display: inline-block;
    width: 50%;
    color: $backgroundColor;
    background-color: $secondaryTextColor;
  }

  .active {
    color: $secondaryTextColor;
    background-color: $backgroundColor;
  }
}
