.receiptSettings {

  @extend %contentLabel;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;

  .previewContainer {

    display: flex;
    @extend %bodyText;
    margin: 3em 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .previewButton {

      background: $lightGray;
      color: $paymentHubsColor;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 0 0.5em;
      border-radius: 5px;
      width: 30%;
      text-align: center;
      min-height: 70px;
      cursor: pointer;

      &.center {
        margin: 0 0.5em;
      }

    }

  }


  .heading {
    @extend %heading;
  }

  .subheading {
    margin-top: 3em;
    @extend %subheading;
  }

  .customToggle {
    margin-bottom: 12px;
  }

  .customToggle.reminderToggle {
    margin-bottom: 0;
  }

  .flexRow {
    align-items: center;
  }

  .description {
    @extend %contentLabel;
    padding-top: 5px;
    margin-bottom: 4em;
    width: auto;
    text-align: left;
    line-height: normal;
  }

  .inputRow {
    margin-top: 0px;
  }

  .bccEmail, .businessName, .fileInputLabel, .brandColor {
    margin-top: 1.6em;
  }

  .brandColor {
    margin-left: 2%;
  }

  .inputSection {

    margin: 42px 0;

    .inputRow {

      .half {
        display: inline-block;
        width: 48%;

        &.halfWidth {
          width: 50%;
        }
      }

      .quarter {
        display: inline-block;
        width: 23%;
      }

      &Flex {
        display: flex;
        justify-content: space-between;
      }

    }

    &:last-child {
      border-bottom: 0;
    }
  }


  .receiptSettingsForm {
    .inputRow, .flexRow {
      margin-bottom: 10px;
    }
  }

  .textFieldHolder {
    padding: 0 10px;
  }

  .websiteWidth {
    width: 48% !important;
  }


  .inputRowImg {
    display: flex;
    background-color: $lightGray;
    align-items: center;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;

    img {
      width: 100px;
      height: auto;
    }

    .removeLogo {
      cursor: pointer;
      margin-left: auto;
    }
  }

  .buttonPanel {
    display: flex;
    box-sizing: border-box;
    padding: 25px 80px 25px 0;
    box-shadow: 0 -10px 25px -20px $regularColor;
    justify-content: flex-end;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $white;

    @media screen and (max-width: $sizeMobileMax) {
      padding: 25px 5px;
    }

    .receiptErrorBottom {
      color: red;
      display: flex;
      align-items: center;
    }

    span {
      display: inline-block;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
