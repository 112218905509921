/*
    Useful Resource:
    https://css-tricks.com/snippets/css/media-queries-for-standard-devices/
*/

@media screen and (max-width: 900px) {

  .subMenuContainer {

    .subMenu {

      ul {

        li {
          min-width: inherit;
        }
      }

    }
  }
}

/* iPhone 6 & 6+ Portrait */

@media screen and (max-width: 768px) {

  .customDateSelectorDialog {

    width: 100% !important;
    height: 100% !important;
    padding-top: 0 !important;

    & > div {
      width: 100% !important;
      height: 100% !important;

      & > div {

        width: 100% !important;
        height: 100% !important;
        transform: none !important;

        & > div {
          height: 100% !important;

          .customDateFilter {
            flex-flow: row wrap;
            padding: 0 !important;

            .halfScreen {
              width: 100% !important;
            }

            .customDateSection {
              width: 100% !important;
            }

            .sectionHeading {
              width: 100% !important;
              font-weight: 400;
              margin-top: 20px;
              display: block;
            }
          }
        }
      }

    }

  }

  .header {
    .logoPhAndPa {
      width: $centeredLoginContentMobileWidth;
    }
  }

  .transactionDetail {

    .levelsRow {
      display: block !important;
      margin-bottom: 0 !important;
    }

    .levelsColumn {
      width: 100% !important;
    }
  }

}

@media screen and (max-width: $sizeMobileMax) {
  .orderFormContainer {

    .halfScreen {
      width: 100% !important;
    }

    .fifthScreen {
      width: 40% !important;
    }

    .quarterScreen {
      width: 50% !important;
    }

  }

}
