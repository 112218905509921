.mobileFilter {
  display: none;
  padding: 15px 0;
  flex-direction: column;
  align-items: center;
  font-family: $primaryFontFamily !important;
  font-weight: 500;
  border-bottom: 1px solid $lineColor;

  @media screen and (max-width: $sizeMobileMax) {
    display: flex;
  }

  .selected {
    @extend %bodyText;
    border: 2px solid #DDDDDD;
    display: flex;
    border-radius: 10px;
    padding: 10px
  }

  .toolbar {
    display: flex;
    padding: 10px 25px;

    span {
      padding-right: 15px;

      &:last-child {
        padding-right: 0;
      }
    }

    .options {
      display: flex;
      align-items: center;

      .text {
        font-size: 14px;
        padding-right: 0;
      }
    }
  }

}