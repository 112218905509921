.invoiceSummary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: $backgroundColor;
  box-sizing: border-box;
  padding: 30px;
  margin-bottom: 10px;
  cursor: pointer;

  .mainInfo, .subtitle {
    width: 100%;
  }

  .mainInfo {
    @extend %bodyText;
  }

  .subtitle {
    @extend %contentLabel;
    color: $gray;
    margin-top: 5px;
  }

  .leftSide {
    display: flex;
    align-items: center;
    width: 68%;
    .info {
      width: 100%;
    }

  }

  .rightSide {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 30%;
    margin-left: 2%;
    text-align: right;

    .info {
      display: flex;
      justify-content: end;
      flex-direction: column;
      width: calc(100% - 30px - 10%);
    }

    .menuContainer {
      margin-left: 10%;
      width: 30px;
      display: flex;
      align-items: center;
    }
  }
}
