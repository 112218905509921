.cardOnFileForm {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: 15px;

  @media screen and (max-width: $sizeTabletMax) {
    .textFieldHolder {
      input {
        font-size: 14px !important;
      }
    }
  }

  .flex {
    display: flex;
    @media screen and (max-width: 620px) { display: block; }
    & > div.quarterRow {
      flex: 50%;
    }
    & > div.halfRow, & > div{
      flex: 100%;
    }

    & > div:not(:only-child):not(:last-child) {
      margin-right: 5px;
    }
  }

  .cardNumberContainer {
    position: relative;
    #cardNumberVisibilityIcon {
      position: absolute !important;
      top: 27px;
      right: 6px;
      padding: 4px 7px 7px;

      > span {
        height: 24px;
      }
    }
  }

  .cardImage {
    position: absolute;
    top: 36px;
    right: 50px;
  }

  .cardNumber {
    width: 100%;
  }

  #cardNumberVisibilityIcon {
    position: absolute !important;
    top: 25px;
    right: 6px;
    padding: 12px;
  }

  .textFieldInfo.debitCard {
    label::after {
      content: 'Debit Card Number'; // To prevent browser credit card saving
      @extend %columnLabel;
      padding: 0 0 0 15px;
    }
  }

  .vtAvsNotice {
    padding-left: 8px;
  }

  .cardAcknowledgement {
    display: flex;
    margin-top: 30px;
    padding: 25px 25px;
    border-radius: 5px;
    background-color: $backgroundColor3;

    .cardAcknowledgementIcon {
      padding-right: 15px;
    }
  }

  .methodTypeSelect, .accountTypeSelect {
    .textFieldHolder {
      height: 48px !important;
    }
  }

}
