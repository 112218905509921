$formWidth: 60%;

.businessOnlinePayments {

  padding: 0 25px 0 6%;
  box-sizing: border-box;
  width: 100%;


  @media screen and (max-width: $sizeTabletMin) { //768, Tablet portrait
    padding: 0 2%;
  }

  .formTitle {
    @extend %heading;
    margin: 0 0 60px 10px;
  }

  .formDescription {
    @extend %bodyText;
    margin: 0 0 28px 8px;

    .formDescriptionLink {
      @extend %link;
    }
  }

  .formItems {
    a {
      margin: 0 10px;
      @extend %link;
      color: $paymentHubsColor;
    }

    #achToggle {
      font-size: 13px !important;
    }
  }

  .textField, .customCheckbox, .chipContainer {
    padding: 0 6px;
    margin-bottom: 18px;
    box-sizing: border-box;
  }

  .chipContainer {
    padding: 0;
  }

  .customCheckbox {

    margin-top: 0;

    &.withTextField {
      margin-top: 36px;
    }

  }

  .vtSwitch {
    margin-left: 4px;
  }

  .cdAmountContainer {

    position: relative;
    padding-top: 0px;

    span {
      margin-left: 5px;
      font-size: 13px;
      font-weight: bold;
    }

    .tooltipCashDiscount {
      position: relative;
      display: inline-block;
      margin: 0;
      top: 5px;
      left: 5px;
    }
  }

  .chipContainer {

    font-family: $primaryFontFamily;
    font-weight: 500;
    display: inline-block;
    position: relative;

    .chipInfo {
      padding: 0 0 7px 15px;
      font-family: $primaryFontFamily;
      font-weight: 500;
      font-size: 13px !important;
      margin: 0;

    }

  }

  .paymentTypeContainer {
    visibility: hidden;
    position: absolute;
  }

  .transactionAmount {

    margin: 0 0 60px;

    .flex > div {
      vertical-align: top;
    }

    .vtTaxInput {
      display: inline-block;
    }
  }

  .optionalReceipt {

    margin: 0 0 60px;

    .customer_first, .customer_last {
      vertical-align: top;
    }

  }

}

.businessOnlinePayments, .invoiceForm {
  .thirdToHalfScreen { //Use a third of a row, but on smaller screens use half of a row
    width: 33.33% !important;

    @media screen and (max-width: 1500px) {
      width: 50% !important;
    }
  }

  .quarterToHalfScreen {
    width: 25% !important;

    @media screen and (max-width: 850px) {
      width: 50% !important;
    }
  }

  .autoSaveCardMessage {
    padding: 0 12px 24px;
    @extend %bodyText;
  }

  .paymentInformation {

    position: relative;

    .cardContainerWithVisibilityToggle {

      position: relative;
      display: inline-block;
      width: 50%;

      @media screen and (max-width: 1440px) {
        width: 100%;
      }

      .cardNumber {
        width: 100%;
      }

    }

    .cardNumber {
      .textFieldInfo::after {
        content: 'Credit Card Number'; // To prevent browser credit card saving
        font-family: $primaryFontFamily;
        font-weight: 600;
        font-size: 13px !important;
        margin: 0;
        padding: 0 0 0 15px;
      }

      &.spanishTranslation {
        .textFieldInfo::after {
          content: 'Número de Tarjeta de Crédito'; // To prevent browser credit card saving
        }
      }
    }

    .textFieldInfo.debitCard {
      label::after {
        content: 'Debit Card Number'; // To prevent browser credit card saving
        @extend %columnLabel;
        padding: 0 0 0 15px;
      }

      &.spanishTranslation {
        .textFieldInfo::after {
          content: 'Número de Tarjeta de Débito'; // To prevent browser credit card saving
        }
      }
    }

    .checkField {
      .disabled {
        opacity: 0.75;
      }
    }

    .infoBlock {
      text-align: center;
      border: 1px solid $cardBorderColor;
      padding: 20px;
    }

    .cardPresentIndicator, .selectPaymentMethodButton {

      margin: 0 0 28px 6px;

      a {
        @extend %link;
      }
    }

    .flex > div {
      vertical-align: top;
      @media screen and (max-width: $sizeMobileMax) {
        vertical-align: baseline;
      }
    }

    .textField{
      padding: 0 6px;
      margin-bottom: 18px;
      box-sizing: border-box;

      @media screen and (max-width: $sizeMobileMax) {
        padding: 0;
      }
    }

  }
}

.virtualTerminalLoading {
  z-index: 1000;
}


.settings {

  &.virtualTerminalSettings {
    padding: 40px 80px;
    margin-bottom: 100px;
  }

  .loadingIndicatorContainer {
    margin-right: 25px;
    margin-top: 25px;
  }

  @media screen and (max-width: $sizeMobileMax) {
    padding: 5px;
  }

  .title {
    font-weight: 600;
    font-size: 29px;
    margin-bottom: 0;
    margin-left: 10px;
  }

  .subtitle {
    color: #b4b3b3;
    margin-bottom: 20px;
    margin-left: 10px;
  }

  .setting {
    &-title {
      margin-left: 15px;
    }

    .box {
      margin-bottom: 40px;
      padding: 0 20px;
      background-color: #f1f1f1ee;
      border: 1px solid #DDDDDD;
      border-radius: 10px;
    }

    .field-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 20px;
      border-bottom: 1px solid #DDDDDD;
      padding: 10px 0;

      .field {
        display: flex;
        align-items: center;
        width: 70%;

        [role=button] > span {
          display: flex;
        }

        .field-name {
          padding-left: 20px;
          width: 100%;

          .textField {
            .textFieldHolder {
              background-color: white;
            }
          }
        }
      }

      .options {
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .option {
          padding: 10px;
          display: flex;
          align-items: center;

          &-aligned-left {
            align-self: self-start;
          }

          .sharedCustomCheckbox {
            padding-left: 40px;
          }
        }
      }
    }
  }
}

.virtualTerminal {

  .filter {
    width: 230px;
  }

  .virtualTerminalFormContainer, .invoiceForm {

    display: flex;
    padding-bottom: 100px;

    @media screen and (max-width: 1200px) { //Moves the Summary Section to the bottom
      display: block;
    }

    form {
      width: $formWidth;

      @media screen and (max-width: 1200px) {
        width: 100%;
      }
    }

    .allowTip {
      display: flex;

      .tipToggle {
        margin-bottom: 0;
      }
    }

    .datepickerHolder {
      height: 48px;
      border: 4px solid #ddd;
      display: flex;
      border-radius: 10px;
      padding: 0 10px 0 15px;
      > div {
        width: 100% !important;
      }
      input {
        font-size: 16px !important;
        box-shadow: none !important;
      }
    }

    .addressInfo {

      flex-direction: column;

      .customerState,
      .customerStreet,
      .customerCityZip {
        .textField:last-child {
          width: 100%;
          margin-left: 0;
        }
      }

      .customerStreet {
        display: flex;
        justify-content: space-between;
        > .customerStreet1Field, .customerStreet2Field {
          width: 50%;
        }
      }

      .customerState,
      .customerCityZip {
        display: flex;

        .customerZip,
        .customerCity {
          width: 50%;
        }
      }
    }

    .levelThree {
      .shipmentZipCode, .destinationZipCode {
        vertical-align: bottom;
      }
    }

    .summarySection {

      width: calc(100% - $formWidth);
      max-width: 600px;
      min-width: 20em;
      margin-top: 102px;
      box-sizing: border-box;
      padding-left: 40px;
      padding-right: 30px;

      @media screen and (max-width: 1200px) {
        margin: 0 0 15px 0;
        padding: 0;
        position: inherit;
        width: 600px;
        right: auto;
      }

      .summaryTitle {
        @extend %contentLabel;
        padding: 10px 15px;
      }

      .amounts, .subtotals {

        @extend %bodyText;
        text-align: left;

        .amountValue, .subtotalValue {
          float: right;
        }

      }

      .amounts {
        background: $lightGray;
        padding: 20px 15px;
        margin: 0 28px 20px 0;
        border-radius: 4px;
        color: $gray !important;
      }

      .subtotals {
        padding: 2px 43px 2px 15px;
      }

      .total {
        font-family: $primaryFontFamily !important;
        font-weight: 600;
      }

      .loyaltyDiscount {
        background: $lightGray;
        padding: 20px 15px;
        margin: 0 28px 20px 0;
        border-radius: 4px;
        display: flex;
        align-items: center;
        column-gap: 10px;

        span {
          font-size: 16px;
        }

        span:last-of-type {
          margin-left: auto;
        }
      }
    }

    .customCheckbox {

      svg + svg {
        fill: $payAnywhereColor !important;
        stroke: none !important;
        color: $payAnywhereColor !important;
      }

      svg {
        fill: #ffffff !important;
        stroke: $gray !important;
        color: $gray !important;
      }
    }

    .itemSearchDropdown {

      width: 36em;
      position: relative;

      .taxNote {

        @extend %contentLabel;
        margin: 0 0 35px 20px;

        .linkLike {
          color: $paymentHubsColor;
          @extend %contentLabel;
        }

      }

      .toolbar {
        .searchBar {

          position: relative;

          .searchBarContainer {
            height: auto;
          }

          .clearButton {
            position: absolute !important;
            top: 0;
            right: 0;
          }

        }

      }

      .itemsList {

        background: #ffffff;
        border-radius: 8px;
        box-shadow: 2px 2px 10px 0 rgba(163, 163, 166, 1);
        box-sizing: border-box;
        left: 6px;
        list-style: none;
        margin: 0;
        min-width: 22em;
        max-height: 50em;
        overflow: auto;
        padding: 0;
        position: absolute;
        top: 90px;
        width: calc(100% - 12px);
        z-index: 999;

        .expressItem, .itemSearchRow {

          padding-left: 2em;
          padding-right: 2em;

          &:hover {
            cursor: pointer;
          }

        }

        .expressItem {

          border-bottom: 4px solid $disabledGray;
          text-align: right;
          padding-top: 2.15em;
          padding-bottom: 2.15em;

        }

        .itemSearchRow {

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid $disabledGray;
          padding-top: 1.6em;
          padding-bottom: 1.6em;

          .leftSide {
            text-align: left;
          }

          .rightSide {
            text-align: right;
          }

          .itemName, .itemPrice {
            @extend %bodyText;
          }

          .itemBarcode, .itemInStockQuantity {
            @extend %contentLabel;
          }

          &.discountSearchRow {
            &.discountSearchRow.disabled {
              background-color: $lightGray;
              cursor: not-allowed;
            }

            .leftSide {

              display: flex;
              flex-direction: row;
              align-items: center;

              .discountIcon {

                padding: 0 !important;
                width: 30px !important;
                height: 30px !important;
                margin-right: 8px !important;

                svg {
                  width: 18px !important;
                  font-size: 18px !important;
                }
              }

            }

            .rightSide {
              display: flex;
              align-items: center;
            }

          }

        }

      }

    }

  }

  .invoiceForm {
    border: none;
    margin: 23px 0;

    a {
      margin: 0 10px;
      @extend %link;
      color: $paymentHubsColor;
    }

    .sendToCustomerMessage {
      padding: 0 12px 24px;
      @extend %bodyText;
    }

    .autoSaveCardMessage {
      padding: 0 12px 24px;
      @extend %bodyText;
    }

    .summary {
      position: unset;

      .summarySection {
        margin-top: 67px;

        @media screen and (max-width: 1200px) {
          margin: 0 0 15px 0;
        }
      }
    }

    .itemSearchDropdown {
      width: auto;
    }

    .menuDateItem {
      display: flex;
      justify-content: space-between;
      width: 100% !important;

      div:nth-child(2) {
        text-align: end;
      }
    }

    .paymentMethodSelect {
      margin-left: 6px;
      margin-right: 6px;
    }
  }

  .loyaltyOptIn {
    .tile {
      h3 {
        margin: 0 0 45px 0;
      }
    }
  }

}

.reputation {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .updateMembershipMessage {
    padding-top: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .reputationIcon {
      background-color: #efeff2;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      padding: 1.25%;

      svg {
        width: 90px;
        height: 90px;
        margin-top: -5px;
      }
    }

    h1 {
      text-align: center !important;
      padding-top: 0.5%;
      width: 100%;
      @extend %heading;
      margin: 1em 0;
    }

    p {
      margin-top: 5px !important;
      max-width: 450px;
      text-align: center;
      @extend %bodyText;
      margin: 2em auto;
    }
  }

  .reputationWrapper {
    display: flex;

    .filter {
      @media screen and (min-width: 1200px) {
        min-width: 330px;
      }
    }

    .filterPanel {
      h1 {
        display: block !important;
      }
    }

    .noData {
      font-size: 16px;
      color: #404143;
      text-align: center;
      padding-top: 25px;
    }

    .tableContainer {
      overflow-x: auto;

      .ReactVirtualized__Grid.ReactVirtualized__Table__Grid {

        &, .ReactVirtualized__Grid__innerScrollContainer{

          &, .ReactVirtualized__Table__row {
            cursor: pointer !important;
          }
        }
      }

      .ReactVirtualized__Table__headerColumn {
        .iconHeader {
          svg {
            height: 7px;
            width: 7px;
          }
        }
      }

      @media screen and (max-width: 1300px) {

        .ReactVirtualized__Table__headerRow {
          border-top: 1px solid $lineColor;
          width: 1200px !important;
        }

        .ReactVirtualized__Grid.ReactVirtualized__Table__Grid {

          &, .ReactVirtualized__Grid__innerScrollContainer{

            &, .ReactVirtualized__Table__row {
              width: 1200px !important;
              max-width: none !important;
            }
          }
        }
      }
    }


    .reputationContent {
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: #efefef;

      .reputationSettings {
        background-color: white;
        padding: 12%;

        .quickReplies {
          padding: 12px 0;
          h1 {
            font-size: 2em;
          }

          .addReply {
            border-radius: 17px;
            color: $paymentHubsColor;
            display: flex;
            align-items: center;
            cursor: pointer;

            .plusIcon {
              pointer-events: none;
              margin-right: 10px;
              span {
                margin: 0 -5px -5px -10px;
              }
              svg {
                fill: $paymentHubsColor;
              }
            }
          }

          .category {
            display: flex;
            align-items: center;
            padding-top: 20px;
          }

          .categoryTitle {
            margin-left: 10px;
          }

          .reply {
            width: 100%;
            min-height: 62px;
            background: #EFEFF2 0% 0% no-repeat padding-box;
            border-radius: 17px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px;
            box-sizing: border-box;
            margin: 12px 0;
            inline-size: initial;
            overflow-wrap: anywhere;

            .replyDeleteIcon {
              margin-left: 12px;
            }
          }
        }

        .notifications {
          h1 {
            margin-bottom: 0;
          }
          p {
            margin: 0 0 16px 0;
            font-size: 10px;
            color: #000000DE;
          }
          span {
            font-size: 10px;
            margin-left: 10px;
            color: #000000DE;
          }
          .contactContainer {
            .contact {
              .detail {
                margin-left: auto;
                padding-right: 15px;
              }
            }
            .addContact, .contact {
              height: 48px;
              border: 0.5px solid #70707053;
              margin-top: 10px;
              border-radius: 11px;
              display: flex;
              align-items: center;
              cursor: pointer;
              .icon {
                padding: 0 15px;
                display: flex;
              }
            }
            .addContact {
              color: #00B4AA;
            }
          }
          .textField {
            .textFieldInfo {
              min-height: unset;
              padding-bottom: 0;
              height: 5px;
            }
          }
        }
      }

      .loadingContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%
      }

      .titleContainer {
        text-align: center;
      }

      .cardsContainer{
        display: flex;
        flex-wrap: wrap;
        padding: 76px 76px 12px;
        flex-wrap: wrap;
        &.middle{
          padding-top:0;
          gap: 13px;
          padding-top: 1px;
          padding-bottom: 76px;
        }
        .list{
          min-width: 156px;
          height: 370px;
          border-radius: 20px;
          border: 1px solid #E0E0E0;
          box-shadow: 2px 2px 4px 1px #e0e0e0;
          overflow: hidden;
          background-color: #FFFFFF;
        }
        @media screen and (max-width: $sizeMobileMax) {
          padding: 45px 0 100px;
          justify-content: center;
          gap: 30px;
        }
      }

      .reputationCard{
        width: 156px;
        .content{
          text-align: center;
          padding: 50px 0 0;
        }
        .list{
          max-height: 236px;
          min-height: 236px;
        }
        .ratingGroup{
          margin-top: 25px;
          p{
            margin-top: 4px;
          }
        }
      }

      .mostCommonWordsCard {
        min-width: 291px;
        padding-left: 20px;

        .titleContainer {
          text-align: center;
        }

        .content {
          padding: 0 !important;
        }

        .loadingContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%
        }
        .noResults{
          margin-top: 84px;
        }
      }

      .scoreCard {
        width: 256px !important;
        margin-left: 16px;
        text-align: center;
        @media screen and (max-width: $sizeMobileMax) {
          margin-top: 16px;
          margin-left: 0;
        }
        &:last-child{
          @media screen and (max-width: 1300px) {
            margin-top: 9px;
          }
        }
        .list{
          background-color: #1D1D1D;
          color: #FFFFFF;
          max-height: 142px;
          min-height: 142px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .caption{
          letter-spacing: 0px;
          font-weight: 400;
          color: #FFFFFF;
          opacity: 0.49;
          min-height: 14.5px;
        }
        .internalContent{
          font-size: 22px;
          font-weight: bold;
          color: white;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          .percent{
            margin-left: 10px;
          }
          .faces{
            display: flex;
            justify-content: space-between;
            gap: 47px;
            div svg path{
              fill: white;
            }
            span{
              margin-left: 5px;
            }
          }
        }
      }
      .MostRecentReviewsCard{
        width: 394px;
        padding-left: 16px;
        text-align: center;
        position: relative;
        @media screen and (max-width: $sizeMobileMax) {
          padding: 0;
          width: 97%;
        }
        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5px;
            padding: 0;
        }
        .list{
          min-height: 266px;
          position: absolute;
          left: 22px;
          right: 0;
          top: 0;
          bottom: 0;
          max-height: inherit;
          height: auto;
          @media screen and (max-width: 1370px) {
            position: relative;
            left: -17px;
          }
          @media screen and (max-width: 1296px) {
            position: static;
          }
        }
        .reviewItem{
          cursor: pointer;
          width: 362px;
          min-height: 62px;
          background: #EFEFF2 0% 0% no-repeat padding-box;
          border-radius: 17px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding: 0 12px;
          box-sizing: border-box;
        }
        .reviewItemPic{
          display: flex;
          position: relative;
          img{
            width: 49px;
            height: 49px;
            border: 0.5px solid #70707053;
            border-radius: 27px;
          }
          .platformContainer{
            background-color: #FFFFFF;
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 14px;
            position: absolute;
            bottom: 0;
            right: -10px;
            img{
              width: 13.25px;
              border: unset;
            }
          }
        }
        .reviewItemInfo{
          width: 139px;
          text-align: left;

          p{
            &:first-child{
              margin-bottom: 0;
              font-weight: bold;
            }
            &:last-child{
              margin-top: 0;
            }
          }
        }
      }

      .competitorPerformaceCard {
        min-width: 395px;
        min-height: 265px !important;
        @media screen and (max-width: $sizeMobileMax) {
          width: 97%;
          padding: 0;
        }
        @media screen and (max-width: 1300px) {
          padding-left: 16px;
        }
        .list {
          min-height: 266px !important;
          max-height: fit-content !important;
          height: auto;
        }

        .content {
          display: flex;
          justify-content: space-around;
          padding: 10px 0 0 0 !important;

          .competitor {
            background-color: #EFEFF2;
            min-height: 195px;
            width: 112px;
            border-radius: 17px;
            cursor: default;
            margin-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            .name {
              font-weight: bold;
              color: #212121;
              padding: 0 10px;
              margin-top: 20px;
              margin-bottom: 7px;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .trend {
              display: flex;
              justify-content: center;

              .icon {
                margin-right: 5px;
                margin-top: -2px;
              }
            }

            .ratings {
              padding-top: 15px;
              p {
                font-size: 10px;
                margin-top: 3px !important;
              }
            }

            .ratingFaces {
              padding: 15px 10px 10px 10px;
              display: flex;
              justify-content: space-around;

              p {
                font-size: 10px;
              }
            }
          }

          .addCompetitor {
            height: 195px;
            width: 112px;
            border-radius: 17px;
            border: 0.5px solid #7070705D;
            color: $paymentHubsColor;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .plusIcon {
              pointer-events: none;
              span {
                margin: 0 -5px -5px -10px;
              }
              svg {
                fill: $paymentHubsColor;
              }
            }
          }
        }
      }

      .competitorPerformance {
        width: 100%;
        height: 100%;
        background-color: $backgroundColor;
        overflow-x: hidden;
        overflow-y: auto;

        .topBarContainer {
          @media screen and (min-width: 990px) {
            height: 84px;
          }
        }
      }
      .trendsToggle{
        display: flex;
        margin: 70px 131px 0 0;
        justify-content: flex-end;
        @media screen and (max-width: $sizeMobileMax){
          margin: 70px 0 0;
          justify-content: center;
        }
      }
      .customerReviews {
        width: 100%;
        height: 100%;
        background-color: $backgroundColor;
        overflow-y: scroll;
        overflow-x: hidden;

        .topBarContainer {
          @media screen and (min-width: 990px) {
            height: auto;
          }
        }
      }
    }

    .disabledReputationFilterPanel {

      .filtersSelectDropdown {
        pointer-events: none;
      }
    }
  }

  .getStartedTrends{
    max-width: 784px;
    margin: 70px auto auto 90px;
    @media screen and (max-width: 1283px) {
      margin: 70px auto;
    }
    @media screen and (max-width: $sizeMobileMax) {
      margin: 70px 20px;
    }
    .trendsHero{
      display: flex;
      padding: 0 0 63px 0;
      .linkLike {
        color: $paymentHubsColor;
        @extend %contentLabel;
      }
      h1{
        font-size: 36px;
        font-weight: bold;
       }
       p {
         font-size: 12px;
         color: #3E3935;
         margin-bottom: 30px;
       };
      @media screen and (max-width: $sizeMobileMax) {
        flex-direction: column-reverse;

        div {
          text-align: center;

          img {
            width: 50vw;
          }
        }
      }
    }
    .trendsCards{
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: $sizeMobileMax) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }

      .trendsCard {
        @media screen and (max-width: $sizeMobileMax) {
          max-width: 80%;
        }

        & {
          max-width: 239px;
          height: 267px;
          border-radius: 20px;
          border: 1px solid #E0E0E0;
          overflow: hidden;
          background-color: #FFFFFF;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          padding: 40px 20px;
          justify-content: end;
          align-items: center;
        }

        p {
          font-size: 12px;
          font-weight: bold;
          text-align: center;
        }

        img {
          max-width: 80%;
        }
      }
    }

  }

  .betaReputationContainer{
    @media screen and (min-width: $sizeTabletMax) {
      display: none;
    }
    button{
      position: absolute;
      bottom: 11px;
      right: 2%;
      &.roundReputationButton{
        svg{
          position: absolute;
          stroke: none;
        }
      }
    }

    .betaReputationMessage{
      width: 100%;
      position: absolute;
      bottom: 0;
      min-height: 62px;
      left: 0;
      right: 0;
      background-color: white;
      z-index: 1;
      box-shadow: 1px -4px 20px -5px rgba(0,0,0,0.75);
      display: flex;
      padding-top: 10px;
      .betaContainer{
        display: flex;
        margin-top: 10px;
        align-items: flex-start;
        width: 88%;
        svg{
          margin:0 11px 0 12px;
          width: 50px !important;
          height: auto;
        }
      }
    }

  }


  .competitorsBar {
    min-height: 158px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    @media screen and (max-width: 1280px) {
      justify-content: center;
    }

    .competitor {
      height: 102px;
      width: 291px;
      border: 0.5px solid #70707053;
      border-radius: 11px;
      display: flex;
      position: relative;
      @media screen and (max-width: 1280px) {
        margin-top: 10px;
      }

      .inactive {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
        border-radius: 11px;
        background-color: rgb(0, 0, 0, 0.7);
        display: flex;

        .icon {
          height: 100%;
          width: 40px;
          display: flex;
          align-items: center;
          padding: 0 15px;
        }

        .information {
          width: 165px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .remove {
            display: flex;
            align-items: center;
            color: $paymentHubsColor;
            cursor: pointer;

            svg {
              margin: 0 5px 0 -5px;
            }
          }

          p {
            margin-top: 0;
            color: #EFEFF2;
            font-size: 14px;
            font-weight: bold;
            cursor: default;
          }
        }
      }

      .location {
        width: 115px;
        height: 100%;
        border-radius: 11px 0 0 11px;

        .mapContainer {
          height: 102px !important;
          width: 115px !important;

          .leaflet-container {
            width: 100% !important;
            border-radius: 11px 0 0 11px;
          }

        }
      }

      .trimmedName {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .info {
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: default;
        white-space: nowrap;
        overflow: hidden;

        p {
          letter-spacing: -0.12px;
          font-size: 10px;
          margin: 0 !important;
          font-family: $primaryFontFamily;
          font-weight: normal;
        }

        .address {
          padding-top: 7px;
        }
      }

      .removeContainer {
        display: flex;
        flex-grow: 1;
        justify-content: end;
        align-items: center;
        padding-right: 27px;

        .icon {
          cursor: pointer;
        }
      }
    }
    .addCompetitor {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $paymentHubsColor;

      .plusIcon {
        pointer-events: none;
        span {
          margin: 0 -5px -5px -10px;
        }
        svg {
          fill: $paymentHubsColor;
        }
      }
    }
  }

  .reputationSettings {
    @media screen and (max-width: $sizeMobileMaxPortrait) {
      padding: 20px;
    }

    #googleConnectButton {
      cursor: pointer;
      font-size: 12px;
      border: none;
      width: 100px;
      font-size: 13px;
      font-weight: bold;
      color: #00B4AA;
      min-width: 119px;
      @media screen and (max-width: $sizeMobileMaxPortrait) {
        margin-left: 74px;
      }
    }

    &Container {
      background-color: #FFFFFF;
      width: 100%;
      height: 100%;
    }

    & {
      padding: 70px;
    }

    h1 {
      font-size: 22px;
      line-height: 36px;
      margin-bottom: 0;
    }

    .subtitle {
      font-size: 10px;
      color: #000000DE;
      margin: 0 0 16px 0;
    }

    .connectionCard {
      position: relative;
      width: 100%;
      max-width: 584px;
      height: 114px;
      border: 0.5px solid #70707053;
      background-color: #FFFFFF;
      border-radius: 27px;
      display: flex;
      align-items: center;

      @media screen and (max-width: $sizeMobileMaxPortrait) {
        flex-direction: column;
        justify-content: center;
      }

      p {
        margin: 0;
      }

      .description {
        img {
          border-radius: 39px;
        }

        & {
          width: 350px;
          margin-left: 36px;
        }

        @media screen and (max-width: $sizeMobileMaxPortrait) {
          justify-content: center;
          margin-left: 0;
        }
      }

      .descriptionItems {
        margin-left: 30px;

        h3, p {
          margin: 0;
        }

        h3 {
          font-size: 14px;
          font-weight: bold;
          color: #000;
        }

        p {
          font-size: 10px;
          text-align: left;
          display: flex;
          align-items: center;
          gap: 4px;
          color: #000;

          svg {
            width: 13px;
            display: inline-block;
            color: #1FC3B3;
          }
        }
      }

      .controls {
        display: flex;
        align-items: center;

        .controlsContainer {

          @media screen and (max-width: $sizeMobileMaxPortrait) {
            margin-right: -22px;
          }

          span {
            display: flex;
            color: black;
          }

        }
      }
    }

    .competitorsBar {
      flex-direction: column;
      row-gap: 14px;
      padding-top: 10px;

      .competitor {
        max-width: 584px;
        width: 100%;
      }

      .addCompetitor {
        justify-content: start;
        padding-left: 38px;
        box-sizing: border-box;
        max-width: 586px;
        height: 104px;
      }
    }
  }


}

.editLocationDialog {
  .MuiDialogContent-root {
    .locationsContainer {
      min-height: 250px;
      min-width: 320px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 15px;

      .location {
        height: 102px;
        width: 550px;
        border: 0.5px solid #70707053;
        border-radius: 11px;
        display: flex;
        position: relative;
        cursor: pointer;

        @media screen and (max-width: $sizeMobileMaxPortrait) {
          width: 60vw;
        }

        .map {
          width: 115px;
          height: 100%;
          border-radius: 11px 0 0 11px;

          .mapContainer {
            height: 102px !important;

            .leaflet-container {
              width: 100% !important;
              border-radius: 11px 0 0 11px;
            }
          }
        }

        .info {
          padding-left: 20px;
          padding-right: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;

          h3 {
            font-size: 14px;
            margin: 0;
          }

          p {
            letter-spacing: -0.12px;
            font-size: 10px;
            margin: 0 !important;
            font-family: $primaryFontFamily;
            font-weight: normal;
          }

          .address {
            padding-top: 7px;
          }
        }

        .activeContainer {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: end;
          padding-right: 10px;
        }
      }

      .activeLocation {
        border: 2.5px solid $paymentHubsColor;
        cursor: default;
      }
    }
  }
}

.addCompetitorDialog {
  .MuiDialog-paper {
    @media screen and (max-width: $sizeMobileMax) {
      margin: 0;
      width: 100%;

      .MuiDialogContent-root {
        .textField {
          width: 60vw;
        }
      }
    }
  }

  .MuiDialogContent-root {
    height: 270px;

    .textField {
      @media screen and (min-width: $sizeMobileMax) {
        width: 320px;
      }

      .textFieldHolder {
        border: 0.5px solid #D0D0D0;
        border-radius: 19px;

        .MuiInput-input {
          font-size: 13px !important;
        }

        .MuiCircularProgress-root {
          color: #D0D0D0;
          width: 22px !important;
          height: 22px !important;
          padding: 0;
          margin-right: 5px;
        }

        .googleLogo {
          width: 18px;
          padding-right: 10px;
        }
      }
    }

    .autocompleteContainer {
      margin-top: 5px;
      display: flex;
      column-gap: 10px;
    }
  }
}

.competitorOption {
  padding: 16px 20px 0px;
  cursor: pointer;

  .name {
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000DE;
    padding-bottom: 5px;
  }

  p {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.12px;
    margin: 0;
  }

  hr {
    margin-top: 16px !important;
    margin-bottom: 0;
    border: unset;
    border-top: 0.5px solid #D0D0D0;
    cursor: default;
  }

  .lastOption {
    padding-bottom: 16px;
  }
}

.activeOption {
  background-color: #D0D0D0;
}

.betaContainer{
  width: 193px;
  h2{
    font-size: 12px;
    line-height: 16px;
    margin: 3px 0;
    white-space: nowrap;
  }
  p{
    margin-top: 0;
    font-size: 10px;
  }
  a{
    color: $paymentHubsColor;
    text-decoration: none;
  }
}

.customRating {
  & .MuiRating-iconFilled {
    color: $paymentHubsColor;
  }
}

.itemsVTDialog, .issueCreditDialogContent {

  .itemsDialogContainer {
    overflow: hidden;

    @media screen and (max-width: $sizeMobileMax) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
    }

    .textFieldHolder > div > div {
      align-items: center;
      display: flex;
    }

    .expressItem {
      .taxable {
        margin-bottom: 10px;
      }
    }
  }

  .MuiDialog-paper {
    width: 70%;

    @media screen and (max-width: $sizeMobileMax) {
      width: 100%;
      margin: 0px;
      max-height: 100% !important;
    }
  }

  .MuiDialog-paper, .content {
    padding: 0px !important;

    @media screen and (max-width: $sizeMobileMax) {
      height: 100%;
      display: flex;
      border-radius: 0px !important;
      justify-content: space-between;
    }
  }

  .itemsDialogBody {

    .existingItem, .expressItem {
      padding: 4em 10%;
      padding-bottom: 3em;
    }

    .titleContainer {

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .itemName {
        @extend %heading;
      }

      .titleMainInfo {

        .itemSubtitle {

          @extend %contentLabel;

          .itemCategoryColor {

            display: inline-block;
            margin-right: 10px;

            svg {
              width: 14px !important;
              position: relative;
              top: 3px;
            }

          }

          .itemTax {

            display: inline-block;

            .refreshIndicator {
              top: -9995px !important;
            }

          }

        }

      }

      .titleImage {

        border-radius: 8px;
        overflow: hidden;
        width: 90px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;

        .itemThumbnail {

          height: 100%;

          img.itemThumb {
            width: 90px !important;
            height: auto;
          }

        }

      }

    }

    .expressItem {

      .textField, .customCheckbox {
        padding: 0 6px;
        margin-bottom: 18px;
        box-sizing: border-box;
        vertical-align: top;
      }

      .customCheckbox {

        margin-top: 0;

        &.withTextField {
          margin-top: 36px;
        }

      }

      .titleContainer {
        margin-bottom: 3.6em;
      }

    }

    .groupLabel {
      @extend %contentLabel;
      padding-left: 25px;
      margin-bottom: 8px;
    }

    .pricesContainer {

      margin: 4em 0 3.2em;

      .selectedItemPriceRadioButton {

        fieldset {
          margin: 0 !important;
          width: 100%;
        }

        label {
          background: #EFEFF2;
          border-radius: 8px;
          box-sizing: border-box;
          margin: 0 0 8px 0;
          padding: 20px;

          span:last-child {
            padding: 5px;
            width: calc(100% - 40px) !important;
          }

        }

        .selectedItemPrice {

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          &.outOfStock {

            .itemName, .itemPrice, .itemBarcode, .itemInStockQuantity {
              color: #b1b3b4 !important;
            }

          }

        }

      }

      .leftSide {
        text-align: left;
      }

      .rightSide {

        text-align: right;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .lowStockWarning, .priceAndQuantity {
          display: inline-block;
        }

        .lowStockWarning {
          margin-right: 40px;
          z-index: 999;
        }

        .priceAndQuantity {
          min-width: 7em;
        }

      }

      .itemName, .itemPrice {
        @extend %bodyText;
      }

      .itemBarcode, .itemInStockQuantity {
        @extend %contentLabel;
      }

    }

    .modifiersContainer {
      margin-bottom: 46px;

      .selectedItemModifierSet {

        .groupLabel {
          margin-bottom: 16px;
        }

        .modifierOptions {

          padding: 0 25px;

          .modifierOptionWrapper {
            label {
              width: 100%;
              > span + span {
                width: 100%;
              }
            }
          }

          .modifierOptionCheckbox {

            margin-bottom: 8px;

            > div {
              justify-content: space-between;

              label {
                width: calc(100% - 40px) !important;
              }
            }

          }

          .modifierOptionLabel {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            @extend %columnLabel;
          }

        }

      }

      .modifierPrice {
        margin-left: 12px;
      }

    }

    .discountsContainer {

      .discountContainer {

        position: relative;

        .itemDiscountTypeSelect {

          .textFieldInfo {
            display: none;
          }

          .textFieldHolder {

            position: absolute;
            top: 1px;
            right: 20px;
            border: none;
            width: 8em;

            > div > div {
              padding-right: 40px;
            }

            div {
              @extend %columnLabel;
            }

          }

        }

      }

    }

    .selectContainer {

      position: relative;

      .bubble {
        position: absolute;
        bottom: 31px;
        left: 18px;
      }

      .textField {
        width: 100%;
      }

    }

    .itemsActionsContainer {
      @media screen and (max-width: $sizeMobileMax) {
        padding: 5px 8%;
        padding-bottom: 20px;
      }

      & {
        padding: 30px 10%;
        text-align: center;
        border: 0 !important;
        box-shadow: 0px 5px 30px 0 #c7c7c7;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .quantityField {

        @extend %columnLabel;
        width: 80px !important;
        margin: 0 10px;
        padding: 0;
        text-align: center;

        @media screen and (max-width: $sizeMobileMax) {
          padding-top: 5px;
        }

        .textFieldHolder {

          padding: 0 10px;

          input {
            text-align: center;
          }

        }

      }

      .quantityButton {

        width: 18px !important;
        height: 18px !important;
        margin: 0 !important;
        padding: 2px !important;

        svg {
          width: 10px !important;
          height: 10px !important;
        }

      }

    }

  }

}

.cardReaderStatusWell {
  border: 1px solid $lineColor;
  overflow: hidden;
  font-family: $primaryFontFamily;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;

  .cardReaderStatusIcon {
    float: left;
    width: 10%;
    margin: 20px 0;
    text-align: center;

    i {
      display: inline-block;
      width: 45px;
      height: 45px;
      border-radius: 45px;
      background-color: $lineColor;
      opacity: 0.5;
    }

    i.error {
      opacity: 0.95;
    }

    i::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: $positiveColor;
      opacity: 0.75;
      margin: 17.5px 0;
    }

    i.error::before {
      background-color: $negativeColor;
      opacity: 0.75;
    }
  }

  .cardReaderStatusInfo {
    float: left;
    width: 50%;
    margin: 20px 20% 15px 0;

    .cardReaderStatusTitle {
      margin: 0;
      padding: 0;
      @extend %bodyText;
      font-family: $primaryFontFamily !important;
      font-weight: 600;
    }

    .cardReaderStatusSubtitle {
      margin-top: 0;
      padding-top: 5px;
      @extend %bodyText;
    }
  }

  .cardReaderStatusInfo.noSublabel {
    margin-top: 30px;
  }

  .cardReaderStatusAction {
    width: 20%;
    justify-self: flex-end;
    text-align: center;
    margin: auto 7px auto 0;

    a {
      @extend %link;
    }
  }
}

.paymentStatusDialog {

  div div div div:last-of-type {
    border-top: none !important;
  }

  .paymentStatusDialogContent {

    > div:first-of-type {
      border-radius: 15px !important;
    }

  }

  .paymentStatusDialogActions {
    padding: 15px !important;
  }

}

.paymentStatusMessageDialog, .invoiceStatusMessageDialog {

  font-family: $primaryFontFamily;
  font-weight: 500;

  .paymentLink, .invoiceLink {
    @extend %link;
    display: block;
    margin: 1.2em 0;
  }

  .statusTitle {
    display: block;
  }

  .loyaltyStatusMessage {
    background-color: $loyaltyResendGray;
    height: 50px;
    border-radius: 28px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin: 15px 0;

    p {
      font-size: 12px;
      display: flex;
      align-items: center;
    }

    .loyaltyIcon {
      padding-right: 15px;
      width: 20px;
      height: 20px;

      svg {
        height: 20px !important;
        width: 20px !important;

        path {
          fill: $paymentHubsColor;
        }
      }
    }
  }

  .paymentLink:hover, .invoiceLink:hover {
    text-decoration: underline;
  }

  span {
    @extend %body;
  }

  h1, span {
    font-family: $primaryFontFamily !important;
    font-weight: 500;
    color: $primaryTextColor !important;
  }

  div svg:first-of-type {
    height: 50px !important;
    width: 50px !important;
  }
}

.processPayment {
  padding: 40px 0 100px 0;
}

.employeeDetail, .customerDetail {

  .filterPanel {
    .listItem {
      svg {
        top: -5px !important;
      }
    }
  }

}

.employeeDetail {

  .detailDescription {
    .detailDescriptionText {
      margin-bottom: 30px;
    }
  }

}

.messageDialog {
  &.deleteConfirmationDialog, &.archiveConfirmationDialog {
    .dialogTitle {
      text-transform: none;
    }
  }
}

.resendInviteContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 35px;

  .resendIconContainer {
    width: 10%;
    vertical-align: top;
    justify-content: center;
    align-items: center;
    text-align: center;

    svg {
      fill: $payAnywhereColor !important;
    }
  }

  .resendMessageContainer {
    font-family: $primaryFontFamily !important;
    font-weight: 500;
    width: 100%;
    display: inline-block;
  }

  .resendLinkContainer {
    padding-top: 10px;
  }

}

.virtualTerminalForm {

  .formTitle {
    margin-top: 30px;
  }
  .categorySelect {
    vertical-align: bottom;
  }

  .selectContainer {

    position: relative;

    .bubble {
      position: absolute;
      bottom: 31px;
      left: 18px;
    }

    .textField {
      width: 100%;
    }

  }

  .shrinkSpacing {
    label {
      letter-spacing: -1.5px;
    }
  }

  .cardNumber {
    label::after {
      content: 'Credit Card Number'; // To prevent browser credit card saving
    }

    &.spanishTranslation {
      label::after {
        content: 'Número de Tarjeta de Crédito'; // To prevent browser credit card saving
      }
    }
  }

  .cardNumber.debitCard {
    label::after {
      content: 'Debit Card Number'; // To prevent browser credit card saving
    }

    &.spanishTranslation {
      label::after {
        content: 'Número de Tarjeta de Débito'; // To prevent browser credit card saving
      }
    }
  }

  .invalidRewardCodeIndicator {
    color: $errorColor;
    padding-left: 10px;
    margin: 0;
  }

  .loyaltySectionTitle {
    font-size: 13px !important;
    color: #404143;
    font-weight: bold !important;
    margin: 0;
    padding-left: 21px;
  }

  .flex {
    display: flex;
    align-items: center;

    .invoiceNumber {
      align-self: baseline;
    }

    .rewardFieldFetching {

      span {
        width: 25px !important;
        height: unset !important;
        padding: 0 22px !important;
      }
    }

    .existingLoyaltyReward {
      background: #f2f2f4;
      display: flex;
      width: 100%;
      border-radius: 5px;
      margin: 0 6px 0 6px;
      height: 55px;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 8px;

      .customToggle {
        margin-left: auto;
        margin-right: 20px;
      }

      p {
        margin-left: 20px;
        font-size: 14px;
      }
    }
  }

  .toolbar {
    .searchBar {
      width: 100%;
    }
  }
}

.summaryContainer {
  border-bottom: 1px solid $lineColor;

  @media screen and (max-width: $sizeMobileMax) {
    border-bottom: none;
  }
}

.paymentLinksHeader {
  display: flex;
  height: 50px;
  margin: 25px 40px;
  justify-content: space-between;

  @media screen and (max-width: $sizeMobileMax) {
    margin: 25px 5px;
  }

  .activeLinks {
    .leftBar {
      background-color: #12BE6F;
    }
  }

  .inactiveLinks {
    .leftBar {
      background-color: #FC0D1B;
    }
  }

  .totalUses {
    .leftBar {
      background-color: #009CFD;
    }
  }

  .totalVolume {
    .leftBar {
      background-color: #FC0DF3;
    }
  }

  .headerItem {
    display: flex;
    align-items: center;
    column-gap: 15px;

    .leftBar {
      min-width: 5px;
      height: 40px;
      border-radius: 3px;
    }

    .container {
      display: flex;
      flex-direction: column;

      h1 {
        margin-bottom: 0;
        margin-top: 0;
        font-size: 16px;
      }
    }
  }
}

.paymentLinksContainer {

  .paymentLinks {

    .paymentLinkTableRow {
      display: flex;
      column-gap: 20px;
      align-items: center;

      svg {
        padding: 10px;
        border-radius: 25px;
      }
    }

    .activeIcon {
      svg {
        color: $backgroundColor;
        background-color: #000;
      }
    }

    .inactiveIcon {
      svg {
        color: $errorColor;
        background-color: $backgroundColor;
      }
    }
  }

  .firstPaymentLinks {
    display: flex;
    height: 100vh;

    .contentWrapper {
      @extend %bodyText;
      margin: 0 auto;

      h2 {
        @extend %heading;
        margin: 51px auto 41px;
        text-align: center;
      }

      h3 {
        @extend %subheading;
        margin: 0;
      }

      .card {
        background-color: #efefef;
        border-radius: 10px;
        display: flex;
        margin: 0 auto 13px;
        max-width: 75%;
        padding: 40px 30px;

        .imageWrapper {
          margin-right: 35px;
        }

        .image {
          width: 133px;
        }

        .textWrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

      }
    }

    @media screen and (max-width: $sizeTabletMax) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 528px) {
    .mobileScrollNote {
      display: flex;
    }
    .masterTableContainer {
      overflow-x: auto;
    }
  }

  @media screen and (max-width: $sizeMobileMax) {
    .masterListContainer table {
      td.cellData {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 160px;
        min-width: 160px;
        max-width: 160px;

      }
    }
  }
}

.transactionCount {
  margin-top: 30px;
}

.customerDetail {

  .ratingContainer, .recurringContainer {
    margin-top: 25px;
  }

}

.businessInvoices {

  .infiniteList {
    .noDataMessage {
      box-sizing: border-box;
      border-bottom: 1px solid $lineColor;
      padding: 110px;
      width: 100%;
      height: auto;
    }
  }

  .masterListContainer {
    > div:nth-child(4) > div {
      @media screen and (max-width: $sizeMobileMax) {
        min-width: 595px !important;
      }
    }
  }

  @media screen and (max-width: 1441px) {
    .mobileScrollNote {
      display: flex !important;
    }
    .masterTableContainer {
      overflow-x: auto !important;
    }
  }

}

.amountSelectionContainer {
  padding: 30px;

  .selectorContainer {
    padding-top: 10px;

    .creditAmount {
      padding-left: 10px;
    }

    .cashAmount {
      padding-left: 20px;
    }
  }

  .titleText {
    @extend %bodyText;
  }
}

.markPaidDialog {
  .markPaidDialogHeader {
    padding: 40px 0px 0px 54px !important;

    .title {
      @extend %heading;
    }

    .subheading {
      @extend %bodyText;
    }
  }
}

.invoiceActions {
  padding: 20px 0 0 36px;
}

.recurringDetail {
  padding-left: 0px !important;

  .recurringActions {
    position: relative;

    .linkButton {
      min-width: 212px !important;
      height: 35px !important;
      text-align: left !important;
    }
  }

  .saveRecurringEdit {
    min-width: auto !important;
    left: -7px;
  }

  .originalValue {
    position: relative;
    display: inline-block;
  }

  .tooltip.tooltipLeft:after {
    font-size: 12px;
    transform: translateX(-71%) translateY(0);
    top: 19px;
  }
}

.invoiceDetail {

  padding-bottom: 100px;
  padding-left: 0px !important;
  padding-top: 0px !important;


  .fieldLabel, .datePickerRecurring input {
    @extend %bodyText;
  }

  .detailsRow.recurrence {
    height: 60px !important;
  }

  .recurringDateRow {

    .flexContainer:first-child {
      height: 44px !important;

      .durationType > div > div > div {
        @extend %bodyText;
      }

      div + div + div {
        top: 5px;
        left: -5px;
      }
    }

    .flexContainer {

      .repeat {
        > div {
          width: 500%;
          top: 5px;
          left: -5px;
        }
      }

      .div {
        padding: 0;
        margin-top: 0;
      }

      > div {
        @extend %bodyText,;
      }

      .textFieldHolder {
        padding: 0 10px 0 15px;
        margin-right: 15px;
      }

      .textField {
        margin: 0;
      }

    }

    .occurrenceLabel {
      align-items: center;
      padding-top: 2%;

      .occurrences {
        width: 150px !important;
      }
    }

    .datePickFlex {
      display: flex;
      align-items: center;

      .recurringDatePicker:first-child {
        width: 100px !important;
      }

      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }

  }

  .flexContainer {
    flex-direction: row;

    & > * {
      margin: 6px;
      flex-basis: 0;
      flex-grow: 1;
    }

    .amount {
      flex-grow: 0;
    }

    .accent {
      flex-grow: 0;
      margin-right: 8px;

      svg {
        @extend %smallIcon;
      }
    }

    .content {
      flex-grow: 2;
    }

    .stretchMid {
      flex-grow: 3;
    }

    .dueDateSelect {
      flex-grow: 1;
      display: block !important;
      top: -20px;
    }
  }

  .secondaryText {
    @extend %contentLabel;
  }

  .invoiceDetails {
    padding-top: 20px;

    .detailsHeaderInputField {
      div:first-of-type {
        @extend %heading1;
      }
    }

    .invoiceSubtitle {
      margin: 0 0 5px 0;
    }
  }

  .transactionDetailItems {
    @extend %body;

    .lineItemDetails {
      padding-bottom: 15px;
      width: 100% !important;

      .transactionDetailText {
        width: 390px;
      }
    }
  }

  .customerDetails {

    padding-top: 20px;

    .addCustomer {
      position: absolute !important;
      right: 0;
      top: -60px;

      svg {
        color: $iconColor !important;
      }
    }

    & > div {
      margin-bottom: 25px;
      position: relative;
    }

    .dueDateSelect {
      div div svg {
        position: absolute !important;
        top: 0;
      }
    }

    button {
      bottom: 70px !important;
    }
  }

  .invoiceDetails, .customerDetails {
    & > div {
      margin-bottom: 25px;

    }
  }

  .customerDetails {
    .customerFirst {
      display: block !important;
    }
  }

  .recurringDetails {

    padding-bottom: 40px;

    .recurringEndHeader {
      margin: 3em 0 0;
    }

    .recurringEndDate {

      .recurringDateRow {

        .flexContainer {
          display: inline !important;
        }
      }

    }

    button {
      fill: $payAnywhereColor !important;
    }
  }

  [name='selectedSendDateValue'], [name='selectedDueDateValue'], .detailDescriptionText, [name='recurring_type'] {
    @extend %bodyText;
  }
}

.addCommentDialog {
  .dialogHeader {
    padding: 0 !important;
  }

  .detailClose {
    margin-left: 20px !important;
  }

  .appBar {
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    font-size: 10px !important;

    div:last-child {
      text-align: center;
      color: #000;
    }

    strong {
      margin-left: -64px;
      font-size: 14px !important;
      color: #000;
    }
  }
}

.rebutDisputeDialog {
  .dialogHeader {
    padding: 0 !important;
  }

  .detailClose {
    margin-left: 20px !important;
  }

  .appBar {
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    font-size: 10px !important;

    div:last-child {
      text-align: center;
      color: #000;
    }

    strong {
      font-size: 14px !important;
      color: #000;
    }
  }
}

.responseSubmittedDialog {
  .dialogHeader {
    padding: 0 !important;
  }

  .detailClose {
    margin-left: 20px !important;
  }

  .appBar {
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    font-size: 10px !important;

    div:last-child {
      text-align: center;
      color: #000;
    }

    strong {
      font-size: 14px !important;
      color: #000;
    }
  }
}

.otherOptionsDialog {
  .dialogHeader {
    padding: 0 !important;
  }

  .detailClose {
    margin-left: 20px !important;
  }

  .appBar {
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    font-size: 10px !important;

    div:last-child {
      text-align: center;
      color: #000;
    }

    strong {
      font-size: 14px !important;
      color: #000;
    }
  }
}

.disclaimerDialog {
  .dialogHeader {
    padding: 0 !important;
  }

  .detailClose {
    margin-left: 20px !important;
  }

  .appBar {
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    font-size: 10px !important;

    div:last-child {
      text-align: center;
      color: #000;
    }

    strong {
      font-size: 14px !important;
      color: #000;
    }
  }
}

.addFilesDialog {

  &Body {
    @extend %bodyText;
  }

   > div {
     > div {
       > div {
          border-radius: 15px !important;
        }
      }
    }

  .actions {
    .raisedButton {
      button {
        div {
          div {
            border-radius: 2px !important;
          }
        }
      }
    }
  }

  .dialogHeader {
    @extend %subheading;
    padding: 0 !important;
  }

  .detailClose {
    margin-left: 20px !important;
  }

  .appBar {
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    font-size: 10px !important;

    div:last-child {
      text-align: center;
      color: #000;
    }

    strong {
      margin-left: -64px;
      font-size: 14px !important;
      color: #000;
    }
  }
}

.disputeDetails {
  cursor: pointer;
  display: flex;

  &-column {
    width: 50%;
  }

  &-status {
    cursor: pointer;
    background-color: $selectedRow;
    margin-left: -20px;
    margin-right: -24px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    min-height: 35px;
    padding: 5px 15px 1px;
    display: flex;

    span, svg {
      align-self: center;
    }

    &-column {
      width: 50%;
      display: flex;
    }
  }

  &-border {
    margin-top: 15px;
    margin-right: -24px;
    background: $selectedRow;
    height: 4px;
    border: 1px solid #CCCCCF;
    border-radius: 1px;
  }
}

.filterIcon {
  fill: $iconColor;
}

.reviewBorder {
  border-right: 10px $disputePositive solid;
}

.actionBorder {
  border-right: 10px $disputeError solid;
}

.closedBorder {
  border-right: 10px $iconColor solid;
}

.dueContainer {
  padding-left: 40px;

  .dueIcon {
    margin-left: -25px;
  }

  .dueTitle {
    font-size: 14px;
  }

  .dueDescription {
    font-size: 12px;
    color: $secondaryTextColor;
  }
}

.stepper {
  margin-left: 40px;

  .title {
    @extend %heading1;
  }

  .steps {
    display: inline-block;
    margin: 0;
    padding-left: 25px;
  }

  .step {
    display: inline-block;
    height: 8px;
    width: 25px;
    margin-right: 3px;
    background-color: $lineColor;

    &-filled {
      background-color: $iconColor;
    }

    &-action {
      background-color: $disputeError;
    }

    &-first {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &-last {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.amountDetails {
  margin-left: 40px;
  margin-top: 15px;
  font-size: 14px;
  position: relative;

  .amount {
    right: 30px;
    position: absolute;
  }
}

.disputeDetailDivider {
  padding-bottom: 5px;
  border-bottom: 1px solid $lineColor;
}

.disputeDetailsContainer {
  margin-left: 40px;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 14px;

  .title {
    font-size: 11px;
    color: $secondaryTextColor;
  }
}

.disputeDocumentsContainer {
  margin-left: 40px;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 14px;
  border-bottom: 1px solid $lineColor;

  .title {
    font-size: 11px;
    color: $secondaryTextColor;
  }

  .noticesDropdown {
    margin-bottom: 15px;
  }

  .commentHolder {
    border-bottom: 1px solid $lineColor;

    &:last-child {
      border: 0;
    }

    .commentTitle {
      padding-bottom: 7px;
      font-size: 11px;
      color: $secondaryTextColor;
    }

    .comment {
      padding: 0 20px 0 10px;
    }
  }
}

.actionsContainer {
  background: $selectedRow;
  border-top: 1px solid $lineColor;
  border-bottom: 1px solid $lineColor;
  padding-bottom: 25px;

  > div {
    margin-left: 40px;

    .title {
      font-size: 11px;
      color: $secondaryTextColor;
    }

    .subtitle {
      margin-bottom: 0;
    }

    .subtitleDescription {
      margin-top: 0;
      margin-left: 25px;
      font-size: 10px;
      color: $iconColor;
    }

    .noAction {
      pointer-events: none;
    }

    .actionButton {
      position: relative;
      background: white;
      width: 145px;
      padding: 3px;
      box-shadow: 0 0 5px 2px $lineColor;
      font-weight: bold;
      display: inline-block;
      cursor: pointer;

      .overlay {
        top: 0;
        right: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        display: inline-block;
        width: 100%;
        height: 100%;
      }

      .accept {
        color: #63C21E;
      }

      .rebut {
        color: #FC281C;
      }

      .center {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-left {
        margin-right: 10px;
      }

      &-right {
        margin-left: 10px;
      }
    }
  }
}

.acceptDisputeIcon, .rebutDisputeIcon {
  width: 54px;
  height: 54px;
}

.tapToPayIcon {
  width: 41px;
  height: 57px;
}

.loyaltyIcon {
  width: 60px;
  height: 46px;
}

.reputationManagementIcon {
  width: 53px;
  height: 50px;
}

.premiumPlusIcon {
  width: 122px;
  height: 89px;
}

.integrationsIcon {
  width: 122px;
  height: 89px;
}

.powerUserIcon {
  width: 100%;
}

.rebutDisputeIcon {
  fill: #FC281C;
}

.transactionDetailsContainer {
  margin-top: 35px;
  margin-left: 40px;
  padding-bottom: 10px;
  position: relative;

  .title {
    font-size: 11px;
    color: $secondaryTextColor;
  }

  .cardInfo {
    font-size: 14px;
  }

  .amount {
    right: 30px;
    position: absolute;
  }

  .invoiceInfo {
    font-size: 10px;
    color: $iconColor;
    margin-bottom: 30px;
  }
}

.disputeInfo {
  position: relative;
  padding-bottom: 15px;

  .tooltipHolder {
    left: 0;
    top: 0;
  }
}

.attachmentsContainer {
  background: white;
  box-shadow: 0 0 5px 2px $lineColor;
  margin-bottom: 30px;
  margin-top: 20px;
  width: 315px;

  .comment {
    margin-left: 40px;
    min-height: 40px;
    padding: 5px;
    border-bottom: 1px solid $lineColor;
  }

  .attachment {
    margin-left: 40px;
    min-height: 40px;
    padding: 5px;
    border-bottom: 1px solid $lineColor;
    cursor: pointer;
  }

  .newAttachment {
    margin-left: 40px;
    min-height: 40px;
    padding: 5px;
    border-bottom: 1px solid $lineColor;

    .removeAttachment {
      cursor: pointer;
    }
  }

  .icon {
    margin-left: -40px;

    svg {
      vertical-align: top;
    }
  }

  .text {
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 220px;
    display: inline-block;
    vertical-align: top;
  }

  .expand {
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: top;
  }

  .subText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 220px;
    display: inline-block;
    color: $secondaryTextColor;
    padding: 5px;
  }

  :last-child {
    border: none;
  }
}

.submitDispute {
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px !important;

  .buttonTooltip {
    position: absolute;
    left: 100px;
    top: 5px;
  }
}

.disputeCompellingEvidence {
  margin-bottom: 12px;
}

.bar {
  background-color: transparent !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-align: center;
  z-index: 0 !important;

  span {
    color: black;
    @extend %heading2;
  }

  .barButton {
    background-color: white !important;

    span {
      @extend %heading2;
      color: $payAnywhereColor !important;
    }
  }
}

.filterItem {
  border-bottom: 1px solid $lineColor;
  width: 515px;

  &.borderTop {
    border-top: 1px solid $lineColor;
    padding-top: 30px;
  }

  &-last {
    border: none;
  }

  .textContainer {
    display: inline-block;

    .subtitle {
      width: 385px;
      font-size: 12px;
      color: $secondaryTextColor;
      white-space: normal;
      line-height: 15px;
    }
  }

  .stepsContainer {
    display: inline-block;
    margin-left: 15px;

    .stepper {
      margin: 0;
      padding: 0;

      .steps {
        padding: 0;
      }
    }
  }
}

.addComments {
  .field {
    width: 80%;
    margin: 0 auto;

    .title {
      display: inline-block;
      width: 100%;
    }

    .commentIcon {
      display: inline-block;
      margin-left: -29px;
      padding-right: 5px;
    }
  }
}

.searchAccessory {

  .dateSelector {

    margin-right: 10px !important;

    .dateFilter {

      button {

        padding: 0px !important;

      }
    }

  }

}

.vtAvsNotice {
  @extend %bodyText;
  margin: 0 6px;
  padding-left: 15px;
}

.recurringInvoiceInfo {
  .recurringDetailHolder {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    .viewLink {
      display: flex;
      margin-left: auto;

      a {
        font-family: $secondaryFontFamily;
        color: $payAnywhereColor;
        text-decoration: none;
      }
    }

    .frequency {
      margin: 0;
    }

    .endDate {
      font-size: 13px;
      color: $iconColor;
      margin: 0;
    }
  }
}

.enrolled {
  margin-top: 30px;

  &Icon, &Data {
    display: inline-block;
  }

  &Icon {
    vertical-align: top;
  }

  &Data {
    list-style: none;
    margin: 0;
  }

  &Item {
    font-size: 17px;
    padding-bottom: 10px;

    &:last-child {
      padding-bottom: 30px;
    }
  }
}

.removeAutopayButton {
  min-width: 17em !important;

  > div {
    text-align: left;
  }
}

.ownersDetail {
  display: flex;
  border-bottom: 1px solid $lineColor;
  justify-content: space-between;
}

.upgradeMembershipContainer {
  width: 100%;
  text-align: center;
  padding-top: 2%;

  button {
    max-width: 250px;
  }

  .upgradeMembershipButton {
    width: 200px;
  }

  @media screen and (max-width: $sizeMobileMax) {
    font-size: 9px !important;
  }
}

.upgradeMembershipContainer {
  width: 100%;
  text-align: center;
  padding-top: 2%;

  button {
    max-width: 250px;
  }

  .upgradeMembershipButton {
    width: 200px;
  }

  @media screen and (max-width: $sizeMobileMax) {
    font-size: 9px !important;
  }
}

.upgradeMembershipContainer {
  width: 100%;
  text-align: center;
  padding-top: 2%;

  button {
    max-width: 250px;
  }

  .upgradeMembershipButton {
    width: 200px;
  }

  @media screen and (max-width: $sizeMobileMax) {
    font-size: 9px !important;
    .ownersContainer {
      display: flex;
      border-bottom: 1px solid $lineColor;
      justify-content: space-between;

      .ownerHeading {
        font-family: $primaryFontFamily;
        font-size: 16px;
      }

      .ownerSubheading {
        font-family: $secondaryFontFamily;
        color: $iconColor;
      }
    }
  }
}

.isMobileDialog {

  .isMobileContent {

    .mobileDialogClose {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }

    > div:first-of-type {
      border-radius: 15px !important;
    }

  }

  .dialogHeader {
    @extend %subheading;
  }

  .dialogBody {
    @extend %bodyText;
  }

}

.businessCustomers {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .customersWrapper {
    display: flex;
    overflow: hidden;
    height: 100%;

    .filter {
      overflow-y: visible;

      @media screen and (min-width: 1200px) {
        min-width: 300px;
      }
    }

    .overflow {
      overflow: auto;
    }

    .customersContent {
      width: 100%;
      height: 100%;

      @media screen and (max-width: $sizeMobileMax) {
        overflow-x: hidden;
      }

      #masterList.masterListContainer.masterListContainerPagination {
        height: calc( 100vh - 72px);

        @media screen and (max-width: $sizeTabletMax) {
          height: calc( 100vh - 72px - 155px);
        }

        .scrollableArea {
          height: calc(100% - 80px);
          overflow-y: scroll;
          overflow-x: hidden;
        }
      }

      table {
        table-layout: fixed !important;
      }

      .customerList {

        th {
          box-sizing: content-box;
        }

        th:nth-child(1) {
          width: 10vw;
        }

        th:nth-child(2) {
          width: 13vw;
        }

        th:nth-child(3) {
          width: 10vw;
        }

        th:nth-child(4) {
          width: 8vw;
        }

        th:nth-child(5) {
          width: 7vw;
        }

        th:nth-child(6) {
          width: 7vw;
        }

        th, td {
          @media screen and (max-width: $sizeMobileMax) {
            padding: 0 20px !important;
          }
        }

        .defaultCard, .activeReward {
          width: 120px;
        }

        .hideMobileOnly {
          @media screen and (max-width: $sizeMobileMaxPortrait) {
            display: none !important;
          }
        }

        .loyaltyIcon {
          padding-left: 10px;
        }
      }

      .loyaltyProgram {
        @media screen and (max-width: $sizeTabletMax) and (min-width: $sizeMobileMax) {
          display: flex;
          justify-content: center;
        }

        .loyaltyProgramContent {
          padding: 50px 150px 128px;
          @media screen and (max-width: $sizeMobileMax) {
            padding: 0 0 100px;
          }
          @media screen and (max-width: $sizeTabletMax) {
            padding: 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
          }
          h1 {
            font-size: 22px;
            font-weight: bold;
            line-height: 36px;
            letter-spacing: 0px;
            color: #000000DE;
            opacity: 1;
          }

          .container {
            padding: 35px;
            border: 1px solid $loyaltyBorderColor;
            border-radius: 11px;

            .viewReceipt {
              color: $paymentHubsColor;
              font-size: 12px;
              font-weight: bold;
              cursor: pointer;
            }

          }
        }

        .membershipUpgrade {
          padding-top: 55px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .loyaltyIcon {
            background-color: #efeff2;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            padding: 1.25%;

            svg {
              width: 90px;
              height: 90px;
              margin-top: 10px;
              margin-left: 10px;
            }
          }

          h1 {
            text-align: center !important;
            padding-top: 0.5%;
            width: 100%;
            @extend %heading;
            margin: 1em 0;
          }

          p {
            margin-top: 5px !important;
            max-width: 450px;
            text-align: center;
            @extend %bodyText;
            margin: 2em auto;
          }
        }
      }

      .defaultCard, .activeReward {
        width: 120px;
      }

      .loyaltyActivity {
        padding-left: 150px;
        padding-right: 150px;
        max-width: 900px;

        @media screen and (max-width: $sizeDesktopMin) {
          padding-top: 25px;
          padding-left: 25px;
          padding-right: 25px;
        }

        @media screen and (max-width: 580px) {
          padding-top: 15px;
          padding-left: 5px;
          padding-right: 5px;
        }

        .pageHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 25px;

          @media screen and (max-width: $sizeMobileMaxPortrait) {
            margin: 0 15px;
          }

          h1 {
            font-size: 22px;
            font-weight: bold;
            line-height: 36px;
            letter-spacing: 0px;
            color: #000000DE;
            opacity: 1;
          }
        }

        .noDataMessage {
          width: 100%;
        }

        .cardsContainer {
          display: flex;
          flex-wrap: wrap;
          column-gap: 10px;
          row-gap: 10px;
          justify-content: center;
        }

        .tablesContainer {
          margin-top: 50px;

          .reportToggleContainer {
            display: flex;
            justify-content: center;

            .reportToggle {
              display: flex;
              background-color: $loyaltyBackgroundColor;
              border-radius: 29px;

              h2 {
                margin: 0;
                font-size: 14px;
                color: $black;
              }

              .topCustomers, .rewardPerformance {
                background-color: $loyaltyBackgroundColor;
                padding: 15px;
                margin: 5px;
                border-radius: 29px;
                cursor: pointer;
              }

              .activeChild {
                background-color: $backgroundColor;
              }
            }
          }

          .tableContainer {
            overflow-x: auto;
          }

          h2 {
            text-align: center;
            font-size: 16px;
          }

          .ReactVirtualized__Table__headerRow, .ReactVirtualized__Table__headerColumn {
            background-color: $backgroundColor !important;
          }

          .ReactVirtualized__Table__rowColumn {
            font-size: 12px !important;

            .rewardName {
              display: flex;
              align-items: center;
              column-gap: 15px;
            }
          }

          @media screen and (max-width: 580px) {
            .ReactVirtualized__Table__headerRow, .ReactVirtualized__Table__row, .ReactVirtualized__Table__Grid, .ReactVirtualized__Grid__innerScrollContainer{
              width: 570px !important;
              max-width: unset !important;
            }

            .mobileScrollNote {
              display: flex;
            }
          }
        }
      }

      .loyaltyActivityCard {
        min-width: 255px;
        max-width: 255px;
        height: 140px;
        background-color: #1D1D1D;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-basis: 33.33%;

        .cardHeader {
          font-size: 10px;
          font-weight: bold;
          color: $backgroundColor;
        }

        .text {
          font-size: 22px;
          font-weight: bold;
          color: $backgroundColor;
          padding: 20px 0;
        }

        .footer {
          font-size: 12px;
          color: $backgroundColor;
          opacity: 0.49;
        }

        .repeatCustomersBody {
          display: flex;
          column-gap: 10px;

          p {
            font-size: 22px;
            font-weight: bold;
            color: $backgroundColor;
            margin: 0;
          }

          .downward {
            transform: rotate(180deg) scaleX(-1);
          }
        }
      }

      .loyaltyProgramSettings {
        padding-left: 150px;
        padding-right: 150px;
        padding-top: 50px;
        max-width: 650px;

        @media screen and (max-width: $sizeDesktopMin) {
          padding-top: 25px;
          padding-left: 25px;
          padding-right: 25px;
        }

        h1 {
          font-size: 22px;
          font-weight: bold;
          line-height: 36px;
          letter-spacing: 0px;
          color: #000000DE;
          opacity: 1;
        }

        .loyaltyPreviewContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            font-size: 14px;
          }

          span {
            color: $paymentHubsColor;
            cursor: pointer;
            font-size: 14px;
          }
        }

        hr {
          color: $black;
          opacity: 0.4;
          margin: 25px 0;
        }

        .tollFreeNumber {
          display: flex;
          column-gap: 20px;

          .tollFreeNumberTitle {
            margin-top: 0;
            font-size: 14px;
          }

          a {
            text-decoration: none;
            color: $paymentHubsColor;
          }

          span {
            font-size: 10px;
          }

          .tollFreeNumberContent {
            height: 50px;
            border-radius: 25px;
            background-color: #f0f0f1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 25px;
            padding: 0 25px;

            p {
              font-size: 10px;
            }

            span {
              font-size: 14px;
            }
          }

          .pendingVerification, .rejectedVerification {
            margin-top: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f8f4f4;
            height: 80px;
            padding: 15px;
            column-gap: 20px;

            span {
              font-weight: bold;
              font-size: 14px;
            }

            p {
              margin-top: 5px;
              margin-bottom: 0;
              font-size: 10px;
            }
          }

          .loyaltySettingsWebsite {
            margin-top: 25px;

            .textField {
              margin-bottom: 7px;
            }

            .businessSettingsRoute {
              cursor: pointer;
              color: $paymentHubsColor;
            }

            .submitFormButton {
              width: 220px;
              display: flex;
              margin-top: 20px;
              border: 2px solid $paymentHubsColor !important;
              background-color: transparent;
              box-shadow: unset !important;
              span {
                font-size: 12px;
                color: $paymentHubsColor;
              }
            }
          }
        }
      }
    }

    .loyaltyProgram {
      h1 {
        font-size: 22px;
        font-weight: bold;
        line-height: 36px;
        letter-spacing: 0px;
        color: #000000DE;
        opacity: 1;
        @media screen and (max-width: $sizeMobileMax) {
          margin-left: 25px;
        }
      }

      .loyaltyFormContainer{
        @media screen and (max-width: $sizeMobileMax) {
          width: 90%;
          margin: 0 auto 40px;
        }
        @media screen and (max-width: $sizeTabletMax) {
          padding-bottom: 100px;
        }

        & {
          min-height: 161px;
          background: #FFFFFF 0% 0% no-repeat padding-box;
          border: 1px solid #E1E1E1;
          border-radius: 11px;
          opacity: 1;
          padding: 11px 28px 28px;
          box-sizing: border-box;
        }

        .viewSample, .viewPreview{
          text-decoration: none;
          color: #00b4aa !important;
          font-size: 12px;
          display: inline-block;
          cursor: pointer;
        }
        .viewPreview {
          padding-right: 15px;
        }
        .viewSample{
          margin-bottom: 20px;
        }
        .mainNames {
          display: flex;
          column-gap: 10px;

          @media screen and (max-width: $sizeMobileMax) {
            flex-direction: column;
          }

          .programName {
            flex: 1.5;
          }

          .pointsName {
            flex: 1;
          }

          .MuiInputAdornment-positionEnd {
            margin-left: 5px;
          }

          .fieldMessageFooter {
            padding-left: 15px;
            font-size: 10px;
          }

        }
        .settingsOption{
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          padding: 30px 0;
          p{
            text-align: left;
            letter-spacing: 0px;
            color: #000000DE;
            font-weight: bold;
          }

          .fieldText {
            margin-bottom: 18px;
            margin-right: 10px;
          }

          .textFieldInfo{
            display: none;
          }
          .twoOptions {
            display: flex;
            width: 203px;
            justify-content: space-between;
            .textField {
              margin-right: 10px;
            }
            .textFieldInfo{
              display: none !important;
            }
            .typeAmount .textFieldHolder{
              padding: 7px !important;
              margin-top: 1px !important;
              @media screen and (max-width: $sizeMobileMax) {
                padding: 9px !important;
              }
            }
          }
          .shouldExpire{
            &.datePicker{
              width: 150px;
            }
          }
          &.columnStyle{
            display: flex;
            flex-direction: column;
            align-items: baseline;
          }
          .customMessage{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-left: 15px;

            p {
              font-size: 13px;
              font-family: $primaryFontFamily !important;
              font-weight: 600;
              color: $primaryTextColor;
            }
          }
          .customMessageFooter {
            padding-left: 15px;
            font-size: 10px;
          }
        }

        .generalPointRule {

          p {
            padding-left: 15px;
            font-size: 13px;
            font-family: $primaryFontFamily !important;
            font-weight: 600;
            color: $primaryTextColor;
          }

          .content {
            height: 100px;
            background: $backgroundColor4;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              padding-right: 15px;
              cursor: pointer;
              color: $paymentHubsColor;
            }

            .generalPointRuleInfo {
              display: flex;
              flex-direction: column;
              justify-content: center;

              p {
                font-size: 12px;
                margin: 0;

                &:first-child{
                  color: $black;
                  margin-bottom: 5px;
                  font-family: $primaryFontFamily !important;
                  font-weight: 600;
                }

                &:last-child{
                  font-family: $primaryFontFamily !important;
                  color: $iconColor;
                }
              }
            }
          }

          .contentZeroState {
            p {
              color: $black;
              opacity: 0.4;
            }
          }
        }

        .rewards {
          margin-top: 50px;

          p {
            padding-left: 15px;
            font-size: 13px;
            font-family: $primaryFontFamily !important;
            font-weight: 600;
            color: $primaryTextColor;
          }

          .content {
            height: 100px;
            border: 1px solid $loyaltyBorderColor2;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;

            span {
              padding-right: 15px;
              cursor: pointer;
              color: $paymentHubsColor;
            }

            .rewardsInfo {
              display: flex;
              align-items: center;

              div {
                p {
                  font-size: 12px;
                  margin: 0;

                  &:first-child{
                    color: $black;
                    margin-bottom: 5px;
                    font-family: $primaryFontFamily !important;
                    font-weight: 600;
                  }

                  &:last-child{
                    color: $iconColor;
                    font-family: $primaryFontFamily !important;
                  }
                }
              }
            }
          }

          .contentZeroState {
            p {
              color: $black;
              opacity: 0.4;
            }
          }
        }

        .createRewardButton {
          margin-top: 20px;
          height: 50px;
          width: 145px;
          border: 2px solid $paymentHubsColor;
          border-radius: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 10px;
          color: $paymentHubsColor;
          font-weight: bold;
          cursor: pointer;

          svg {
            width: 14px;
            height: 14px;

            path {
              fill: $paymentHubsColor;
            }
          }
        }

        .inputWidth{
          width: 90px;
        }
      }

      .disabledContent {
        width: 618px;

        @media screen and (max-width: $sizeTabletMax) {
          padding-bottom: 150px;
          p {
            font-size: 12px !important;
            width: 70vw;
          }
        }

        p {
          font-size: 18px;
          font-weight: bold;
        }

        @media screen and (min-width: $sizeMobileMax) {
          margin-top: 50px;
          img {
            margin: 35px !important;
            width: unset !important;
          }
        }

        img {
          margin: 15px;
          width: 100px;
        }

        hr {
          border: 1px solid $loyaltyBorderColor;
        }

        .contentLine {
          display: flex;
          align-items: center;
          margin: 40px;

          @media screen and (max-width: $sizeMobileMax) {
            margin: 0;
          }
        }
      }

      .loyaltyFormBottomBar {

        .previewLoyaltyProgram {
          padding-right: 40px;
          cursor: pointer;
          color: $paymentHubsColor;
        }
      }

      .loyaltyToggleContainer{
        display: flex;
        align-items: center;
        padding-bottom: 37px;
        h2{
          text-align: left;
          font-size: 12px;
          line-height: 15px;
          font-weight: bold;
          letter-spacing: 0px;
          color: #000000DE;
          opacity: 1;
        }
        p {
          text-align: left;
          font-size: 10px;
          line-height: 14px;
          font-weight: 500;
          letter-spacing: -0.12px;
          color: #000000DE;
          opacity: 1;
          margin-bottom: 0px;
        }
        a {
          text-decoration: none;
          color: $paymentHubsColor;
        }
        .betaDisclaimerContent p{
          color: white;
        }

        .toggleContainer, .activateAddOnButton {
          margin: 10px 0;
          align-self: baseline;
        }
        .appSpacer{
          margin: 40px 0;
          border-top: 1px solid #e1e1e1;
        }
        .loyaltyTermsLabel  > label > span {
          font-size: 10px;
        }

        .activateAddOnButton {
          width: 270px;
          margin-left: 25px;
          span {
            font-size: 12px;
          }
        }
      }
      .betaLabel{
        border: 1px solid $paymentHubsColor;
        padding: 3px;
        border-radius: 20px;
        font-size: 10px;
        color: $paymentHubsColor;
        display: inline-block;
        line-height: 7px;
        vertical-align: text-bottom;
      }
    }
  }
}

.exportCustomersHeader {
  text-transform: uppercase;
}

.twoFactorDialog{
  &.buttonContainer{
    display: flex;
    justify-content: center;
  }
}

.noWrap {
  white-space: nowrap;
}

.createInvoiceModal {
  margin-top: 100px;

  .title {
    padding-left: 10%;
    margin-left: 0 !important;

    @media screen and (max-width: $sizeMobileMax) {
      margin-left: 20px !important;
      margin-bottom: 10px;
    }
  }

  .content {
    padding: 12px 10% !important;

    label {
      @extend %contentLabel;
    }

    .summarySection {

      min-width: 20em;
      box-sizing: border-box;
      padding-left: 40px;

      @media screen and (max-width: $sizeMobileMax) {
        padding-left: 0px;
      }

      .summaryTitle {
        @extend %contentLabel;
        padding: 0px 15px;
        padding-bottom: 10px;
      }

      .amounts, .subtotals {

        @extend %bodyText;
        text-align: left;

        .amountValue, .subtotalValue {
          float: right;
        }

      }

      .amounts {
        background: $lightGray;
        padding: 20px 15px;
        margin: 0 28px 20px 0;
        border-radius: 4px;
        color: $gray !important;
      }

      .subtotals {
        padding: 2px 43px 2px 15px;
      }

      .total {
        font-family: $primaryFontFamily !important;
        font-weight: 600;
      }

    }
  }
}

.quickRepliesCategory {
  #sharedSelect div {
    display: flex;
    align-items: center;
    height: 40px;
  }

  h4 {
    margin-left: 10px;
  }
}

.createInvoiceModal.modal {

  .wrap-margin {
    @media screen and (max-width: $sizeMobileMax) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .close-icon {
    top: 48px;
    left: 50px;
    right: auto;
  }

}

.betaDisclaimer {

  .betaWebContainer, .betaMobileContainer {

    h2 {
      font-size: 12px;
      line-height: 15px;
      font-weight: bold;
      letter-spacing: 0px;
      color: #1F1F1F;
      opacity: 1;
    }

    p {
      font-size: 10px;
    }

    a {
      color: #1FC4B3;
    }
  }

  .betaWebContainer {
    max-width: 190px;
    margin-bottom: 10px;

    .bottomMargin {
      margin-bottom: 135px;
    }
  }

  .mobileVariantBetaDisclaimer {
    width: 100%;
    position: absolute;
    bottom: 0;
    min-height: 62px;
    left: 0;
    right: 0;
    background-color: white;
    z-index: $zIndexTopEverything;
    box-shadow: 1px -4px 20px -5px rgba(0,0,0,0.75);
    display: flex;
    padding-top: 10px;

    .betaMobileContainer {
      display: flex;
      align-items: flex-start;

      .betaIcon {

        svg {
          width: 50px !important;
          height: auto;
        }
      }

      h2 {
        margin-top: 0;
      }
    }
  }

  button {
    position: absolute;
    bottom: 11px;
    right: 2%;
    border-radius: 30px !important;
    width: 48px !important;
    padding: 0 !important;
    margin: 0 !important;
    min-width: unset;

    .roundBetaButton {

      svg {
        position: absolute;
        width: 24px !important;
        height: 24px !important;
        stroke: #ffffff;
        stroke-width: 2px;
        vertical-align: middle;
      }
    }
  }
}

.betaDisclaimerContainer {

  display: inline-block;
  position: relative;

  .betaWebContainer, .betaMobileContainer {
    h2 {
      color: $white !important;
    }
  }

  .enableHover {
    display: inline-block;
  }

  .betaBtn {
    width: 50px;
    height: auto !important; //All Button components have !important in its height as default
  }

  .betaDisclaimerContent {
    position: absolute;
    width: 190px;
    z-index: 99999;
    background: #212121;
    border-radius: 17px;
    padding: 20px;
    color: white;
    bottom: 25px;
    left: 80px;

    &:hover {
      visibility: visible;
    }
  }
}

.generalPointRuleModal, .rewardModal  {

  .contentHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 380px;

    h1 {
      font-size: 22px;
      margin: 0;
    }

    p {
      font-size: 12px;
      padding: 0 20px;
    }
  }

  .modalContent {
    padding-top: 15px;

    .fieldText {
      font-size: 10px;
      padding-left: 15px;
      font-family: $primaryFontFamily !important;
      font-weight: 600;
      color: $primaryTextColor;
    }
  }
}

.rewardModal {

  .modalContent {

    .twoOptionsTitle {
      display: flex;
      column-gap: 15px;

      p {
        width: 50%;

        &:last-child{
          padding-left: 15px;
        }
      }
    }

    .twoOptions {
      display: flex;
      column-gap: 15px;

      .rewardAmountType {

        .textFieldInfo {
          display: none;
        }
      }
    }

    .rewardExpiration {

      .textFieldInfo {
        display: none;
      }

    }
  }
}

.previewsModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  .previewsBar {
    background: rgba(235, 235, 235, .14);
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    column-gap: 25px;

    @media screen and (max-width: $sizeMobileMax) {
      column-gap: 5px;
    }

    .previewsBarElement {
      height: 45px;
      padding: 0 30px;
      border-radius: 22px;
      font-size: 12px;
      color: $backgroundColor;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .activeElement {
      background-color: $black;
    }

    .closeButton {
      padding: 0 !important;
      width: 45px;
      background-color: $black;

      svg {

        path {
          fill: $backgroundColor;
        }
      }
    }
  }

  .receipt {
    max-height: 70vh;
    background-color: $backgroundColor;
    max-width: 350px;
    overflow-y: auto;
    margin: 5vh 0;
    padding: 40px 50px;
    pointer-events: all;

    @media screen and (max-width: $sizeMobileMax) {
      padding: 0;
    }

    img {
      max-width: 350px;
      width: unset;
      height: unset;
    }
  }
}
