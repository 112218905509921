.activityReports {
  .allAccountsContainer {
    font-family: $primaryFontFamily !important;
    font-weight: 500;

    .allAccountsCheckbox {
      label span {
        font-size: 13px;
      }
    }

    span:not([class*='-checked']) {

      >span>svg {
        color: #404143 !important;
      }

    }
  }

  .searchBar {
    .customFilterDropdown {
      padding-top: 6px;

      button {
        padding-top: 8px !important;
        padding-left: 14px !important;
      }
    }
  }

  .reportView {
    border-top: solid 1px $lineColor;
  }

  .reportRow {
    @media screen and (max-width: $sizeTabletMin) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    & {
      display: flex;
      background-color: white;
      width: 100%;
      border-bottom: solid 1px $lineColor;
      color: $primaryTextColor;
      min-height: 105px !important;
      align-items: center;
    }

    .reportDetails {
      box-sizing: border-box;
      padding: 15px 30px 15px 52px;
      position: relative;
      text-align: left;
      width: 85%;

      .reportName,
      .reportSummary {
        @extend %bodyText;
      }

      .reportName {
        font-family: $primaryFontFamily !important;
        font-weight: 600 !important;
      }

      .reportSummary {
        font-family: $primaryFontFamily !important;
        font-weight: 500 !important;
      }

      .chevron {
        align-items: center;
        display: none;
        height: 100%;
        position: absolute;
        right: 20px;
        top: 0;

        svg {
          fill: $primaryTextColor;
        }
      }
    }

    .scheduledIndicator {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 20px;
      width: 200px;

      @media screen and (max-width: $sizeTabletMin) {
        width: unset;
      }

      .frequency {
        @media screen and (max-width: $sizeTabletMin) {
          display: none;
        }
      }
    }

    .reportActions {
      height: 100%;
      font-family: $primaryFontFamily !important;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 15px;
      column-gap: 10px;

      @media screen and (max-width: $sizeTabletMin) {
        justify-content: center !important;
        padding-right: 0 !important;
        padding-bottom: 10px;
        width: 100%;
      }

      .exportButtonContainer {
        @extend %bodyText;
        color: $payAnywhereColor !important;
        display: none;

        @media screen and (max-width: $sizeTabletMin) {
          .raisedButton {
            margin-right: 0 !important;
          }

          .flatButton {
            padding: 0 24px !important;
          }
        }

        svg {
          transform: none;
          position: relative;
          top: 2px;
          fill: $payAnywhereColor !important;
        }
      }

      .extendedButton {
        width: 146px;
      }
    }
  }

  .reportRow:hover:not(.reportRowDataTime) {
    background-color: $selectedRow;
  }

  .reportRow:hover {
    .reportDetails .chevron {
      display: flex;
    }

    .reportActions .exportButtonContainer {
      display: block;
    }

    .scheduledIndicator {
      display: none;
    }
  }

  .reportDetail {
    .reportDetailHeader {
      display: flex;
      font-family: $primaryFontFamily;
      font-weight: 500;
      padding: 0 0 0 48px;

      .goBack {
        cursor: pointer;
        padding: 12px 14px 0 0;
        z-index: 100;

        svg {
          fill: $primaryTextColor;
        }
      }

      .reportDetailInfo {
        .reportDetailTitle {
          @extend %heading;
          margin: 0;
        }

        .reportDetailDates {
          @extend %contentLabel;
          margin: 2px 0 0 0;
        }
      }

      .reportDetailBody {
        font-family: $primaryFontFamily;
        font-weight: 500;
      }
    }
  }

  //.reportDetail
}

//.activityReports

.reportNavItem {
  color: $primaryTextColor;
  margin-left: 20px;
  height: 75px;
  padding: 10px 0 0 10px;
  border-radius: 10px 0 0 10px;

  .reportNavIcon {
    vertical-align: top;
    width: 10% !important;
    height: 100%;
    display: inline-block;
    text-align: center;

    .categoriesIcon,
    .itemsIcon,
    .cashDrawIcon,
    .pdfIcon {
      width: 24px;
      height: 24px;
    }
  }

  .reportNavText {
    width: 90%;
    height: 100%;
    display: inline-block;
    border-bottom: solid 1px $lineColor;
  }

  .reportNavHeader {
    font-size: 17px;
    color: $primaryTextColor;
    margin-bottom: 10px;
  }

  .reportNavDescription {
    color: $secondaryTextColor;
  }
}

.reportNavItem.active,
.reportNavItem:hover {
  background-color: $subNavBackgroundColor;
}

.reportNavText.active {
  border-bottom: none;
}

.reportNavigation {
  width: 33%;
  border-right: solid 1px #dddddd;
  padding-top: 25px;
  display: inline-block;
}

.reportDetail {
  width: 100%;
  box-sizing: border-box;
  padding: 25px 0 63px;
  display: inline-block;
  vertical-align: top;
  overflow: auto;
}

.activityToolbarButtonsReports {
  @media screen and (max-width: $sizeMobileMax) {
    width: 30% !important;
  }
}

.openBatchButton {
  cursor: pointer;
  display: flex;
  align-items: center;

  .batchIcon {
    width: 48px;
    box-sizing: border-box;
    padding: 12px;

    @media screen and (max-width: $sizeTabletMax) {
      padding: 5px;
    }

    @media screen and (max-width: $sizeMobileMax) {
      padding: 5px;
    }
  }
}

.activityToolbarButtons {
  margin-right: 8px;
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  height: 100%;
  flex-direction: row;

  .dateButton,
  .employeeButton,
  .compareButton,
  .exportButton,
  .filterDropdown,
  .openBatchButton {
    display: inline-block;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
  }

  .compareButton {
    display: flex;
    width: auto;
    height: 60px;

    span {
      @extend %contentLabel;

      .btn-openMenu {
        background-color: transparent;
        border: none;
      }
    }
  }

  .filterDropdown {
    margin: 4px !important;
    display: none;

    @media screen and (max-width: $sizeMobileMax) {
      display: block;
    }

    svg {
      fill: $iconColor !important;
    }
  }

  .filterDropdownReports {
    width: 25px;
    padding-top: 3px;
  }

  .dateButton {
    width: auto;
    display: inline-flex;
    flex-direction: row;

    p {
      text-transform: uppercase;
      color: $secondaryTextColor;
    }
  }
}

.filterDateRange {
  @extend %columnLabel;
}

.activityToolbar {
  width: 100%;
  border-bottom: solid 1px $lineColor;

  .compareButton svg {
    fill: $iconColor !important;
  }

  .divider {
    border: 1px solid $lineColor;
  }
}

.activitySales {
  clear: both;
  margin: 0 auto;
  overflow: hidden;
  position: relative;

  .salesViews {
    background-color: $subNavBackgroundColor;
    padding: 50px 20px 40px 20px;
  }

  .salesWrapper {
    display: flex;
    height: 100vh;

    @media screen and (max-width: $sizeTabletMax) {
      flex-direction: column;
    }
  }

  .salesData {
    width: 100%;
    height: auto;
    overflow-y: auto;
    padding-bottom: 140px;

    @media screen and (max-width: $sizeDesktopMin) {
      width: 100%;
      min-height: auto;
    }

    @media (max-height: 740px) {
      min-height: 280px;

      .salesBarChart {
        padding-bottom: 10px;
      }
    }

    .salesBarChart,
    #MethodChart,
    #ItemsChart,
    .customerContainerChart {
      padding-top: 20px;

      @media screen and (max-width: $sizeTabletMax) {
        padding-top: 35px;
      }
    }

    .salesBarChart,
    .customerContainerChart,
    .itemsContainerChart {
      @media screen and (max-width: $sizeDesktopMin) {
        width: 100%;
        box-sizing: border-box;
      }

      canvas {
        @media screen and (max-width: $sizeDesktopMin) {
          width: 100% !important;
        }
      }
    }

    .chips {
      text-align: right;
      width: 50%;

      @media screen and (max-width: $sizeDesktopMin) {
        width: auto;
      }

      .comparisonChip {
        @media screen and (max-width: $sizeDesktopMin) {
          margin-left: 0 !important;
        }
      }
    }

    .titlePanel {
      margin: 0;
      padding: 0 24px;

      @media screen and (max-width: $sizeDesktopMin) {
        margin: 10px 0 10px 10px;
        flex-direction: column-reverse;
      }

      .titleText {
        @media screen and (max-width: $sizeDesktopMin) {
          margin-top: 10px;
        }
      }

      .titleSubText {
        @media screen and (max-width: $sizeDesktopMin) {
          font-size: 13px !important;
        }
      }
    }
  }

  .salesSummaryRow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 15px;

    @media screen and (max-width: $sizeMobileMax) {
      margin-bottom: auto;
    }

    .summaryPanel,
    .donutChartHalf,
    .barChartFull {
      background-color: $backgroundColor;
      padding: 1%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 3px;
    }

    .summaryPanel {
      width: 22%;
      height: 125px;
      font-size: 18px;

      .summaryPanelHeader {
        color: $secondaryTextColor;
      }

      .summaryPanelHeader {
        display: inline-block;

        .summaryPanelTitle {
          display: inline-block;
        }

        .summaryPanelCount {
          display: inline-block;
          background-color: $payAnywhereColor;
          color: $backgroundColor;
          float: right;
          border-radius: 25px;
          padding: 4px;
          font-size: 14px;
        }
      }

      .summaryAmount {
        font-size: 32px;
        color: $primaryTextColor;
      }
    }

    .small {
      width: 17%;
      height: 100px;
      font-size: 17px;

      .summaryAmount {
        font-size: 28px;
      }
    }

    .donutChartHalf {
      width: 30%;
      padding: 0;
      height: 100%;
      display: inline;

      @media screen and (max-width: $sizeDesktopMin) {
        width: 37%;
        margin-bottom: 10px;
      }

      &:last-child {
        @media screen and (max-width: $sizeDesktopMin) {
          margin-bottom: 0;
        }
      }

      .donutChartTitle {
        color: $secondaryTextColor;
        font-size: 17px;
        margin: 10px;
        display: inline-block;
        width: 80%;
      }
    }

    .barChartFull {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  .tableRow td {
    padding: 16px !important;

    @media screen and (max-width: $sizeMobileMax) {
      padding: 10px !important;
    }

    :last-child {
      padding-right: 0px !important;
    }
  }

  .methodChartTableContainer,
  #customerChartTable {
    width: 100%;
    overflow-x: auto;

    table {
      min-width: 600px !important;
    }
  }

  .statisticsContainer {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: $primaryFontFamily;
    text-align: left;
    padding-left: 15px;

    .statisticsHeader {
      .title {
        font-size: 12px;
        font-family: $primaryFontFamily;
        font-weight: 500;
        letter-spacing: -0.14px;
        margin: 0;
      }

      .volume {
        @extend %heading1a;
        margin: 0;
        font-weight: 800 !important;
        font-size: 22px !important;
      }
    }

    .statistics {
      display: flex;
      flex-direction: row;

      .percentage {
        margin-left: 5px;
        line-height: 22px;
        font-size: 10px;
        font-family: $primaryFontFamily;
        font-weight: 500;
      }
    }
  }
}

.donutChartFull {
  margin: auto;
  text-align: center;
}

.itemSelector {
  display: inline-block;
  width: 50%;
  max-width: 250px;
  margin-left: 10px;
}

.activityTransactions {
  .iconHeader {
    display: inline-block;
  }
}

.activityTransactions,
.activityDeposits {
  .searchAccessory {
    .exportButtonIcon {
      top: -2px;
    }
  }
}


.activityTransactions {

  #masterList.masterListContainer {
    overflow-y: auto !important;
    height: auto;
    flex: 1 1 auto;

    #masterList {
      overflow-x: auto;

      &,
      .infiniteListAutoSizer,
      .ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
        height: auto !important;
      }
    }

    &.scroll-x {
      height: auto;
    }

    .ReactVirtualized__Grid__innerScrollContainer {
      height: auto !important;
      max-height: none !important;
    }

    .ReactVirtualized__Table__row {
      top: 0 !important;
      position: relative !important;
    }

  }

}


.activityTransactions {
  overflow: hidden;

  .transactionsSummary {
    @media screen and (max-width: $sizeMobileMax) {
      flex-direction: column;

      li {
        width: 100%;
        box-sizing: border-box;
        padding: 0 25px 25px;

        &:last-of-type {
          padding-bottom: 0px;
        }
      }
    }
  }

  #masterList.masterListContainer {

    @media screen and (max-width: 1300px) {

      .ReactVirtualized__Table__headerRow {
        border-top: 1px solid $lineColor;
        width: 1200px !important;
      }

      .ReactVirtualized__Grid.ReactVirtualized__Table__Grid {

        &,
        .ReactVirtualized__Grid__innerScrollContainer {

          &,
          .ReactVirtualized__Table__row {
            width: 1200px !important;
            max-width: none !important;
          }

        }

      }

    }

  }
}

.incompleteTransaction {
  color: $errorColor !important;
}

.openTransactionsHeader,
.incompleteTransactionsFilter {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px 0 0 24px;
  box-sizing: border-box;
  border-bottom: 1px solid $lineColor;

  @media screen and (max-width: 1300px) {
    border-bottom: none;
  }

  @media screen and (max-width: $sizeMobileMax) {
    width: 100%;
    padding-right: 94px;
  }

  .filterIconContainer {
    padding-top: 18px;
    padding-right: 10px;

    svg {
      fill: $payAnywhereColor !important;
    }
  }

  .filterMessageContainer {
    margin: 10px 0 15px 0;
    width: 90%;
    display: inline-block;

    .filterMessageTitle {
      font-family: $primaryFontFamily;
      font-weight: 500;
      @extend %bodyText;
      padding-bottom: 10px;
      padding-top: 12px;
    }

    .filterMessageDetails {
      font-family: $primaryFontFamily;
      font-weight: normal;
      @extend %contentLabel;
    }

    &.mobile {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;

      .filterMessageDetails {
        display: none;
      }

      .filterLinkContainer {
        margin: 0;
        padding: 0;

        & button {
          margin-right: 0;
        }
      }
    }
  }

  .filterLinkContainer {
    padding-top: 5px;
    margin-left: -27px;
  }
}

.customerContainer {
  display: flex;

  @media screen and (max-width: $sizeMobileMax) {
    flex-direction: column;
  }

  &Chart {
    padding-top: 20px;
    width: 75%;

    @media screen and (max-width: $sizeMobileMax) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &Wrapper {
    width: 25%;
    padding: 50px 10px;
    box-sizing: border-box;

    @media screen and (max-width: $sizeMobileMax) {
      width: 100%;
      border-left: 0;
    }
  }

  &Card {
    .row {
      display: flex;
      flex-direction: row;
      padding-bottom: 35px;
      align-items: flex-start;
    }

    .iconContainer {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .customers-quantity {
      @extend %heading1;
    }
  }
}

.customerLastVisit {
  width: 150px !important;
}

.invoiceList {
  width: 100%;
}

.statements {
  .statementsInfo {
    text-transform: capitalize;
  }

  .titlePanel {
    padding: 34px 48px 31px;
    display: block;
    box-sizing: border-box;
    height: auto;

    @media screen and (max-width: $sizeMobileMax) {
      padding-left: 10px;
      box-sizing: border-box;
    }

    .titleText,
    .titlePanelSubText {
      padding: 0;
      width: 100%;

      @media screen and (max-width: $sizeMobileMax) {
        line-height: normal;
      }
    }

    .titleText {
      @extend %subheading;
    }

    .titlePanelSubText {
      @extend %bodyText;
    }
  }

  &Bar {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $lineColor;
    padding: 15px;
    justify-content: space-between;
  }

  &List {
    list-style: none;
    display: none;
    padding: 0;
    margin: 0;
  }

  &Empty {
    @extend %bodyText;
    padding: 80px 48px;
    text-align: center;
  }

  &Processor {
    text-transform: uppercase;
  }

  &Link {
    @extend %bodyText;
    color: $payAnywhereColor;
    display: none;
    cursor: pointer;

    svg {
      transform: none;
      position: relative;
      top: 2px;
      fill: $payAnywhereColor;
    }
  }
}

.transactionsSummary+.mobileScrollNote,
.incompleteTransactionsFilter+.mobileScrollNote,
.openTransactionsHeader+.mobileScrollNote {
  @media screen and (max-width: 1300px) {
    display: flex !important;
  }
}

.scheduleReportDialog {
  >div {
    >div {
      >div {
        >div {
          overflow-y: unset !important;

          @media screen and (max-width: $sizeTabletMin) {
            overflow-y: auto !important;
          }
        }
      }
    }
  }
}

.scheduleReportDialogExternal {
  margin: 10px;

  .MuiDialog-paper {
    @media screen and (max-width: $sizeTabletMin) {
      padding: 10px !important;
      margin: 10px;
    }
  }
}

.scheduleReportForm {
  margin-bottom: 45px;

  .reportToggle {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .frequencyFieldsContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .frequencyField {
    flex: 1 1 5px;
  }
}