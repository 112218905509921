.uploadedFile {
  list-style: none;
  margin: 0;
  padding: 0;

  &Item {
    display: flex;
    box-sizing: border-box;
    padding: 8px 15px;
    border-radius: 5px;
    background-color: $lightGray;
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-around;
    gap: 15px;

    &.download {
      cursor: pointer;
    }

    .name {
      @extend %bodyText;
      flex-grow: 2;
      max-width: 340px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .remove {
      cursor: pointer;
    }

    .icon + .name {
      padding-left: 15px;
    }
  }
}
