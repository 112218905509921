.titlePanel {
  border-bottom: 1px solid $lineColor;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: $sizeMobileMax) {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .titleText {
    font-family: $primaryFontFamily !important;
    font-weight: 500;
    @extend %subheading;
    color: $black;
    width: 50%;
    padding-left: 24px;

    @media screen and (max-width: $sizeMobileMax) {
      width: 100%;
    }
  }

  .titlePanelSubText, .titlePanelDetailsText {
    font-family: $primaryFontFamily !important;
    font-weight: normal;
    @extend %bodyText;
    color: $black;
    text-align: left;
    width: 50%;
    padding-left: 24px;

    @media screen and (max-width: $sizeMobileMax) {
      width: 100%;
      font-size: 12px !important;
      text-align: left;
      white-space: normal;
      margin-top: 5px;
    }
  }

  .titleSubText {
    display: block;
    color: $secondaryTextColor;
    @extend %body;
    padding-top: 5px;
  }

  .titlePanelDetails {
    box-sizing: border-box;
    padding-left: 24px;
    width: 50%;

    @media screen and (max-width: $sizeMobileMax) {
      width: 100%;
    }

    .titleText {
      font-family: $primaryFontFamily !important;
      font-weight: 500;
      @extend %subheading;
      color: $black;
    }

    .titlePanelSubText, .titlePanelDetailsText {
      font-family: $primaryFontFamily !important;
      font-weight: normal;
      @extend %bodyText;
      color: $black;
      text-align: left;
    }
  }

  .titlePanelAction {
    width: 50%;
    text-align: right;
  }

}

.mobileContainer, .testDriveContainer {
  .titlePanel {
    padding-left: 24px;
    padding-right: 24px;
  }

  .titleText {
    width: 45%;
  }

  .titlePanelSubText, .titlePanelDetailsText {
    width: 45%;
  }
}

.activitySales, .activityReports {
  .titlePanel {
    width: auto;
    margin-left: 24px;
    margin-right: 24px;
  }
}

.infiniteList {

  .titlePanel {

    .titlePanelSubText {
      padding-right: 20px;
    }
  }

}
