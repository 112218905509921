.dialogRootContainer {
  z-index: 1200 !important;
}

.detailDialog {

  position: absolute !important;
  top: 0;
  right: 0;
  transform: none !important;
  height: 100%;
  max-height: none !important;
  overflow: auto;
  width: 750px !important;
  max-width: 100% !important;

  @media screen and (max-width: $sizeMobileMax) {
    min-width: 100%;
    max-width: 100%;
  }

  .bar {
    background-color: transparent !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-align: center;
    z-index: 0 !important;
    height: 64px;

    svg {
      fill: $iconColor !important;
    }

    svg:hover {
      fill: $payAnywhereColor !important;
    }

    span, .detailsTitle {
      color: black !important;
      @extend %heading2;
    }

    .barButton {

      background-color: white !important;

      span {
        @extend %heading2;
        color: $payAnywhereColor !important;
      }
    }

    .detailClose > div > div > span,
    .detailSave > div > div > span {
      color: white !important;
    }

  }

  .dialogBody {
    max-height: none !important;
    padding: 0 !important;
    height: 100vh;
  }

  .dialogBody + div { //Actions container
    position: absolute;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0 -5px 10px -10px #a3a3a6;
  }

}

.taxGrow {
  padding-top: 10px;
  display: flex;
}

.sharedCustomSwitch {
  margin: 0;

  span:first-child {
    margin-right: 15px;
  }
}

.cashDiscounting label, .sharedCustomCheckbox.taxable label, .sharedCustomSwitch label {
  @extend %columnLabel;
}

.cashDiscountAmount {
  display: inline-block;
  @extend %bodyText;
  padding-top: 40px;
}

.taxRate {
  display: inline-block;
}

.taxableInvoiceDetail {
  padding-top: 27px;
  margin-right: 50px;
}

.taxableInvoiceDetail, .createRecurringCheckbox, .tipsEnabled {
  label {
    @extend %columnLabel;
  }
}

.selectedSendDateValue {
  @extend %bodyText;
}

.cashDiscountInvoiceDetail {
  padding-top: 27px;
  margin-right: 25px;
}

.cashDiscount {
  .cashDiscountInfo {
    @extend %contentLabel;
    font-size: 12px !important;
    padding: 30px 0;
  }
}

.tipsEnabled {
  margin-bottom: 30px;
}

.tipCheckbox{
  display: inline-block;
  margin-right: 86px;
  padding-top: 27px;
  min-width: 250px;
  label{
    @extend %columnLabel;
  }
}

.detailsContainer {
  padding-right: 40px;
  padding-top: 24px;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: $sizeMobileMax) {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .textColumn {
    width: 70%;
    display: inline-block;
  }

  .depositDetailHeader {

    .textColumn {
      width: 60% !important;
    }

    .amountColumn {
      width: 40% !important;
    }
  }

  .amountColumn {
    width: 30%;
    display: inline-block;
    @extend .floatRight;
  }

  .detailContent {
    padding: 20px;
  }

  .detailsDivider {
    @extend %customLine;
  }

  .headerDivider {
    @extend %customLine;
    margin-top: 0 !important;
  }

  .detailsRow {
    height: 24px;
  }

  .detailsRowGrow {
    height: auto;
    padding-bottom: 2px;
  }

  .detailsHeaderText {
    @extend %heading1;
  }

  .detailsHeaderInputField {

    input {
      @extend %heading1;
    }

    label {
      @extend %detailsLabel;
    }
  }

  .detailDescriptionText {

    .placeholder {
      color: rgba(0, 0, 0, 0.3);
      font-size: 16px;
    }

  }

  .detailDescriptionTextSmall {
    @extend %body3;
  }

  .detailTextField {
    @extend %body;

    label {
      @extend %detailsLabel;
    }

  }

  .detailsCheckBox {
    display: inline-block;
    width: 100%;
    height: auto;
    margin: 15px 0;
    @extend %bodyText;
  }

  .chargebacksTable, .feesTable, .transactionsTable, .representmentsTable {
    padding-bottom: 10px;
  }


  .transactionsTable {
    .detailsListColumn {
      width: 9% !important;
    }
  }

  .transactionsTable.noRateNoFees {
    .detailsListColumn {
      width: 11% !important;
    }
  }

  .feesTable {
    .detailsListColumn {
      width: 25%;
    }

    .detailsListLargeColumn {
      width: 50% !important;
      @extend %detailsListColumn;
    }
  }

  .chargebacksTable {
    .detailsListColumn {
      width: 25%;
    }
  }

  .representmentsTable {
    .detailsListColumn {
      width: 20%;
    }

    .detailsListLargeColumn {
      width: 30% !important;
      @extend %detailsListColumn;
    }
  }


  .detailsListColumn {
    @extend %detailsListColumn;
  }

  .detailsListRow {
    display: inline-block;
    width: 100%;
    border-bottom: solid 1px $lineColor;
    line-height: 2em;
    font-size: 10px;
  }

  .detailsListHeaderRow {
    display: inline-block;
    width: 100%;
  }

}

.transactionDetail {

  .customer {
    .detailDescriptionText {
      font-size: 12px !important;
    }
  }

  .flexContainer {
    & > div {
      flex-direction: column;
    }

    .customer {
      flex-grow: 2;

      span.name {
        font-weight: 400 !important;
        font-size: 14px;
      }
    }

    .customerRating {
      flex-grow: 1;
      text-align: right;

      .starContainer {
        margin-top: 2px;
        margin-bottom: 3px;

        svg {
          width: 16px !important;
          height: 16px !important;
        }
      }

      button {
        height: auto !important;
      }
    }

  }

  .actionButtons {
    margin: 10px 0;
  }

  .removeAutopayButton {
    height: 18px !important;
    line-height: unset !important;
  }

}

.customerDialog {

  @media screen and (max-width: $sizeMobileMax) {
    margin: 0px;
  }

  h3 {
    @extend %subheading;
  }

  .customerDialogContent {

    .toolbar {
      .searchBar {
        width: 100%;
      }
    }

    @media screen and (max-width: $sizeMobileMax) {
      margin: 0px;
    }

    > div:first-of-type {
      border-radius: 15px !important;
    }

  }

  .customerDialogBody {
    border-top: none !important;

    @media screen and (max-width: $sizeMobileMax) {
      margin: 0px;
    }

    .toolbar {

      .searchBar {

        position: relative;

        .clearButton {
          position: absolute !important;
          top: 7px;
          right: 0;
        }

      }

    }

  }

}

.customerActive {
  background-color: $subNavBackgroundColor;
}

.customerListTable {

  width: 100%;
  border-collapse: collapse;

  .customerRow {

    border-bottom: 1px solid $lineColor;

    td {
      padding: 0 50px;
      @media screen and (max-width: $sizeMobileMax) {
        padding: 0;
      }
    }

  }

  .customerData {
    display: flex;
    flex-direction: row;
    padding: 0 15px;
    min-width: 541px;
    @extend %bodyText;
    align-items: center;

    .name {
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      @media screen and (max-width: $sizeMobileMax) {
        display: block;
        padding-bottom: 5px;
      }

      & {
        max-width: 250px;
        overflow: hidden;
      }
    }

    .contactDetail {
      font-size: 13px;
      margin-left: auto;
      line-height: 19px;
      @media screen and (max-width: $sizeMobileMax) {
        margin-left: 0;
      }
    }
    .customerIcons {
      width: 50px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      padding-left: 20px;
    }
    .hideLoyaltyIcon {
      visibility: hidden;
    }

    .customerDataGroup {
      width: 100%;
      display: flex;
    }
    @media screen and (max-width: $sizeMobileMax) {
      flex-direction: row;
      min-width: 100%;
      align-items: flex-start;
      padding: 0;

      .customerDataGroup{
        display: flex;
        flex-direction: column;
        width: 70%;
      }
      .contactDetail {
        margin: 0;
        font-size: 11px;
      }
      .cardIcon {
        align-self: center;
        justify-self: center;
        width: 35px;
        padding: 0 5px;
        margin: 0;
        img{
          width: 35px !important;
          height: 20px !important;
        }
      }
    }

  }
}
.customerRow {
  word-break: break-word;
}
.listShowHideBlock {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}
