.loyaltyOptIn {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 45px 0;

  h2 {
    font-family: $primaryFontFamily;
    font-weight: 600;
    color: $primaryTextColor;
    font-size: 27px;
    margin: 0 0 30px 0;

    @media screen and (max-width: $sizeMobileMax) {
      text-align: center;
    }
  }

  .btnContainer {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 70px;

    span {
      font-weight: 600;
    }
  }

  .tile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    .topImg {
      height: 152px;
      margin-bottom: 10px;
    }

    .bottomImg {
      height: 142px;
      margin: 30px 0;
    }

    h3 {
      font-family: $primaryFontFamily;
      font-weight: 600;
      color: $primaryTextColor;
      font-size: 18px;
      text-align: center;
      margin: 0 0 45px 0;
      padding: 0 25px;
    }
  }

}

.upgradeContainerPadding {
  padding: 50px 310px;

  @media screen and (max-width: $sizeTabletMax) {
    padding: 50px 130px;
  }

  @media screen and (max-width: $sizeMobileMax) {
    padding: 50px 30px;
  }

  &LeftPanel {
    padding: 50px 200px;

    @media screen and (max-width: $sizeTabletMax) {
      padding: 50px 130px;
    }

    @media screen and (max-width: $sizeMobileMax) {
      padding: 50px 30px;
    }

  }

}

.upgradeInvoices, .upgradePaymentLinksPage, .upgradeReputation {
  max-height: 100vh;
  overflow-y: auto;
}

.upgradeInvoices {

  .filter {
    border-right: 0 !important;
  }

  &Container {
    box-sizing: border-box;
    margin-top: -115px;
    padding-bottom: 60px;

    @media screen and (max-width: $sizeTabletMax) {
      height: auto;
      margin-top: 0;
    }

    @media screen and (max-width: $sizeMobileMax) {
      height: auto;
      margin-top: 0;
    }
  }

}

.upgradePaymentLinksPage {

  .filter {
    border-right: 0 !important;
  }

  &Container {
    box-sizing: border-box;
    padding: 0 310px 60px 310px;
    margin-top: -20px;

    .loyaltyOptIn {
      margin: 0;
    }

    @media screen and (max-width: $sizeTabletMax) {
      height: auto;
      margin-top: 0;
      padding: 50px 130px;
    }

    @media screen and (max-width: $sizeMobileMax) {
      height: auto;
      margin-top: 0;
      padding: 50px 30px;
    }
  }

}
