header {
  z-index: 1;

  a {
    text-decoration: none;
  }

  .hMenu {
    flex: 1;
    display: none;
    align-items: center;

    .container {
      cursor: pointer;
      width: 20px;
      margin-left: 10px;
      display: block;

      .line {
        width: 20px;
        height: 2px;
        background-color: $iconColor;
        margin-top: 3px;
        display: block;
        transition: all 0.5s ease-in-out;
      }
    }

    .active {
      .line {
        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(1) {
          transform: translateY(5px) rotate(45deg);
        }

        &:nth-child(3) {
          transform: translateY(-5px) rotate(-45deg);
        }
      }
    }

    @media screen and (max-width: $sizeMobileMax) {
      display: flex;
    }
  }

  .headerMenu {
    flex: 25;
    font-family: $primaryFontFamily;
    font-size: 17px;

    @media screen and (max-width: $sizeMobileMax) {
      display: none;
    }

    nav {
      display: flex;

      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        li {
          margin: 15px 30px;
          height: 30px;

          a {
            color: $secondaryTextColor;
          }

        }

        li.niceIndicator {
          margin: 15px 10px;
        }
      }
    }
  }

  .closedAccountContainer {
    float: right;
    right: 80px;
    position: absolute;

    .closedAccountIndicator {
      @extend %secondaryFontFamilySemiBold;
      border-radius: 3px;
      padding: 5px;
      display: flex;
      background-color: $subNavBackgroundColor;
      color: $payAnywhereColor;
      margin-top: -4px;

      .closedAccountText {
        padding-right: 3px;
      }

      .closedAccountIcon {
        display: flex;
        margin-top: -2px;
        margin-right: 5px;

      }
    }
  }
}

.headerSmall {
  display: flex;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;

  .logo {
    flex: 1;
    display: flex;
    height: 100%;

    @media screen and (max-width: $sizeMobileMax) {
      justify-content: center;
    }

    a {
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }

  .headerTop {
    flex: 1;
  }


}

.smallPaxDevice {
  .header {
    display: none;
  }

  .resetHeader, .niceHeader {
    margin: 5px 0 5px;
    font-size: 16px !important;
  }

  .login .loginContainer .forgotPasswordDescription {
    font-size: 12px !important;
  }
}
