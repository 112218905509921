.batchDetails {

  .detailDescription {
    padding-top: 0;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $lineColor;

    &:last-child {
      border: 0;
      margin-bottom: 0;
    }

    .detailDescriptionRow {

      .details {
        display: flex;
        margin-bottom: 5px;
        font-size: 14px;

        .type {
          width: 50%;
        }

        .quantity {
          width: 25%;
        }

        .amount {
          width: 25%;
          text-align: right;
        }

        &Bold {
          font-weight: bold;
        }
      }

      .th {
        box-sizing: border-box;
        color: $black;
        font-size: 12px;
        padding: 8px 5px;
        display: inline-block;
        width: 14.67%;
        text-align: left;
      }

      .tr {
        border-bottom: 1px solid $lineColor;

        &:last-child {
          border-bottom: 0;
        }
      }

      .td {
        box-sizing: border-box;
        font-size: 12px;
        padding: 10px 5px;
        display: inline-block;
        width: 14.67%;
        text-align: left;
        vertical-align: top;
      }

      .buttonHolder {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .transactionCount {
          margin: 0;
          @extend %contentLabel;
        }

        .toggleHolder {
          margin-left: auto;
          padding-right: 30px;

          label {
            @extend %contentLabel;
          }
        }
      }

    }
  }

  .batchDetailsSummaryWarning {
    margin: 20px auto;
    width: fit-content;

    .warningBox {
      align-items: center;
      background: $lightGray;
      display: flex;
      padding: 20px 20px 20px 10px;

      .icon {
        margin: 0 10px;

        svg {
          vertical-align: bottom;
        }
      }

      h3 {
        font-weight: bold;
      }
    }
  }

}
