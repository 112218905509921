@import 'prismjs/themes/prism.css';
@import 'prismjs/plugins/line-numbers/prism-line-numbers.css';

.paymentLinksDetailRoot {

  &, .detailContent .paymentLinksDetailBottomBar {

    width: 1200px !important;

    @media screen and (max-width: $sizeMobileMax) {
      width: 100% !important;
    }

  }

  .detailPanelContent {
    padding: 0;
  }

  .detailPanelButtons {
    position: absolute;
    margin: 11px 22px;
  }

  .detailPanelHolder {

    padding: 0;

    .detailPanelHolderTitle {
      text-align: center;
      border-bottom: 1px solid $lineColor;
      padding: 12px 0 25px;
    }

  }

  .detailContent {

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    @media screen and (max-width: $sizeTabletMax) {
      flex-direction: column;
      padding: 0 20px;
    }

    .optionsList {

      position: relative;
      width: 300px;

      @media screen and (max-width: $sizeTabletMax) {
        width: auto;
      }

      .optionsListItem {

        padding: 10px 90px 10px 0;

        &.disabled span {

          color: #00b4aa5c;

          svg {
            fill: #00b4aa5c;
          }

        }

      }

      .optionsHolder {

        > span {
          position: absolute;
          right: 0;
        }

      }

    }

    .paymentLinksDetailContainer {

      padding: 0;
      margin-bottom: 90px;
      max-width: 60%;

      @media screen and (max-width: $sizeTabletMax) {
        max-width: initial;
      }

      .subsection {
        margin: 0 0 67px 0;
      }

      .linkInformation {
        margin-top: 33px;
      }

      .mainInfo {

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media screen and (max-width: $sizeMobileMax) {
          flex-direction: column;
        }

        .titleDescriptionContainer {

          flex-grow: 1;

          .paymentLinkTitle {
            text-align: left;
          }

        }

        .imageContainer {

          width: 233px;
          margin: 0 0 0 20px;
          position: relative;

          @media screen and (max-width: $sizeMobileMax) {
            margin: 0;
            width: auto;
          }

          .uploadedImage {

            position: relative;

            .imageLabel {
              @extend %columnLabel;
              margin: 0 0 15px;
            }

            img {
              width: 100%;
            }

            .removeLogo {
              position: absolute;
              top: -12px;
              right: -11px;
              width: 24px;
              height: 24px;
              cursor: pointer;
              background: $paymentHubsColor;
              border-radius: 12px;
            }

          }

          .attachFilesForm {

            flex-basis: 233px;
            margin: 0;

            .fileInput > div:first-of-type {
              height: auto !important;
            }

            .dropZoneArea {

              flex-direction: column;
              justify-content: center;
              text-align: center;
              cursor: pointer;

              .iconHolder {

                background-color: inherit;
                margin: 0;

                .iconHolderText {
                  display: none;
                }

              }

              .secondaryText {
                color: $primaryTextColor;
              }

            }

          }

          .updateSpinner.imageContainerSpinner {
            position: absolute;
            opacity: 0.5;
            z-index: 800;
          }

          .updateSpinner.imageContainerSpinner, .attachFilesForm .dropZoneArea {
            height: 233px !important;
          }

        }

      }

      .linkContainer {

        padding: 16px 18px 14px;
        display: flex;
        align-items: center;

        @media screen and (max-width: $sizeMobileMax) {
          justify-content: space-between;
        }

        .linkLike {

          max-width: 520px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          @media screen and (max-width: $sizeMobileMax) {
            max-width: 215px;
          }

          &.inactive {
            color: #00b4aa5c !important;
          }

        }

        .copyIcon {
          margin-left: 35px;
          position: relative;
          top: -2px;
          fill: #000000;
          cursor: pointer;
        }

        .inactiveLinkText {
          margin: 0 0 0 5px;
        }

      }

      .paymentInformation {

        .subsectionTitle {
          border-bottom: 1px solid $lineColor;
        }

        .paymentDetails {

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 26px 0 30px;

          .detailTitle {
            @extend %bodyText;
          }

          .detailSubtitle {
            @extend %contentLabel;
            margin-top: 4px;
            font-weight: 600 !important;
          }

        }

        .paymentAmounts {

          .amountItem {

            display: inline-block;
            padding: 15px 17px;
            background: $subNavBackgroundColor;
            border: 1px solid $lineColor;
            border-radius: 7px;
            margin: 0 7px 8px 0;

            .amountItemValue {
              @extend %bodyText;
            }

            .amountItemDetails {
              @extend %contentLabel;
              margin-left: 9px;
            }


          }

        }

        .paymentOptionsToggle {
          margin-top: 22px;
        }

      }

      .buttonCode {

        .buttonCodeContainer {

          border: 1px solid $lineColor;
          position: relative;

          .codeArea {
            height: 200px;
            background: $subNavBackgroundColor;
            margin: 0;
            padding-top: 0;
            padding-bottom: 0;
            white-space: pre-wrap;
            word-break: break-word;

            code {
              word-break: break-word;
            }
          }

          .preview {

            border-top: 1px solid $lineColor;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 10px;

            .previewText {
              margin-left: 10px;
            }

          }

          .copyIconContainer {
            position: absolute;
            top: 15px;
            right: 25px;
            cursor: pointer;
            z-index: 1;
          }

          .nab-payment-link {
            background: #0185F8;
            color: #FFF;
            font-family: Arial, serif;
            font-size: 15px;
            text-decoration: none;
            display: inline-block;
            border-radius: 20px;
            padding: 12px 30px;
          }

        }

      }

    }

    .paymentLinksDetailBottomBar {

      box-sizing: border-box;
      height: auto;
      left: initial;
      max-width: 100%;
      z-index: 1000;

      > div {
        text-align: center;
      }

      @media screen and (max-width: $sizeTabletMax) {

        padding: 12px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

      }

    }

  }

}
