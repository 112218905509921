// --- colors ---
$regularColor: #777777;                                                                                // theme.palette.common.regularColor
$backgroundColor: #FFFFFF;                                                                       // theme.palette.common.backgroundColor
$black: #000000;                                                                                          // theme.palette.common.black
$phoneSwipeColor: #C2CB52;                                                                     // theme.palette.common.phoneSwipeColor
$phoneSwipeColorAlternativeColor: #A3A3A6;                                          // theme.palette.common.phoneSwipeColorAlternativeColor
$paymentHubsColor: #00B4AA;                                                                  // theme.palette.common.paymentHubsColor
$paymentHubsColorAlternativeColor: #A3A3A6;                                       // theme.palette.common.paymentHubsColorAlternativeColor
$paymentHubsButtonAlternativeColor: #05A899;                                     // theme.palette.common.paymentHubsButtonAlternativeColor
$northColor: #1857F7;                                                                                   // theme.palette.common.northColor
$backgroundColor2: #E9E9EC;                                                                    // theme.palette.common.backgroundColor2
$backgroundColor3: #F2F2F4;                                                                    // theme.palette.common.backgroundColor3
$backgroundColor4: #F4F4F4;                                                                    // theme.palette.common.backgroundColor4
$backgroundColor5: #f7f8fa;                                                                       // theme.palette.common.backgroundColor5
$payAnywhereColor: #F57F38;                                                                    // theme.palette.common.payAnywhereColor
$payAnywhereAlternativeColor: #BF5E04;                                                 // theme.palette.common.payAnywhereAlternativeColor
$genericColor: #5BADC9;                                                                             // theme.palette.common.genericColor
$primaryTextColor: #404143;                                                                      // theme.palette.common.primaryTextColor
$disabled: rgba(153, 153, 153, 0.2);                                                             // theme.palette.common.disabled
$secondaryTextColor: #888C8D;                                                                 // theme.palette.text.secondaryTextColor ****
$placeholderTextColor: #393A3F;                                                               // theme.palette.common.placeholderTextColor
$subNavBackgroundColor: #EFEFF2;                                                          // theme.palette.common.subNavBackgroundColor
$mobileTitlePanelBackgroundColor: #393A3F;                                          // theme.palette.common.mobileTitlePanelBackgroundColor
$todaySecondaryDataColor: #A3A3A6;                                                      // theme.palette.common.todaySecondaryDataColor
$iconColor: #3E3935;                                                                                   // theme.palette.common.iconColor
$lineColor: #DDDDDD;                                                                                  // theme.palette.common.lineColor
$lineColor2: #D9D9D9;                                                                                 // theme.palette.common.lineColor2
$lineColor3: rgba(0, 0, 0, 0.23);                                                                   // theme.palette.common.lineColor3
$selectedRow: #EFEFF2;                                                                              // theme.palette.common.selectedRow
$positiveColor: green;                                                                                   // theme.palette.common.positiveColor
$negativeColor: red;                                                                                     // theme.palette.common.negativeColor
$errorColor: red;                                                                                           // theme.palette.common.errorColor
$warningColor: #ffcc00;                                                                               // theme.palette.common.warningColor
$disputeError: #EC3830;                                                                              // theme.palette.common.disputeError
$disputePositive: #36B1FF;                                                                          // theme.palette.common.disputePositive
$elevioHotSpot: #36B1FF;                                                                            // theme.palette.common.elevioHotSpot
$navyBlue: #7195f5;                                                                                     // theme.palette.common.navyBlue
$cardBorderColor: #E0E0E0;                                                                        // theme.palette.common.cardBorderColor
$cardBorderColor2: #D3D3D3;                                                                    // theme.palette.common.cardBorderColor2
$inventoryTrendDiscount: #6CE232;                                                           // theme.palette.common.inventoryTrendDiscount
$inventoryTrendCategory: #3FC3FA;                                                          // theme.palette.common.inventoryTrendCategory
$loginHeadingColor: #404143;                                                                     // theme.palette.common.loginHeadingColor
$paymentPageGrey: #F2F2F2;                                                                     // theme.palette.common.paymentPageGrey
$paymentAddressColor: #505152;                                                               // theme.palette.common.paymentAddressColor
$paymentDetailText: #5D5E5F;                                                                    // theme.palette.common.paymentDetailText
$paymentCardBackground: #F2F2F2;                                                         // theme.palette.common.paymentCardBackground
$customerPaymentPrimaryColor: #000;                                                      // theme.palette.common.customerPaymentPrimaryColor
$loyaltyEmailGray: #F2F2F2;                                                                        // theme.palette.common.loyaltyEmailGray
$receiptSeparatorColor: #707070;                                                              // theme.palette.common.receiptSeparatorColor
$loyaltyBorderColor: #E1E1E1;                                                                      // theme.palette.common.loyaltyBorderColor
$loyaltyResendGray: #F2F2F4;                                                                    // theme.palette.common.loyaltyResendGray
$loyaltyBorderColor2: #B5B5B5;                                                                  // theme.palette.common.loyaltyBorderColor2
$loyaltyBackgroundColor: #EBEBEB;                                                           // theme.palette.common.loyaltyBackgroundColor
$grayButtonColor: #F5F5F5;                                                                        // theme.palette.common.grayButtonColor
$loginTextColor: #4A5460;                                                                          // theme.palette.common.loginTextColor
$listItemBackground: #E6E6EA;                                                                   // theme.palette.common.listItemBackground
$paGradientOne: #f5af19;                                                                             // theme.palette.common.paGradientOne
$paGradientTwo: #f12711;                                                                            // theme.palette.common.paGradientTwo
$phGradientOne: #00D0C4;                                                                         // theme.palette.common.phGradientOne
$phGradientTwo: #00B4AA;                                                                         // theme.palette.common.phGradientTwo
$white: #FFFFFF;                                                                                           // theme.palette.common.white
$green: #49C930;                                                                                         // theme.palette.common.green
$lightGray: #F6F8FA;                                                                                    // theme.palette.common.lightGray
$gray: #8A8C8C;                                                                                           // theme.palette.common.gray
$borderGray: #cccccc;                                                                                 // theme.palette.common.borderGray
$grayText: #5d5e5f;                                                                                     // theme.palette.common.grayText
$black: #3E3935;                                                                                          // theme.palette.common.iconColor      ****
$fieldsHintText: rgba(0, 0, 0, 0.3);                                                               // theme.palette.common.fieldsHintText
$disabledGray: #D9D9D9;                                                                            // theme.palette.common.disabledGray
$borderGrayColor: rgba(157,157,157,0.3);                                                  // theme.palette.common.borderGrayColor
$borderShadowColor: #0000000B;                                                            // theme.palette.common.borderShadowColor

// --- fonts ---
//Century Gothic
$centuryGothic: 'Century Gothic', sans-serif;                                     // theme.typography.centuryGothic
$inter: 'Inter', sans-serif;                                                     // theme.typography.inter
$primaryFontFamily: $inter;                                                            // theme.typography.primaryFontFamily
$secondaryFontFamily: $centuryGothic;                                        // theme.typography.secondaryFontFamily
$tertiaryFontFamily: $centuryGothic;                                              // theme.typography.tertiaryFontFamily

// --- measures ---
$buttonHeight: 50px;                                                                                    // theme.measures.buttonHeight
$noticeHeight: 142px;                                                                                    // theme.measures.noticeHeight
$centeredLoginContentWidth: 30%;                                                           // theme.measures.centeredLoginContentWidth
$centeredLoginContentMobileWidth: 70%;                                                // theme.measures.centeredLoginContentMobileWidth

// --- zIndex ---
$zIndexTopEverything: 999;                                                                        // theme.zIndex.zIndexTopEverything

// --- sizes ---
$sizeDesktopMax: 1439px;                                                                           // theme.breakpoints['sizeDesktopMax']
$sizeDesktopMid: 1339px;                                                                            // theme.breakpoints['sizeDesktopMid']
$sizeDesktopMedium: 1309px;                                                                    // theme.breakpoints['sizeDesktopMedium']
$sizeDesktopMin: 992px;                                                                             // theme.breakpoints['sizeDesktopMin']
$sizeTabletMax: 991px;                                                                                // theme.breakpoints['sizeTabletMax']
$sizeTabletMedium: 791px;                                                                          // theme.breakpoints['sizeTabletMedium']
$sizeTabletMin: 768px;                                                                                 // theme.breakpoints['sizeTabletMin']
$sizeMobileMaxPortrait: 767px;                                                                   // theme.breakpoints['sizeMobileMaxPortrait']
$sizeMobileMax: 479px;                                                                               // theme.breakpoints['sizeMobileMax']
$sizeMobileMedium: 375px;                                                                         // theme.breakpoints['sizeMobileMedium']
$sizeMobileMin: 320px;                                                                                // theme.breakpoints['sizeMobileMin']

// tooltip only
$cubic: cubic-bezier(.64, .09, .08, 1);                                                          // theme.transitions.cubic

// --- shared styles typography ---

// theme.typography.secondaryFontFamilySemiBold
%secondaryFontFamilySemiBold {
  font-family: $secondaryFontFamily !important;
  font-weight: 600 !important;
}

// theme.typography.secondaryFontFamilyExtraBold
%secondaryFontFamilyExtraBold {
  font-family: $secondaryFontFamily !important;
  font-weight: 800 !important;
}

// theme.typography.tertiaryFontFamilyRegular
%tertiaryFontFamilyRegular {
  font-family: $tertiaryFontFamily !important;
  font-weight: 400 !important;
}

// theme.typography.tertiaryFontFamilyExtraBold
%tertiaryFontFamilyExtraBold {
  font-family: $tertiaryFontFamily !important;
  font-weight: 800 !important;
}

// theme.typography.heading1
%heading1 {
  @extend %secondaryFontFamilyExtraBold;
  font-size: 30px !important;
}

// theme.typography.heading1a
%heading1a {
  font-family: $primaryFontFamily !important;
  font-size: 30px !important;
}

// theme.typography.heading2
%heading2 {
  @extend %secondaryFontFamilySemiBold;
  font-size: 13.5px !important;
  text-transform: uppercase;
}

// theme.typography.heading2RegularCase
%heading2RegularCase {
  @extend %secondaryFontFamilySemiBold;
  font-size: 13.5px !important;
}

// theme.typography.heading3
%heading3 {
  @extend %secondaryFontFamilySemiBold;
  font-size: 11px !important;
  text-transform: uppercase;
}

// theme.typography.heading4
%heading4 {
  @extend %tertiaryFontFamilyExtraBold;
  font-size: 17px !important;
}

// theme.typography.body
%body {
  @extend %tertiaryFontFamilyRegular;
  font-size: 17px !important;
}

// theme.typography.body3
%body3 {
  @extend %tertiaryFontFamilyRegular;
  font-size: 14px !important;
}

// --- shared styles ---

// theme.sharedStyles.customLine
%customLine {
  border-bottom: solid 1px $lineColor;
  margin-top: 20px;
  margin-bottom: 20px;
}

// theme.sharedStyles.pageBar
%pageBar {
  height: 62px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $lineColor;
  padding: 0 10px;
}

// theme.sharedStyles.disabledLink
%disabledLink {
  text-decoration: none;
  color: $secondaryTextColor;
  font-family: $secondaryFontFamily;
  cursor: not-allowed;
}

// theme.sharedStyles.flip
%flip {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: 'FlipH';
}

// theme.sharedStyles.centerWithin
%centerWithin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// theme.sharedStyles.headerTextMedium(theme)
%headerTextMedium {
  @extend %secondaryFontFamilyExtraBold;
  font-size: 15px;
  margin-bottom: 5px;
}

// theme.sharedStyles.detailsHeader(theme)
%detailsHeader {
  @extend %heading3;
  color: $secondaryTextColor;
}

// theme.sharedStyles.detailsLabel(theme)
%detailsLabel {
  @extend %heading3;
  transform: translate(0px, -28px) !important;
}

// theme.sharedStyles.detailsListColumn
%detailsListColumn {
  width: 10%;
  font-size: 9.5px;
  display: inline-block;
  text-align: center;
  text-transform: capitalize;
}

// theme.sharedStyles.x-smallIcon
%x-smallIcon {
  width: 12px !important;
  height: 12px !important;
}

// theme.sharedStyles.smallIcon
%smallIcon {
  width: 16px !important;
  height: 16px !important;
}

// theme.sharedStyles.mediumIcon
%mediumIcon {
  width: 20px !important;
  height: 20px !important;
}

// theme.sharedStyles.largeIcon
%largeIcon {
  width: 24px !important;
  height: 24px !important;
}

// theme.sharedStyles.heading(theme)
%heading {
  font-family: $secondaryFontFamily !important;
  font-size: 37px !important;
  color: $primaryTextColor;
  font-weight: 700 !important;
  margin: 0;

  @media screen and (max-width: $sizeTabletMax) {
    font-size: 27px !important;
  }

  @media screen and (max-width: $sizeMobileMax) {
    font-size: 24px !important;
  }
}

// theme.sharedStyles.subheading(theme)
%subheading {
  font-family: $primaryFontFamily !important;
  font-size: 28px !important;
  color: $primaryTextColor !important;
  font-weight: 700 !important;
  margin: 0;

  @media screen and (max-width: $sizeTabletMax) {
    font-size: 21px !important;
  }

  @media screen and (max-width: $sizeMobileMax) {
    font-size: 20px !important;
  }
}

// theme.sharedStyles.bodyText(theme)
%bodyText {
  font-family: $primaryFontFamily !important;
  font-weight: 500;
  font-size: 15px !important;
  color: $primaryTextColor;
  margin: 0;

  @media screen and (max-width: $sizeMobileMax) {
    font-size: 14px !important;
  }
}

// theme.sharedStyles.bodyTextSubHeading(theme)
%bodyTextSubHeading {
  font-family: $primaryFontFamily !important;
  font-weight: 600;
  font-size: 17px !important;
  color: $primaryTextColor;
  margin: 0;

  @media screen and (max-width: $sizeMobileMax) {
    font-size: 14px !important;
  }
}

// theme.sharedStyles.columnLabel(theme)
%columnLabel {
  font-family: $primaryFontFamily !important;
  font-size: 12px !important;
  color: $primaryTextColor;
  font-weight: 700 !important;
  margin: 0;

  @media screen and (max-width: $sizeMobileMax) {
    font-size: 11px !important;
  }
}

// theme.sharedStyles.contentLabel(theme)
%contentLabel {
  font-family: $primaryFontFamily !important;
  font-weight: 600;
  font-size: 12px !important;
  color: $primaryTextColor;

  @media screen and (max-width: $sizeMobileMax) {
    font-size: 11px !important;
  }
}

// theme.sharedStyles.active
%active {
  color: $payAnywhereColor;
}

// theme.sharedStyles.0(theme)
%button {
  border-radius: 50px !important;
  height: $buttonHeight !important;

  button {
    height: $buttonHeight !important;
    background: linear-gradient(to top right, $paGradientOne, $paGradientTwo);
    border-radius: 50px !important;
    overflow: hidden;

    span {
      font-family: $primaryFontFamily !important;
      font-weight: bold !important;
      text-transform: none !important;
      font-size: 14px !important;
    }

    &:disabled {
      background: $gray !important;

      div {
        height: $buttonHeight !important;
        line-height: $buttonHeight !important;
        padding: 0 24px !important;

        span {
          font-size: 14px !important;
          text-transform: none !important;
          font-family: $primaryFontFamily !important;
          font-weight: bold !important;
          padding: 0 !important;
        }
      }
    }

    div {

      span {
        color: $white !important;
      }

      div {
        height: $buttonHeight !important;
        line-height: $buttonHeight !important;
        padding: 0 24px !important; //Add padding to div instead of span, in case the div also contains an icon

        span {
          font-size: 14px !important;
          text-transform: none !important;
          font-family: $primaryFontFamily !important;
          font-weight: bold !important;
          padding: 0 !important;
        }
      }
    }
  }
}

// theme.sharedStyles.reverseButton(theme)
%reverseButton {
  height: $buttonHeight !important;
  border-radius: 50px !important;
  background-color: $white;

  span {
    font-family: $primaryFontFamily !important;
    font-weight: bold !important;
    text-transform: none !important;
    font-size: 14px !important;
  }

  div {

    padding: 0 24px !important; //Add padding to div instead of span, in case the div also contains an icon

    span {
      text-transform: none !important;
      font-size: 14px !important;
      font-family: $primaryFontFamily !important;
      font-weight: bold !important;
      padding: 0 !important;
    }
  }

  &:hover {
    background-color: $disabled !important;
  }
}

// theme.sharedStyles.linkButton(theme)
%linkButton {
  z-index: 1;
  font-size: 14px;
  font-family: $primaryFontFamily !important;
  font-weight: 500;
  border-radius: 50px !important;
  height: $buttonHeight !important;
  background: linear-gradient(to top right, $paGradientOne, $paGradientTwo) !important;
  box-shadow: 2px 2px 10px 0 rgba(163, 163, 166, 1) !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  color: $white;
  text-decoration: none;
}

// theme.sharedStyles.link(theme)
%link {
  @extend %bodyText;
  text-decoration: none;
  color: $payAnywhereColor;
  cursor: pointer;
}

// theme.sharedStyles.contentRow
%contentRow {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

// theme.sharedStyles.subheadingInvoicing(theme)
%subheadingInvoicing {
  @extend %subheading;
  font-size: 25px !important;
  @media screen and (max-width: $sizeTabletMax) {
    font-size: 21px !important;
  }

  @media screen and (max-width: $sizeMobileMax) {
    font-size: 20px !important;
  }
}

// theme.sharedStyles.settingsWrapper(theme)
%settingsWrapper {
  @extend %contentLabel;
  background-color: #f2f2f4;
  padding: 20px 15px;
  border-radius: 10px;
}
