.createPaymentLinkForm {

  .paymentLinkFormContainer {

    margin-bottom: 200px;

    @media screen and (max-width: $sizeMobileMax) {
      margin-bottom: 0;
    }

    .formTitle {
      margin: 35px 0 25px;
    }

    .formSubsection {
      padding: 25px 0;
      margin: 0 0 20px;
    }

    .textFieldHolder {
      min-height: 48px;
    }

    .mainInfo {

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media screen and (max-width: $sizeMobileMax) {
        flex-direction: column;
      }

      .titleDescriptionContainer {

        flex-grow: 1;

        .paymentLinkTitle {
          text-align: left;
        }

      }

      .imageContainer {

        flex-basis: 233px;
        margin: 0 0 0 20px;

        @media screen and (max-width: $sizeMobileMax) {
          margin: 0;
          padding: 0 6px;
        }

        .attachFilesForm {
          div[role=presentation] {
            margin-bottom: 0;
          }
        }

        .uploadedImage {

          position: relative;

          .imageLabel {
            @extend %columnLabel;
            margin: 0 0 15px;
          }

          img {
            width: 100%;
          }

          .removeLogo {
            position: absolute;
            top: 20px;
            right: -11px;
            width: 24px;
            height: 24px;
            cursor: pointer;
            background: $paymentHubsColor;
            border-radius: 12px;
          }

        }

        .dropZoneArea {

          height: 233px !important;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          cursor: pointer;

          .iconHolder {

            background-color: inherit;
            margin: 0;

            .iconHolderText {
              display: none;
            }

          }

          .secondaryText {
            color: $primaryTextColor;
          }

        }

      }

    }

    .paymentInfo {

      .paymentLinkType {
        margin-bottom: 8px;
      }

      .paymentTypeDescription {
        margin: 0 0 30px 19px;
      }

      .paymentOptionsToggle {
        margin: 0 0 0 10px;

        &.enableTax {
          margin-bottom: 0;
        }
      }

      .toggleTaxDescription {
        color: $secondaryTextColor;
        font-size: 14px;
        padding-left: 50px;

        .businessSettingsLinksLink {
          font-size: 14px !important;
        }
      }

      .cdContainer {

        display: flex;
        align-items: center;

        .cdAmountContainer {
          position: relative;
          display: flex;
          @extend %contentLabel;
          margin-left: 3px;
          > span:last-child {
            bottom: 15px;
            left: -5px;
          }
        }

        span {
          margin-right: 0;
        }

      }

    }

    .addCustomField {
      margin-bottom: 8px;
    }

    .customRequiredField {
      margin: 0 0 30px 10px;
    }

    .redirectUrl {
      margin-bottom: 8px;
    }

    .redirectUrlDescription {
      padding: 0px 32px;
      margin: 0;
    }

  }

  .paymentLinkSharingInfoContainer {

    padding: 0 10% 0;
    margin: 119px 0 150px;

    @media screen and (max-width: $sizeMobileMax) {
      margin-top: 0;
    }

    .sharingTitle {
      margin: 0;
    }

    .sharingFeature {

      border-bottom: 1px solid #d6d1d1;

      .featureImage {
        margin: 28px 0 0;
      }

      .featureText {
        margin: 10px 0 30px;
      }

    }

    .manageInfo {

      margin: 30px 0;

      .paymentLinksLink {
        font-size: inherit !important;
      }

    }

  }

}

.paymentLinksBottomBar {

  @media screen and (max-width: $sizeMobileMax) {
    text-align: center;
    padding-bottom: 15px;
  }

  svg {
    margin-right: 8px;
  }

  .flatButton, .raisedButton {
    width: 211px;
  }

  .flatButton {
    margin-right: 15px;
  }

  .raisedButton {

    span {
      margin-right: 8px;
    }

  }

}

.createPaymentLinkStatusDialog {

  .title {
    display: none;
  }

  .content {

    text-align: center;
    padding-bottom: 26px !important;

    .contentTitle {
      @extend %subheading;
      margin: 11px 0 0;
    }
  }

  .dialogActions {
    justify-content: center;
  }

  .content .options .option, .dialogActions .raisedButton {

    width: 480px;
    max-width: 100%;

    @media screen and (max-width: $sizeMobileMax) {
      width: 270px;
    }

  }

}

.paymentLinkPreview {
  height: 100%;
  overflow-y: auto;
  max-width: 950px;
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: $backgroundColor3;

  @media screen and (min-width: $sizeTabletMin) {
    margin-top: 5vh;
    margin-bottom: 5vh;
    height: 90vh;
  }

  .closeIcon {
    padding: 5px;
    display: flex;
    width: 100%;
    justify-content: end;
    background-color: $backgroundColor;

    svg {
      cursor: pointer;
      margin-right: 15px;
    }
  }

  .paymentLinkForm {
    padding: 50px 8px;

    .payButton {
      pointer-events: none;
    }
  }

  .userData, .cardData, .cardMetadata {
    display: flex;
    gap: 15px;

    .textField {
      width: auto;
    }

    > div {
      width: 50%;
    }

    @media screen and (max-width: $sizeMobileMaxPortrait) {
      flex-direction: column;
      gap: 0;

      > div {
        width: 100%;
      }
    }
  }

  .userData .textField {
    width: 100%;
  }

  .cardContainerWithVisibilityToggle {
    position: relative;
    display: inline-block;
    width: 100%;

    .cardImage {
      position: absolute;
      top: 39px;
      right: 48px;
    }

    .cardNumber {
      width: 100%;
    }

    #cardNumberVisibilityIcon {
      position: absolute;
      top: 26px;
      right: 5px;
      padding: 8px;

      > span:not(:last-child) {
        height: 26px;
        width: 26px;
        margin-bottom: 2px;
      }

      svg {
        width: 21px;
      }
    }
  }

  .textFieldHolder {
    border-width: 1px;
  }

  .footerText {
    font-size: 14px;
    text-align: center;
    font-family: $primaryFontFamily;
    font-weight: normal;
    margin-top: 18px;
  }

  #tipSelector {
    margin-top: 20px;

    > div {
      width: 100%;
      margin-bottom: 0;

      > label {
        margin: 0 0 7px 0;
      }
    }

    label {
      flex: 1;
      font-family: $primaryFontFamily;
      font-weight: 600;
      color: #404143;
      margin-left: 0;
    }
  }

  .textField + #tipSelector {
    margin-top: 0;
  }
}
