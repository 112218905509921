.deposits, .batches {
  .detailPanel {
    height: 100vh !important;
  }

  .detail {
    max-width: 850px;
    min-width: 850px;
  }

}

.detailPanel {
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @extend %contentLabel;

  .saveBtnWrapper {
    display: flex;
    .unsavedMessage {
      color: red;
      margin-left: 10px;
    }
  }

  &.largerPanel {
    padding: 20px 30px;
  }

  &.hasButtons {
    padding-bottom: 100px;
  }

  .dueDate {
    @extend %bodyText;
    display: flex !important;
    align-items: center;

    .detailsHeader {
      @extend %contentLabel;
    }

    > div {
      display: flex;
      padding-left: 5px;
    }

    .content {
      padding: 15px 20px 10px 20px;
    }

    .dueDateSelect {
      padding-left: 5px !important;
    }

    button {
      fill: $payAnywhereColor !important;
    }

  }

  .sendDateSelect {
    button {
      fill: $payAnywhereColor !important;
    }
  }

  .dueDateDisplay, .failedPaymentInfo {
    @extend %bodyText;
    display: flex !important;
    border-radius: 5px;
    background-color: $lightGray;
    padding: 20px 15px;
    margin-bottom: 15px;
    width: 80%;
    align-items: center;

    .dueDateSelect {
      padding-left: 5px !important;
    }

    .due {
      margin-left: auto;
    }

    .accent {
      flex-grow: 0;
      margin-right: 8px;

      svg {
        @extend %largeIcon;
      }
    }

    > div {
      display: flex;
      margin: 0 10px;

      .content {
        padding-top: 5px;
      }
    }

  }

  .failedPaymentInfo {
    padding-left: 25px;
    width: 78%;

    .failedPaymentDetails {
      display: block;
    }

    .failureButtonContainer {
      margin-top: 6%;
      .failureResendButton {
        cursor: pointer;
      }
    }

    h4.failureReasonTitle {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .failureReason {
      color: $gray !important;
      font-size: 12px ;
    }

    .failureReason, h4.failureReasonTitle, .failureButtonContainer {
      margin-left: 16px;
    }
  }

  .viewTransactionButton {
    cursor: pointer;
    text-decoration: none !important;
    color: $payAnywhereColor;
  }

  &Content {
    box-sizing: border-box;
    margin: 0;
    padding: 0 30px;

    &.largerPanel {
      width: 100%;
    }
  }

  &Buttons {
    margin-bottom: 45px;

    .closeButton {
      cursor: pointer;
    }
  }

  &Holder {
    padding: 0 50px;

    @media screen and (max-width: $sizeMobileMax) {
      padding: 0;
    }

    &Title {
      margin-bottom: 30px;

      h2 {
        @extend %subheading;
        margin-bottom: 5px;
      }

      h3 {
        @extend %contentLabel;
      }
    }

    &Buttons {
      display: flex;
      box-sizing: border-box;
      box-shadow: 0 -10px 25px -20px $regularColor;
      width: 750px;
      position: fixed;
      bottom: 0;
      background-color: $white;
      align-items: center;
      justify-content: center;
      height: 100px;
      z-index: 10;
      @media screen and (max-width: $sizeMobileMax) {
        width: 100%;
      }

      .errorMessage {
        color: $errorColor;
        margin-left: 10px;
      }

      &>span {
        margin-right: 10px;

        .raisedButton {
          width: 100%;
        }
      }
    }

    .detailDescription {
      padding-top: 10px;
      margin-bottom: 30px;

      &.dueDate {
        margin-bottom: 15px;
      }

      .detailsHeader {
        @extend %contentLabel;
        padding-top: 10px;
        padding-bottom: 10px;
        text-transform: none !important;
      }

      .detailsInfoHeader {
        @extend %bodyText;
        margin-bottom: 7px;
      }

      .detailDescriptionRow {
        margin-bottom: 5px !important;
      }

      .detailDescriptionText {
        @extend %bodyText;

        .isActiveAccount {
          font-family: $primaryFontFamily !important;
          font-weight: 500;
        }
      }
    }

    .declinedErrorBox {
      align-items: center;
      background: $lightGray;
      display: flex;
      padding: 20px 20px 20px 10px;
      .icon {
        margin: 0 10px;
      }
      .errorLabel {
        text-transform: capitalize;
      }
    }
  }

  .invoice {
    padding-left: 60px !important;
  }

  &Logo {
    max-height: 50px;
    margin-bottom: 15px;
  }

  .chargezoomContent {

    .chargezoomPlans {
      display: flex;
      column-gap: 65px;
      justify-content: start;

      @media screen and (max-width: $sizeMobileMax) {
        flex-direction: column;
        row-gap: 50px;
      }

      .plan {
        align-items: center;
        border: 2px solid $paymentHubsColor;
        display: flex;
        flex-direction: column;
        min-width: 200px;
        padding: 0 5% 30px;

        .planType {
          text-align: center;
          width: 150px;
          line-height: 35px;
          border-radius: 16px;
          font-size: 16px;
          color: $backgroundColor;
          margin: 30px 0 !important;
        }

        p {
          margin: 0;
          font-size: 14px;
        }

        .planBody {

          div {
            margin-top: 30px;

            span {
              color: #7C7C7C;
              font-size: 12px;
            }
          }
        }

        .activatePlan {
          margin-top: 30px;
          color: $paymentHubsColor;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
        }

        .disabled {
          color: $secondaryTextColor;
          cursor: default;
        }
      }
    }

    .chargezoomLink {
      margin: 30px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      text-decoration: none;

      span {
        margin-left: 20px;
        color: $paymentHubsColor;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        line-height: 10px;
      }
    }

    .disconnectButton {
      margin-bottom: 30px;

      svg {
        margin-right: 15px;
      }
    }

    .details {

      span {
        font-size: 14px;
      }

      ol {
        padding-left: 15px;
      }
    }
  }
}
