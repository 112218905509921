.tooltipHolder {
  position: absolute;
  right: 0;
  top: 28px;

  p {
    display: inline;
  }
}

.linkNotification .linkLike {
  margin-left: 1px;
}
