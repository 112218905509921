.disputes {

  .detail {
    max-width: 650px;
    min-width: 650px;
  }

  &Title {
    display: flex;
    flex-direction: column;
  }

  &Container {
    h1, p {
      margin: 0;
    }

    h1 {
      color: $primaryTextColor;
    }

    p {
      span:first-child {
        vertical-align: text-top;
        margin-right: 5px;
      }

      span:nth-child(3) {
        margin: 0 8px;
      }
    }
  }

  &Message {
    background-color: $subNavBackgroundColor;
    padding: 20px;
    width: 60%;
    border-radius: 10px;

    p {
      padding-left: 24px;
    }
  }

  .detailPanelHolderButtons {
    width: 650px;
  }

  table {
    .tableRow {
      th {
        line-height: 1.5;
      }

      td {
        @media screen and (min-width: $sizeDesktopMin) and (max-width: 1200px) {
          font-size: 15px !important;
        }
      }
    }
  }

  .allAccountsContainer {
    padding-right: 10px;
    width: 180px;

    span {
      font-size: 13px;
      font-weight: 600 !important;
      text-transform: capitalize;

      &:last-child {
        margin-right: 10px;
      }

      &:not([class*='-checked']){

        > span > svg {
          color: #404143 !important;
        }

      }

    }

  }

}

.disputesInfoDialog {
  > div {

    > div {
      padding: 0 24px 24px !important;
      border-bottom: 0 !important;
    }
  }

  .disputesInfoTitle {
    @extend %subheading;
  }
}

.disputesErrorTitle {
  svg {
    width: 24px;
  }
}
