.customFilterDropdown {
  font-family: $primaryFontFamily !important;
  font-weight: 500;
  display: inline-block;

  > div {
    @extend %contentLabel;
  }

  div {
    div {
      font-family: $primaryFontFamily !important;
      font-weight: 500;

      button {
        div {
          svg {
            fill: $black !important;
          }
        }
      }

      span {
        @extend %contentLabel;
      }

      span:last-of-type {
        margin-right: 10px;
      }
    }
  }

  .hideMobile {
    @media screen and (max-width: $sizeMobileMax) {
      display: none !important;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }
}
