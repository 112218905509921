
.fullScreen {
  width: 100% !important;
}

.halfScreen {
  display: inline-block !important;
  width: 50% !important;
}

.quarterScreen {
  display: inline-block !important;
  width: 25% !important;
}

.paddingLeft {
  padding-left: 2%;
}

.threeQuarterScreen {
  display: inline-block !important;
  width: 75% !important;
}

.fifthScreen {
  display: inline-block !important;
  width: 20% !important;
}

.sixthScreen {
  width: 16.6666667% !important;
}

.tenthScreen {
  display: inline-block !important;
  width: 10% !important;
}

.rowMain {
  display: inline-block !important;
  width: 40% !important;
}

.rowThird {
  display: inline-block !important;
  width: 33.3% !important;
}

.rowSecondary {
  display: inline-block !important;
  width: 30% !important;
}

.rowTertiary {
  display: inline-block !important;
  width: 15% !important;
}

.tableRowTertiary {
  width: 15% !important;
}

.fullScreenRes {
  @media screen and (max-width: $sizeMobileMax) {
    width: 100% !important;
  }
}

.halfScreenRes {
  @media screen and (max-width: $sizeMobileMax) {
    width: 50% !important;
  }
}

.thirdScreen {
  width: 33.33% !important;
}
