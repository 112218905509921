.receipt {
  padding: 15px;
  width: 28vw;
  pointer-events: none;
  user-select: none;
  border-radius: 16px;

  .closeButton {
    width: 20px;
  }

  @media screen and (max-width: $sizeMobileMax) {
    width: auto;
    min-width: 200px;
  }

  @media screen and (min-width: $sizeDesktopMax) {
    width: 19vw;
  }

  .brand {
    display: flex;
    justify-content: center;
    margin-top: 45px;
    img {
      width: 156px;
      height: 20px;
    }
  }

  .social {
    text-align: center;

    ul {
      list-style: none;
      display: inline-block;
      margin: 0;
      padding-left: 0;

      li {
        display: inline-block;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }

        .icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-size: cover;
          border-radius: 50%;

          &Facebook {
            background-image: url('/images/social/facebook_circle.svg');
          }

          &Instagram {
            background-image: url('/images/social/instagram_circle.svg');
          }

          &Twitter {
            background-image: url('/images/social/twitter_circle.svg');
          }

          &Yelp {
            background-image: url('/images/social/yelp_circle.svg');
          }

        }
      }
    }
  }

  &Header {
    .logoImg {
      height: auto;
      width: auto;
      max-height: 100px;
      max-width: 150px;
      margin-bottom: 10px;
    }
  }

  &Header, .customer, .rating, .rewards {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2, h3 {
      margin: 0 0 10px 0;
    }

    h2 {
      color: $primaryTextColor;
      font-size: 18px;
    }

    h3 {
      color: $secondaryTextColor;
      font-size: 13px;
    }

  }

  .listing {
    padding: 15px 20px;
    list-style: none;
    background-color: $selectedRow;

    li {
      border-bottom: 1px solid $secondaryTextColor;
      margin-bottom: 10px;

      &:last-child {
        border-bottom: none;
      }
    }

    .row {
      display: block;
      margin-bottom: 5px;

      .item, .amount, .extra {
        display: inline-block;
      }

      .item {
        color: $primaryTextColor;
        font-size: 13px;
        width: 50%;
        font-weight: bold;
      }

      .amount {
        color: $secondaryTextColor;
        font-size: 13px;
        width: 50%;
        text-align: right;
        font-weight: bold;
      }

      .extra {
        color: $secondaryTextColor;
        font-size: 13px;
        width: 50%;
        font-weight: bold;
      }
    }
  }

  .customer {
    padding: 15px 0 25px 0;

    p {
      margin: 0 0 4px 0;
    }

    .name {
      color: $primaryTextColor;
      font-weight: bold;
    }

    .card, .auth {
      color: $secondaryTextColor;
    }
  }

  .customMessage {
    color: $secondaryTextColor;
    margin: 0 0 15px 0;
    text-align: center;
  }

  .rewards {
    margin-bottom: 20px;
    padding: 30px 0 22px 0;
    border-top: 1px solid $receiptSeparatorColor;
    border-bottom: 1px solid $receiptSeparatorColor;

    p {
      margin: 0;
      text-align: center;
    }

    &Icon {
      margin-bottom: 10px;
      svg {
        height: 30px;
        width: 30px;
      }
    }
    .heading {
      font-weight: bold;
      margin-bottom: 4px;
    }

    .text {
      font-weight: normal;
      margin-bottom: 10px;
      color: $secondaryTextColor;
    }

    .qr {
      height: 94px;
      width: 94px;
      background-image: url('/images/loyalty/qrcode.png');
      background-size: contain;
      margin-bottom: 8px;
    }

    .qrText {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .rating {
    padding-bottom: 15px;

    p {
      margin: 0 0 10px 0;
      color: $primaryTextColor;
      font-weight: bold;
    }

    .box {
      border: 1px solid $lineColor;
      border-radius: 5px;

      span {
        box-sizing: border-box;
        padding: 5px;
        border-right: 1px solid $lineColor;
        width: 35px;
        display: inline-block;
        height: 35px;

        &:last-child {
          border-right: none;
        }
      }
    }

  }
}

.receiptDialog {

  > div {
    border-radius: 15px !important;
    padding: 15px;

    div {
      border: 0 !important;
    }
  }

  .receiptTitle {
    @extend %subheading;
  }

}
