.invoiceDetail {

  .detailDescription {

    .detailTotals {
      padding: 0 15px;

      .cartDiscounts {
        margin-bottom: 1.6em;
      }

      .details {
        @extend %bodyText;
        display: flex;
        margin-top: 0;
        margin-bottom: 2px;

        &Total {
          font-family: $primaryFontFamily !important;
          font-weight: 600;
        }

        .number {
          margin-left: auto;
        }
      }
    }

    .addCustomerButton {
      margin-top: 20px;
    }

  }

  .sendInvoiceButton {
    top: 180px !important;
    position: relative !important;
  }

  .transactionHistory {
    font-size: 12px !important;
    font-weight: bold;
    padding-bottom: 48px;
  }
}
