.payInvoiceContainer {

  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  font-family: $primaryFontFamily;
  font-weight: 500;

  .invoiceHeader, .invoiceFooter {
    width: 100%;
    box-sizing: border-box;
  }

  .invoicePaymentError {
    margin-top: 5px;
    margin-bottom: 30px;
    font-size: 24px;
  }

  .invoiceHeader {
    height: 20vh;
    min-height: 10em;
    margin: 0;
    padding: 8px 0;

    .merchantLogo {
      float: left;

      img {
        height: 50px;
        object-fit: cover;
      }
    }

    .contact {
      margin-top: 12px;
      float: right;
      text-align: right;
      color: $secondaryTextColor;
      @extend %contentLabel;

      .businessName {
        @extend %bodyText;
      }
    }
  }

  .payNowLoyaltyStatusContainer {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    .payNowLoyaltyStatus {
      box-sizing: border-box;
      display: flex;
      padding: 32px;
      background-color: $paymentPageGrey;
      max-width: 500px;
      border-radius: 7px;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 25px;
      }

      .textContainer {
        text-align: left;

        .bold {
          font-weight: bold;
        }
      }
    }
  }


  .invoiceFooter {

    padding: 0;
    margin: 55px 0 0;
    text-align: center;
    display: block;

    .address {
      @extend %bodyText;
      font-family: $tertiaryFontFamily !important;
      color: $grayText !important;
      margin: 0 0 2.5em;
    }

    img {
      max-height: 100%;
      width: auto;
      display: block;
      margin: 0 auto;
    }

  }

  .content {

    box-sizing: border-box;
    height: auto;
    width: 100%;
    overflow: auto;
    padding: 55px 0;
    display: flex;

    .payInvoiceFormContainer, .orderSummaryContainer {
      padding-top: 40px;
    }

    .mainContent {
      width: 75%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .payInvoiceFormContainer {

      padding: 0 9%;
      box-sizing: border-box;

      .applePayContainer {
        position: absolute;
        right: 0;
        bottom: -65px;
      }

      .textField, .textFieldInfo, .textFieldHolder {

        div, label, span {
          font-family: $tertiaryFontFamily !important;
        }

      }

      .selectField {
        height: 48px;
      }

      .raisedButton button div div span, button button div div span {
        font-family: $tertiaryFontFamily !important;
      }

      .textFieldHolder {

        border: 1px solid $borderGray;
        border-radius: 4px;

      }

      .merchantLogo {

        width: 390px;
        margin: 0 auto 80px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: auto;
          height: auto;
          max-width: 150px;
          max-height: 150px;
        }

      }

      .formHeading {
        @extend %subheading;
        font-family: $tertiaryFontFamily !important;
      }

      .formItems {
        margin-top: 22px;
      }

      .textField {
        vertical-align: top;
        padding: 0 6px;
        margin-bottom: 18px;
        box-sizing: border-box;

        @media screen  and (max-width: $sizeMobileMax) {
          padding: 0 4px;
        }

      }

      .recurringInvoiceDetails {

        @extend %bodyText;
        font-family: $tertiaryFontFamily !important;

        .recurringInvoiceDetailsText {
          color: $secondaryTextColor;
        }

      }

      .invoiceFormContainer {

        min-height: 370px;
        text-align: center;
        position: relative;

        .paymentInformation {

          text-align: left;

          .flex {
            margin-bottom: 1em;
          }

        }

        .halfToFullScreen {
          width: 50%;

          @media screen and (max-width: 950px) {
            width: 100%;
          }
        }

        .quarterToHalfScreen {
          width: 25%;

          @media screen and (max-width: 785px) {
            width: 50%;
          }
        }

        .invoiceTitle, .invoiceSubtitle, .transactionAmount, .paymentInformation .formHeading, .recurringInvoiceDetails, .autopayEnrollment {
          margin-left: 6px;
        }

        .autopayEnrollment {
          margin-top: 0.5em;
          margin-bottom: 0.5em;

          .customToggle {

            margin: 0;

            label {
              font-family: $tertiaryFontFamily !important;
            }

          }

        }

        .invoiceTitle, .invoiceSubtitle {
          margin-bottom: 5px;
          text-align: center
        }

        .invoiceTitle {
          @extend %heading;
          font-family: $tertiaryFontFamily !important;
        }

        .invoiceSubtitle {
          @extend %bodyText;
          font-family: $tertiaryFontFamily !important;
          color: $grayText !important;
        }

        .viewOrderSummaryButton {
          margin-top: 40px !important;
          margin-bottom: 10px;
        }

        .transactionAmount {

          .formHeading {
            margin-bottom: 2px;
          }

        }

        .cardContainerWithVisibilityToggle {

          position: relative;
          display: inline-block;

          .cardImage {
            position: absolute;
            top: 36px;
            right: 54px;
          }

          .cardNumber {
            width: 100%;
          }

          #cardNumberVisibilityIcon {
            position: absolute !important;
            top: 25px;
            right: 6px;
            padding: 12px;
          }

        }

        .detailsLine {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          border-bottom: solid 1px rgb(224, 224, 224);
          line-height: 24px;
          font-size: 16px;
          margin-top: 14px;
          margin-bottom: 14px;
          color: $primaryTextColor;

          .invoiceNumber {
            float: left;
          }

          .dueDate {
            float: right;
            font-weight: 600
          }

          .name {
            float: left;
            margin-right: 8px;
            word-break: break-word;
          }

          .email {
            float: right;
            word-break: break-word;
            text-align: right;
          }
        }
      }

      .submitButton {
        width: auto;
        margin: 15px 0 0 6px;
      }
    }

    .orderSummaryContainer {

      background-color: #F6F8FA;
      border-radius: 7px;
      border: 1px solid $borderGray;
      color: $primaryTextColor;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-right: 5%;
      width: 25%;

      .orderSummaryItemsContainer, .orderSummaryTotalsContainer {

        padding-left: 10%;
        padding-right: 10%;
        box-sizing: border-box;

        @media screen and (max-width: 1024px) {
          padding: 0 5%;
        }

        .orderRow {

          @extend %bodyText;
          font-family: $tertiaryFontFamily !important;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          @media screen and (max-width: 800px) {
            font-size: 14px !important;
          }

          .orderRowInfo {
            padding-right: 8px;
          }

          .orderRowName {
            font-weight: 600;
          }

          .orderRowDiscount, .orderModifiersContainer {
            @extend %contentLabel;
            font-family: $tertiaryFontFamily !important;
            color: $grayText !important;
          }

          .orderRowAmounts {
            text-align: right;
          }

          .infoBold {
            font-weight: 600;
          }

          &.cartDiscount {
            div {
              color: $grayText !important;
            }
          }

          &.subtotal, &.tax, &.tip {
            color: $grayText !important;
            padding-bottom: 8px;
          }

          &.subtotal {
            padding: 28px 0 8px;
          }

          &.total {
            margin-top: 27px;
            border-top: 1px solid $borderGray;
            padding-top: 27px;
          }

        }

        .loyaltyButtonContainer {
          margin-top: 15px;
          display: flex !important;
          justify-content: center;

          .loyaltyButton {
            column-gap: 10px;
          }
        }
      }

      .orderSummaryItemsContainer {
        border-bottom: none;

        .orderSummaryHeaderContainer {

          min-height: 5em;
          text-align: center;

          .orderSummaryHeader {
            @extend %subheading;
            font-family: $tertiaryFontFamily !important;
            margin-bottom: 1em;
          }

          .orderSummaryDetails {
            @extend %bodyText;
            font-family: $tertiaryFontFamily !important;
            color: $grayText !important;
            margin-bottom: 1.3em;
          }

        }

        .orderRow {
          padding: 28px 0 27px;
          border-bottom: 1px solid $borderGray;
        }

      }

      .orderSummaryTotalsContainer {

        border-top: none;
        padding-top: 0;
        padding-bottom: 1.6em;

        .cartDiscountRows {

          .orderRow {
            padding: 28px 0 27px;
            border-bottom: 1px solid #cccccc6a;
          }

        }

        .orderSummaryTotals {
          height: 100%;
        }

        .orderRow {
          padding-bottom: 5px;
        }

      }
    }

  }

  @media screen and (orientation: landscape) and (max-height: 500px) {

    .content {
      height: auto;
      overflow: auto;
    }

  }

  //Mobile adjustments for .content container
  @media screen and (max-width: $sizeMobileMax) {

    .content {

      height: auto;
      overflow: auto;

      .mainContent {

        width: 100%;

        .payInvoiceFormContainer {

          .merchantLogo {
            width: 100%;
            margin-bottom: 50px;
          }

          .formItems {
            position: relative;
          }

          .cardBillingStreet {
            width: 100% !important;
          }

          .submitButton {
            margin-top: 20px;
            margin-bottom: 60px;
          }

          .cardZip, .cardZipPlus4 {
            width: 50% !important;
          }

          .applePayContainer {
            left: 6px;
            right: initial;
            bottom: -127px;
          }

        }

        .invoiceFooter {

          .address {
            margin-bottom: 1em;
          }

          img {
            margin-bottom: 2em;
          }
        }

      }

      .orderSummaryContainer {
        display: none;
      }

    }

  }
  //End of mobile adjustments for content

}

.payNowContainer {

  .isvInvoiceHeader {
    margin: 0;
    padding: 8px 30px;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .05);
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #dee2e6!important;
  }

  .viewOrderSummaryButton {
    background-color: $paymentHubsColor !important;
    span {
      text-transform: capitalize;
    }
  }

  .formItems {
    .flex {
      flex-wrap: wrap;
    }
  }

}
.orderSummaryDialog {

  > div {
    background-color: #F6F8FA !important;
  }

  .dialogTitle {
    display: none;
  }

  .dialogBody {
    max-height: none !important;
  }

  .orderSummaryHeader {
    @extend %subheading;
    font-family: $tertiaryFontFamily !important;
    margin-top: 2em;
    margin-bottom: 1em;
    text-align: center;
  }

  .orderSummaryDetails {
    @extend %bodyText;
    font-family: $tertiaryFontFamily !important;
    color: $grayText !important;
    margin-bottom: 0;
    text-align: center;
  }

  .orderRow {

    @extend %bodyText;
    font-family: $tertiaryFontFamily !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    .orderRowInfo {
      padding-right: 15px;
    }

    .orderRowName {
      font-weight: 600;
    }

    .orderRowDiscount, .orderModifiersContainer {
      @extend %contentLabel;
      font-family: $tertiaryFontFamily !important;
      color: $grayText !important;
    }

    .orderRowAmounts {
      text-align: right;
    }

    .infoBold {
      font-weight: 600;
    }

    &.cartDiscount {
      div {
        color: $grayText !important;
      }
    }

    &.subtotal, &.tax {
      color: $grayText !important;
    }
    &.subtotal {
      padding: 5px 0 8px;
    }

    &.total {
      margin-top: 22px;
      border-top: 1px solid $borderGray;
      padding-top: 22px;
    }

  }

  .orderSummaryItemsContainer {

    padding-bottom: 16px;

    .orderRow {
      padding: 28px 0 25px;
      border-bottom: 1px solid #cccccc96;
    }
  }
}

.viewOrderSummaryButton {
  position: relative;
  top: -9px;
  display: none !important;

  @media screen and (max-width: $sizeMobileMax) {
    display: inline-block !important;
  }

  span {
    @extend %heading2;
    font-family: $tertiaryFontFamily !important;
    padding: 0 !important;
  }
}

.customerRatingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #EBEBED;
  height: 100vh;

  .logo, .content, .footer {
    margin: 15px;
    background-color: #FFFFFF;
    width: 60%;

    @media screen and (max-width: $sizeMobileMax) {
      width: 100%;
    }

    & > * {
      text-align: center;
    }
  }

  .ratingButtons {
    margin: 15px 0;
  }

  .logo {
    text-align: center;

    svg {
      height: 60px !important;
      width: 60px !important;
    }
  }

  .logo, .content {
    padding: 30px 0;
  }

  .logo, .footer {
    height: 50px;
  }

  .content {
    text-align: center;
    margin: 0;

    p.message {
      @extend %body3;
      display: block;
      margin: 20px;
      line-height: 20px;
    }

    svg {
      width: 50px !important;
      height: 50px !important;
    }

    div.ratingHeading {
      @extend %heading1;
      font-size: 52px !important;
      margin-top: 15px;
      margin-bottom: 2px;
      padding-top: 0;
    }

  }

  .footer {
    background-color: $payAnywhereColor;
    padding: 0;
    overflow: hidden;
    height: 100px;
    position: relative;
  }

  .rating {
    button div svg {
      width: 100px;
      height: 100px;
    }
  }

  .submitButton {
    margin: 0 auto;
  }

  svg.itemsIcon {
    height: 100px;
    width: 100px;
  }

  img.poweredBy {
    @extend %centerWithin;
    display: block;
  }

  svg.payAnywhereTiledImage {
    position: relative;
    width: 800px;
    top: -55px;
    opacity: 0.35;
  }

}
