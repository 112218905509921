.today {
  height: 100vh;
}

.today {

  .flip {
    svg {
      transform: scaleX(-1);
    }
  }

  .trendDown {

    .percentage {
      color: $paGradientTwo !important;
    }
  }

  .trendUp {

    .percentage {
      color: $green !important;
    }
  }

  ol, ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .todayLink {
    display: flex;
    align-items: center;
    justify-content: center;
    @extend %secondaryFontFamilySemiBold;
    height: 35px;
    text-transform: uppercase;
    color: $backgroundColor;
    background-color: $payAnywhereColor;
    text-decoration: none;
    z-index: 1;
    align-self: flex-start;

    .textLink {
      padding: 0 20px;
    }

    .iconLink {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $payAnywhereAlternativeColor;
      padding: 0 5px;
      height: 35px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:last-child {

      .statisticsContainer {
        border-bottom: 0
      }
    }
  }

  .list {
    max-height: 366px;
    min-height: 366px;
    box-sizing: border-box;

    .noData {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .message {
        font-family: $primaryFontFamily !important;
        font-weight: 500;
        font-size: 55px;
        color: $phoneSwipeColorAlternativeColor;
        margin: 0;
      }
    }

    .noDataMessage {
      padding: 25% 10px;

      .iconContainer {
        display: inline-block;
        vertical-align: top;
        padding-top: 5px;
        width: 12%;

        .icon {
          background-color: $backgroundColor;

          svg {
            width: 18px !important;
          }
        }
      }

      .noDataDescription {
        display: inline-block;
        width: 88%;

        .noDataHeader {
          font-family: $primaryFontFamily !important;
          font-weight: 600;
          font-size: 29px !important;
          padding: 0 0 10px;
        }

        .noDataText {
          @extend %bodyText;
          padding: 0;

          @media screen and (max-width: $sizeMobileMax) {
            margin-right: 0;
          }
        }

        .linkLike {
          font-family: $primaryFontFamily !important;
          font-size: 13px !important;
          font-weight: bold;
        }
      }

    }
  }

  .chartContainer {
    padding: 25px 55px;

    @media screen and (max-width: $sizeMobileMax) {
      padding-right: 10px;
      padding-left: 10px;
    }

    .heading {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: $sizeMobileMax) {
        flex-direction: column-reverse;
      }

      .title {
        @extend %subheading;
        margin: 0;
        width: 50%;

        @media screen and (max-width: $sizeMobileMax) {
          width: 100%;
          margin-top: 10px;
        }
      }

      .chips {
        text-align: right;
        width: 50%;

        @media screen and (max-width: $sizeMobileMax) {
          width: 100%;
          display: flex;
        }

        div {
          @media screen and (max-width: $sizeMobileMax) {
            margin-left: 0 !important;
          }
        }
      }
    }

    .date {
      @extend %contentLabel;
      margin-top: 8px;
    }

    .chart {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: $sizeDesktopMin) {
        flex-direction: column;
        align-items: center;
      }

      .salesBarChart {
        width: 75%;
        padding: 0 20px;

        @media screen and (max-width: $sizeDesktopMin) {
          padding: 0;
          margin: 20px 0;
        }

        canvas {
          @media screen and (max-width: $sizeDesktopMin) {
            width: 100% !important;
            margin: 0;
          }
        }

        .noDataMessage {
          @extend %bodyText;
        }
      }

      .summary {
        width: 25%;

        @media screen and (max-width: $sizeDesktopMin) {
          width: 80%;
        }

      }
    }
  }

  .gross {

    .grossRow {

      &:last-child {

        .statisticsContainer {
          border-bottom: 0;
        }
      }

      .iconContainer {
        display: flex;
        justify-content: center;

        .icon {
          padding-top: 16px;

          svg {
            fill: $iconColor !important;
            width: 18px !important;
          }
        }
      }

      .statisticsContainer {
        width: 75%;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        padding: 15px 0 15px 20px;
        border-bottom: 1px solid $cardBorderColor;

        .statistics {
          display: flex;
          flex-direction: row;

          .graph {

            svg {
              width: 18px !important;
              transform: translateY(-4px);
            }
          }

          .percentage {
            font-family: $primaryFontFamily;
            font-weight: 500;
            font-size: 10px !important;
            margin-left: 5px;
          }
        }

        .title {
          @extend %contentLabel;
          margin: 5px 0 5px 0;
          font-weight: 100 !important;
        }

        .amount {
          @extend %subheading;
          margin: 0 0 15px;
        }
      }
    }
  }

  .utilityBar {
    @extend %pageBar;
    justify-content: space-between;
    border-bottom: none;

    .menu {
      margin-left: 35px;

      svg {
        fill: $iconColor !important;
      }

      &:last-child {
        margin-left: 0;
      }
    }

    .toolbar {
      border-bottom: none;

      .logoBar {
        border-right: none;
      }
    }
  }

  .gridContainer {
    height: 100%;
    padding: 40px;
    margin: 0 auto;

    @media screen and (max-width: $sizeDesktopMin) {
      padding: 20px;
    }

    .grid {
      margin: 0 auto;
      display: inline-block;
      text-align: center;

      @media screen and (max-width: $sizeDesktopMin) {
        display: block;
      }

      .col {
        display: inline-block;
        width: 390px;
        height: 410px;
        margin: 16.5px 16.5px 42px 16.5px;
        vertical-align: top;

        @media screen and (max-width: $sizeDesktopMin) {
          width: 100%;
          margin: 10px 0;
        }



        &Load {
          position: relative;
          border-radius: 20px;
          background-color: rgba(0, 0, 0, 0.541176);
          transform: translateZ(0px);
          transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

          .spinner {
            position: absolute !important;
            top: 40%;
            left: 40%;
          }
        }

        &Marketing {

          .marketing {
            height: 366px;
            border-radius: 20px;
            border: 1px solid $cardBorderColor;
            box-shadow: 2px 2px 4px 1px $cardBorderColor;
            overflow: hidden;
            position: relative;
            background-image: url('/images/payanywhere/today_ad.png');
            background-size: cover;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, .6);
            }
          }
        }

        &Tips, &Feedback {

          .tips {
            height: 366px;
            border-radius: 20px;
            border: 1px solid $cardBorderColor;
            box-shadow: 2px 2px 4px 1px $cardBorderColor;
            overflow: hidden;
            position: relative;
            background-image: url('/images/payanywhere/today_tips_pa_terminal.png');
            background-size: cover;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, .35);
            }
          }
        }

        .titleContainer {
          display: inline-block;
          flex-direction: row;
          align-items: center;
          margin-bottom: 10px;
          height: 27px !important;
          width: 100%;

          .title {
            @extend %contentLabel;
            font-family: $primaryFontFamily !important;
            font-weight: bold;
            float: left;
            margin-top: 5px;

            .redirect {
              @extend %contentLabel;
              text-decoration: none;
              color: $payAnywhereColor !important;

              &:visited {
                color: $payAnywhereColor;
              }

              svg {
                width: 13px !important;
                fill: $payAnywhereColor !important;
                margin-left: 8px;
                transform: translateY(2px);
              }
            }
          }

          .goTo {
            float: right;
            margin-right: 26px;

            svg {
              fill: $primaryTextColor;
              width: 10px;
            }

            button {
              border-radius: 50%;
              border: 1px solid $cardBorderColor !important;
              box-shadow: 2px 2px 4px 1px $cardBorderColor !important;
              padding: 0 !important;
              height: 24px !important;
              width: 24px !important;
              margin: 3px 0 0 3px !important;
            }
          }

          .linkToViewOpenBatch {
            margin-left: auto;
          }
        }
      }

      .batchInfoList {

        .batchRow {
          height: 107px;
          padding: 8px 10px;
          border-bottom: 1px solid $cardBorderColor;

          .batchInfoNotice {
            display: inline-block;
            margin: 35px 0 30px 0;
            font-size: 12px;
            font-family: $primaryFontFamily;
            font-weight: 500;

            @media screen and (max-width: $sizeMobileMax) {
              font-size: 11px;
            }

            .batchInfoIcon {
              float: left;
              justify-content: center;
              width: 12%;

              svg {
                fill: $primaryTextColor !important;
                width: 18px !important;
              }
            }

            .batchInfoIconText {
              float: right;
              width: 88%;
              text-align: left;
            }
          }

          .batchLabelContainer {
            display: inline-block;
            width: 100%;
            font-family: $primaryFontFamily;
            font-weight: 500;
            font-size: 12px !important;
            margin: 29px 0 30px 0;
            padding: 0 15px;

            .batchLabel {
              float: left;
            }

            .batchValue {
              float: right;
            }
          }

        }
      }

      .volume {

        .volumeRow {
          height: 107px;
          padding: 8px 10px;
          border-bottom: 1px solid $cardBorderColor;

          &:last-child {
            border-bottom: 0;
          }

          .iconContainer {
            width: 12%;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {

              svg {
                height: 18px !important;
                width: 18px !important;
                fill: $iconColor !important;
              }
            }

            .tips {
              fill: $iconColor;

              svg {
                height: 18px;
                width: 18px;
              }
            }

            .category {
              background-color: $inventoryTrendCategory;
              fill: $backgroundColor;

              svg {
                height: 24px;
                width: 24px;
              }
            }

            .noCategory {
              background-color: $subNavBackgroundColor;
              fill: $iconColor;

              svg {
                height: 24px;
                width: 24px;
              }
            }

            .discount {
              background-color: $inventoryTrendDiscount;
              transform: scaleX(-1);
            }

            .noDiscount {
              background-color: $subNavBackgroundColor;
              transform: scaleX(-1);
            }
          }

          .statisticsContainer {
            border-bottom: 0;
            margin: 15px 0 10px 0;
            text-align: justify;

            .title {
              @extend %columnLabel;
              font-family: $primaryFontFamily;
              font-weight: 500;
              margin-bottom: 5px;
            }

            .amount {
              @extend %columnLabel;
              font-family: $primaryFontFamily;
              font-weight: 500;
              margin: 10px 0 5px;
            }

            .statistics {

              .graph {

                svg {
                  width: 18px !important;
                  transform: translateY(5px);
                }
              }

              .percentage {
                @extend %columnLabel;
                margin-left: 5px;
              }
            }
          }
        }
      }

      .deposits {
        background-color: $payAnywhereColor !important;
        font-family: $primaryFontFamily;
        font-weight: 500;

        .depositsRow {
          height: 107px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 26.5px;
          color: $backgroundColor;
          text-align: left;

          .batchReminderRow {
            display: inline-block;
            width: 100%;

            .batchReminderLeft {
              @extend %secondaryFontFamilySemiBold;
              width: 40px;
              display: inline-block;

              svg {
                fill: $backgroundColor !important;
                transform: translateY(5px);
              }
            }

            .batchReminderHeaderText {
              font-size: 18px;
              font-weight: 400;
              display: inline-block;
            }

            .batchReminderText {
              display: inline-block;
              width: 250px;
            }
          }

          .icon {
            background-color: $backgroundColor;
            width: 35px;
            border-radius: 15%;

            svg {
              width: 35px !important;
              height: 35px !important;
            }
          }

          .date {
            font-size: 18px;
            font-weight: 400;
            margin: 5px 0 0 0;
          }

          .volume {
            @extend %heading1a;
            margin: 0;
            color: $backgroundColor;
            font-size: 50px !important;
            font-weight: 800;
          }

          .statisticsContainer {
            .statistics {

              .percentage {
                color: $backgroundColor;
              }

              .graph {
                svg {
                  width: 18px;
                  transform: translateY(5px);
                }
              }
            }
          }
        }

        .noData {

          .message {
            font-size: 35px;
            color: $backgroundColor;
          }
        }
      }

      .employees {
        overflow: auto !important;

        .employeesRow {
          height: 70px;
          padding: 17px 25px 5px 15px;
          border-bottom: 1px solid $cardBorderColor;

          &:last-child {
            border-bottom: 0;
          }

          .employeesIconContainer {
            width: 12%;
            margin-top: 15px;
          }

          .dataContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 10px;

            .data {
              display: flex;
              flex-direction: row;

              .right {
                margin-left: auto;

                svg {
                  width: 18px !important;
                }
              }

              .name {
                @extend %contentLabel;
                font-family: $primaryFontFamily;
                font-weight: 500;
                text-align: left;

                @media screen and (max-width: $sizeMobileMax) {
                  font-size: 14px;
                }

              }

              .specifics {
                @extend %columnLabel;
                font-family: $primaryFontFamily;
                font-weight: normal;
                text-transform: capitalize;

                @media screen and (max-width: $sizeMobileMax) {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }

      .customers {
        overflow: auto !important;

        .customersRow {
          height: 69px;
          padding: 2px 25px;
          border-bottom: 1px solid $cardBorderColor;

          @media screen and (max-width: $sizeMobileMax) {
            padding: 2px 15px;
          }

          .dataContainer {
            margin: 25px 0;
            width: 100%;

            .data {
              @extend %columnLabel;
              display: inline-block;
              width: 100%;

              @media screen and (max-width: $sizeMobileMax) {
                font-size: 11px !important;
              }

              .name {
                text-align: left;
                float: left;
                width: 70%;
              }

              .right {
                text-align: right;
                float: right;
                width: 30%;
              }
            }
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }

      .trends {

        .trendsRow {
          height: 107px;
          padding: 8px 22px;
          border-bottom: 1px solid $cardBorderColor;

          @media screen and (max-width: $sizeMobileMax) {
            padding: 20px 15px;
            height: 85px;
          }

          &:last-child {
            border-bottom: 0;
          }

          .iconContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;

            @media screen and (max-width: $sizeMobileMax) {
              margin-right: 10px;
            }

            .icon {

              svg {
                height: 22px !important;
                width: 22px !important;
                fill: $iconColor !important;
              }
            }
          }

          .statisticsContainer {
            border-bottom: 0;
            margin: 35px 0 30px 0;
            text-align: justify;
            width: 100%;

            .title {
              @extend %columnLabel;
              font-family: $primaryFontFamily;
              font-weight: 500;
              margin: 0;
              float: left;
              width: 55%;
            }

            .amount {
              @extend %columnLabel;
              font-family: $primaryFontFamily;
              font-weight: 500;
              float: right;
              text-align: right;
              margin: 0;
              width: 25%;
            }

            .rating {
              float: right;
              width: 25%;
              text-align: right;

              button {
                height: 16px !important;
                margin-right: 0 !important;
              }
              .stars {

                @media screen and (max-width: $sizeMobileMax) {
                  width: 12px !important;
                }

                svg {
                  width: 18px !important;

                  @media screen and (max-width: $sizeMobileMax) {
                    width: 11px !important;
                    height: 14px !important;
                  }
                }
              }
            }

            .statistics {

              .graph {

                svg {
                  width: 18px !important;
                  transform: translateY(5px);
                }
              }

              .percentage {
                font-family: $primaryFontFamily;
                font-weight: 600;
                font-size: 11px !important;
                margin-left: 5px;
              }
            }
          }
        }
      }

      .inventoryTrends {

        .inventoryRow {
          padding: 8px 10px;
          border-bottom: 1px solid $cardBorderColor;

          &:last-child {
            border-bottom: 0;
          }

          .iconContainer {
            width: 12%;
            display: flex;
            justify-content: center;
            align-items: center;

            .icon {

              svg {
                height: 18px !important;
                width: 18px !important;
                fill: $iconColor !important;
              }
            }

            .category {
              svg {
                height: 24px;
                width: 24px;
              }
            }

            .noCategory {
              background-color: $backgroundColor;
              fill: $iconColor;

              svg {
                height: 24px;
                width: 24px;
              }
            }

            .discount {
              background-color: $inventoryTrendDiscount;
              transform: scaleX(-1);
            }

            .noDiscount {
              background-color: $backgroundColor;
              transform: scaleX(-1);

              svg {
                height: 18px;
                width: 18px;
                fill: $iconColor;
              }
            }
          }

          .statisticsContainer {
            border-bottom: 0;
            margin: 20px 0 10px 0;
            text-align: justify;

            .title {
              @extend %columnLabel;
              margin: 0 0 10px 0;
            }

            .amount {
              @extend %contentLabel;
              margin: 0;
            }

            .statistics {
              margin-top: 10px;

              .graph {

                svg {
                  width: 18px !important;
                  transform: translateY(5px);
                }
              }

              .percentage {
                font-family: $primaryFontFamily;
                font-weight: 600;
                font-size: 11px !important;
                margin-left: 5px;
              }
            }
          }
        }
      }

      .pci {
        position: relative;
        background-image: url('/images/paymenthubs/today_pci.png');
        background-size: cover;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, .6);
        }

        .pciLogo {
          position: relative;
          display: block;
          width: 163px;
          height: 45px;
          margin-top: 30px;
          z-index: 1;
        }

        .pciRow {
          flex-direction: column;
          padding-left: 30px;
          height: 345px;
          z-index: 1;
          text-align: left;

          .heading {
            color: $backgroundColor;
            font-family: $primaryFontFamily;
            font-weight: 600;
            font-size: 29px !important;
            padding-right: 15%;
            margin: 8% 0 0 0;
            z-index: 1;

            @media screen and (max-width: $sizeMobileMax) {
              margin-top: 5%;
              font-size: 23px !important;
            }

            span {
              white-space: nowrap;
            }
          }

          .todayLink {
            margin: 27px 0 0 0;
          }
        }
      }

      .marketing {

        .marketingRow {
          flex-direction: column;
          padding-left: 30px;
          height: 345px;
          z-index: 1;
          text-align: left;

          .heading {
            color: $backgroundColor;
            font-family: $primaryFontFamily;
            font-weight: 600;
            font-size: 29px !important;
            padding-right: 15%;
            margin: 15% 0 0 0;
            z-index: 1;

            @media screen and (max-width: $sizeMobileMax) {
              margin-top: 10%;
              font-size: 27px !important;
            }

            span {
              white-space: nowrap;
            }
          }

          .todayLink {
            margin: 55px 0 0 0;
          }

          .linkAsButton {
            box-shadow: none !important;
            align-self: flex-start;
            margin-top: 55px;
          }
        }
      }

      .items {
        overflow: auto !important;

        .itemsRow {
          height: 55px;
          padding: 17px 25px 20px 20px;
          border-bottom: 1px solid $cardBorderColor;

          &:last-child {
            border-bottom: 0;
          }

          .itemsIconContainer {
            width: 16%;

            .itemThumbnail {

              .itemThumb {
                border-radius: 20% !important;
                font-family: $primaryFontFamily !important;
                font-weight: 500;
                font-size: 13px !important;
                background: linear-gradient(to top right, $paGradientOne, $paGradientTwo) !important;
                width: 60px !important;
                height: 60px !important;
              }
            }
          }

          .dataContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 15px;

            .data {
              display: flex;
              flex-direction: row;

              .right {
                margin-left: auto;

                svg {
                  width: 18px !important;
                }
              }

              .name {
                @extend %contentLabel;
                font-family: $primaryFontFamily;
                font-weight: 500;
                text-align: left;

                @media screen and (max-width: $sizeMobileMax) {
                  font-size: 14px;
                }

              }

              .specifics {
                @extend %columnLabel;
                font-family: $primaryFontFamily;
                font-weight: normal;
                text-transform: capitalize;

                @media screen and (max-width: $sizeMobileMax) {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }

      .stock {
        overflow: auto;

        .stockRow {
          padding: 21px 22px;
          border-bottom: 1px solid $cardBorderColor;

          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }

          .stockIconContainer {
            width: 25%;

            .itemThumbnail {
              display: flex !important;
            }
          }

          .dataContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 10px;

            .data {
              display: flex;
              flex-direction: row;

              .right {
                margin-left: auto;

                svg {
                  width: 18px !important;
                }
              }

              .name {
                @media screen and (max-width: $sizeMobileMax) {
                  font-size: 14px;
                }
              }

              .ellipsis {
                width: 190px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                @media screen and (max-width: $sizeMobileMax) {
                  width: 105px;
                }
              }

              .name {
                @extend %contentLabel;
                font-family: $primaryFontFamily;
                font-weight: 500;
                text-align: left;

                @media screen and (max-width: $sizeMobileMax) {
                  font-size: 14px;
                }

              }

              .specifics {
                @extend %columnLabel;
                font-family: $primaryFontFamily;
                font-weight: normal;
                text-transform: capitalize;

                @media screen and (max-width: $sizeMobileMax) {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }

      .news {

        background-position: 170px 190px !important;
        background-size: 170px 170px !important;
        background-repeat: no-repeat !important;

        &:before {
          background-color: transparent !important;
        }

        .newsRow {
          flex-direction: column;
          padding-left: 30px;
          height: 345px;
          text-align: left;

          .linkAsButton {
            box-shadow: none !important;
            align-self: flex-start;
            margin-top: 55px;
          }

          .linkHolder {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            width: 100%;
          }

          .reverseLinkAsButton {
            box-shadow: none !important;
            align-self: flex-start;
            margin-bottom: 15px;
            height: 15px !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
          }

          .heading {
            padding-top: 15px;
            color: $black;
            @extend %bodyTextSubHeading;
            padding-right: 10%;
            margin: 10% 0 0 0;
            padding-bottom: 30px;

            @media screen and (max-width: $sizeMobileMax) {
              margin-top: 20px;
              font-size: 23px !important;
            }

            span {
              white-space: nowrap;
            }
          }

          .text {
            @extend %bodyText;
            color: $backgroundColor !important;
            padding-right: 15%;
            margin: 4% 0 0 0;
            font-weight: 325;
            z-index: 1;

            @media screen and (max-width: $sizeMobileMax) {
              font-size: 14px !important;
              padding-right: 3%;
            }

            span {
              white-space: nowrap;
            }
          }

          .todayLink {
            margin: 40px 0 0 0;

            @media screen and (max-width: $sizeMobileMax) {
              margin: 15px 0 0 0;
            }
          }
        }
      }

      .tips {

        .tipsRow, .feedbackRow {
          flex-direction: column;
          padding-left: 30px;
          height: 345px;
          text-align: left;

          .linkAsButton {
            box-shadow: none !important;
            align-self: flex-start;
            margin-top: 55px;
          }

          .reverseLinkAsButton {
            box-shadow: none !important;
            align-self: flex-start;
            margin-bottom: 15px;
            height: 15px !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
          }

          .heading {
            padding-top: 15px;
            color: $backgroundColor;
            font-family: $primaryFontFamily;
            font-weight: 600;
            font-size: 29px !important;
            padding-right: 10%;
            margin: 1% 0 0 0;
            z-index: 1;

            @media screen and (max-width: $sizeMobileMax) {
              margin-top: 20px;
              font-size: 23px !important;
            }

            span {
              white-space: nowrap;
            }
          }

          .text {
            @extend %bodyText;
            color: $backgroundColor !important;
            padding-right: 15%;
            margin: 4% 0 0 0;
            font-weight: 325;
            z-index: 1;

            @media screen and (max-width: $sizeMobileMax) {
              font-size: 14px !important;
              padding-right: 3%;
            }

            span {
              white-space: nowrap;
            }
          }

          .todayLink {
            margin: 40px 0 0 0;

            @media screen and (max-width: $sizeMobileMax) {
              margin: 15px 0 0 0;
            }
          }
        }
      }

      .noDecoration {
        text-decoration: none !important;
      }

      .feature {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 35px;
        padding-top: 65px;


        h3 {
          color: $black;
          @extend %bodyTextSubHeading;
          margin-top: 36px;

          span {
            white-space: nowrap;
          }
        }

        p {
          color: $gray;
          text-align: center;
          line-height: 20px;
          font-size: 15px;
        }
      }

      .northFeature {
        padding: 0 35px;
        background-color: #222529 !important;

        div {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
        }

        #icon {
          flex: 4;
          align-items: center;
          justify-content: center;
        }

        h3 {
          color: $white;
          font-size: 18px !important;
          margin: 0px;
          flex: 1;
          padding-top: 28px;

          span {
            white-space: nowrap;
          }
        }

        p {
          color: $white;
          flex: 1;
          margin-top: 0px !important;
          text-align: center;
          line-height: 20px;
          font-size: 14px;
        }
      }

      .DAVObody {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 33px 30px 0;

        h3{
          font-family: $primaryFontFamily;
          font-weight: 600;
          font-size: 22px !important;
          margin-bottom: 17px;
          margin-top: 26px;
        }

        .disclaimer{
          font-size: 10px !important;
          margin-top: 10px;
        }
      }

      .statementTips {
        background-image: url('/images/paymenthubs/today_tips_ph_statement.png');
        background-position: 140px 190px !important;
        background-size: 200px 150px !important;
        background-repeat: no-repeat !important;

        .statementTipsRow {
          flex-direction: column;
          padding-left: 30px;
          height: 345px;
          text-align: left;

          .linkAsButton {
            box-shadow: none !important;
            align-self: flex-start;
            margin-top: 55px;
          }

          .reverseLinkAsButton {
            box-shadow: none !important;
            align-self: flex-start;
            margin-bottom: 15px;
            height: 15px !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
          }

          .heading {
            padding-top: 15px;
            color: $black;
            font-size: 23px !important;
            padding-right: 10%;
            margin: 10% 0 0 0;
            padding-bottom: 30px;

            @media screen and (max-width: $sizeMobileMax) {
              margin-top: 15%;
            }

            span {
              white-space: nowrap;
            }
          }

          .todayLink {
            margin: 40px 0 0 0;

            @media screen and (max-width: $sizeMobileMax) {
              margin: 15px 0 0 0;
            }
          }
        }

      }

      .invoices {

        .invoicesRow {
          height: 70px;
          padding: 17px 25px 5px 20px;
          border-bottom: 1px solid $cardBorderColor;

          &:last-child {
            border-bottom: 0;
          }

          .invoicesIconContainer {
            width: 12%;
            margin-top: 18px;

            .icon {

              svg {
                width: 18px !important;
              }
            }
          }

          .dataContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 10px;

            .data {
              display: flex;
              flex-direction: row;

              .right {
                margin-left: auto;

                svg {
                  width: 18px !important;
                }
              }

              .name {
                @extend %contentLabel;
                font-family: $primaryFontFamily;
                font-weight: 500;
                text-align: left;

                @media screen and (max-width: $sizeMobileMax) {
                  font-size: 14px;
                }

              }

              .specifics {
                @extend %columnLabel;
                font-family: $primaryFontFamily;
                font-weight: normal;
                text-transform: capitalize;

                @media screen and (max-width: $sizeMobileMax) {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }

      .disputes {
        overflow: auto !important;

        .disputesRow {
          height: 50px;
          padding: 17px 25px 5px 5px;
          border-bottom: 1px solid $cardBorderColor;

          &:last-child {
            border-bottom: 0;
          }

          .disputesIconContainer {
            width: 12%;
            position: relative;

            .circle {
              width: 10px;
              height: 10px;
              margin-top: 10px;
              border-radius: 50%;
              position: absolute;
            }
          }

          .dataContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 10px;

            .data {
              display: flex;
              flex-direction: row;

              .right {
                margin-left: auto;

                svg {
                  width: 18px !important;
                }
              }

              .name {
                @extend %contentLabel;
                font-family: $primaryFontFamily;
                font-weight: 500;
                text-align: left;

                @media screen and (max-width: $sizeMobileMax) {
                  font-size: 14px;
                }

              }

              .specifics {
                @extend %columnLabel;
                font-family: $primaryFontFamily;
                font-weight: normal;
                text-transform: capitalize;

                @media screen and (max-width: $sizeMobileMax) {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }

      .statements {

        &Notice {
          padding: 0 9px;
          text-align: left;

          .primaryText {
            display: flex;
            align-items: center;
            color: $backgroundColor;
            font-size: 16px;
          }

          .secondaryText {
            color: $backgroundColor2;
            font-size: 14px;
            padding: 0 0 17px 55px;
            margin-top: -10px;
            font-weight: 200;
          }
        }

        .statementRow {
          padding: 22px;
          border-bottom: 1px solid $cardBorderColor;

          &:last-child {
            border-bottom: 0;
          }

          .dataContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 12px 0;

            .data {
              display: flex;
              flex-direction: row;

              .right {
                margin-left: auto;

                svg {
                  width: 28px !important;
                  fill: $primaryTextColor !important;
                  transform: rotate(90deg);
                  cursor: pointer;
                }
              }

              .name {
                @extend %contentLabel;
                font-family: $primaryFontFamily;
                font-weight: 500;
                text-align: left;

                @media screen and (max-width: $sizeMobileMax) {
                  font-size: 14px;
                }

              }

              .specifics {
                @extend %columnLabel;
                font-family: $primaryFontFamily;
                font-weight: 500;
                text-transform: uppercase;

                @media screen and (max-width: $sizeMobileMax) {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }

      .epxUpdateTerms {
        .widgetBody {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;

          h3 {
            font-weight: 600;
            font-size: 22px;
            padding: 0 45px;
          }

          .reverseLinkAsButton {
            box-shadow: none !important;
            border-radius: 0 !important;
            height: 30px !important;
          }
        }
      }

      .reputationCard {
        .content{
          padding: 0 35px;
          position: relative;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);

          .reverseLinkAsButton {
            cursor: pointer;
            box-shadow: none !important;
            font-size: 12px;
            height: 35px !important;
            margin-top: -15px;

            span:first-of-type {
              margin-right: 3px !important;
            }
            .buttonIcon {
              margin-bottom: -3px !important;
            }
          }

          .buttonAndImageContainer {
            display: flex;
            justify-content: center;
            margin-top: 25px;
          }
        }

        .getStartedButton {
          height: 32px !important;
          font-size: 12px !important;
        }

        h3 {
          @extend %bodyTextSubHeading;
          margin: 5px 0 !important;
        }

        p {
          text-align: center;
          line-height: 20px;
          font-size: 15px;
        }
        .controlImg{
          width: 60%;
        }
      }

      .loyaltyProgramCard {
        position: relative;
        h3 {
          font-weight: 600;
          font-size: 21px;
          padding: 0 35px;
          max-width: 400px;
          margin: 22px auto 20px auto;

          @media screen and (max-width: $sizeMobileMax) {
            margin-bottom: 10px;
          }
        }
        .loyaltyProgramImg {
          display: block;
          margin: 30px auto 0 auto;
          width: 200px;
          height: 138px;
        }
        .newLabelImg {
          max-width: 100%;
          width: 57px;
          display: block;
          margin: -30px auto 0 auto;
        }
        .loyaltyProgramCta {
          font-size: 12px;
          height: 32px !important;
        }
        .loadingGraph {
          height: 363px;
        }
      }
    }
  }

  .loadingGraph {
    height: 392px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
