.tableRow,
.ReactVirtualized__Table__headerRow,
.ReactVirtualized__Table__row {
  cursor: default !important;

  &:hover {
    background-color: $lightGray !important;
  }

  .hideMobile {
    @media screen and (max-width: $sizeMobileMax) {
      display: none;
    }
  }

  .statisticsContainer {
    padding: 0;
  }

  th,
  td {
    box-sizing: border-box;
    white-space: normal !important;
  }

  .headerTooltip {
    display: flex;
    align-items: center;

    & svg {
      fill: #404143 !important;
      width: 35px;
    }
  }

  @media screen and (max-width: 1200px) {
    th,
    td {
      padding: 0 8px !important;
    }
  }

  @media screen and (max-width: $sizeTabletMax) {
    th,
    td {
      padding: 0 5px !important;
    }
  }

  th,
  .ReactVirtualized__Table__headerColumn {
    @extend %columnLabel;
    margin: 0 !important;
    text-align: left !important;
    background-color: $lightGray !important;
    cursor: pointer;
    color: $black !important;

    .activeHeader {
      color: $payAnywhereColor !important;
    }

    .iconHeader {
      display: inline-block;
      width: 7px;
      height: 7px;
      margin-left: 3px;

      svg {
        fill: $payAnywhereColor !important;
      }
    }

    .flipIcon {
      svg {
        transform: rotate(180deg);
      }
    }

    > span {
      @extend %columnLabel;
      display: inline-block;
      padding: 12px 0 12px 3px;
    }
  }

  td {
    @extend %bodyText;
    margin-left: 0 !important;
    text-transform: capitalize;

    > span {
      @extend %bodyText;
      display: inline-block;
      padding: 15px 0 15px 0;
    }
  }

  &.lastRow {
    border-top: 10px solid rgb(224, 224, 224);
  }
}

.ReactVirtualized__Grid__innerScrollContainer {
  .ReactVirtualized__Table__row {
    outline: none !important;

    .ReactVirtualized__Table__rowColumn {
      font-family: $primaryFontFamily !important;
      font-weight: 500;
      @extend %bodyText;
      display: inline-block;
      padding: 15px 24px 15px 24px;
      span.statusCell {
        color: $disputeError;
        text-transform: capitalize;
        text-overflow: ellipsis;
        display: inline-block;
        overflow: hidden;
        width: 100%;
      }
      div.tipCell {
        margin-right: 20px;
        .textField {
          margin-bottom: 0;
        }
      }
    }
  }
}

.ReactVirtualized__Table__row,
.ReactVirtualized__Table__headerRow {
  margin-left: 0 !important;
  border-bottom: 1px solid $lineColor;
}

.ReactVirtualized__Table__headerRow {
  background-color: $lightGray !important;
}

.ReactVirtualized__Table__headerColumn {
  padding: 10px 24px 5px 24px;
  font-size: 13px !important;
  text-transform: capitalize !important;
}

.mobile {
  .ReactVirtualized__Table__headerColumn {
    padding: 5px 5px 5px 5px;
  }
  .ReactVirtualized__Table__headerColumn:last-child {
    padding-right: 24px;
    text-align: right !important;
  }
  .ReactVirtualized__Grid__innerScrollContainer .ReactVirtualized__Table__row .ReactVirtualized__Table__rowColumn {
    padding: 10px 5px 10px 5px;
  }
}
