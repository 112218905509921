.mobileContainer, .testDriveContainer {

  .mobileToolbarButtons {
    display: flex;
    .logoutMobileUser {
      margin: 12px 20px 0px 5px;
      svg {
        color: #888C8D;
      }
    }
  }

  .mobileToolbar {
    display: flex;
    float: right;
    flex-direction: row;

    .dateButton, .filterButton {
      display: inline-block;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 100%;

      svg {
        color: $iconColor !important;
      }

    }
  }

  .titlePanel {
    width: 100%;
    box-sizing: border-box;
    font-family: $primaryFontFamily;
    background-color: $mobileTitlePanelBackgroundColor;
    margin-bottom: 15px;

    .titleText {
      width: 100%;
      color: $backgroundColor !important;
      font-family: $primaryFontFamily !important;
    }

    .titlePanelSubText, .titlePanelDetailsText {
      width: 100%;
      font-size: 13px;
      color: $payAnywhereColor !important;
    }

  }

  .reportNavigation {
    width: 100% !important;

    .reportNavItem {
      cursor: pointer;

      &:hover {
        background-color: $subNavBackgroundColor;
      }
    }
  }

  .reportsToolbar {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-around;
    color: $secondaryTextColor;
    font-family: $primaryFontFamily;
    font-size: 13.5px;
    margin-bottom: 30px;
    border-bottom: solid 1px $lineColor;

    .backButton, .mobileExportButton {
      cursor: pointer;

      &:hover {
        color: $payAnywhereColor !important;
      }
    }
  }

  .flashReportContainer, .productSalesReportContainer {
    margin-left: 10px;
    margin-right: 10px;
  }

}

.mobileNavigationContainer {
  padding-top: 10px;
  width: 100%;
  font-family: $primaryFontFamily;
  font-size: 13.5px;

  a {
    text-decoration: none;
  }

  nav {
    display: flex;

    svg {
      color: $iconColor !important;
    }

    ul {
      display: flex;

      li {
        margin: 5px 10px;
        height: 30px;

        a {
          color: $secondaryTextColor;
        }

      }

    }

  }

}

.mobileNavigationItem {
  color: $iconColor !important;
  display: block;
  text-decoration: none;
}

.mobileNavigationItem .active {
  color: $payAnywhereColor !important;
}

.mobileFooterToggle {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  color: $secondaryTextColor;
  font-size: 13.5px;

  .leftToggle, .rightToggle {
    cursor: pointer;
  }

  .active {
    color: $payAnywhereColor !important;
  }

}

.mobileExportButton {
  text-decoration: none;
  color: $secondaryTextColor;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13.5px;
}

.mobileTable {

  .tableHeader, .tableData {
    display: block;
    text-align: center;
  }

  .tableFirst {
    color: $payAnywhereColor;
  }

  .row {
    padding: 10px 0;
    border-bottom: 1px solid $cardBorderColor;
  }

  .noPaddingLeft {
    padding-left: 0 !important;
  }

  .noPaddingRight {
    padding-left: 0 !important;
  }

  .textMobile {

    @media screen and (max-width: $sizeMobileMax) {
      font-size: 10px !important;
    }

  }

}

.testDriveContainer {
  .mobileToolbarButtons {
    .filterButton {
      display: none;
    }
  }
}
