.wbCampaign {
  &Header, &Body, &Text {
    font-family: 'Archivo', sans-serif;
    color: $black;
    & h1, & h2 {
      font-family: 'Poppins', sans-serif;
    }
  }
  &Header {
    font-weight: 400;
    font-size: 15px;
    background-color: $black;
    padding: 100px 20px 0 20px;
    display: flex;
    justify-content: center;
    &Container {
      display: flex;
      flex-flow: column nowrap;
      max-width: 600px;
      & h1 {
        color: $white;
        font-size: 48px;
        padding-bottom: 40px;
        margin: 0;
        text-align: center;
      }
    }
    &Img {
      height: auto;
      width: 60%;
      max-width: 250px;
      margin-bottom: -31.5px;
    }
  }
  &Body {
    text-align: center;
    padding: 70px 50px 20px 50px;
    & h2 {
      font-family: 'Archivo';
      font-size: 21px;
      line-height: 26px;
      margin: 0 20px 0;
    }
  }
  &Text {
    font-size: 16px;
    margin: 0 0 10px;
  }
}
