.accountIntegrations {
  .pageWrap {
    @media screen and (max-width: $sizeMobileMax) {
      min-width: 100%;
      width: 100%;
    }
  }

  .legal {
    background-color: $subNavBackgroundColor;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px $lineColor;
    margin-bottom: 40px;

    .title {
      display: flex;
      flex-direction: row;

      span {
        margin-left: 5px;
        line-height: 25px;
        font-size: 15px;
      }
    }

    .text {
      padding-left: 33px;
      color: $secondaryTextColor;
      font-family: $primaryFontFamily;
      font-size: 13px;
      margin-top: 0;
    }
  }

  td {
    @media screen and (max-width: $sizeMobileMax) {
      display: block !important;
      clear: both !important;
      white-space: normal !important;
    }

    img {
      @media screen and (max-width: $sizeMobileMax) {
        display: block;
        margin: auto;
      }
    }
  }

  .quickBooks {
    display: flex;
    flex-direction: row;

    .text {
      margin-top: 5px;
    }

    .subText {
      color: $secondaryTextColor;
    }
  }
}

.giftCardPrice {
  background-color: $selectedRow;
  border-radius: 5px;
  padding: 30px 20px;
  color: $loginHeadingColor;

  & p {
    margin: 0;
    font-size: 15px;
    color: black;
  }

  & a {
    text-decoration: none;
    color: inherit;
  }
}

.giftCardSubscription {
  &Text {
    padding: 24px !important;
  }
  &Actions {
    padding: 0 24px 24px 24px !important;
  }
}

.activePlan{
  align-items: center;
  background-color: black;
  border-radius: 7px;
  box-sizing: border-box;
  color: white;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding: 8px 0;
  width: 100%;

  span {
    margin-left: 12px;
    padding-top: 2px;
  }
}
