.redux-toastr {

  .toastr {

    &.rrt-error, &.rrt-success {
      background-color: #36322E !important;
    }

    &.rrt-success {
      svg {
        margin-top: 10px;
        height: 32px !important;
        width: 32px !important;
        border-radius: 50%;
        background: radial-gradient(#fff 40%, transparent 60%) !important;
      }
    }
    &.rrt-success.successToast{
      svg{
        margin-top: 13px;
        height: 29px !important;
        width: 29px !important;
        background: none !important;
      }
    }

    .close-toastr {
      color: white;
      opacity: 1;
      font-family: $primaryFontFamily;
      font-weight: 600;
      height: auto;
      top: 17px;
      font-size: 14px;
    }

    .rrt-left-container .toastr-icon {
      fill: $paymentHubsColor !important;
    }

    .rrt-middle-container {
      @extend %contentLabel;
      color: #eeeef1;
      font-size: 12px !important;

      .rrt-text {
        word-break: break-word;
      }
    }

  }

}
