.recurringInvoice {

  margin-bottom: 60px;

  .autopayHolder {
    display: flex;
    margin-top: 20px;

    .autopayInfo {
      margin: 2px 0 0 10px;

      .autopayText {
        font-family: $primaryFontFamily !important;
        font-weight: 500;
        @extend %contentLabel;
        color: $black;
      }

      .autopayButton {
        margin-top: 20px;
      }
    }
  }

  .viewRecurringButton {
    margin-top: 20px;
  }

}