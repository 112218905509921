// Rules for sizing the icon.
.material-icons.md-18 svg {
  font-size: 18px !important;
}

.material-icons.md-24 svg {
  font-size: 24px !important;
}

.material-icons.md-36 svg {
  font-size: 36px !important;
}

.material-icons.md-48 svg {
  font-size: 48px !important;
}

.material-icons.md-inactive svg {
  opacity: 0.50;
}

.material-icons.active svg {
  color: $payAnywhereColor !important;
}

.material-icons svg {
  color: $iconColor !important;
}
