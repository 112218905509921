.login, .clientService {

  width: 100%;

  &.forgotPassword, &.resetPasswordContainer {
    margin-top: 60px;
  }

  .loginContainer {
    margin: 0 auto;
    width: 560px;
    padding: 0 20px;
    box-sizing: border-box;

    @media screen and (max-width: 560px) {
      width: 100%;
      min-width: auto;
    }

    .detailsHeaderInputField {
      height: 48px;
      input {
        height: 100%;
      }
    }

    .tokenField {
      margin-bottom: 20px;
    }

    .signUpContainer, .requestTokenContainer {
      a {
        font-family: $secondaryFontFamily;
      }

      & {
        cursor: pointer;
        font-family: $primaryFontFamily;
        color: $paymentHubsColor;
      }
    }

    .requestTokenContainer {
      margin-left: 10px;
      margin-bottom: 10px;
      font-size: 14px;
    }

    .forgotPasswordContainer {
      font-weight: normal;

      a {
        @extend %bodyText;
      }
    }

    .forgotPasswordDescription, .resetPasswordDescription, .registerMbpDescription {
      @extend %bodyText;
      color: $secondaryTextColor !important;
    }

    .registerMbpDescription {
      p {
        b {
          color: $black;
        }
      }
    }

    .signUpContainer, .requestTokenContainer, .forgotPasswordContainer {
      margin-top: 5px;

      a {
        @extend %bodyText;
        text-decoration: none;
        color: $payAnywhereColor;
      }
    }

    .loginErrorMessage {
      @extend %contentLabel;
      color: $positiveColor;
      margin-bottom: 15px;
    }

    .loadingIndicator {
      margin-bottom: 20px;
      width: 100%;
    }

    .rememberDeviceField {
      margin-bottom: 30px;

      .content {
        padding-top: 9px;
        span {
          font-size: 14px;
        }
        p {
          color: $loginTextColor;
          font-size: 14px;
          margin: 5px 0 0 0;
        }
      }
    }

    .twoFactorMessage {
      display: flex;
      font-family: $primaryFontFamily;
      color: $black;
      font-size: 14px;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;

      span {
        margin-right: 10px;
      }
    }


    .forgotPasswordContainer {
      margin-top: 25px;
      @extend %secondaryFontFamilySemiBold;

      .arrowIcon {
        svg {
          width: 10px;
          height: 10px;
          fill: $phGradientTwo;
          margin-left: 10px;
        }
      }
    }
  }

  .paymentHubsLogin {

    input[type=text], input[type=password] {
      box-sizing: border-box !important;
    }

    .loginContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
    }

    img {
      width: 301px;
      height: 39px;
      margin-bottom: 90px;
    }

    .forceResetPasswordForm {
      width: 560px;

      @media screen and (max-width: $sizeMobileMax) {
        width: inherit;
      }
    }

    .loadingIndicator, .MFASetupForm {
      width: 100%;
    }

    .MFABlock {
      width: 560px;
      min-height: 350px;
    }

    .MFASetupForm {
      min-height: 450px;

      .selectCountry .textFieldHolder{
        height: 48px;
      }

      .actionsRow {
        margin-top: 60px;
      }

      .formRow {
        margin-top: 30px;
      }
    }

    .loginForm {
      width: 100%;

      fieldset {
        margin: 0 !important;
      }

      h4 {
        font-family: $primaryFontFamily;
        font-weight: bold;
        font-size: 12px;
        margin: 0 0 10px 0;
        color: $loginTextColor;
        position: relative;
      }

      .paymentsHub2FAButton {
        width: 128px !important;
        margin: 20px 0 80px 0;
        box-shadow: none !important;
        svg {
          fill: $backgroundColor;
          height: 15px;
        }
      }

      .paymentsHub2FAButton{
        width: 158px !important;
      }

      .loginErrorMessage {
        margin-top: 10px;
      }

      .signUpLink {
        margin-bottom: 80px !important;
      }

    }

    .forceResetPasswordForm {
      width: 560px;

      @media screen and (max-width: $sizeMobileMax) {
        width: inherit;
      }
    }

    .forgotPasswordContainer, .signUpLink {
      text-align: left;
      p {
        font-family: $primaryFontFamily;
        color: $loginTextColor;
        margin: 0 0 4px 0;
        font-size: 14px;
        font-weight: 100 !important;
        -moz-osx-font-smoothing: auto;
        -webkit-font-smoothing: antialiased;
      }
      a {
        font-size: 14px !important;
        font-weight: 500 !important;
      }
    }

    .separator {
      margin: 25px 0;
      border-top: 1px solid $paymentHubsColorAlternativeColor;
    }
  }

  .disclaimerBox {
    display: flex;
    align-items: center;
    background-color: #F7F7F7;
    padding: 24px 30px;
    margin-bottom: 50px;

    @media screen and (max-width: $sizeMobileMax) {
      flex-direction: column;

      .icon {
        margin: 0 0 20px 0 !important;
      }

      .content {
        text-align: center;
        h4 {
          font-size: 16px !important;
        }

        p {
          font-size: 12px !important;
        }
      }
    }

    .icon {
      display: inline-block;
      width: 52px;
      height: 52px;
      margin: 0 20px 0 0;
      img {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }
    .content {
      h4 {
        font-family: $primaryFontFamily;
        font-weight: bold;
        font-size: 18px;
        color: $customerPaymentPrimaryColor;
        line-height: 36px;
        margin: 0;
      }
      p {
        font-size: 14px;
        line-height: 18px;
        margin: 0;
      }
      span {
        font-size: 10px;
        display: inline-block;
        margin-top: 20px;
      }
    }
  }
}

.loginButtonContainer {

  display: flex;
  align-items: center;

}

.signUpLink {
  margin-top: 90px !important;
  color: $secondaryTextColor;
  width: 400px;
  text-align: right;

}

.developersHub {
  margin-top: 10px;
  margin-bottom: 115px;
}

.paymentsHubDevelopersButton {
  box-sizing: border-box;
  display: flex;
  height: 85px;
  border-radius: 12px;
  background: $backgroundColor2;
  width: 100%;
  text-decoration: none;
  padding: 23px 20px;
  color: $black;
  justify-content: space-between;
  align-items: center;

  .iconText {
    display: flex;
    align-items: center;

    img {
      margin: 0 24px 0 0;
    }

    p {
      margin: 0;
      font-size: 14px;
      font-family: $primaryFontFamily;
    }
  }
}

.loginButton, .submitButton, .resetTokenButton {
  @extend %secondaryFontFamilySemiBold;
  height: 35px;
  border-radius: 3px;
  width: 134px;
  text-transform: uppercase;
  display: flex;
  cursor: pointer;

  .loginText, .submitText {
    vertical-align: middle;
  }

  .loginFront, .loginArrow, .submitArrow, .submitFront {
    vertical-align: middle;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loginFront, .submitFront {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    width: 100%;
    color: $backgroundColor;
    background-color: $payAnywhereColor;
  }

  .loginArrow, .submitArrow {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 35px;
    color: $backgroundColor;
    background-color: $payAnywhereAlternativeColor;
  }
}

.buttonFlex {
  display: flex;
  justify-content: center;
  flex: 1
}

.mbpMigrationError {
  @extend %bodyText;
  color: $errorColor !important;
  padding: 20px;
}

.instructionText {
  @extend %bodyText;
  margin-bottom: 80px;
}

.contactFooter {
  margin-top: 20px !important;
}
