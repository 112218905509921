.loyaltyEmail {
  display: flex;
  align-items: center;
  padding: 40px 50px;
  flex-direction: column;
  max-height: 70vh;
  background-color: $backgroundColor;
  max-width: 350px;
  overflow-y: auto;
  margin: 5vh 0;

  @media screen and (max-width: $sizeMobileMax) {
    padding: 0;
  }

  img {
    max-width: 350px;
    margin-bottom: 45px;
  }

  p, h1, h2 {
    text-align: center;
  }

  header {
    display: flex;
    align-items: center;
    flex-direction: column;

    h1 {
      font-weight: bold;
      font-size: 26px;
    }

    p {
      font-size: 16px;
    }
  }

  .grayContainer {
    display: flex;
    align-items: center;
    background-color: $loyaltyEmailGray;
    padding: 45px 35px;
    flex-direction: column;
    @media screen and (max-width: $sizeMobileMax) {
      padding: 20px 15px;
    }

    .icon {
      svg {
        height: 30px;
        width: 30px;
      }
    }

    h2 {
      font-weight: bold;
      font-size: 22px;
      margin-bottom: 4px;
    }

    .qrText {
      font-size: 16px;
      margin-bottom: 25px;
    }

    .qr {
      height: 174px;
      width: 174px;
      background-image: url('/images/loyalty/qrcode.png');
      background-position: center;
      background-color: $backgroundColor;
      background-size: contain;
      margin-bottom: 7px;
    }

    .discountCode {
      font-size: 12px;
    }
  }

  .expiration {
    border-bottom: 1px solid $receiptSeparatorColor;
    padding: 40px 105px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: $sizeMobileMax) {
      padding: 20px 25px;
    }

    .expText {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0;
    }

    .terms {
      font-size: 14px;
    }
  }

  .merchant {
    margin-bottom: 25px;
    margin-top: 40px;

    p {
      margin: 0 0 4px 0;
    }

    .name {
      color: $primaryTextColor;
      font-weight: bold;
    }

    .card, .auth {
      color: $secondaryTextColor;
    }
  }

  .social {
    text-align: center;

    ul {
      list-style: none;
      display: inline-block;
      margin: 0;
      padding-left: 0;

      li {
        display: inline-block;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }

        .icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-size: cover;
          border-radius: 50%;

        }
      }
    }
  }
  .brand {
    display: flex;
    justify-content: center;
    margin-top: 45px;
    img {
      width: 156px;
      height: 20px;
    }
  }
}
