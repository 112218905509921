.notificationDialog {
  > div {
    > div {
      > div {
        border-radius: 15px !important;
      }
    }
  }
}

.notificationDialogTitle {
  display: inlineBlock !important;
  padding-bottom: 0px !important;
}

.notificationDialogTitleActionsContainer {

  .raisedButton {
    margin-right: 10px !important;
  }

  @media screen and (max-width: $sizeMobileMax) {
    display: block !important;
  }
}

.getStartedHomebase {

  margin-right: 30px !important;
  color: white !important;

}

.titleButton {
  margin-right: 30px !important;
  color: white !important;

  span {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }

}

.trialNotificationActions {
  display: flex;
}

.notificationContentTitle {
  @extend %body;
  display: flex;
  margin-left: 39px;
  margin-bottom: 30px;
  color: black;

  a {
    padding-left: 10px;
    font-weight: 500;
  }

  .icon {

    svg {
      width: 20px !important;
    }
  }
}

.notificationMessage1, .notificationMessage2, .notificationMessage3 {
  font-size: 14px;
  margin: 35px;

}

.notificationMessage1 {
  margin-top: 10px;
  margin-bottom: 20px;
}

.notificationMessage2 {
  margin-top: 5px;
  margin-bottom: 20px;
}

.notificationMessage3 {
  margin-top: 5px;
  margin-bottom: 10px;
}

