.paymentDialog {

  .iconHeading {
    display: flex;

    .headingHolder {
      margin-left: 10px;

      .heading {
        @extend %subheading;
        margin: 0 0 5px 0;
      }
    }
  }

  .iconHeading, .confirmation {
    padding: 24px;
  }

  .mainContent {
    max-height: 65vh;
    overflow: auto;
    padding: 0 24px;
  }

  .detailTotals {

    padding: 0;

    .totalsContainer {

      margin-top: 55px;

      .cartDiscounts {
        margin-bottom: 0;
      }

      .details {

        display: flex;
        margin-top: 0;
        margin-bottom: 0.6em;
        @extend %bodyText;

        &Total {

          font-family: $primaryFontFamily !important;
          font-weight: 600;

          .totalLimit {
            @extend %contentLabel;

            &.error {
              color: $errorColor;
            }
          }

        }

        .number {
          margin-left: auto;
        }
      }

    }


  }

  .itemizedOrCustomTabs {

    border-bottom: 1px solid $lineColor;
    margin: 0.5em 0 3em;

    .tab {

      display: inline-block;
      width: 50%;
      text-align: center;
      cursor: pointer;
      padding-bottom: 0.8em;
      @extend %bodyText;

      &.customDisabled {
        color: $gray !important;
        cursor: not-allowed;
      }

    }

  }

  .refundTextField {
    margin: 1.5em 0 0em;
  }

  .mainContent {

    .itemizedRefundContent {

      .refundAmountTitle {
        text-align: right;
        margin: 0 0 0.6em 0;
        @extend %contentLabel;
      }

    }

    .customRefundContent {

      .customRefundTitle {

        @extend %bodyText;

        .customRefundLimit {

          @extend %contentLabel;

          &.error {
            color: $errorColor;
          }

        }

      }

      .optionalRestock {
        @extend %bodyText;
        margin: 4.6em 0 1.6em;
      }

    }

    .previousPartialRefunds {

      margin-bottom: 2.6em;

      .partialRefundsLabel {
        @extend %contentLabel;
        margin-bottom: 0.6em;
      }

      .partialRefundAmount {
        @extend %bodyText;
      }

    }

  }

  .formErrorMessage {
    color: $errorColor;
    padding: 0 24px;
  }

}

.twoFATermsDialog {
  .headerBody {
    color: $primaryTextColor;
    font-weight: 400;
    font-size: 14px;
    margin: 0 0 30px;
  }
  .processFinished {
    &Success, &Failure {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      .headerBody {
        margin-top: 12px;
      }
    }
    &Success {
      svg {
        width: 36px;
      }
    }
    &Failure {
      svg {
        fill: $negativeColor;
        transform: rotate(45deg);
      }
    }
  }
  .dialogActions {
    padding: 25px !important;
    display: flex !important;
    flex-direction: row;
    justify-content: flex-end;

    .resendCodeButton {
      margin-right: auto !important;
      &.shrink span {
        font-size: 12px !important;
      }
    }

  }
}

.messageDialog .refundDialogBody {
  max-height: calc(100vh - 300px) !important;
  padding: 0 0 30px !important;
}
