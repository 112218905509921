.flashReportContainer, .productSalesReportContainer, .depositTransactionsReport {
  @extend %bodyText;

  .reportHeaderContainer {
    border-bottom: solid 1px $lineColor;
    width: 100%;

    .reportHeader {
      display: inline-block;
      font-size: 12px;
    }

  }

  .totalColumn, .soldColumn, .averageColumn {
    text-align: right;
  }

  .nameColumn, .soldColumn, .totalColumn, .averageColumn {
    display: inline-block;
  }

  .sectionHeader, .sectionRow {
    color: $primaryTextColor;
  }

  .subtotal, .sectionHeader, .bottomRow {
    padding: 10px 0 10px 0;
  }

  .subtotal, .bottomRow {
    border-bottom: solid 1px $lineColor;
  }

  .topRow {
    padding-top: 10px;
  }

  .grandTotal {
    margin-top: 10px;
    padding: 10px 0 10px 0;
    border-top: solid 1px $lineColor;
    color: $primaryTextColor;
    font-size: 19px;
  }

  .subtotal.paymentMethods {
    border-bottom: none;
  }

}

.flashReportContainer {

  .nameColumn {
    width: 60%;

  }

  .soldColumn {
    width: 15%;
  }

  .totalColumn {
    width: 20%;
  }

  .reportMid {
    padding: 3px 0 0 79px;
    @extend %contentLabel;
  }

}

.giftCardSummaryContainer {

  .reportMid {
    padding: 3px 0 0 79px;
    @extend %contentLabel;
  }

  .reportDBA {
    padding: 3px 0 0 79px;
    @extend %contentLabel;
  }

}

.productSalesReportContainer {

  .nameColumn {
    width: 50%;
  }

  .averageColumn {
    width: 15%;
  }

  .soldColumn {
    width: 15%;
  }

  .totalColumn {
    width: 20%;
  }

  .exportContainer.isMobile {
    top: -20px;

    @media screen and (max-width: $sizeMobileMaxPortrait) {
      margin-top: 30px;
    }
  }

}

.reportDetail .noDataMessage {
  @extend %bodyText;
  margin: 77px 48px 0 0;
  width: auto;
}

.exportContainer {

  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  position: relative;
  top: -42px;
  font-family: $primaryFontFamily;
  font-weight: 500;
  padding: 0 48px 0 0;

  .reportNavIcon {

    svg {
      fill: $secondaryTextColor !important;
      color: $secondaryTextColor !important;
      height: 60px !important;
      width: 50px !important;
    }

  }

  .exportItemHeader {
    font-family: $secondaryFontFamily;
    font-size: 38px;
    color: $primaryTextColor;
  }

  .noDataMessage {
    margin: 122px 0 0 0;
    width: 100%;
  }

}

.depositTransactionsDetail {
  width: 100%;
  text-align: center;
  position: relative;

  .depositTransactionLink {
    color: $payAnywhereColor;
    cursor: pointer;
  }

  .depositType {

    span {
      display: inline-block;
      vertical-align: middle;
    }

    svg {
      position: relative;
      margin-right: 7px;
    }

  }

}

.depositTransactionsReport {
  max-width: 80vw;
  text-transform: capitalize;
  font-size: 12px;
  font-family: $primaryFontFamily;
  font-weight: 500;

  .exportContainer {
    position: absolute;
    top: 35px;
    right: 0;
  }

  .noDataMessage {
    text-transform: none !important;
  }
}

.depositTransactionsReportDialog.modal {
  .title {
    margin: 40px 12px 24px;
  }
}

.accountSummaryReportContainer {

  width: 100%;

  @media screen and (max-width: $sizeMobileMaxPortrait) {

    .exportContainer {
      top: 10px;
    }

    .mobileScrollNote {
      margin-top: 29px;
    }

  }

  .mobileScrollNote {
    @media screen and (max-width: 1630px) {
      display: flex;
    }
  }

  .outerTableContainer {

    overflow: auto;

    .innerTableContainer {
      min-width: 1300px;
    }

  }

  .accountSummaryRow {
    display: inline-flex;
    width: 100%;

    .accountSummaryColumn {
      width: 14%;
    }

  }
}

.employeeListTable {
  width: 100%;

  .employeeReportRow {
    width: 100%;
    cursor: pointer;

    td {
      @extend %bodyText;
      height: auto !important;
      padding: 18px 24px 14px;
      font-family: $primaryFontFamily;
      font-weight: 500;

      > * {
        pointer-events: none;
      }

      &:first-of-type {
        padding-left: 48px !important;
      }

      &:last-of-type {
        padding-right: 48px !important;
      }

      @media screen and (max-width: $sizeMobileMax) {
        padding: 18px 16px 14px !important;
      }

    }

    &:hover {
      background-color: $selectedRow !important;
    }

    .employeeRole {
      float: right;
    }

    .employeeStatus {
      .active {
        color: $positiveColor;
      }

      .inactive {
        color: $negativeColor;
      }
    }
  }

}

.employeeFlashReport {

  .selectEmployee {
    @extend %bodyText;
    padding: 3px 0 0 79px;
  }

  .toolbar {

    @media screen and (max-width: $sizeMobileMax) {
      border-bottom: none;
    }

  }

  div:nth-of-type(4) > div {
    overflow: auto !important;
  }

  .mobileScrollNote {
    border-bottom: 1px solid #dddddd;
  }

  .searchBar {
    width: 100%;
  }

  .searchBarContainer .textFieldContainer {
    margin-left: 39px;
  }

  .employeeName {
    color: $primaryTextColor;
    padding: 3px 0 0 79px;

    @media screen and (max-width: $sizeMobileMax) {
      padding: 3px 0 0;
    }
  }

}

.employeeReportPopover {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 6px, rgba(0, 0, 0, 0.02) 0px 1px 4px !important;
  border-radius: 10px !important;
}

.employeeReportMenuItem {

  @extend %bodyText;
  font-family: $primaryFontFamily !important;
  font-weight: 500;
  fill: $payAnywhereColor !important;
  color: $payAnywhereColor !important;

  svg {
    position: relative;
    top: 2px;
    margin-right: 10px;
  }

  div {
    display: inline;
  }

  > div > div {
    padding: 0 28px !important;
  }
}

.reportsInfoDialog {

  > div {
    padding: 15px;
  }

  .reportsInfoTitle {
    @extend %subheading;
  }

  h4, p {
    @extend %bodyText;
  }

  h4 {
    font-family: $primaryFontFamily !important;
    font-weight: 600;
  }

  p {
    margin: 5px 0 30px;
  }

  .raisedButton {
    margin: 0 7px 7px 0;
  }

}

.customReportDialog {
  .reportColumns {
    overflow-y: scroll;
    max-height: 190px;
    padding-left: 16px;
  }
}

.customReport {
  .actions > div {
    cursor: pointer;
    margin-right: 15px;
    display: flex;
    align-items: center;
    > svg {
      width: 20px ;
      margin-right: 6px;
    }
  }
}
