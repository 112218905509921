.detailList {

  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
  overflow-y: auto;
  max-height: 550px;

  &.withItemCartHandlers {

    .detailItem {

      display: flex;
      margin-bottom: 15px;

      .itemMainContainer {
        margin-bottom: 0px;
        flex-grow: 1;
      }

      .itemModifyOptions {

        display: flex;
        opacity: 0;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
        box-sizing: border-box;
        width: 28px;
        flex-grow: 0;

        .itemModifyButton {

          width: 18px !important;
          height: 18px !important;
          margin: 6px 0 !important;
          padding: 2px !important;

          svg {
            width: 11px !important;
            height: 11px !important;
          }
        }

      }

      &:hover {

        .itemMainContainer {
          background-color: #e6e8ea;
        }

        .itemModifyOptions {
          opacity: 1;
        }

      }

      &.regularItem:hover {

        .itemMainContainer {
          cursor: pointer;
        }

      }

    }

  }

  .detailItem {

    .itemMainContainer {

      display: flex;
      align-items: center;
      border-radius: 5px;
      background-color: $lightGray;
      padding: 20px 15px;
      margin-bottom: 15px;

      .itemThumbnailContainer {

        position: relative;

        .itemQuantity {
          box-sizing: border-box;
          min-width: 30px;
          min-height: 30px;
          padding: 3px 3px 0;
          margin: 0;
          position: absolute;
          top: -10px;
          right: -10px;
          background: $paymentHubsColor;
          color: #ffffff;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .discountIcon {

          padding: 0 !important;
          width: 50px !important;
          height: 50px !important;
          margin-right: 0 !important;
          cursor: default !important;

        }

      }

      .itemInfo {
        display: flex;
        margin-left: 15px;
        flex-direction: column;
        width: 100%;

        &.disabled {
          .name, .specifics {
            color: $gray !important;
          }
        }

        .name {
          display: flex;
          align-items: center;
          @extend %bodyText;
          margin-bottom: 10px;
          position: relative;

          .text {
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 330px;

            @media screen and (max-width: $sizeDesktopMin) {
              max-width: 250px;
            }

            @media screen and (max-width: 650px) {
              max-width: 110px;
            }
          }

          .number {
            margin-left: auto;
            word-break: keep-all;
          }

          .taxed {
            position: absolute;
            right: -11px;
            font-size: 10px;
            padding-bottom: 2px;
          }

        }

        .specifics {
          display: flex;
          @extend %contentLabel;

          .text {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .number {
            margin-left: auto;
          }
        }
      }
    }

    .itemRefundOptions {

      margin: 0 7% 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      .quantityButton {
        width: 18px !important;
        height: 18px !important;
        margin: 0 !important;
        padding: 2px !important;
        position: absolute;
        top: 40px;

        &:first-child {
          left: -15px;
        }

        &:last-child {
          right: -15px;
        }

        svg {
          width: 10px !important;
          height: 10px !important;
        }
      }

      .refundQuantitySelector, .restockQuantitySelector {
        position: relative;
      }

      .lineItemRefundQuantity, .lineItemRestockQuantity {
        box-sizing: border-box;
        padding: 0;
        margin: 0 14px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 65px;

        .textFieldInfo {
          text-align: center;

          label {
            padding: 0;
          }
        }

        .textFieldHolder {
          max-width: 65px;;
          padding: 0 10px;
          height: 58px !important;
          box-sizing: border-box;

          input {
            text-align: center;
          }

          + span { //Error message
            position: absolute;
            width: 12em;
            bottom: -36px;
            left: -31px;
            padding: 0;
          }

        }

      }

    }

  }

}
