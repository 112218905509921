.exportSelector {
  display: flex;
  align-items: center;
  font-family: $primaryFontFamily;
  font-weight: bold;
  color: $payAnywhereColor;

  .label {
    cursor: pointer;
  }

  .exportButtons {
    display: flex;
    width: 100px;
    align-items: center;

    .label {
      @extend %bodyText;
      color: $payAnywhereColor;
      font-weight: normal;
      padding-top: 3px;
      padding-left: 10px;
      position: absolute;
      left: 30px;
    }
  }

  .exportIconWrapper {
    svg {
      padding-top: 5px;
      fill: $payAnywhereColor;
      transform: inherit;
      -webkit-transform: revert; /* safari 6.0 - 9.0 */
      transform: revert;
    }
  }

  .exportLabelContainer {
    padding: 16px 0px 16px 0px;
  }
}

.exportProgress {
  justify-content: center;
  display: flex;
}

