.termsContainer {
  border-top: 1px solid #DDDDDD;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .userAgree, .termsAgree {
    width: 94%;
    margin: 0 3%;
  }

  .termsHeader, .content, .termsFooter {
    margin: 15px;
    background-color: #FFFFFF;
    width: 60%;
    padding: 0 20px;

    & > * {
      margin: 0px;
    }
  }

  .content {
    height: 400px;
    overflow: auto;

    textarea {
      color: $secondaryTextColor !important;
      @extend %tertiaryFontFamilyRegular;
      font-size: 11px !important;
    }
  }

  .termsHeader {
    margin-top: 30px;
    margin-bottom: 0;

    .headingText {
      @extend %heading1;
    }

    p {
      @extend %tertiaryFontFamilyRegular;
      color: $primaryTextColor !important;
      font-weight: 200 !important;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .termsFooter {
    div label {
      @extend %tertiaryFontFamilyRegular;
      font-size: 12px;
    }
  }

  .fdContainer {
    margin: 15px;
    background-color: #FFFFFF;
    width: 60%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fdText {
    font-size: 24px;
  }

  .content {
    border: 1px solid #DDDDDD;
    border-radius: 5px;
  }

  .tableBorder {
    border: 1px solid #000000;
  }

  .tableBackground {
    background-color: silver;
    font-size: 18px;
    text-align: center;
  }

  .userCheckbox {
    stroke: none !important;
  }

}

.nonAuthTerms {
  height: 100vh;
  overflow-y: scroll;
  padding: 0 45px;

  .larger {
    font-size: 1.2em;
  }

}

.termsContainer a, .nonAuthTerms a {
  word-wrap: break-word;
}

.fd {

  &Container {
    margin: 15px;
    background-color: #FFFFFF;
    width: 60%;
    padding: 0 20px;
  }


}
