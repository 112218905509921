.customCheckbox {
  display: inline-block;

  label {
    line-height: 25px !important;
    width: auto !important;
    @extend %columnLabel;
  }

  svg + svg {
    fill: $payAnywhereColor !important;
    stroke: none !important;
    color: $payAnywhereColor !important;
  }
}
