.toolbar {
  display: flex;
  border-bottom: 1px solid $lineColor;
  @media screen and (max-width: $sizeMobileMax) {
    display: inline-block;
  }

  .logoBar {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $lineColor;
    width: 170px;
    margin: 10px 0;
    flex-shrink: 0;

    h1 {
      margin: 0;

      .logo {
        display: inline-block;
        background-image: url('/images/payanywhere/logoSmall.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 51px;
        height: 35px;
      }
    }
  }

  .searchBar {
    width: 100%;
    flex-shrink: 0;

    @media screen and (max-width: $sizeDesktopMax) {
      &.hasOptions {
        height: 120px;
      }
    }


    &Logo {
      width: calc(100vw - 240px);
    }

    @media screen and (max-width: $sizeTabletMax) {
      width: calc(100vw - 70px);
    }

    @media screen and (max-width: $sizeMobileMax) {
      width: auto;
    }

    .searchBarContainer {
      padding: 0;
      list-style: none;
      display: flex;
      height: 62px;
      align-items: center;

      .textFieldContainer {
        display: flex;
        align-items: center;
        height: 100%;
        flex: 1;

        .textFieldHolder {
          border: 0;
        }

        .searchIcon {
          margin-left: 15px;
        }

        .textField {
          width: 100% !important;
          margin-left: 17px;
          flex-grow: 2;
          margin-bottom: 0 !important;
          font-family: $primaryFontFamily !important;
          font-weight: 500;
        }

      }

      .clearButton {
        width: 80px !important;
        padding: 0 !important;

        @media screen and (max-width: $sizeMobileMax) {
          width: 30px !important;
          position: absolute !important;
          left: 193px;
        }
      }

    }
  }

}

.customerDialogSearch {

  .searchBar {
    width: auto !important;
  }

  .clearButton {
    width: 80px !important;

    @media screen and (max-width: $sizeMobileMax) {
      padding: 0 !important;
      width: 30px !important;
      position: absolute !important;
      left: 193px;
    }
  }
}

.searchAccessory {
  display: flex;
  align-items: center;
  margin-left: auto;

  .exportButton {
    width: 110px;
  }

  .filterButton {
    display: none;

    svg {
      fill: $iconColor !important;
    }

    @media screen and (max-width: $sizeMobileMax) {
      display: block;
      width: 35px !important;
    }
  }

  .deleteItemsButton {
    .flatButton {
      min-width: max-content !important;
    }
  }

  .importCsvButton {
    margin-right: 10px !important;
  }

  .stageButton {

    button {
      height: 48px !important;
    }
  }

  .addButton {
    background-color: $payAnywhereColor;
    width: 104px;
    height: 36px !important;

    @media screen and (max-width: $sizeMobileMax) {
      width: 50px !important;
      min-width: 50px !important;
      margin-left: 20px !important;
    }
  }

  .deleteButton {
    margin-right: 12px;

    button {
      max-width: none !important
    }
  }

}

.allAccountsContainer {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 11px;
  @extend %heading3;

  @media screen and (max-width: $sizeMobileMax) {
    width: auto;
  }

  .allAccountsCheckbox {
    top: 0;

    label {
      color: $primaryTextColor !important;
      font-family: $primaryFontFamily !important;
      font-weight: 500;
      font-size: 13px;
      bottom: -2px;
      text-transform: none;

      @media screen and (max-width: $sizeMobileMax) {
        display: none !important;
      }

    }

  }

  .allAccountsCheckbox.selected {
    label {
      color: $payAnywhereColor !important;
    }
  }
}


