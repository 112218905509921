.optionsList {
  font-family: $primaryFontFamily;
  font-weight: 500;
  list-style: none;
  margin: 25px 0;
  padding: 0;

  &Item {
    @extend %bodyText;
    border-bottom: 1px solid $lineColor;
    margin: 7px 0;
    cursor: pointer;
    padding: 10px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    .optionsHolder {
      display: flex;
      align-items: center;

      span {
        color: $payAnywhereColor;
        margin-right: 10px;
        line-height: 27px;

        a {
          text-decoration: none;
          color: $payAnywhereColor;
        }

        svg {
          fill: $payAnywhereColor;
          height: 20px !important;
        }

        &:first-child {
          width: 14px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
