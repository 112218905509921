.privacyPolicy {

  margin: 100px auto;
  width: 80%;
  max-width: 1200px;

  .title {
    @extend %heading;
    text-align: center;
    margin: 3em 0;
  }

  .subtitle, .inlineSubtitle {
    @extend %bodyText;
    font-family: $primaryFontFamily !important;
    font-weight: 600;
  }

  .subtitle, p, ul {
    margin: 1.6em 0;
  }

  p, li {
    @extend %bodyText;
  }

  li {
    margin-bottom: 0.8em;
  }

  a {
    color: $paymentHubsColor;
  }

  .subsection {
    margin-bottom: 4em;
  }

}