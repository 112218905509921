//Default Message Dialogs

.messageDialog {

  > div:first-of-type {
    border-radius: 15px !important;

    @media screen and (max-width: 600px) {
      padding: 10px;
    }

  }

  .MuiDialog-paper {
    width: 100%;

  }

  .dialogTitle {
    text-transform: capitalize;
    @extend %subheading;
  }

  .dialogBody {
    border: 0 !important;
    @extend %bodyText;
    color: $primaryTextColor !important;
    padding-bottom: 52px !important;
  }

  .dialogActions {

    &MerchantVerifyBCS, &PaymentMethods {
       padding: 24px !important;
    }

    .raisedButton {
      margin-left: 4px;
    }

  }

  .closeIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

}

//Custom

.dialogBody {
  &.editBusinessProfile{
    padding-bottom: 24px!important;
  }
}
.dialogTitle {
  text-transform: capitalize;
  @extend %subheading;
}

.dialogBody.apiPasswordContent, .dialogBody.apiResetPasswordContent, .dialogBody.removeMIDContent {
  .credential {
    .dataValue {
      word-break: break-word;
      flex: 3;
      padding-left: 10px;
      padding-right: 15px;
    }
  }

  .end {
    flex: 1;
    text-align: end;
  }

  .apiPasswordText, .removeMID {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 29px;

    .importantText {
      color: $errorColor;
      text-transform: uppercase;
    }
  }
  .showPassword {
    border: 2px solid $paymentHubsColor !important; // it's needed since  in global styles border is set with none
    &:hover {
      background: none !important; // it's needed since  in global styles background is set as gray
    }
  }
  .credential {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 19px;
    background: $backgroundColor3 0% 0% no-repeat padding-box;
    border-radius: 30px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .data, .copyIcon {
      display: inline-flex;
      align-items: center;
    }

    .copyIcon {
      cursor: pointer;
    }

    .dataKey {
      color: $navyBlue;
    }
  }
}

.messageDialog.apiCredentialDialog, .messageDialog.apiResetCredentialDialog, .messageDialog.removeMIDDialog{
  .title-label {
    justify-content: center;
  }
}

.messageDialog.loyaltyAddOnDialog {
  .MuiDialog-paper {
    max-width: 475px;
  }

  .dialogBody {
    padding-bottom: 0 !important;
  }

  .title-label, .dialogActions {
    justify-content: center;
  }

}
